import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Routes, Navigate } from "react-router-dom";
// Layouts
import PlaygroundLayout from "../layouts/Playground";
// Pages
import Webcomponents from "pages/webcomponents";
import IntegrationGuide from "pages/documentation/IntegrationGuide";
import ReleaseHistory from "pages/documentation/ReleaseHistory";
import Social from "../pages/social/Social";
import Profile from "../pages/profile/Profile";
import NoDataForMarket from "pages/documentation/components/NoDataForMarket";
import OddsSingleEventInput from "pages/documentation/components/OddsSingleEventInput";
import OddsMultiEventInput from "pages/documentation/components/OddsMultiEventInput";
import OddsMultiEvent from "pages/documentation/components/OddsMultiEvent";
import OddsOutcomes from "pages/documentation/components/OddsOutcomes";
import SocialProfileCard from "pages/documentation/components/SocialProfileCard";
import SocialProfile from "pages/documentation/components/SocialProfile";
import SocialBettor from "pages/documentation/components/SocialBettor";
import CountryLeaguesSearch from "pages/documentation/components/CountryLeaguesSearch";
import MyBets from "pages/documentation/components/MyBets";
import BetSlipConfirmation from "pages/documentation/components/BetSlipConfirmation";
import FooterNavigation from "pages/documentation/components/FooterNavigation";
import BetSlip from "pages/documentation/components/BetSlip";
import ModalGeneric from "pages/documentation/components/ModalGeneric";
import UnauthenticatedUserModal from "pages/documentation/components/UnauthenticatedUserModal";
const Router = () => {
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(Navigate, { to: "/integration-guide" }) }), _jsx(Route, { path: "/webcomponents", element: _jsx(Webcomponents, {}) }), _jsxs(Route, Object.assign({ element: _jsx(PlaygroundLayout, {}) }, { children: [_jsx(Route, { path: "/integration-guide", element: _jsx(IntegrationGuide, {}) }), _jsx(Route, { path: "/release-history", element: _jsx(ReleaseHistory, {}) }), _jsx(Route, { path: "no-data-for-market", element: _jsx(NoDataForMarket, {}) }), _jsx(Route, { path: "odds-single-event-input", element: _jsx(OddsSingleEventInput, {}) }), _jsx(Route, { path: "odds-multi-event-input", element: _jsx(OddsMultiEventInput, {}) }), _jsx(Route, { path: "odds-multi-event", element: _jsx(OddsMultiEvent, {}) }), _jsx(Route, { path: "odds-outcomes", element: _jsx(OddsOutcomes, {}) }), _jsx(Route, { path: "social-profile-card", element: _jsx(SocialProfileCard, {}) }), _jsx(Route, { path: "social-profile", element: _jsx(SocialProfile, {}) }), _jsx(Route, { path: "social-bettor", element: _jsx(SocialBettor, {}) }), _jsx(Route, { path: "country-leagues-search", element: _jsx(CountryLeaguesSearch, {}) }), _jsx(Route, { path: "my-bets", element: _jsx(MyBets, {}) }), _jsx(Route, { path: "bet-slip-confirmation", element: _jsx(BetSlipConfirmation, {}) }), _jsx(Route, { path: "bet-slip", element: _jsx(BetSlip, {}) }), _jsx(Route, { path: "footer-navigation", element: _jsx(FooterNavigation, {}) }), _jsx(Route, { path: "modal-generic", element: _jsx(ModalGeneric, {}) }), _jsx(Route, { path: "unauthenticated-user-modal", element: _jsx(UnauthenticatedUserModal, {}) })] })), _jsx(Route, { path: "/social", element: _jsx(Social, {}) }), _jsx(Route, { path: "/profile", element: _jsx(Profile, {}) })] }));
};
export default Router;
