// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ARUWHrw9Yq1S1Q_gtSf7{font-size:13px;border-radius:2px;padding:5px;cursor:pointer}html[data-theme=dark] .ARUWHrw9Yq1S1Q_gtSf7{color:var(--darkColor);background-color:var(--darkMediumGrey)}.FVEAlcdiiTRD33yjNxe6{display:flex;color:#8a8989;font-size:10px;justify-content:right;cursor:pointer}html[data-theme=dark] .FVEAlcdiiTRD33yjNxe6{color:var(--darkColor)}.FVEAlcdiiTRD33yjNxe6 span{margin-left:3px;font-size:15px;margin-top:-4.7px}`, "",{"version":3,"sources":["webpack://./src/components/composed/oddsMultiEvent/OddsMultiEvent.module.scss"],"names":[],"mappings":"AAEA,sBACE,cAAA,CACA,iBAAA,CACA,WAAA,CACA,cAAA,CACA,4CACE,sBAAA,CACA,sCAAA,CAIJ,sBACE,YAAA,CACA,aAAA,CACA,cAAA,CACA,qBAAA,CACA,cAAA,CACA,4CACE,sBAAA,CAEF,2BACE,eAAA,CACA,cAAA,CACA,iBAAA","sourcesContent":["@import \"../../../assets/styles/variables.scss\";\n\n.oddsRow {\n  font-size: 13px;\n  border-radius: 2px;\n  padding: 5px;\n  cursor: pointer;\n  html[data-theme=\"dark\"] & {\n    color: var(--darkColor);\n    background-color: var(--darkMediumGrey);\n  }\n}\n\n.oddsMarketCount {\n  display: flex;\n  color: #8a8989;\n  font-size: 10px;\n  justify-content: right;\n  cursor: pointer;\n  html[data-theme=\"dark\"] & {\n    color: var(--darkColor);\n  }\n  span {\n    margin-left: 3px;\n    font-size: 15px;\n    margin-top: -4.7px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"oddsRow": `ARUWHrw9Yq1S1Q_gtSf7`,
	"oddsMarketCount": `FVEAlcdiiTRD33yjNxe6`
};
export default ___CSS_LOADER_EXPORT___;
