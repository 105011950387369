import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col, Skeleton } from "antd";
import clsx from "clsx";
// styles
import styles from "./Skeleton.module.scss";
const SkeletonBetSlipConfirmation = () => {
    return (_jsx("div", Object.assign({ className: styles.wrapper }, { children: [1, 2].map((n) => {
            return (_jsxs("div", Object.assign({ className: styles.card }, { children: [_jsxs(Row, Object.assign({ gutter: [16, 8] }, { children: [_jsx(Col, Object.assign({ span: 24 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", className: clsx(styles.paddingTopSmall, styles.maxHeightLarge) }) })), _jsx(Col, Object.assign({ span: 24 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightSmall }) })), _jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightLarge }) })), _jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightLarge }) }))] })), _jsx("div", { style: { minWidth: "100%", borderTop: "2px solid #f2f2f2", margin: "10px 0px" } }), _jsxs(Row, Object.assign({ gutter: [16, 8] }, { children: [_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightLarge }) })), _jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightLarge }) }))] })), _jsx("div", { style: { minWidth: "100%", borderTop: "2px solid #f2f2f2", margin: "8px 0px" } }), _jsxs(Row, Object.assign({ gutter: [16, 8] }, { children: [_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightSmall }) })), _jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightSmall }) }))] }))] }), `skeleton-betslip-confirmation-${n}`));
        }) })));
};
export default SkeletonBetSlipConfirmation;
