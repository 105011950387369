import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Row, Col, Button, Input, Space } from "antd";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
// document details of inner components
import { documentDetails as singleEventInputDocumentDetails } from "pages/documentation/components/OddsSingleEventInput";
import { documentDetails as noDataForMarketDocumentDetails } from "pages/documentation/components/NoDataForMarket";
const OddsOutcomes = () => {
    const [seriesId, setSeriesId] = React.useState(); // 620750
    const [eventId, setEventId] = React.useState();
    const [documentDetails, setDocumentDetails] = React.useState({});
    const [selectedTab, setSelectedTab] = React.useState("1");
    const updateComponent = (component) => {
        if (component === "eSportsInput") {
            const value = document.getElementById("eSportsInput").value;
            setSeriesId(value);
            setSelectedTab("2");
        }
        else if (component === "sportsInput") {
            const value = document.getElementById("sportsInput").value;
            setEventId(value);
            setSelectedTab("1");
        }
    };
    const InteractiveInputs = () => {
        return (_jsxs(Row, Object.assign({ justify: "start", align: "top", gutter: [30, 10] }, { children: [_jsxs(Col, Object.assign({ xs: 20, sm: 20, md: 10, lg: 6 }, { children: [_jsx("label", { children: "Event id for sports" }), _jsxs(Space.Compact, Object.assign({ style: { width: "100%" } }, { children: [_jsx(Input, { id: "sportsInput", defaultValue: eventId, size: "large" }), _jsx(Button, Object.assign({ type: "primary", size: "large", onClick: () => updateComponent("sportsInput") }, { children: "Submit" }))] }))] })), _jsxs(Col, Object.assign({ xs: 20, sm: 20, md: 10, lg: 6 }, { children: [_jsx("label", { children: "Series id for eSports" }), _jsxs(Space.Compact, Object.assign({ style: { width: "100%" } }, { children: [_jsx(Input, { id: "eSportsInput", defaultValue: seriesId, size: "large" }), _jsx(Button, Object.assign({ type: "primary", size: "large", onClick: () => updateComponent("eSportsInput") }, { children: "Submit" }))] }))] }))] })));
    };
    React.useEffect(() => {
        setDocumentDetails({
            title: "Odds outcomes",
            component: "esports-odds-outcomes",
            description: `The outcomes component can render eSports and/or sports information.
        It is a composed component, that presents match tabs, when dealing with eSports,
        or only odds within its related markets when processing sports.`,
            dependencies: [
                "esports-odds-singleevent-input"
            ],
            attributes: [
                {
                    key: "1",
                    attr: "id",
                    type: "string",
                    notes: ["It is recommended to pass a unique identifier.",
                        "TODO: If id is not informed, then we should create an internal UUID"
                    ]
                },
                {
                    key: "2",
                    attr: "product-id",
                    type: "number",
                    notes: [
                        "1 - sports",
                        "2 - eSports"
                    ]
                },
                {
                    key: "3",
                    attr: "series-id",
                    type: "number",
                    notes: ["this is the series identifier."]
                },
                {
                    key: "4",
                    attr: "no-data-options",
                    type: "object{} optional",
                    notes: [
                        "Custom no data options.",
                        `{
            message:"No Events Available at the moment",
            src:"https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg",
            cta:{JSON.stringify(label: "Go To Sportsbook",url: "/")}
          }`,
                        `Default value is:           
            {
              message:"No Events Available at the moment",
              src:"https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg",
            }`
                    ]
                }
            ],
            events: [
                {
                    event: "odds-outcomes-details",
                    type: "emit",
                    data: [
                        `This emit, sends the following information back to its parent.`,
                        `detail: {
              product: <value>,
              seriesId: <value>,
              matchId: <value>,
              marketId: <value>,
              selectionId: <value>,
              label: <value>,
              value: <value>
          }`
                    ]
                },
                {
                    event: "odds-singleevent-input-details",
                    type: "listener",
                    data: [
                        `Each time an input is clicked, its event gets emitted to this component,
              to be used if necessary by the odds-outcomes-details.`,
                        `{
              productId: <value>,
              seriesId: <value>,
              matchId: <value>,
              marketId: <value>,
              selectionId: <value>,
              label: <value>,
              value: <value>
          }`
                    ]
                },
                {
                    event: "nodata-cta-event-details",
                    type: "listener",
                    data: [
                        "When a user clicks on the button, this event gets emitted.",
                        `detail : {
              url: <value>
            }`
                    ]
                }
            ],
            innerComponents: [
                {
                    name: "esports-odds-singleevent-input",
                    documentDetails: singleEventInputDocumentDetails
                },
                {
                    name: "esports-nodata-for-market",
                    documentDetails: noDataForMarketDocumentDetails
                }
            ],
            example: `<esports-odds-outcomes
        id={id}
        product-id={1 || 2}
        series-id={seriesId}
        no-data-options={JSON.stringify({
          message: "No Events Available at the moment",
          src: "https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg",
          cta: { label: "Go To Sportbook", url: "/" }
        })}
      />`,
            interactiveInputs: _jsx(InteractiveInputs, {}),
            activeKey: selectedTab,
            tabs: [
                {
                    key: "2",
                    label: "eSports",
                    children: _jsx("esports-odds-outcomes", { id: 2, "product-id": 2, "series-id": seriesId, "no-data-options": JSON.stringify({
                            message: "No Events Available at the moment",
                            src: "https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg",
                            cta: { label: "Go To Sportbook", url: "/" }
                        }) })
                }
            ]
        });
        Object.assign(documentDetails);
    }, [seriesId, eventId, selectedTab]);
    // render the page based on the provided object
    const OddsOutcomesComponent = React.useCallback(() => {
        if (Object.keys(documentDetails).length)
            return _jsx(MountDocumentComponent, { data: documentDetails });
        return _jsx("div", { children: "Loading page..." });
    }, [documentDetails]);
    return _jsx(OddsOutcomesComponent, {});
};
export default OddsOutcomes;
