import { jsx as _jsx } from "react/jsx-runtime";
import clsx from "clsx";
import styles from "./ScoresBySport.module.scss";
const ScoresBySport = ({ sportName, type, clock, score, periodScores = [], className }) => {
    var _a, _b;
    if (sportName === "basketball") {
        const scores = [];
        // pushes the current quarter
        scores.push(_jsx("span", Object.assign({ className: clsx(styles.score, className, {
                [styles.current]: false // if quarters are in place, then this should be true
            }) }, { children: type === "home" ? score === null || score === void 0 ? void 0 : score.home : score === null || score === void 0 ? void 0 : score.away })));
        return scores.reverse();
    }
    else if (sportName === "tennis") {
        const scores = [];
        // points
        scores.push(_jsx("span", Object.assign({ className: clsx(styles.score, className) }, { children: type === "home" ? (score === null || score === void 0 ? void 0 : score.home) || 0 : (score === null || score === void 0 ? void 0 : score.away) || 0 })));
        // games
        scores.push(_jsx("span", Object.assign({ className: clsx(styles.score, className), style: { borderRight: "1px solid #cccccc", paddingRight: "10%" } }, { children: (type === "home" ? (_a = periodScores === null || periodScores === void 0 ? void 0 : periodScores.slice(-1)[0]) === null || _a === void 0 ? void 0 : _a.home : (_b = periodScores === null || periodScores === void 0 ? void 0 : periodScores.slice(-1)[0]) === null || _b === void 0 ? void 0 : _b.away) || 0 })));
        // sets
        const sets = periodScores === null || periodScores === void 0 ? void 0 : periodScores.reduce((accumulator, currentValue) => {
            if (type === "home" &&
                (Number(currentValue.home) === 7 ||
                    (Number(currentValue.home) === 6 && Number(currentValue.away) <= 4) // e.g. 6 - 4
                )) {
                accumulator++;
            }
            else if (type === "away" &&
                (Number(currentValue.away) === 7 ||
                    (Number(currentValue.away) === 6 && Number(currentValue.home) <= 4) // e.g. 6 - 2
                )) {
                accumulator++;
            }
            return accumulator;
        }, 0);
        scores.push(_jsx("span", Object.assign({ className: clsx(styles.score, styles.current, className) }, { children: sets || 0 })));
        return scores.reverse();
    }
    else if (sportName === "volleyball") {
        const scores = [];
        // points
        scores.push(_jsx("span", Object.assign({ className: clsx(styles.score, className), style: { borderRight: "1px solid #cccccc", paddingRight: "10%" } }, { children: type === "home" ? (score === null || score === void 0 ? void 0 : score.home) || 0 : (score === null || score === void 0 ? void 0 : score.away) || 0 })));
        // sets
        // rules: First 11 points wins the set, unless it goes to 10-10, as it would require 2 clear points to win
        const sets = periodScores === null || periodScores === void 0 ? void 0 : periodScores.reduce((accumulator, currentValue) => {
            if (type === "home" &&
                (Number(currentValue.home) >= 25 &&
                    Number(currentValue.away) <= Number(currentValue.home) - 2)) {
                accumulator++;
            }
            else if (type === "away" &&
                (Number(currentValue.away) >= 25 &&
                    Number(currentValue.home) <= Number(currentValue.away) - 2)) {
                accumulator++;
            }
            return accumulator;
        }, 0);
        scores.push(_jsx("span", Object.assign({ className: clsx(styles.score, styles.current, className) }, { children: sets || 0 })));
        return scores.reverse();
    }
    else if (sportName === "tabletennis") {
        const scores = [];
        // points
        scores.push(_jsx("span", Object.assign({ className: clsx(styles.score, className), style: { borderRight: "1px solid #cccccc", paddingRight: "10%" } }, { children: type === "home" ? (score === null || score === void 0 ? void 0 : score.home) || 0 : (score === null || score === void 0 ? void 0 : score.away) || 0 })));
        // sets
        // rules: First 11 points wins the set, unless it goes to 10-10, as it would require 2 clear points to win
        const sets = periodScores === null || periodScores === void 0 ? void 0 : periodScores.reduce((accumulator, currentValue) => {
            if (type === "home" &&
                ((Number(currentValue.home) === 11 && Number(currentValue.away) <= 9) || // e.g. 11 - 6
                    (Number(currentValue.home) >= 12 &&
                        Number(currentValue.home) - 2 >= Number(currentValue.away)) // e.g. 13 - 11
                )) {
                accumulator++;
            }
            else if (type === "away" &&
                ((Number(currentValue.away) === 11 && Number(currentValue.home) <= 9) || // e.g. 9 - 11
                    (Number(currentValue.away) >= 12 &&
                        Number(currentValue.away) - 2 >= Number(currentValue.home)) // e.g. 10 - 12
                )) {
                accumulator++;
            }
            return accumulator;
        }, 0);
        scores.push(_jsx("span", Object.assign({ className: clsx(styles.score, styles.current, className) }, { children: sets || 0 })));
        return scores.reverse();
    }
    // this is the default. e.g., soccer falls under this default section
    return _jsx("span", Object.assign({ className: clsx(styles.score, className) }, { children: type === "home" ? score === null || score === void 0 ? void 0 : score.home : score === null || score === void 0 ? void 0 : score.away }));
};
export default ScoresBySport;
