import { jsx as _jsx } from "react/jsx-runtime";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
const componentObj = {
    data: {
        seriesId: 580899,
        marketId: 1774122,
        marketCount: 1,
        marketSelections: [
            {
                abId: 4252290,
                label: "1",
                name: "FlyQuest",
                odds: 1.38,
                probability: 0.7,
                isOpen: true
            },
            {
                abId: 4252291,
                label: "x",
                name: "Draw",
                odds: 2.12,
                probability: 0.2,
                isOpen: true
            },
            {
                abId: 4252292,
                label: "2",
                name: "Golden Guardians",
                odds: 2.88,
                probability: 0.3,
                isOpen: false
            }
        ]
    }
};
export const documentDetails = {
    title: "Odds multi-event",
    component: "esports-odds-multievent",
    description: "This is a base component, that is used as part of many composed components.",
    dependencies: [
        "esports-odds-multievent-input"
    ],
    attributes: [
        {
            key: "1",
            attr: "data",
            type: "object",
            notes: ["Data object expects the following information.",
                `{
          seriesId: number,
          marketId: number,
          marketCount: number,
          marketSelections: MarketSelection[]
        }`,
                "MarketSelection is an interface",
                `interface MarketSelection {
          abId: number
          label: string
          odds: number
          probability: number
          isOpen: boolean
        }`
            ]
        },
        {
            key: "2",
            attr: "selected-outcomes",
            type: "number[]",
            notes: []
        }
    ],
    events: [
        {
            event: "odds-multievent-details",
            type: "emit",
            data: [
                `The component details object is emitted when the market counter button is clicked,
          containing the following information.`,
                `detail: {
          seriesId: <value>,
          marketId: <value>,
          selectionId: <value>,
          label: <value>,
          value: <value>
       }`
            ]
        },
        {
            event: "odds-multievent-input-details",
            type: "listener",
            data: [
                "The component details object is emitted when input is clicked, containing the following information.",
                `detail: {
          seriesId: <value>,
          marketId: <value>,
          selectionId: <value>,
          label: <value>,
          value: <value>
       }`
            ]
        }
    ]
};
const OddsMultiEvent = () => {
    documentDetails.example = `<esports-odds-multievent
      data={JSON.stringify({
        seriesId: oddsButtonsData.seriesId,
        marketId: oddsButtonsData.marketId,
        marketCount: oddsButtonsData.marketCount,
        marketSelections: oddsButtonsData.marketSelections
      })}
      selected-outcomes={JSON.stringify([111,222,333])}
    />`;
    documentDetails.code = _jsx("esports-odds-multievent", { data: JSON.stringify(componentObj.data) });
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default OddsMultiEvent;
