import { gql } from "@apollo/client";
// subscription to return all live and respective sports counters
const SUBSCRIBE_LIVE_EVENT_COUNTS = gql `
  subscription GetLiveEventCounts {
    getLiveEventCounts {
      SportId
      SportName
      TotalEvents
    }
  }
`;
// this subscription returns all the important information related to the live event
const SUBSCRIBE_EVENTS = gql `
  subscription GetEventsByIds($eventIds: [bigint!]) {
    sport_domain_event(where: {id: {_in: $eventIds}}) {
      id
      name
      event_status_id
      home_score
      away_score
      start_at
      resulted_at
      event_clock {
        minutes
        minutes_left_in_period
        event_clock_status_id
        period_name
        period_type
        seconds
        seconds_left_in_period
        timestamp_epoch
        timestamp_at
      }
      period_scores {
        away_score
        home_score
      }
    }
  }
`;
// this subscription returns the important information related to the live event at the market level + outcomes
const SUBSCRIBE_EVENT_MARKET_OUTCOMES = gql `
  subscription GetEventMarket($eventId: bigint!, $marketId: bigint!) {
    sport_domain_market(where: {
      event_id: {_eq: $eventId},
      market_type_id: {_neq: 1000},
      id: {_eq: $marketId}
    }, order_by: {market_rl_market_type_config: {id: asc}}) {
      id
      event_id
      name
      market_type_id
      market_type_config_id
      market_status_id
      market_rl_outcomes {
        id
        name
        odds
        is_active
        outcome_status_id
      }
    }
  }
`;
// this subscription returns the important information related to the live event at the market level
const SUBSCRIBE_EVENT_MARKET = gql `
  subscription GetEventMarket($eventId: bigint!, $marketId: bigint!) {
    sport_domain_market(where: {
      event_id: {_eq: $eventId},
      market_type_id: {_neq: 1000},
      id: {_eq: $marketId}
    }, order_by: {market_rl_market_type_config: {id: asc}}) {
      id
      event_id
      name
      market_type_id
      market_type_config_id
      market_status_id
    }
  }
`;
// this subscription returns the important information related to the live event at the market level
const SUBSCRIBE_EVENT_MARKETS = gql `
  subscription GetEventMarkets($eventId: bigint!, $marketIds: [bigint!]) {
    sport_domain_market(where: {
      event_id: {_eq: $eventId},
      market_type_id: {_neq: 1000},
      id: {_in: $marketIds}
    }, order_by: {market_rl_market_type_config: {id: asc}}) {
      id
      event_id
      name
      market_type_id
      market_type_config_id
      market_status_id
    }
  }
`;
// subscribe to one outcome
const SUBSCRIBE_OUTCOME = gql `
  subscription GetEventMarketOutcome($eventId: bigint = "", $outcomeId: bigint = "", $marketId: bigint = "") {
    sport_domain_outcome(
      where: {market_id: {_eq: $marketId}, id: {_eq: $outcomeId}, market: {event_id: {_eq: $eventId}}}
    ) {
      id
      odds
      market_id
      outcome_status_id
    }
  }
`;
// subscribe to multiple outcomes
const SUBSCRIBE_OUTCOMES = gql `
  subscription GetEventMarketOutcomes($eventIds: [bigint!], $marketIds: [bigint!], $outcomeIds: [bigint!]) {
    sport_domain_outcome(
      where: {market_id: {_in: $marketIds}, id: {_in: $outcomeIds}, market: {event_id: {_in: $eventIds}}}
    ) {
      id
      odds
      market_id
      outcome_status_id
    }
  }
`;
const SUBSCRIBE_ESPORTS_MARKET_SELECTIONS = gql `
  subscription GetEsportsMarketSelections($marketId: Int!) {
    GetEsportsMarketSelections(args: { marketId: $marketId }) {
      id
      label
      state
      selections
    }
  }
`;
const SUBSCRIBE_ESPORTS_MARKET_SELECTION = gql `
  subscription GetEsportsMarketSelection($marketId: Int!, $outcomeId: Int!) {
    GetEsportsMarketSelections(args: { marketId: $marketId, outcomeId: $outcomeId}) {
      id
      label
      selections
      state
    }
  }
`;
export { SUBSCRIBE_LIVE_EVENT_COUNTS, SUBSCRIBE_EVENTS, SUBSCRIBE_EVENT_MARKET_OUTCOMES, SUBSCRIBE_EVENT_MARKET, SUBSCRIBE_EVENT_MARKETS, SUBSCRIBE_OUTCOME, SUBSCRIBE_OUTCOMES, SUBSCRIBE_ESPORTS_MARKET_SELECTIONS, SUBSCRIBE_ESPORTS_MARKET_SELECTION };
