var _a, _b;
import { jsx as _jsx } from "react/jsx-runtime";
// @webcomponents/custom-elements: Do this import before any code that manipulates the DOM.
// It is a polyfill for Edge
import "@webcomponents/custom-elements";
import "core-js/actual/structured-clone"; // adds a polyfill to the StructuredClone method
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.scss";
import axios from "axios";
import "./webcomponents";
// TODO: remove after full API handler implementation
// Sets axios sportsbook globals
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL =
    (_a = process.env.REACT_APP_SPORTSBOOK_API_URL) !== null && _a !== void 0 ? _a : "https://sportsbook-api-staging.leetent.co.uk/api/v1";
axios.defaults.headers.common["X-API-Key"] =
    (_b = process.env.REACT_APP_SPORTSBOOK_API_KEY) !== null && _b !== void 0 ? _b : "3FBD4C43-0AC0-445B-A0BA-8536739C635B";
const container = document.getElementById("leetent-webcomponents");
const root = createRoot(container);
root.render(_jsx(React.StrictMode, { children: _jsx(App, {}) }));
