var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createRoot } from "react-dom/client";
// styles
import styles from "./DropdownGeneric.module.scss";
export const DropdownGeneric = ({ label, name, allOption, options, labelKey = "label", valueKey = "value", selected }) => {
    return (_jsxs("div", Object.assign({ className: styles.dropdown, "data-testid": "DropdownGeneric" }, { children: [label && (_jsx("div", { children: _jsx("label", Object.assign({ htmlFor: label, "data-testid": "DropdownGeneric.label" }, { children: label })) })), _jsxs("select", Object.assign({ name: name, id: name, defaultValue: selected, "data-testid": "DropdownGeneric.select" }, { children: [allOption && _jsx("option", Object.assign({ value: -1 }, { children: `All ${name === null || name === void 0 ? void 0 : name.toLowerCase()}` }), -1), options.map((option) => {
                        return (_jsx("option", Object.assign({ "data-testid": `DropdownGeneric.option-${option[valueKey]}`, value: option[valueKey] }, { children: option[labelKey] }), option[labelKey]));
                    })] }))] })));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this.name = "";
        this["label-required"] = undefined;
        this.options = [
            {
                label: "No data returned",
                value: "nodata"
            }
        ];
        this["label-key"] = "label";
        this["value-key"] = "value";
        this.selected = undefined;
    }
    static get observedAttributes() {
        return ["label", "name", "all-option", "options", "label-key", "value-key", "selected"];
    }
    changeHandler(e) {
        var _a;
        const selectedOption = this.options.find((option) => String(this["value-key"] ? option[this["value-key"]] : option.value) ===
            e.target.value);
        const triggerDropdownEvent = new CustomEvent(`${(_a = this.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().trim()}-selected-option`, {
            detail: Object.assign({}, selectedOption),
            bubbles: true
        });
        this.dispatchEvent(triggerDropdownEvent);
    }
    connectedCallback() {
        var _a;
        // TODO: move events handling to the DropdownGeneric
        // dispatch the events
        this.addEventListener("change", this.changeHandler);
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(DropdownGeneric, { label: this.label, name: this.name, allOption: this["all-option"], options: this.options, labelKey: this["label-key"], valueKey: this["value-key"], selected: this.selected }));
    }
    disconnectedCallback() {
        this.removeEventListener("change", this.changeHandler);
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, oldValue, newValue) {
        var _a;
        switch (attrName) {
            case "label":
            case "name":
            case "label-key":
            case "value-key":
            case "selected":
                this[attrName] = String(newValue);
                break;
            case "all-option":
                this[attrName] = JSON.parse(newValue);
                break;
            case "options":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(DropdownGeneric, { label: this.label, name: this.name, allOption: this["all-option"], options: this.options, labelKey: this["label-key"], valueKey: this["value-key"], selected: this.selected }));
    }
}
(_a = customElements.get("esports-dropdown-generic")) !== null && _a !== void 0 ? _a : customElements.define("esports-dropdown-generic", Element);
