var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import clsx from "clsx";
import { createRoot } from "react-dom/client";
import { ModalBase } from "./ModalBase/ModalBase";
import styles from "./ModalGeneric.module.scss";
export const ModalGeneric = ({ open, title, requiredOptions, children, modalId, disableCloseModal, position }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    React.useEffect(() => {
        setIsOpen(!!open);
    }, [open]);
    const handleButtonClick = (option) => () => {
        setIsOpen(false);
        const triggerAuthButton = new CustomEvent("click-modal-button", {
            detail: Object.assign({ modalId, origin: window.location.pathname }, option),
            bubbles: true
        });
        window.dispatchEvent(triggerAuthButton);
    };
    const closeModalHandler = () => {
        setIsOpen(false);
        const triggerCloseModal = new CustomEvent("close-modal", {
            detail: {
                modalId,
                origin: window.location.pathname
            },
            bubbles: true
        });
        window.dispatchEvent(triggerCloseModal);
    };
    return (_jsx(ModalBase, Object.assign({ open: isOpen, onClose: closeModalHandler, disableCloseModal: disableCloseModal, position: position }, { children: _jsxs("div", Object.assign({ className: styles.root }, { children: [title && _jsx("strong", { children: title }), children !== null && children !== void 0 ? children : (_jsx("div", Object.assign({ className: styles.btns }, { children: requiredOptions === null || requiredOptions === void 0 ? void 0 : requiredOptions.map((option, index) => {
                        const { Icon } = option;
                        return (_jsxs("button", Object.assign({ className: clsx(styles.btn, {
                                [styles.primary]: index === 1 || requiredOptions.length === 1
                            }), onClick: handleButtonClick(option), "data-testid": `Modal.btn-${index}` }, { children: [(option === null || option === void 0 ? void 0 : option.Icon) && _jsx(Icon, {}), option.label] }), option.id));
                    }) })))] })) })));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this["modal-title"] = "";
        this.options = [];
    }
    static get observedAttributes() {
        return ["open", "modal-title", "options", "modal-id"];
    }
    connectedCallback() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(ModalGeneric, { open: this.open, title: this["modal-title"], modalId: this["modal-id"], requiredOptions: this.options }));
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        var _a;
        switch (attrName) {
            case "modal-title":
            case "modal-id":
                this[attrName] = String(newValue);
                break;
            case "open":
            case "options":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(ModalGeneric, { open: this.open, title: this["modal-title"], modalId: this["modal-id"], requiredOptions: this.options }));
    }
}
(_a = customElements.get("esports-modal-generic")) !== null && _a !== void 0 ? _a : customElements.define("esports-modal-generic", Element);
