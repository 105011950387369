var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { getTimeConverter, toFixed } from "helpers/GeneralFunctions";
import SocialBetDetails from "../socialBetDetails/SocialBetDetails";
import { avatars } from "assets/avatars/Avatars";
import { charValueSumModulo } from "pages/profile/editProfile/EditProfileWrapper";
import { Customskeleton } from "helpers/CustomSkeleton/CustomSkeleton";
import { createRoot } from "react-dom/client";
import clsx from "clsx";
import { LocalStorage } from "helpers/Storage";
import { CURRENCY } from "enums";
// styles
import style from "./SocialProfileCard.module.scss";
import flexStyle from "assets/styles/flex.module.scss";
const betTypes = (value) => {
    switch (value) {
        case 1: {
            return "Singles";
        }
        case 2: {
            return "Doubles";
        }
        default:
            return "Multiples";
    }
};
const betStatuses = (value) => {
    switch (value) {
        case 1:
            return "UNSETTLED";
        case 2:
            return "SETTLED";
        case 3:
            return "CANCELLED";
        default:
            return "UNSETTLED";
    }
};
const playerId = LocalStorage.get("widget-playerId");
const SocialProfileCard = ({ posts, userPostRequired, isLoading, noDataOptions }) => {
    const [error, setError] = React.useState();
    const [clickedImagePost, setClickedImagePost] = useState(null);
    const [expandedPosts, setExpandedPosts] = useState([]);
    // adds the postId to the expanded posts array, so the card gets expanded as well
    const handleSeeAll = (postId) => {
        setExpandedPosts((prevPosts) => {
            if (prevPosts.includes(postId)) {
                return prevPosts === null || prevPosts === void 0 ? void 0 : prevPosts.filter((id) => id !== postId);
            }
            else {
                return [...prevPosts, postId];
            }
        });
    };
    // method to add a bet to the betslip, based on a copied bet
    const handleAddToBetslip = React.useCallback((post) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        const isMyProfile = playerId === ((_d = (_c = (_b = (_a = post.detail) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.Bets) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.platformBettorId);
        const userProfileString = sessionStorage.getItem("userProfile");
        const userProfile = userProfileString ?
            JSON.parse(userProfileString) :
            null;
        let betsData;
        if ((_g = (_f = (_e = post === null || post === void 0 ? void 0 : post.detail) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.Legs) === null || _g === void 0 ? void 0 : _g[0]) {
            betsData = (_h = post === null || post === void 0 ? void 0 : post.detail) === null || _h === void 0 ? void 0 : _h.data;
        }
        else {
            betsData = (_l = (_k = (_j = post === null || post === void 0 ? void 0 : post.detail) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.Bets) === null || _l === void 0 ? void 0 : _l[0];
        }
        // add to local storage in every case
        const betDetails = (_m = betsData === null || betsData === void 0 ? void 0 : betsData.Legs) === null || _m === void 0 ? void 0 : _m.map((elem) => {
            var _a, _b, _c;
            return {
                id: elem.Outcome.AbId,
                label: elem.Outcome.Market.Name,
                matchId: (_c = (_b = (_a = elem === null || elem === void 0 ? void 0 : elem.Outcome) === null || _a === void 0 ? void 0 : _a.Market) === null || _b === void 0 ? void 0 : _b.Series) === null || _c === void 0 ? void 0 : _c.AbId,
                value: elem.odds
            };
        });
        localStorage.setItem("betDetails", JSON.stringify(betDetails));
        const processedTotalBets = betDetails === null || betDetails === void 0 ? void 0 : betDetails.reduce((total, bet, index) => {
            const newValue = bet.value * (index === 0 ? 1 : total);
            return newValue;
        }, 0);
        // emit the bet details event
        let oids = "";
        const bets = [];
        betsData === null || betsData === void 0 ? void 0 : betsData.Legs.forEach((selection, index) => {
            const seriesid = Number(selection.Outcome.Market.Series.AbId);
            const selectionid = Number(selection.Outcome.AbId);
            oids += `${index > 0 ? "&" : ""}oid=${seriesid}_${selectionid}`;
            bets.push({
                market: selection.Outcome.Market.Name,
                pick: selection.Outcome.Name,
                event: selection.Outcome.Market.Series.Name,
                odds: selection.odds,
                startDate: selection.Outcome.Market.Series.StartDate,
                outcomeId: selection.Outcome.AbId,
                marketId: selection.Outcome.Market.Id,
                eventId: selection.Outcome.Market.Series.AbId,
                productId: 2
            });
        });
        const triggerAddBetDetails = new CustomEvent("updated-bet-details", {
            detail: {
                lastUpdated: dayjs().toISOString(),
                betSlipRef: post.detail.data.betSlipRef,
                bettorId: (_o = userProfile === null || userProfile === void 0 ? void 0 : userProfile.id) === null || _o === void 0 ? void 0 : _o.toString(),
                betDetails,
                oids,
                betCounter: betDetails.length,
                totalOdds: Math.round(processedTotalBets * 100) / 100,
                bets,
                stake: betsData === null || betsData === void 0 ? void 0 : betsData.stake
            },
            bubbles: true
        });
        window.dispatchEvent(triggerAddBetDetails);
        // preps the request payload
        if (userProfile) {
            const { postId } = post;
            const payload = {
                bettorId: (_p = userProfile === null || userProfile === void 0 ? void 0 : userProfile.id) === null || _p === void 0 ? void 0 : _p.toString(),
                postId
            };
            // records the copied post, in case it has not been already copied
            if (((_q = process.env) === null || _q === void 0 ? void 0 : _q.REACT_APP_GATEWAY_API_URL) && ((_r = process.env) === null || _r === void 0 ? void 0 : _r.REACT_APP_GATEWAY_API_KEY) && !isMyProfile) {
                yield axios
                    .post(`${process.env.REACT_APP_GATEWAY_API_URL}/copies`, payload)
                    .then((res) => {
                    post.disabled = true;
                })
                    .catch((e) => {
                });
            }
            else {
                setError("Environment variables are not in place");
            }
        }
    }), []);
    const triggerProfileImageDetails = React.useMemo(() => {
        return new CustomEvent("profile-image-details", {
            detail: {
                showUserProfile: true,
                // TODO: Check why detail is being highlighted
                bettorId: clickedImagePost === null || clickedImagePost === void 0 ? void 0 : clickedImagePost.profileId
            },
            bubbles: true
        });
    }, [clickedImagePost]);
    React.useEffect(() => {
        if (clickedImagePost) {
            window.dispatchEvent(triggerProfileImageDetails);
        }
    }, [clickedImagePost]);
    return (_jsxs(_Fragment, { children: [isLoading && _jsx(Customskeleton, {}), !error && (posts === null || posts === void 0 ? void 0 : posts.map((post) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
                let bets;
                if ((_c = (_b = (_a = post === null || post === void 0 ? void 0 : post.detail) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.Legs) === null || _c === void 0 ? void 0 : _c[0]) {
                    bets = (_d = post === null || post === void 0 ? void 0 : post.detail) === null || _d === void 0 ? void 0 : _d.data;
                }
                else {
                    bets = (_g = (_f = (_e = post === null || post === void 0 ? void 0 : post.detail) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.Bets) === null || _g === void 0 ? void 0 : _g[0];
                }
                const isExpanded = expandedPosts === null || expandedPosts === void 0 ? void 0 : expandedPosts.includes(post.postId);
                const betLegs = isExpanded ? bets === null || bets === void 0 ? void 0 : bets.Legs : (_h = bets === null || bets === void 0 ? void 0 : bets.Legs) === null || _h === void 0 ? void 0 : _h.slice(0, 2);
                return (_jsxs("div", Object.assign({ className: style.socialCard }, { children: [_jsxs("div", Object.assign({ onClick: () => {
                                setClickedImagePost(post);
                            }, className: clsx(style.socialCard__profile, flexStyle.flex, flexStyle.alignCenter) }, { children: [_jsx("a", { children: (post === null || post === void 0 ? void 0 : post.profileAvatarUrl) ?
                                        (_jsx("img", { src: post === null || post === void 0 ? void 0 : post.profileAvatarUrl, alt: "profile", style: { cursor: "pointer" } })) :
                                        (_jsx(_Fragment, { children: avatars[charValueSumModulo(post === null || post === void 0 ? void 0 : post.profileDisplayName)] })) }), _jsx("aside", { children: _jsx("h5", { children: post === null || post === void 0 ? void 0 : post.profileDisplayName }) })] })), _jsxs("div", Object.assign({ className: clsx(style.socialCard__potential, flexStyle.flex, flexStyle.alignCenter, flexStyle.justifyCenter) }, { children: ["\uD83C\uDF89", _jsxs("p", { children: ["Potential Winnings:", " ", _jsx("strong", { children: `${CURRENCY}${toFixed(bets === null || bets === void 0 ? void 0 : bets.potentialPayout)}` })] }), "\uD83C\uDF89"] })), _jsxs("div", Object.assign({ className: clsx(style.socialCard__state, flexStyle.flex, flexStyle.alignCenter, flexStyle.justifySpaceBetween) }, { children: [_jsx("strong", { children: `${CURRENCY}${toFixed(bets === null || bets === void 0 ? void 0 : bets.stake)} ${betTypes((_j = bets === null || bets === void 0 ? void 0 : bets.Legs) === null || _j === void 0 ? void 0 : _j.length)}` }), _jsx("span", { children: betStatuses(bets === null || bets === void 0 ? void 0 : bets.statusId) })] })), _jsx(SocialBetDetails, { betLegs: betLegs && betLegs }), ((_k = bets === null || bets === void 0 ? void 0 : bets.Legs) === null || _k === void 0 ? void 0 : _k.length) > 2 && (_jsx("div", Object.assign({ className: style.socialCard__more, onClick: () => { handleSeeAll(post.postId); } }, { children: isExpanded ?
                                "See Less" :
                                `See all(${((_l = bets.Legs) === null || _l === void 0 ? void 0 : _l.length) - 2})` }))), _jsxs("div", Object.assign({ className: clsx(style.socialCard__stats, flexStyle.flex, flexStyle.alignCenter, flexStyle.justifySpaceBetween) }, { children: [_jsxs("aside", { children: [_jsx("span", { children: getTimeConverter((_o = (_m = post === null || post === void 0 ? void 0 : post.detail) === null || _m === void 0 ? void 0 : _m.data) === null || _o === void 0 ? void 0 : _o.createdDate) }), _jsx("strong", { children: ((_p = bets === null || bets === void 0 ? void 0 : bets.Legs) === null || _p === void 0 ? void 0 : _p.length) === 0 || ((_q = bets === null || bets === void 0 ? void 0 : bets.Legs) === null || _q === void 0 ? void 0 : _q.length) === 1 ?
                                                `${(_r = bets === null || bets === void 0 ? void 0 : bets.Legs) === null || _r === void 0 ? void 0 : _r.length} Selection` :
                                                `${(_s = bets === null || bets === void 0 ? void 0 : bets.Legs) === null || _s === void 0 ? void 0 : _s.length} Selections` })] }), _jsxs("aside", { children: [_jsx("span", { children: "Stake" }), _jsx("strong", { children: `${CURRENCY}${toFixed(bets === null || bets === void 0 ? void 0 : bets.stake)}` })] }), _jsxs("aside", { children: [_jsx("span", { children: "To Return" }), _jsx("strong", { children: `${CURRENCY}${toFixed(bets === null || bets === void 0 ? void 0 : bets.potentialPayout)}` })] })] })), _jsx("div", Object.assign({ className: style.socialCard__btn }, { children: _jsx("button", Object.assign({ className: `button_social_widget success`, style: { cursor: "pointer" }, onClick: () => { handleAddToBetslip(post); } }, { children: "Add to BetSlip" })) })), Number(post === null || post === void 0 ? void 0 : post.copyCount) > 0 && (_jsx("div", Object.assign({ className: style.socialCard__text }, { children: _jsx("p", { children: `Copied ${post.copyCount}
                        ${post.copyCount > 1 ? "times" : "time"}` }) })))] }), post.postId));
            }))] }));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.posts = [];
        this["user-post-required"] = false;
        this.loading = false;
    }
    static get observedAttributes() {
        return ["posts", "user-post-required", "loading", "no-data-options"];
    }
    connectedCallback() {
        createRoot(this).render(_jsx(SocialProfileCard, { posts: this.posts, userPostRequired: this["user-post-required"], isLoading: this.loading, noDataOptions: this["no-data-options"] }));
    }
    attributeChangedCallback(attrName, oldValue, newValue) {
        switch (attrName) {
            // case "loading":
            case "posts":
            case "user-post-required":
                this[attrName] = JSON.parse(newValue);
                break;
            case "no-data-options":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
    }
}
(_a = customElements.get("esports-social-profile-card")) !== null && _a !== void 0 ? _a : customElements.define("esports-social-profile-card", Element);
