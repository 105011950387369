import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from "clsx";
// styles
import style from "./SocialBetDetails.module.scss";
import flexStyle from "assets/styles/flex.module.scss";
// helpers
import { getTimeConverter, toFixed } from "helpers/GeneralFunctions";
const SocialBetDetails = ({ betLegs }) => {
    const getGameName = (gameName) => {
        const gamesString = localStorage.getItem("games");
        const games = gamesString ? JSON.parse(gamesString) : null;
        const gettingGameName = games === null || games === void 0 ? void 0 : games.find((el) => {
            return el.name === gameName;
        });
        return (gettingGameName === null || gettingGameName === void 0 ? void 0 : gettingGameName.abbreviation) ? gettingGameName === null || gettingGameName === void 0 ? void 0 : gettingGameName.abbreviation : "";
    };
    const getGameColor = (gameName) => {
        const gamesString = localStorage.getItem("games");
        const games = gamesString ? JSON.parse(gamesString) : null;
        const gettingGameName = games === null || games === void 0 ? void 0 : games.find((el) => {
            return el.name === gameName;
        });
        return (gettingGameName === null || gettingGameName === void 0 ? void 0 : gettingGameName.color) ? `#${gettingGameName === null || gettingGameName === void 0 ? void 0 : gettingGameName.color}` : "white";
    };
    return (_jsx(_Fragment, { children: betLegs === null || betLegs === void 0 ? void 0 : betLegs.map((bet, index) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
            return (_jsxs("div", Object.assign({ className: style.SocialBetDetails, style: { borderBottomWidth: betLegs.length === index + 1 ? 0 : 1 } }, { children: [_jsxs("div", Object.assign({ className: clsx(style.SocialBetDetails__caption, flexStyle.flex, flexStyle.justifySpaceBetween) }, { children: [_jsxs("aside", { children: [_jsx("span", Object.assign({ style: {
                                            display: getGameName((_e = (_d = (_c = (_b = (_a = bet === null || bet === void 0 ? void 0 : bet.Outcome) === null || _a === void 0 ? void 0 : _a.Market) === null || _b === void 0 ? void 0 : _b.Series) === null || _c === void 0 ? void 0 : _c.Tournament) === null || _d === void 0 ? void 0 : _d.Game) === null || _e === void 0 ? void 0 : _e.Name) ? "inherit" : "none",
                                            backgroundColor: getGameColor((_k = (_j = (_h = (_g = (_f = bet === null || bet === void 0 ? void 0 : bet.Outcome) === null || _f === void 0 ? void 0 : _f.Market) === null || _g === void 0 ? void 0 : _g.Series) === null || _h === void 0 ? void 0 : _h.Tournament) === null || _j === void 0 ? void 0 : _j.Game) === null || _k === void 0 ? void 0 : _k.Name),
                                            marginRight: "7px"
                                        } }, { children: getGameName((_q = (_p = (_o = (_m = (_l = bet === null || bet === void 0 ? void 0 : bet.Outcome) === null || _l === void 0 ? void 0 : _l.Market) === null || _m === void 0 ? void 0 : _m.Series) === null || _o === void 0 ? void 0 : _o.Tournament) === null || _p === void 0 ? void 0 : _p.Game) === null || _q === void 0 ? void 0 : _q.Name) })), (_s = (_r = bet === null || bet === void 0 ? void 0 : bet.Outcome) === null || _r === void 0 ? void 0 : _r.Name) === null || _s === void 0 ? void 0 : _s.split("to win")[0]] }), (bet === null || bet === void 0 ? void 0 : bet.odds) && _jsx("strong", { children: toFixed(bet.odds) })] })), _jsx("div", Object.assign({ className: style.SocialBetDetails__result }, { children: _jsx("p", { children: (_u = (_t = bet === null || bet === void 0 ? void 0 : bet.Outcome) === null || _t === void 0 ? void 0 : _t.Market) === null || _u === void 0 ? void 0 : _u.Name }) })), _jsxs("div", Object.assign({ className: clsx(style.SocialBetDetails__item, flexStyle.flex, flexStyle.justifySpaceBetween) }, { children: [_jsx("p", { children: (_x = (_w = (_v = bet === null || bet === void 0 ? void 0 : bet.Outcome) === null || _v === void 0 ? void 0 : _v.Market) === null || _w === void 0 ? void 0 : _w.Series) === null || _x === void 0 ? void 0 : _x.Name }), _jsx("p", { children: getTimeConverter((_0 = (_z = (_y = bet === null || bet === void 0 ? void 0 : bet.Outcome) === null || _y === void 0 ? void 0 : _y.Market) === null || _z === void 0 ? void 0 : _z.Series) === null || _0 === void 0 ? void 0 : _0.StartDate) })] }))] }), bet.id));
        }) }));
};
export default SocialBetDetails;
