import { MARKET_STATUS, OUTCOME_STATUS } from "enums";
// isOpen can be false if the event/market/outcome is set to inactive in the admin
// even if the statusId is open
export const isOutcomeOpen = (isOpen, statusId) => {
    return isOpen && statusId === OUTCOME_STATUS.OPEN;
};
export const checkIsOpen = (outcomeIsOpen, // original isOpen (generally from the endpoint)
marketData, statusId // this is the outcome statusId from the topic
) => {
    // by default we assume the outcome?.isOpen information is in place
    // this is the information shared with us when fetching the initial data
    // and shouldn't be the returned information, unless DD info is not available
    let shouldOpen = outcomeIsOpen;
    if (marketData === null || marketData === void 0 ? void 0 : marketData.statusId) {
        // when market gets suspended or closed, then outcomes should be locked
        if (marketData.statusId !== MARKET_STATUS.OPEN) {
            shouldOpen = false;
        }
        else if (
        // when market becomes active, the decision is based on the outcome status
        marketData.statusId === MARKET_STATUS.OPEN && outcomeIsOpen) {
            shouldOpen = statusId === OUTCOME_STATUS.OPEN;
        }
    }
    return shouldOpen;
};
