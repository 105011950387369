import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Row } from "antd";
import clsx from "clsx";
import { CURRENCY } from "../../../../enums";
import { toFixed } from "helpers/GeneralFunctions";
import { getEventsLegs } from "helpers/Bets";
import styles from "./BetSummary.module.scss";
export const BetSummary = ({ totalStake, totalReturn, totalEvents, totalLegs, totalBets, isBB }) => {
    return (_jsxs(Row, Object.assign({ justify: "space-between", align: "middle", className: styles.root, "data-testid": "BetslipSummary" }, { children: [_jsx(Col, Object.assign({ className: styles.col }, { children: totalBets ?
                    (_jsxs(_Fragment, { children: ["Number Of Bets", _jsx("strong", { children: totalBets })] })) :
                    (_jsxs(_Fragment, { children: [isBB ? "Bet Builder" : "Multiple", _jsx("div", Object.assign({ className: styles.betBuilder }, { children: getEventsLegs(Number(totalEvents), Number(totalLegs)) }))] })) })), _jsxs(Col, Object.assign({ className: clsx(styles.col, styles.rightCol) }, { children: [_jsxs("div", Object.assign({ className: styles.col }, { children: ["To Return", _jsx("strong", { children: `${CURRENCY}${toFixed(totalReturn !== null && totalReturn !== void 0 ? totalReturn : 0)}` })] })), _jsxs("div", Object.assign({ className: styles.col }, { children: ["Total Stake", _jsx("strong", Object.assign({ "data-testid": "BetslipSummary.totalStake" }, { children: `${CURRENCY}${toFixed(totalStake)}` }))] }))] }))] })));
};
