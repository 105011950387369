var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import clsx from "clsx";
import { createRoot } from "react-dom/client";
import axios from "axios";
import { SUBSCRIBE_LIVE_EVENT_COUNTS } from "v2/graphql/subscriptions";
import { useSubscribeByQuery } from "helpers/graphql/useSubscribeByQuery";
import { LocalStorage } from "helpers/Storage";
import { toFixed } from "helpers/GeneralFunctions";
import SkeletonFooterNavigation from "components/base/skeleton/FooterNavigation";
// icons
import { ReactComponent as LiveIcon } from "assets/images/sensors.svg";
import { ReactComponent as SocialIcon } from "assets/images/social.svg";
import { ReactComponent as ProfileIcon } from "assets/images/profile.svg";
import { ReactComponent as MyBetsIcon } from "assets/images/contract.svg";
import { ReactComponent as SearchIcon } from "assets/images/feature_search.svg";
// esports icons
import { ReactComponent as ControllerIcon } from "assets/images/controller.svg";
import styles from "./FooterNavigation.module.scss";
const defaultEsportsOptions = [
    {
        id: 1,
        label: "Esports",
        url: "/",
        Icon: ControllerIcon
    },
    {
        id: 2,
        label: "Live",
        url: "/live",
        Icon: LiveIcon,
        isDisabled: true
    },
    {
        id: 3,
        label: "My Bets",
        url: "/redirect-to-component-mybets",
        Icon: MyBetsIcon,
        isAuthenticationRequired: true
    },
    {
        id: 4,
        label: "Search",
        url: "/redirect-to-component-search",
        Icon: SearchIcon
    }
];
// isDisabled is a temporary solution as the live page is broken/empty
// Related to SPOR-1280
const FooterNavigationTab = ({ tabClickHandler, Icon, label, isActiveClicked, isDisabled = false, dataTestid, counter = 0 }) => {
    return (_jsxs("div", Object.assign({ className: clsx(styles.tab, {
            [styles.disabled]: isDisabled
        }), onClick: isDisabled ? undefined : tabClickHandler, "data-testid": dataTestid }, { children: [Icon && (
            // TODO: Update colors to correct vars
            _jsx(Icon, { fill: isActiveClicked ?
                    "var(--darkGreen)" :
                    isDisabled ?
                        "#4f525d" :
                        "#818694" })), _jsx("div", Object.assign({ className: clsx(styles.tabName, { [styles.active]: isActiveClicked }) }, { children: label }))] })));
};
export const FooterNavigation = (_a) => {
    var _b;
    var { productId, currentRoute, bets = (_b = LocalStorage.get("betsDetails")) !== null && _b !== void 0 ? _b : [], isRelative, menuOptions, maxWidth = "inherit" } = _a;
    // subscribes to the live counter topic and updates
    const valueHandlerCallback = (details) => {
        const nextData = details.getLiveEventCounts;
        const processing = {
            all: 0,
            sports: {}
        };
        // processes the results by sports
        nextData.forEach((item) => {
            processing.all = Number(processing.all) + Number(item.TotalEvents);
            if (Object.prototype.hasOwnProperty.call(processing.sports, item.SportId)) {
                processing.sports[item.SportId] = Number(processing.sports[item.SportId]) + Number(item.TotalEvents);
            }
            else {
                processing.sports[item.SportId] = item.TotalEvents;
            }
        });
        setLiveEvents(processing);
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_LIVE_EVENT_COUNTS,
        valueHandlerCallback
    });
    const [isLoading, setIsLoading] = React.useState(false);
    const [activeTab, setActiveTab] = React.useState(1);
    const [totalOdds, setTotalOdds] = React.useState(0);
    const totalOddsRef = React.useRef(null);
    // const [liveSortsNavigation, setLiveSortsNavigation] = React.useState<boolean | null>(null);
    // sets the default menu options
    const [navigationTabs, setNavigationTabs] = React.useState(defaultEsportsOptions);
    const [liveEvents, setLiveEvents] = React.useState({
        all: 0,
        sports: {}
    });
    const filteredBets = bets.filter((bet) => bet.productId === productId);
    const betslipItems = filteredBets.length;
    React.useEffect(() => {
        var _a, _b, _c, _d;
        // only applies isSocialEnabled configuration, when override is not provided by client
        const isIdProvided = menuOptions === null || menuOptions === void 0 ? void 0 : menuOptions.every((override) => Object.prototype.hasOwnProperty.call(override, "id"));
        // When there is no override, or there is one where the Profile route
        // is not being changed, then we fetch clients data
        if (!menuOptions ||
            (isIdProvided && (menuOptions === null || menuOptions === void 0 ? void 0 : menuOptions.filter(option => option.id === 4 && !(option === null || option === void 0 ? void 0 : option.url)).length) === 0)) {
            const firstSubmenu = navigationTabs.find(submenu => submenu.id === 1);
            const secondSubmenu = navigationTabs.find(submenu => submenu.id === 2);
            const fourthSubmenu = navigationTabs.find(submenu => submenu.id === 4);
            if (firstSubmenu && productId === 2) {
                const firstSubmenuOverrides = menuOptions === null || menuOptions === void 0 ? void 0 : menuOptions.find(submenu => submenu.id === 1);
                firstSubmenu.label = (_a = firstSubmenuOverrides === null || firstSubmenuOverrides === void 0 ? void 0 : firstSubmenuOverrides.label) !== null && _a !== void 0 ? _a : "Home";
            }
            if (fourthSubmenu && productId === 2) {
                const fourthSubmenuOverrides = menuOptions === null || menuOptions === void 0 ? void 0 : menuOptions.find(submenu => submenu.id === 4);
                fourthSubmenu.label = (_b = fourthSubmenuOverrides === null || fourthSubmenuOverrides === void 0 ? void 0 : fourthSubmenuOverrides.label) !== null && _b !== void 0 ? _b : "Profile";
                fourthSubmenu.url = (_c = fourthSubmenuOverrides === null || fourthSubmenuOverrides === void 0 ? void 0 : fourthSubmenuOverrides.url) !== null && _c !== void 0 ? _c : "/profile";
                fourthSubmenu.Icon = (_d = fourthSubmenuOverrides === null || fourthSubmenuOverrides === void 0 ? void 0 : fourthSubmenuOverrides.Icon) !== null && _d !== void 0 ? _d : ProfileIcon;
            }
            const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
                var _e;
                if (!((_e = process.env) === null || _e === void 0 ? void 0 : _e.REACT_APP_GATEWAY_API_URL)) {
                    return;
                }
                setIsLoading(true);
                yield axios
                    .get(`${process.env.REACT_APP_GATEWAY_API_URL}/client/config`)
                    .then((res) => {
                    var _a, _b, _c, _d;
                    const clientProduct = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.clientProducts.find((clientProduct) => clientProduct.product.id === productId);
                    if ((clientProduct === null || clientProduct === void 0 ? void 0 : clientProduct.isSocialEnabled) && secondSubmenu) {
                        const secondSubmenuOverrides = menuOptions === null || menuOptions === void 0 ? void 0 : menuOptions.find(submenu => submenu.id === 2);
                        secondSubmenu.label = (_b = secondSubmenuOverrides === null || secondSubmenuOverrides === void 0 ? void 0 : secondSubmenuOverrides.label) !== null && _b !== void 0 ? _b : "Social";
                        secondSubmenu.url = (_c = secondSubmenuOverrides === null || secondSubmenuOverrides === void 0 ? void 0 : secondSubmenuOverrides.url) !== null && _c !== void 0 ? _c : "/social";
                        secondSubmenu.Icon = (_d = secondSubmenuOverrides === null || secondSubmenuOverrides === void 0 ? void 0 : secondSubmenuOverrides.Icon) !== null && _d !== void 0 ? _d : SocialIcon;
                    }
                    setNavigationTabs([...navigationTabs]);
                })
                    .catch((e) => {
                    console.log(e);
                })
                    .finally(() => {
                    setIsLoading(false);
                });
            });
            // fetches the client settings
            fetchData();
        }
        ;
        if (menuOptions) {
            menuOptions.forEach((override, index) => {
                // check id attribute exists, and try overriding it by id, and if not provided, then do it sequentialy
                let submenu;
                if (isIdProvided) {
                    submenu = navigationTabs.find(submenu => submenu.id === override.id);
                }
                else {
                    submenu = navigationTabs[index];
                }
                if (submenu && Object.prototype.hasOwnProperty.call(override, "label"))
                    submenu.label = override.label;
                if (submenu && Object.prototype.hasOwnProperty.call(override, "url"))
                    submenu.url = override.url;
                if (submenu && Object.prototype.hasOwnProperty.call(override, "Icon"))
                    submenu.Icon = override.Icon;
                if (submenu && Object.prototype.hasOwnProperty.call(override, "isDisabled")) {
                    submenu.isDisabled = override.isDisabled;
                }
                if (submenu && Object.prototype.hasOwnProperty.call(override, "isAuthenticationRequired")) {
                    submenu.isAuthenticationRequired = override.isAuthenticationRequired;
                }
            });
        }
        // Set default active tab
        navigationTabs.forEach(tab => {
            const isEsportsTab = productId === 1 && tab.id === 2;
            if (currentRoute) {
                if (tab.url === currentRoute && !isEsportsTab) {
                    setActiveTab(tab.id);
                }
            }
            else {
                if (tab.url === (window.location.pathname + window.location.hash) && !isEsportsTab) {
                    setActiveTab(tab.id);
                }
            }
        });
    }, []);
    React.useEffect(() => {
        const betslipTotalOddsUpdate = (e) => {
            const { totalOdds } = e.detail;
            setTotalOdds(totalOdds);
        };
        const betslipTotalEventsLegsUpdate = (e) => {
        };
        const betslipBBChange = (e) => {
        };
        // const selectedIconTabHandler = (e: any): void => {
        // setLiveSortsNavigation(e.detail.id === -1);
        // if (e.detail.id === -1) {
        //   dispatchUpdateTagsListSelection({ id: "all_live", name: "Live" });
        // }
        // };
        // window.addEventListener("selected-icon-tab-details", selectedIconTabHandler);
        window.addEventListener("bet-slip-total-events-legs-update", betslipTotalEventsLegsUpdate);
        window.addEventListener("bet-slip-bb-change", betslipBBChange);
        window.addEventListener("bet-slip-total-odds-update", betslipTotalOddsUpdate);
        return () => {
            // window.removeEventListener("selected-icon-tab-details", selectedIconTabHandler);
            window.removeEventListener("bet-slip-total-events-legs-update", betslipTotalEventsLegsUpdate);
            window.removeEventListener("bet-slip-bb-change", betslipBBChange);
            window.removeEventListener("bet-slip-total-odds-update", betslipTotalOddsUpdate);
        };
    }, []);
    const toggleBetSlipHandler = () => {
        if (betslipItems !== null && betslipItems !== void 0 ? betslipItems : filteredBets.length) {
            const toggleBetSlip = new CustomEvent("open-bet-slip", {
                detail: {
                    productId
                },
                bubbles: true
            });
            window.dispatchEvent(toggleBetSlip);
        }
    };
    const updatePadding = () => {
        const totalOddsElement = totalOddsRef.current;
        if (totalOddsElement) {
            const hasEllipsis = totalOddsElement.scrollWidth > totalOddsElement.clientWidth;
            const paddingStyle = hasEllipsis ? "0 0 0 4px" : "0 4px";
            totalOddsElement.style.padding = paddingStyle;
        }
    };
    React.useEffect(() => {
        window.addEventListener("load", updatePadding);
        return () => {
            window.removeEventListener("load", updatePadding);
        };
    }, []);
    React.useEffect(() => {
        updatePadding();
    }, [totalOdds]);
    if (isLoading) {
        return _jsx(SkeletonFooterNavigation, {});
    }
    return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ className: clsx(styles.root, { [styles.relative]: isRelative }), style: { maxWidth }, "data-testid": "FooterNavigation" }, { children: navigationTabs.map((tab, index) => {
                var _a;
                const tabClickHandler = () => {
                    const option = navigationTabs === null || navigationTabs === void 0 ? void 0 : navigationTabs.find(submenu => submenu.label === tab.label);
                    const switchFooterRoute = new CustomEvent("switch-footer-route", {
                        detail: {
                            id: option === null || option === void 0 ? void 0 : option.id,
                            route: tab.url,
                            isAuthenticationRequired: (option === null || option === void 0 ? void 0 : option.isAuthenticationRequired) === true
                        },
                        bubbles: true
                    });
                    window.dispatchEvent(switchFooterRoute);
                    const isEsportsTab = productId === 1 && tab.id === 2;
                    if (!(option === null || option === void 0 ? void 0 : option.isAuthenticationRequired) && !isEsportsTab) {
                        setActiveTab(tab.id);
                    }
                };
                return (_jsxs(React.Fragment, { children: [index === 2 && (_jsxs("div", Object.assign({ className: clsx(styles.totalBets, {
                                [styles.active]: !!(betslipItems !== null && betslipItems !== void 0 ? betslipItems : filteredBets.length)
                            }), onClick: toggleBetSlipHandler, "data-testid": "FooterNavigation.OpenBetSlip" }, { children: [_jsx("cite", Object.assign({ "data-testid": "FooterNavigation.OpenBetSlip.items" }, { children: betslipItems !== null && betslipItems !== void 0 ? betslipItems : filteredBets.length })), _jsx("div", Object.assign({ className: styles.totalOdds, "data-testid": "FooterNavigation.OpenBetSlip.totalOdds", ref: totalOddsRef }, { children: toFixed(totalOdds !== null && totalOdds !== void 0 ? totalOdds : 0) })), _jsx("strong", { children: "Total Odds" })] }))), _jsx(FooterNavigationTab, { tabClickHandler: tabClickHandler, isActiveClicked: activeTab === tab.id, Icon: tab === null || tab === void 0 ? void 0 : tab.Icon, label: tab.label, isDisabled: (tab === null || tab === void 0 ? void 0 : tab.label.toLowerCase()) === "live" && (liveEvents === null || liveEvents === void 0 ? void 0 : liveEvents.all) > 0 ?
                                false :
                                (tab === null || tab === void 0 ? void 0 : tab.label.toLowerCase()) === "live" && (liveEvents === null || liveEvents === void 0 ? void 0 : liveEvents.all) === 0 ?
                                    true :
                                    tab === null || tab === void 0 ? void 0 : tab.isDisabled, dataTestid: `FooterNavigationTab-${tab.id}${activeTab === tab.id ? "-active" : ""}`, counter: (tab === null || tab === void 0 ? void 0 : tab.id) === 2 ?
                                liveEvents === null || liveEvents === void 0 ? void 0 : liveEvents.all :
                                0 }, (_a = tab === null || tab === void 0 ? void 0 : tab.id) !== null && _a !== void 0 ? _a : tab.url + tab.label)] }, `footer-navigation-${index}`));
            }) })) }));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this["product-id"] = -1;
        this.relative = false;
    }
    connectedCallback() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(FooterNavigation, { productId: this["product-id"], currentRoute: this["current-route"], bets: this.bets, isRelative: this.relative, menuOptions: this["menu-options"], maxWidth: this["max-width"] }));
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    static get observedAttributes() {
        return ["product-id", "current-route", "bets", "relative", "menu-options", "max-width"];
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        var _a;
        switch (attrName) {
            case "product-id":
                this[attrName] = Number(newValue);
                break;
            case "current-route":
                this[attrName] = newValue;
                break;
            case "relative":
            case "menu-options":
            case "bets":
                this[attrName] = JSON.parse(newValue);
                break;
            case "max-width":
                this[attrName] = String(newValue);
                break;
            default:
                break;
        }
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(FooterNavigation, { productId: this["product-id"], currentRoute: this["current-route"], bets: this.bets, isRelative: this.relative, menuOptions: this["menu-options"], maxWidth: this["max-width"] }));
    }
}
(_a = customElements.get("esports-footer-navigation")) !== null && _a !== void 0 ? _a : customElements.define("esports-footer-navigation", Element);
