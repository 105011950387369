import React from "react";
export const useShowIndicator = ({ refVariation }) => {
    const [showIndicator, setShowIndicator] = React.useState(false);
    const [timeoutActive, setTimeoutActive] = React.useState(false);
    React.useEffect(() => {
        let timeoutId;
        if (refVariation !== 0 && !timeoutActive) {
            setShowIndicator(true);
            setTimeoutActive(true);
            timeoutId = setTimeout(() => {
                setShowIndicator(false);
                setTimeoutActive(false);
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
                setShowIndicator(false);
            }
        };
    }, [refVariation]);
    return { showIndicator };
};
