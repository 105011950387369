var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import clsx from "clsx";
import { Row, Col } from "antd";
import { createRoot } from "react-dom/client";
import { LocalStorage } from "helpers/Storage";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import { formatOdds } from "helpers/Bets";
import { useSubscribeByQuery } from "helpers/graphql/useSubscribeByQuery";
import { SUBSCRIBE_ESPORTS_MARKET_SELECTION } from "v2/graphql/subscriptions";
import { BETSLIP_SELECTION_STATUS } from "enums";
// styles
import styles from "./OddsMultiEventInput.module.scss";
export const OddsMultiEventInput = ({ data, variation = 0, selectedOutcomes, productId, isWebcomponent, onClick }) => {
    const [liveData, setLiveData] = React.useState(data);
    const { abId, isOpen, marketName, selectionLabel, odds, eventName, seriesId, label, marketId, statusId } = liveData;
    const valueHandlerCallback = (details) => {
        const data = details.GetEsportsMarketSelections[0];
        if (data) {
            setLiveData(liveData => {
                const updatedOddsButtonDetails = Object.assign({}, liveData);
                const isOpen = data.state === "open";
                updatedOddsButtonDetails.isOpen = isOpen;
                updatedOddsButtonDetails.statusId = isOpen ? BETSLIP_SELECTION_STATUS.OPEN : BETSLIP_SELECTION_STATUS.CLOSED;
                updatedOddsButtonDetails.odds = data.selections[0].odds;
                return updatedOddsButtonDetails;
            });
        }
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_ESPORTS_MARKET_SELECTION,
        variables: {
            marketId,
            outcomeId: abId
        },
        valueHandlerCallback
    });
    const suspended = isOpen === false;
    const highlight = selectedOutcomes.includes(abId);
    const webcomponentClickHandler = () => {
        var _a;
        let operation = "select";
        if (selectedOutcomes.includes(abId)) {
            operation = "unselect";
        }
        const bets = (_a = LocalStorage.get("betsDetails")) !== null && _a !== void 0 ? _a : [];
        const isBetAlreadyInPlace = bets.find((bet) => bet.outcomeId === abId);
        let updatedBets = [...bets];
        if (!isBetAlreadyInPlace && operation === "select") {
            if (isOpen) {
                const newBet = {
                    odds,
                    outcomeId: abId,
                    marketId,
                    market: marketName,
                    pick: selectionLabel,
                    eventId: seriesId,
                    productId,
                    event: eventName,
                    statusId
                    // time: e.detail.time,
                    // startDate: e.detail.startDate,
                };
                updatedBets.push(newBet);
            }
        }
        else if (isBetAlreadyInPlace && operation === "unselect") {
            updatedBets = updatedBets.filter((bet) => !(bet.outcomeId === abId));
        }
        selectedOutcomes = updatedBets.map((bet) => bet.outcomeId);
        LocalStorage.set("betsDetails", JSON.stringify(updatedBets));
        const triggerOddsMultiEventInputDetails = new CustomEvent("odds-multievent-input-details", {
            detail: {
                updatedBets
            },
            bubbles: true
        });
        window.dispatchEvent(triggerOddsMultiEventInputDetails);
    };
    const onOddsClick = () => {
        onClick === null || onClick === void 0 ? void 0 : onClick({ abId, isOpen, marketName, selectionLabel, odds, eventName, statusId, marketId });
    };
    if (!label || !odds) {
        return null;
    }
    return (_jsxs(Row, Object.assign({ className: clsx(styles.input, { [styles.suspended]: suspended, [styles.highlight]: highlight }), justify: "center", align: "middle", gutter: 0, onClick: isWebcomponent ? webcomponentClickHandler : onOddsClick }, { children: [_jsx(Col, Object.assign({ flex: "15px", className: styles.label }, { children: label })), _jsx(Col, Object.assign({ flex: "auto", className: styles.odds }, { children: suspended ? "-" : formatOdds(odds) })), suspended ?
                (_jsx(ImageFromCDN, { alt: "Lock", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/lock.svg", size: 8 })) :
                variation !== 0 &&
                    (_jsx(Col, Object.assign({ flex: "15px", style: { minHeight: "100%" } }, { children: variation > 0 ?
                            (_jsx("svg", Object.assign({ width: "8", height: "8", style: {
                                    position: "absolute",
                                    top: "0px",
                                    right: "0px"
                                } }, { children: _jsx("polygon", { points: "8, 0, 0, 0, 8, 8", fill: "green" }) }))) :
                            (variation < 0 && (_jsx("svg", Object.assign({ width: "8", height: "8", style: {
                                    position: "absolute",
                                    bottom: "0px",
                                    right: "0px"
                                } }, { children: _jsx("polygon", { points: "8, 0, 0, 8, 8, 8", fill: "red" }) })))) })))] }), `odds_multievent_input_${abId}`));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this.data = {};
        this.variation = 0;
        this["selected-outcomes"] = [];
    }
    static get observedAttributes() {
        return ["data", "variation", "product-id", "selected-outcomes"];
    }
    renderComponent() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(OddsMultiEventInput, { data: this.data, variation: this.variation, selectedOutcomes: this["selected-outcomes"], productId: this["product-id"], isWebcomponent: true }, `odds_multievent_input_${this.data.abId}`));
    }
    connectedCallback() {
        this.renderComponent();
    }
    attributeChangedCallback(attrName, oldValue, newValue) {
        switch (attrName) {
            case "data":
            case "selected-outcomes":
                this[attrName] = JSON.parse(newValue);
                break;
            case "product-id":
                this[attrName] = Number(newValue);
                break;
            case "variation":
                if (oldValue && newValue) {
                    this[attrName] = Number(newValue) - Number(oldValue);
                }
                else {
                    this[attrName] = 0;
                }
                break;
            default:
                break;
        }
        this.renderComponent();
    }
}
(_a = customElements.get("esports-odds-multievent-input")) !== null && _a !== void 0 ? _a : customElements.define("esports-odds-multievent-input", Element);
