import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import clsx from "clsx";
import styles from "./StakeInput.module.scss";
export const StakeInput = ({ inputId, inputValue, setInputValue, isActive, applyToAll, className, removeLatestDigit, disabled }) => {
    const triggerActiveInput = () => {
        const activeInput = new CustomEvent(`bet-slip-active-input`, {
            detail: {
                inputId
            },
            bubbles: true
        });
        window.dispatchEvent(activeInput);
    };
    React.useEffect(() => {
        applyToAll && triggerActiveInput();
    }, [applyToAll]);
    return (_jsxs("div", Object.assign({ className: clsx(styles.root, className, { [styles.active]: isActive }), onFocus: triggerActiveInput, "data-testid": "StakeInput" }, { children: [_jsx("label", Object.assign({ htmlFor: inputId, className: styles.placeholder }, { children: "Stake" })), _jsx("input", { id: inputId, type: "text", className: styles.input, placeholder: "0", inputMode: "none", onFocus: (e) => {
                    e.preventDefault();
                }, disabled: disabled, value: inputValue, onChange: (e) => {
                    const { value } = e.target;
                    !isNaN(+value) && +value > 0 && setInputValue(value);
                }, onKeyDown: (e) => {
                    if (e.key === "Backspace") {
                        // Prevent the default behavior of the backspace key
                        e.preventDefault();
                        removeLatestDigit();
                    }
                }, step: "any", min: 0, maxLength: 10 })] })));
};
