var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
// import clsx from "clsx";
import { createRoot } from "react-dom/client";
import SearchInput from "../../base/searchInput/SearchInput";
import { AZ } from "components/composed/leagueSingleMarketFeatured/filteredComponents/AZ/AZ";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import clsx from "clsx";
import axios from "axios";
import { SportsEventsSkeleton } from "./sportsEventsSkeleton/SportsEventsSkeleton";
import { SearchEvent } from "./searchEvent/SearchEvent";
import { slugify } from "helpers/GeneralFunctions";
import styles from "./CountryLeaguesSearch.module.scss";
const tagsSource = "country-leagues-search";
const defaultSearchFilters = [
    { id: "events", name: "Events", disabled: false },
    { id: "competitions", name: "Competitions", disabled: false }
];
const CountryLeaguesSearch = ({ withHeader, withSearch, focused, noDataOptions }) => {
    var _a, _b;
    const [isLoading, setIsLoading] = React.useState(false);
    const [eventsData, setEventsData] = React.useState();
    const [tournamentsData, setTournamentsData] = React.useState();
    const [isBBEnabled, setIsBBEnabled] = React.useState(false);
    const [searchFilters, setSearchFilters] = React.useState(defaultSearchFilters);
    const [selectedOption, setSelectedOption] = React.useState(searchFilters[0].id);
    const isEventsSelected = selectedOption === "events";
    const isCompetitionsSelected = selectedOption === "competitions";
    React.useEffect(() => {
        const legueMarketsTabsHandler = (e) => {
            setSelectedOption(e.detail.id);
        };
        window.addEventListener("selected-tag-details", legueMarketsTabsHandler);
        return () => {
            window.removeEventListener("selected-tag-details", legueMarketsTabsHandler);
        };
    }, []);
    // fetch isBetBuilderEnabled
    React.useEffect(() => {
        const fetchClientData = () => {
            var _a;
            if (!((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL))
                return;
            setIsLoading(true);
            axios
                .get(`${process.env.REACT_APP_GATEWAY_API_URL}/client/config`)
                .then((res) => {
                var _a;
                const { isBetBuilderEnabled } = (_a = res.data) !== null && _a !== void 0 ? _a : {};
                setIsBBEnabled(isBetBuilderEnabled);
            })
                .catch((e) => {
                console.log(e);
            })
                .finally(() => {
                setIsLoading(false);
            });
        };
        fetchClientData();
    }, []);
    const fetchSearchResults = (query) => __awaiter(void 0, void 0, void 0, function* () {
        if (query.length < 4) {
            if (isEventsSelected) {
                setEventsData(null);
            }
            if (isCompetitionsSelected) {
                setTournamentsData(null);
            }
            setSearchFilters(defaultSearchFilters);
            return;
        }
        const timezoneOffset = new Date().getTimezoneOffset() * -1;
        const eventEndpoint = `/event-search-results/${query}?timezoneOffset=${timezoneOffset}`;
        const tournamentEndpoint = `/tournament-search-results/${query}?timezoneOffset=${timezoneOffset}`;
        setIsLoading(true);
        const eventPromise = axios.get(eventEndpoint);
        const tournamentPromise = axios.get(tournamentEndpoint);
        Promise.all([eventPromise, tournamentPromise])
            .then(([eventResponse, tournamentResponse]) => {
            var _a, _b;
            const eventData = eventResponse.data;
            const tournamentData = tournamentResponse.data;
            setEventsData(eventData);
            const eventsCount = (_a = eventData === null || eventData === void 0 ? void 0 : eventData.reduce((acc, item) => acc + item.events.length, 0)) !== null && _a !== void 0 ? _a : 0;
            setSearchFilters(prevFilters => prevFilters.map(filter => {
                if (filter.id === "events") {
                    return Object.assign(Object.assign({}, filter), { name: `Events${eventsCount > 0 ? ` (${eventsCount})` : ""}` });
                }
                return filter;
            }));
            setTournamentsData(tournamentData);
            const competitionsCount = (_b = tournamentData === null || tournamentData === void 0 ? void 0 : tournamentData.reduce((acc, item) => acc + item.tournaments.length, 0)) !== null && _b !== void 0 ? _b : 0;
            setSearchFilters(prevFilters => prevFilters.map(filter => {
                if (filter.id === "competitions") {
                    return Object.assign(Object.assign({}, filter), { name: `Competitions${competitionsCount > 0 ? ` (${competitionsCount})` : ""}` });
                }
                return filter;
            }));
        })
            .catch((err) => {
            console.log(err);
        })
            .finally(() => {
            setIsLoading(false);
        });
    });
    const dispatchEventLeagueRow = ({ country, league, sport }) => {
        var _a;
        const triggerEventLeagueDetails = new CustomEvent("league-event-details", {
            detail: {
                sport: { id: sport.id, name: sport.name },
                country: {
                    id: country.id,
                    name: country.name
                },
                league: {
                    id: league.id,
                    name: league.name
                },
                totalEvents: (_a = league.events) === null || _a === void 0 ? void 0 : _a.length
            },
            bubbles: true
        });
        window.dispatchEvent(triggerEventLeagueDetails);
    };
    const tournamentClickHandler = ({ country, league, sport }) => () => {
        dispatchEventLeagueRow({ country, league, sport });
    };
    const eventClickHandler = ({ match, sport }) => () => {
        const triggerEventMatchDetails = new CustomEvent("match-event-details", {
            detail: {
                sport: {
                    id: sport === null || sport === void 0 ? void 0 : sport.id,
                    name: sport === null || sport === void 0 ? void 0 : sport.name
                },
                country: {
                    id: match.tournament.category.id,
                    name: match.tournament.category.name
                },
                league: {
                    id: match.tournament.id,
                    name: match.tournament.name
                },
                match: {
                    id: match.id,
                    name: match.name,
                    homeTeam: match.homeTeam,
                    awayTeam: match.awayTeam,
                    status: match.statusId,
                    startDate: match.startDate,
                    isBetBuilderEnabled: match.isBetBuilderEnabled
                }
            },
            bubbles: true
        });
        window.dispatchEvent(triggerEventMatchDetails);
    };
    let totalResults = 0;
    totalResults += (_a = eventsData === null || eventsData === void 0 ? void 0 : eventsData.reduce((acc, item) => acc + item.events.length, 0)) !== null && _a !== void 0 ? _a : 0;
    totalResults += (_b = tournamentsData === null || tournamentsData === void 0 ? void 0 : tournamentsData.reduce((acc, item) => acc + item.tournaments.length, 0)) !== null && _b !== void 0 ? _b : 0;
    return (_jsxs(_Fragment, { children: [withHeader && withSearch &&
                _jsx(SearchInput, { fetchResults: fetchSearchResults, focused: focused, results: eventsData && tournamentsData && totalResults, selectedOption: selectedOption }), _jsx("div", Object.assign({ className: styles.tagsListWrapper }, { children: _jsx("esports-tags-list", { source: tagsSource, selected: selectedOption, tags: JSON.stringify(searchFilters), "full-width": true }) })), _jsxs("div", Object.assign({ className: styles.contentWrapper }, { children: [isLoading ?
                        _jsx(SportsEventsSkeleton, {}) :
                        _jsxs(_Fragment, { children: [isEventsSelected && (eventsData === null || eventsData === void 0 ? void 0 : eventsData.map(sportData => {
                                    return _jsx("div", Object.assign({ className: styles.sportListWrapper }, { children: _jsxs("div", Object.assign({ className: styles.sportList }, { children: [_jsx("div", Object.assign({ className: clsx(styles.item, styles.title) }, { children: _jsxs("div", Object.assign({ className: styles.content }, { children: [_jsx(ImageFromCDN, { alt: "Football", url: `https://d1sg8aqjbar3ur.cloudfront.net/sports/${slugify(sportData.sport.name)}.svg`, width: 16 }), sportData.sport.name] })) })), sportData.events.map(event => {
                                                    return _jsx(SearchEvent, { match: event, sport: sportData.sport, isBBEnabled: isBBEnabled, onClick: eventClickHandler }, `search-sport-item-${sportData.sport.id}-${event.id}`);
                                                })] })) }), `search-sport-item-${sportData.sport.id}`);
                                })), isCompetitionsSelected && (tournamentsData === null || tournamentsData === void 0 ? void 0 : tournamentsData.map(sportData => {
                                    return _jsx("div", Object.assign({ className: styles.sportListWrapper }, { children: _jsxs("div", Object.assign({ className: styles.sportList }, { children: [_jsx("div", Object.assign({ className: clsx(styles.item, styles.title) }, { children: _jsxs("div", Object.assign({ className: styles.content }, { children: [_jsx(ImageFromCDN, { alt: "Football", url: `https://d1sg8aqjbar3ur.cloudfront.net/sports/${slugify(sportData.sport.name)}.svg`, width: 16 }), sportData.sport.name] })) })), sportData.tournaments.map(tournament => {
                                                    return _jsxs("div", Object.assign({ className: styles.item, onClick: tournamentClickHandler({
                                                            country: tournament.category,
                                                            league: tournament,
                                                            sport: sportData.sport
                                                        }) }, { children: [_jsx("div", Object.assign({ className: styles.content }, { children: _jsxs("div", Object.assign({ className: styles.eventName }, { children: [tournament.category.imageUrl && _jsx(ImageFromCDN, { alt: tournament.category.name, url: tournament.category.imageUrl, width: 16 }), tournament.name] })) })), _jsx(ImageFromCDN, { alt: "Arrow right", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/keyboard_arrow_right.svg", color: "#1C1B1F", size: 10 })] }), `search-sport-item-${sportData.sport.id}-${tournament.id}`);
                                                })] })) }), `search-sport-item-${sportData.sport.id}`);
                                }))] }), _jsx(AZ, { noDataOptions: noDataOptions, showFeatured: !totalResults })] }))] }));
};
class Element extends HTMLElement {
    static get observedAttributes() {
        return ["with-header", "with-search", "bordered", "scrollable", "focused", "no-data-options"];
    }
    connectedCallback() {
        createRoot(this).render(_jsx(CountryLeaguesSearch, { withHeader: this["with-header"], withSearch: this["with-search"], bordered: this.bordered, scrollable: this.scrollable, focused: this.focused, noDataOptions: this["no-data-options"] }));
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        switch (attrName) {
            case "with-header":
            case "with-search":
            case "bordered":
            case "scrollable":
            case "focused":
            case "no-data-options":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
    }
}
(_a = customElements.get("esports-country-leagues-search")) !== null && _a !== void 0 ? _a : customElements.define("esports-country-leagues-search", Element);
