import { CLOCK_STATUS } from "enums";
export const periodConverter = (period, sportId, statusId, minutes, seconds, isShorten) => {
    // returns a period label based on a given period, independently of its type
    let convertedPeriod;
    switch (period === null || period === void 0 ? void 0 : period.toUpperCase()) {
        case "1H":
        case "1ST HALF":
            convertedPeriod = "1ST HALF";
            break;
        case "2H":
        case "2ND HALF":
            convertedPeriod = "2ND HALF";
            break;
        case "EXTRA TIME FIRST HALF":
            convertedPeriod = "1ST HALF ET";
            break;
        case "EXTRA TIME SECOND HALF":
            convertedPeriod = "2ND HALF ET";
            break;
        case "PENALTY SHOOTOUT":
            convertedPeriod = "PENS";
            break;
        case "1ST QUARTER":
            convertedPeriod = isShorten ? "Q1" : "1ST QUARTER";
            break;
        case "2ND QUARTER":
            convertedPeriod = isShorten ? "Q2" : "2ND QUARTER";
            break;
        case "3RD QUARTER":
            convertedPeriod = isShorten ? "Q3" : "3RD QUARTER";
            break;
        case "4TH QUARTER":
            convertedPeriod = isShorten ? "Q4" : "4TH QUARTER";
            break;
        case "SET1":
        case "GAME 1":
            convertedPeriod = "1st SET";
            break;
        case "SET2":
        case "GAME 2":
            convertedPeriod = "2nd SET";
            break;
        case "SET3":
        case "GAME 3":
            convertedPeriod = "3rd SET";
            break;
        case "SET4":
        case "GAME 4":
            convertedPeriod = "4th SET";
            break;
        case "SET5":
        case "GAME 5":
            convertedPeriod = "5th SET";
            break;
        case "GAME 6":
            convertedPeriod = "6th SET";
            break;
        case "GAME 7":
            convertedPeriod = "7th SET";
            break;
        default:
            convertedPeriod = period;
            break;
    }
    // Check for exceptions to the converted period
    // If something is returned, then it gets passed down to the convertedPeriod
    if (sportId && statusId && minutes !== undefined && seconds !== undefined && convertedPeriod) {
        const checkedPeriod = checkForExceptions(sportId, statusId, minutes, seconds, convertedPeriod);
        if (checkedPeriod !== undefined)
            convertedPeriod = checkedPeriod;
    }
    return convertedPeriod;
};
export const checkForExceptions = (sportId, statusId, minutes, seconds, convertedPeriod) => {
    let convertedPeriodException;
    // exception 1: When dealing with soccer to present the Half Time label
    if (sportId === 1 &&
        convertedPeriod === "2ND HALF" &&
        statusId === CLOCK_STATUS.STOPPED &&
        minutes === 45 &&
        seconds === 0)
        convertedPeriodException = "HALF TIME";
    return convertedPeriodException;
};
export const periods = {
    1: {
        "1ST HALF": 45,
        "2ND HALF": 90
    }
};
