import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Row, Col } from "antd";
import { useSubscribeByQuery } from "helpers/graphql/useSubscribeByQuery";
import { SUBSCRIBE_ESPORTS_MARKET_SELECTIONS } from "v2/graphql/subscriptions";
import styles from "./OddsOutcomes.module.scss";
export const EsportsMarketGroupFromMarket = ({ marketGroup, item, productId, defaultOutcomes, seriesId, name, startDate, sport }) => {
    const [data, setData] = React.useState(item);
    const valueHandlerCallback = (details) => {
        const data = details.GetEsportsMarketSelections[0];
        if (data) {
            setData((liveData) => {
                const updatedOddsButtonDetails = Object.assign({}, liveData);
                const isOpen = data.state === "open";
                updatedOddsButtonDetails.isOpen = isOpen;
                updatedOddsButtonDetails.marketSelections.forEach((selection) => {
                    const matchingActualSelection = data.selections.find((actualSelection) => actualSelection.id === selection.abId);
                    if (matchingActualSelection && selection.odds !== matchingActualSelection.odds) {
                        selection.odds = matchingActualSelection.odds;
                        selection.isOpen = isOpen;
                    }
                });
                return updatedOddsButtonDetails;
            });
        }
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_ESPORTS_MARKET_SELECTIONS,
        variables: {
            marketId: item.abId
        },
        valueHandlerCallback
    });
    return _jsx(Row, Object.assign({ justify: "space-between", align: "middle", gutter: [5, 8], className: styles.contentRow }, { children: marketGroup.marketSelections.map((selection) => {
            var _a, _b;
            const selectionFromDD = (_a = ((data === null || data === void 0 ? void 0 : data.selections) || (data === null || data === void 0 ? void 0 : data.marketSelections))) === null || _a === void 0 ? void 0 : _a.find((selectionFromDD) => selectionFromDD.id === selection.abId);
            const isOpen = (data === null || data === void 0 ? void 0 : data.state) ?
                data.state === "open" :
                (marketGroup === null || marketGroup === void 0 ? void 0 : marketGroup.state) ?
                    marketGroup.state === "open" :
                    selection.isOpen;
            return (_jsx(Col, Object.assign({ span: item.marketSelections.length > 3 ? 8 : 24 / item.marketSelections.length }, { children: _jsx("esports-odds-singleevent-input", { id: `odds-singlevent-input-${selection.abId}`, "product-id": productId, "default-outcomes": JSON.stringify(defaultOutcomes), data: JSON.stringify({
                        market: {
                            id: item.abId,
                            name: item.label,
                            status: item.state,
                            type: item.marketType
                        },
                        match: {},
                        series: {
                            id: seriesId,
                            name,
                            startDate
                        },
                        selection: {
                            id: selection.abId,
                            label: selection.label,
                            value: (_b = selectionFromDD === null || selectionFromDD === void 0 ? void 0 : selectionFromDD.odds) !== null && _b !== void 0 ? _b : selection.odds,
                            isOpen,
                            statusId: selection.statusId
                        }
                    }), sport: JSON.stringify(sport) }) }), `outcome-${selection.abId}`));
        }) }));
};
export const EsportsMarketGroupFromMatches = ({ marketGroup, productId, defaultOutcomes, seriesId, name, startDate, match, sport }) => {
    const [data, setData] = React.useState(marketGroup);
    const valueHandlerCallback = (details) => {
        const data = details.GetEsportsMarketSelections[0];
        if (data) {
            setData((liveData) => {
                const updatedOddsButtonDetails = Object.assign({}, liveData);
                const isOpen = data.state === "open";
                updatedOddsButtonDetails.isOpen = isOpen;
                updatedOddsButtonDetails.marketSelections.forEach((selection) => {
                    const matchingActualSelection = data.selections.find((actualSelection) => actualSelection.id === selection.abId);
                    if (matchingActualSelection && selection.odds !== matchingActualSelection.odds) {
                        selection.odds = matchingActualSelection.odds;
                        selection.isOpen = isOpen;
                    }
                });
                return updatedOddsButtonDetails;
            });
        }
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_ESPORTS_MARKET_SELECTIONS,
        variables: {
            marketId: marketGroup.abId
        },
        valueHandlerCallback
    });
    return _jsx(Row, Object.assign({ justify: "space-between", align: "middle", gutter: [5, 8], className: styles.contentRow }, { children: marketGroup.marketSelections.map((selection) => {
            var _a, _b;
            const selectionFromDD = (_a = ((data === null || data === void 0 ? void 0 : data.selections) || (data === null || data === void 0 ? void 0 : data.marketSelections))) === null || _a === void 0 ? void 0 : _a.find((selectionFromDD) => selectionFromDD.id === selection.abId);
            const isOpen = (data === null || data === void 0 ? void 0 : data.state) ?
                data.state === "open" :
                (marketGroup === null || marketGroup === void 0 ? void 0 : marketGroup.state) ?
                    marketGroup.state === "open" :
                    selection.isOpen;
            return (_jsx(Col, Object.assign({ span: marketGroup.marketSelections.length > 3 ?
                    8 :
                    24 / marketGroup.marketSelections.length }, { children: _jsx("esports-odds-singleevent-input", { id: `odds-singlevent-input-${selection.abId}`, "product-id": productId, "default-outcomes": JSON.stringify(defaultOutcomes), data: JSON.stringify({
                        market: {
                            id: marketGroup.abId,
                            name: marketGroup.label,
                            status: marketGroup.state,
                            type: marketGroup.marketType
                        },
                        match,
                        series: {
                            id: seriesId,
                            name,
                            startDate
                        },
                        selection: {
                            id: selection.abId,
                            label: selection.label,
                            value: (_b = selectionFromDD === null || selectionFromDD === void 0 ? void 0 : selectionFromDD.odds) !== null && _b !== void 0 ? _b : selection.odds,
                            isOpen,
                            statusId: selection.statusId
                        }
                    }), sport: JSON.stringify(sport) }) }), `outcome-${selection.abId}`));
        }) }));
};
