// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fBdLm6Tl2muFPdusJoXe{width:14px;height:14px;border:2px solid #fff;border-bottom-color:rgba(0,0,0,0);border-radius:50%;display:inline-block;box-sizing:border-box;animation:_oszaZmCSINawbXvITIR 1s linear infinite}@keyframes _oszaZmCSINawbXvITIR{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}`, "",{"version":3,"sources":["webpack://./src/helpers/loader/Loader.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,qBAAA,CACA,iCAAA,CACA,iBAAA,CACA,oBAAA,CACA,qBAAA,CACA,iDAAA,CAGA,gCACA,GACI,sBAAA,CAEJ,KACI,wBAAA,CAAA","sourcesContent":[".loader {\n  width: 14px;\n  height: 14px;\n  border: 2px solid #FFF;\n  border-bottom-color: transparent;\n  border-radius: 50%;\n  display: inline-block;\n  box-sizing: border-box;\n  animation: rotation 1s linear infinite;\n  }\n\n  @keyframes rotation {\n  0% {\n      transform: rotate(0deg);\n  }\n  100% {\n      transform: rotate(360deg);\n  }\n  } "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `fBdLm6Tl2muFPdusJoXe`,
	"rotation": `_oszaZmCSINawbXvITIR`
};
export default ___CSS_LOADER_EXPORT___;
