var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import { Row } from "antd";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import { EventCardRow } from "./EventCardSelection";
import { BETS_LIMIT } from "enums";
import { LocalStorage } from "helpers/Storage";
import styles from "./Event.module.scss";
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
const EventCard = ({ sport, events, country, league, marketCount, defaultOutcomes, marketView, noMarginTop, displayRule, isBBEnabled }) => {
    const [selectedOutcomes, setSelectedOutcome] = React.useState(defaultOutcomes !== null && defaultOutcomes !== void 0 ? defaultOutcomes : []);
    const [eventsData, setEventsData] = React.useState(events);
    React.useEffect(() => {
        events && setEventsData(events);
    }, [events]);
    const onClick = (obj) => {
        var _a;
        const limited = ((_a = LocalStorage.get("betsDetails")) === null || _a === void 0 ? void 0 : _a.length) >= BETS_LIMIT;
        // add or remove odd id to the selected state
        let operation = "select";
        if (selectedOutcomes.includes(obj === null || obj === void 0 ? void 0 : obj.outcomeId)) {
            operation = "unselect";
            setSelectedOutcome(oldArray => [...oldArray.filter(outcome => outcome !== (obj === null || obj === void 0 ? void 0 : obj.outcomeId))]);
        }
        else {
            (obj === null || obj === void 0 ? void 0 : obj.isOpen) && !limited && setSelectedOutcome(oldArray => [...oldArray, obj === null || obj === void 0 ? void 0 : obj.outcomeId]);
        }
        const { isOpen } = obj, rest = __rest(obj, ["isOpen"]);
        const triggerMatchesByMarketInputDetails = new CustomEvent("matches-bymarket-input-details", {
            detail: Object.assign(Object.assign({}, rest), { operation, isOpen: isOpen && !limited }),
            bubbles: true
        });
        window.dispatchEvent(triggerMatchesByMarketInputDetails);
        // emits a data layer when input is selected
        if (operation === "select") {
            const triggerBetDataLayerEvent = (action) => {
                window.dispatchEvent(new CustomEvent("bet-data-layer", {
                    detail: {
                        event: action,
                        event_count: 1,
                        events: [{
                                id: `${String(obj === null || obj === void 0 ? void 0 : obj.outcomeId)}`,
                                product_category: "Sports",
                                sport_category: sport === null || sport === void 0 ? void 0 : sport.name,
                                name: obj === null || obj === void 0 ? void 0 : obj.event,
                                market_name: obj === null || obj === void 0 ? void 0 : obj.marketName,
                                add_to_betslip_place: "",
                                league_name: obj === null || obj === void 0 ? void 0 : obj.leagueName,
                                outcome_name: obj === null || obj === void 0 ? void 0 : obj.name,
                                odd_type: dayjs(obj === null || obj === void 0 ? void 0 : obj.startDate) >= dayjs() ? "pregame" : "inplay",
                                odd: obj === null || obj === void 0 ? void 0 : obj.odds
                            }]
                    },
                    bubbles: true
                }));
            };
            triggerBetDataLayerEvent("add_bet");
        }
    };
    // injects default outcomes in the selectedOutcomes state, so it is highlighted
    React.useEffect(() => {
        if (defaultOutcomes && (defaultOutcomes === null || defaultOutcomes === void 0 ? void 0 : defaultOutcomes.length) > 0) {
            setSelectedOutcome(oldArray => [...oldArray, ...defaultOutcomes]);
        }
        const handleRemoveBet = (e) => {
            const { outcomeId } = e.detail;
            if (outcomeId) {
                setSelectedOutcome(oldArray => [...oldArray.filter(outcome => outcome !== outcomeId)]);
            }
        };
        const handleClearBets = () => { setSelectedOutcome([]); };
        const handleReuseBets = (e) => {
            var _a;
            ((_a = e === null || e === void 0 ? void 0 : e.detail) === null || _a === void 0 ? void 0 : _a.bets) && setSelectedOutcome(e.detail.bets);
        };
        window.addEventListener("bet-slip-reuse-bets", handleReuseBets);
        window.addEventListener("bet-slip-bet-remove", handleRemoveBet);
        window.addEventListener("bet-slip-clear-bets", handleClearBets);
        return () => {
            window.removeEventListener("bet-slip-reuse-bets", handleReuseBets);
            window.removeEventListener("bet-slip-bet-remove", handleRemoveBet);
            window.removeEventListener("bet-slip-clear-bets", handleClearBets);
        };
    }, []);
    if (eventsData === null || eventsData === void 0 ? void 0 : eventsData.length) {
        return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ className: styles.wrapper }, { children: eventsData.map((match, index) => {
                    return (_jsx("div", Object.assign({ className: styles.oddsRow, style: {
                            borderBottom: (eventsData === null || eventsData === void 0 ? void 0 : eventsData.length) === index + 1 ? "" : "1px solid #f2f2f2"
                        } }, { children: _jsx(Row, Object.assign({ style: {
                                marginLeft: 8,
                                marginRight: 8
                                // paddingBottom: (eventsData?.length === index + 1) ? 12 : 0
                            } }, { children: _jsx(EventCardRow, { match: match, sport: sport, onClick: onClick, country: country, league: league, displayRule: displayRule, selectedOutcomes: selectedOutcomes, marketView: marketView, isBBEnabled: isBBEnabled }) })) }), `league-wrapper-${match.id}-${index}`));
                }) })) }));
    }
    // TODO: Add skeleton and error messages - WIP
    return _jsx("div", {});
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this.sport = {};
        this.events = [];
        this.country = {};
        this.league = {};
        this["market-count"] = 0;
        this["default-outcomes"] = [];
        this["market-view"] = "default";
        this["display-rule"] = "DEFAULT";
    }
    static get observedAttributes() {
        return [
            "sport",
            "events",
            "country",
            "league",
            "market-count",
            "default-outcomes",
            "market-view",
            "no-margin-top",
            "display-rule",
            "bb-enabled"
        ];
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    renderComponent() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(EventCard, { sport: this.sport, events: this.events, country: this.country, league: this.league, marketCount: this["market-count"], defaultOutcomes: this["default-outcomes"], marketView: this["market-view"], noMarginTop: this["no-margin-top"], displayRule: this["display-rule"], isBBEnabled: this["bb-enabled"] }));
    }
    connectedCallback() {
        this.renderComponent();
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        switch (attrName) {
            case "sport":
            case "events":
            case "country":
            case "league":
            case "default-outcomes":
            case "no-margin-top":
            case "bb-enabled":
                this[attrName] = JSON.parse(newValue);
                break;
            case "market-count":
                this[attrName] = Number(newValue);
                break;
            case "market-view":
            case "display-rule":
                this[attrName] = String(newValue);
                break;
            default:
                break;
        }
        this.renderComponent();
    }
}
(_a = customElements.get("esports-league-event-card")) !== null && _a !== void 0 ? _a : customElements.define("esports-league-event-card", Element);
