import { jsx as _jsx } from "react/jsx-runtime";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
const componentObj = {
    abId: 1,
    data: {
        market: {
            id: 234,
            name: "Series Winner",
            status: null,
            type: null
        },
        match: {},
        series: {
            id: 123,
            name: "Team vitality vs Monte",
            startDate: "2023-09-29T13:30:00+00:00"
        },
        selection: {
            id: 8307809,
            label: "Team vitality to win",
            value: 2.10,
            isOpen: false
        }
    }
};
export const documentDetails = {
    title: "Odds single event input",
    component: "esports-odds-singleevent-input",
    description: "This is a base component, that is used as part of many composed components.",
    attributes: [
        {
            key: "1",
            attr: "data",
            type: "object",
            notes: [
                "Data object expects the following information.",
                `{
          series: { id: number, name: string, startDate: string },
          market: { id: number, name: string, status: string, type: string },
          match?: { id: number, status: string },
          selection: { id: number, label: string, value: number, isOpen: boolean }
        }`,
                "startDate example would be 2023-09-29T13:30:00+00:00"
            ]
            // tags: ["nice", "developer"]
        },
        {
            key: "2",
            attr: "default-outcomes",
            type: "number[] (optional)",
            notes: "You can decide to inject some default outcomes, which means they would be highlighted by default."
        },
        {
            key: "3",
            attr: "variation",
            type: "number (automatically generated)",
            disabled: true,
            notes: "The old and new values are checked, and processed, so the component knows its variation"
            // tags: ["loser"]
        }
    ],
    events: [
        {
            event: "odds-singleevent-input-details",
            type: "emit",
            data: [
                "The component details object is emitted when input is clicked, containing the following information.",
                `detail: {
          seriesid: <value>,
          matchid: <value>,
          marketid: <value>,
          selectionid: <value>,
          label: <value>,
          value: <value>,
          isOpen: <value>
       }`
            ]
        }
    ]
};
const OddsSingleEventInput = () => {
    documentDetails.example = `<esports-odds-singleevent-input
      id={\`odds_singlevent_input_\${oddsButtonsData.marketSelections[0].abId\`}}
      data={JSON.stringify({
        market: {
          id: item.abId,
          name: item.label,
          status: item.state,
          type: item.marketType
        },
        match: {},
        series: {
          id: seriesId,
          name: data?.name,
          startDate: data?.startDate
        },
        selection: {
          id: selection.abId,
          label: selection.label,
          value: selection.odds,
          isOpen: selection.isOpen
        }
      })}
    />`;
    documentDetails.code = _jsx("esports-odds-singleevent-input", { id: `odds_singlevent_input_${componentObj.abId}`, data: JSON.stringify(componentObj.data) });
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default OddsSingleEventInput;
