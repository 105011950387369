import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import { toFixed } from "helpers/GeneralFunctions";
import { BETSLIP_SELECTION_STATUS } from "enums";
import clsx from "clsx";
import styles from "./GroupedBetSelection.module.scss";
export const GroupedBetSelection = ({ groupedBet, totalOdds, onBetRemove, errors }) => {
    var _a, _b, _c;
    const [prevOdds, setPrevOdds] = React.useState(null);
    const removeBetHandler = (outcomeId) => {
        onBetRemove(outcomeId);
        const triggerBetRemove = new CustomEvent(`bet-slip-bet-remove`, {
            detail: {
                outcomeId
            },
            bubbles: true
        });
        window.dispatchEvent(triggerBetRemove);
        setPrevOdds(null);
    };
    const isSuspended = groupedBet === null || groupedBet === void 0 ? void 0 : groupedBet.some(bet => bet.statusId === BETSLIP_SELECTION_STATUS.SUSPENDED);
    const isClosed = groupedBet === null || groupedBet === void 0 ? void 0 : groupedBet.some(bet => bet.statusId === BETSLIP_SELECTION_STATUS.CLOSED);
    const sortedBets = [...groupedBet];
    const eventIds = sortedBets.map((bet) => bet === null || bet === void 0 ? void 0 : bet.eventId); // returns a list of unique event IDs
    const isSameEventId = eventIds.every(eventId => eventId === eventIds[0]); // check every event ID is the same
    // finds the event level related information
    const errorEvent = isSameEventId && (eventIds === null || eventIds === void 0 ? void 0 : eventIds.length) ?
        (_a = errors === null || errors === void 0 ? void 0 : errors.atEventLevel) === null || _a === void 0 ? void 0 : _a.find((bet) => bet.eventId === eventIds[0]) :
        {};
    return _jsxs("div", Object.assign({ className: styles.groupedBet }, { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx("div", Object.assign({ className: styles.eventTitle }, { children: groupedBet[0].event })), _jsx("div", Object.assign({ className: styles.details }, { children: isSuspended || isClosed ?
                            _jsx("div", Object.assign({ className: styles.warning }, { children: isSuspended ? "Suspended" : "Closed" })) :
                            _jsxs("div", Object.assign({ className: styles.odds }, { children: [prevOdds && prevOdds !== totalOdds && (_jsx("strong", Object.assign({ className: clsx(styles.odd, styles.prevOdds, {
                                            [styles.arrowUpIcon]: prevOdds < totalOdds,
                                            [styles.arrowDownIcon]: prevOdds > totalOdds
                                        }) }, { children: toFixed(prevOdds) }))), _jsx("strong", Object.assign({ className: styles.odd }, { children: toFixed(totalOdds) === "1.00" ?
                                            _jsx(ImageFromCDN, { alt: "Lock", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/lock.svg", size: 16 }) :
                                            toFixed(totalOdds) }))] })) }))] })), isSameEventId &&
                errorEvent &&
                ((_b = Object.keys(errorEvent)) === null || _b === void 0 ? void 0 : _b.length) > 0 &&
                _jsx("div", Object.assign({ className: styles.error, "data-testid": "Betslip.groupedBetSelectionErrorMessage" }, { children: errorEvent === null || errorEvent === void 0 ? void 0 : errorEvent.message })), (_c = sortedBets === null || sortedBets === void 0 ? void 0 : sortedBets.sort((a, b) => a.order - b.order)) === null || _c === void 0 ? void 0 : _c.map((bet, index) => {
                const { market, pick, outcomeId } = bet;
                return (_jsxs("div", Object.assign({ className: styles.bet, "data-testid": `GroupedBet-${index}` }, { children: [_jsxs("div", Object.assign({ className: styles.groupSelections }, { children: [_jsx("div", { className: styles.line }), _jsx("div", { className: styles.indicator }), _jsx("div", { className: clsx(styles.line, { [styles.hidden]: index === sortedBets.length - 1 }) })] })), _jsx("div", Object.assign({ className: styles.content }, { children: _jsxs("div", Object.assign({ className: styles.info }, { children: [_jsx("div", Object.assign({ className: styles.pick }, { children: pick })), _jsx("div", Object.assign({ className: styles.type }, { children: market }))] })) })), _jsx("div", Object.assign({ className: styles.removeBet, onClick: () => { removeBetHandler(outcomeId); }, "data-testid": `GroupedBet-${index}.removeBtn` }, { children: _jsx(ImageFromCDN, { alt: "Remove bet", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/close.svg", width: 16, height: 18 }) }))] }), outcomeId));
            })] }));
};
