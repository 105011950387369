import { jsx as _jsx } from "react/jsx-runtime";
import { Table, Typography } from "antd";
import { v4 as uuidv4 } from "uuid";
const { Text } = Typography;
const columns = [
    {
        title: "Attribute",
        dataIndex: "attr",
        key: "attr-" + uuidv4(),
        width: 145,
        render: (text, record) => _jsx(Text, Object.assign({ copyable: !(record === null || record === void 0 ? void 0 : record.disabled), style: { color: !(record === null || record === void 0 ? void 0 : record.disabled) ? "blue" : "grey" } }, { children: text }))
    },
    {
        title: "Type",
        dataIndex: "type",
        key: "type-" + uuidv4(),
        width: 85
    },
    {
        title: "Notes",
        dataIndex: "notes",
        key: "notes-" + uuidv4(),
        render: (text, record) => Array.isArray(text) ?
            text.map((item) => {
                // TODO: Test if element or text, and render it accordingly
                // TODO: Add a UUID as component name doesn't get here
                // TODO: If JSON format, then use a JSON visualiser
                // TODO: If code, then use a visualiser
                return _jsx("div", { children: item }, `attr-${record.attr}-note-${uuidv4()}`);
            }) :
            text
    }
    // {
    //   title: "Tags",
    //   key: "tags",
    //   dataIndex: "tags",
    //   render: (_, { tags }) => (
    //     <>
    //       {tags.map((tag) => {
    //         let color = tag.length > 5 ? "geekblue" : "green";
    //         if (tag === "loser") {
    //           color = "volcano";
    //         }
    //         return (
    //           <Tag color={color} key={tag}>
    //             {tag.toUpperCase()}
    //           </Tag>
    //         );
    //       })}
    //     </>
    //   )
    // },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <a>Invite {record.name}</a>
    //       <a>Delete</a>
    //     </Space>
    //   )
    // }
];
const AttributesTable = ({ data }) => {
    const dataSource = [...data];
    return (_jsx("div", { children: _jsx(Table, { rowKey: "key", columns: columns, dataSource: dataSource, pagination: false }, `attributes-table-${uuidv4()}`) }, uuidv4()));
};
export default AttributesTable;
