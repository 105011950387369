import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import PageHeader from "components/base/pageHeader/PageHeader";
import JsonData from "./documents/integration-guide.json";
import { Typography } from "antd";
import Code from "components/base/editor/Code";
// import { v4 as uuidv4 } from "uuid";
const { Title, Paragraph } = Typography;
const IntegrationGuide = () => {
    return (_jsxs("div", { children: [_jsx(PageHeader, { title: "Integration Guide" }), _jsx("div", Object.assign({ style: {
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: "lighter"
                } }, { children: JsonData === null || JsonData === void 0 ? void 0 : JsonData.description })), (JsonData === null || JsonData === void 0 ? void 0 : JsonData.tableOfContents) && (_jsxs("div", Object.assign({ style: { marginTop: 30, backgroundColor: "#f4f4f4", padding: 15 } }, { children: [_jsx(Title, Object.assign({ level: 3 }, { children: "Table of contents" })), JsonData === null || JsonData === void 0 ? void 0 : JsonData.tableOfContents.map((content, index) => {
                        return (_jsxs("div", Object.assign({ style: { marginBottom: 10 } }, { children: [_jsx(Title, Object.assign({ level: 4 }, { children: `${index + 1}. ${content === null || content === void 0 ? void 0 : content.title}` })), content === null || content === void 0 ? void 0 : content.items.map((item, idx) => {
                                    return _jsx("li", Object.assign({ style: { marginLeft: 20, fontSize: 15 } }, { children: item }), `section_${index + 1}_${idx}`);
                                })] }), `title_${content.title}`));
                    })] }))), (JsonData === null || JsonData === void 0 ? void 0 : JsonData.content) && (_jsx("div", Object.assign({ style: { marginTop: 30 } }, { children: JsonData === null || JsonData === void 0 ? void 0 : JsonData.content.map((content, index) => {
                    return (_jsxs("div", Object.assign({ style: { marginBottom: 10 } }, { children: [_jsx(Title, Object.assign({ level: 3 }, { children: `${index + 1}. ${content === null || content === void 0 ? void 0 : content.title}` })), (content === null || content === void 0 ? void 0 : content.description) && (_jsx("div", Object.assign({ style: { marginLeft: 20, marginBottom: 20, fontSize: 15 } }, { children: content.description }))), content === null || content === void 0 ? void 0 : content.items.map((item, idx) => {
                                return (_jsxs(_Fragment, { children: [_jsx(Title, Object.assign({ style: { marginLeft: 20, fontSize: 15 }, level: 4 }, { children: item === null || item === void 0 ? void 0 : item.title }), `section_${index + 1}_${idx}`), item === null || item === void 0 ? void 0 : item.content.map((content, idx2) => {
                                            if (content && typeof content === "object" && content.title) {
                                                return (_jsxs("div", Object.assign({ style: { marginLeft: 20, marginBottom: 30, fontSize: 15 } }, { children: [_jsx(Title, Object.assign({ level: 5 }, { children: content === null || content === void 0 ? void 0 : content.title })), content === null || content === void 0 ? void 0 : content.content.map((item, idx3) => {
                                                            return (_jsx(Paragraph, Object.assign({ style: { fontSize: 15 } }, { children: item }), `subcontent_${idx3}`));
                                                        })] }), `section_${index + 1}_${idx}_paragraph_${idx2}`));
                                            }
                                            else if (content && typeof content === "object" && content.type) {
                                                return (_jsx("div", Object.assign({ style: { marginLeft: 0, marginBottom: 30, fontSize: 15 } }, { children: content.type === "list" ?
                                                        content === null || content === void 0 ? void 0 : content.items.map((item, idx4) => {
                                                            return _jsx("li", Object.assign({ style: { marginLeft: 20, fontSize: 15 } }, { children: item }), `subitem_${idx4}`);
                                                        }) :
                                                        content.type === "code" ?
                                                            // TODO: Code
                                                            // <div key={`subsection_${idx2}`}>{JSON.stringify(content.html)}</div> :
                                                            _jsxs("div", Object.assign({ style: { marginLeft: 20 } }, { children: [_jsx("div", Object.assign({ style: { color: "#333333" } }, { children: content === null || content === void 0 ? void 0 : content.description })), (content === null || content === void 0 ? void 0 : content.html) ?
                                                                        _jsx(Code, { code: content.html, language: "html" }) :
                                                                        _jsx(Code, { code: content === null || content === void 0 ? void 0 : content.javascript, language: "html" })] })) :
                                                            content.type === "url" ?
                                                                _jsx("a", Object.assign({ target: "_blank", rel: "noreferrer", href: content === null || content === void 0 ? void 0 : content.url, style: { marginLeft: 20 } }, { children: (content === null || content === void 0 ? void 0 : content.label) || content.url })) :
                                                                _jsx("div", { children: JSON.stringify(content.html) }, `subsection_${idx2}`) }), `subsection_${idx2}`));
                                                // } else {
                                                //   return (
                                                //     <div key={`subsection_${idx2}`}>{JSON.stringify(item)}</div>
                                                //   );
                                                // }
                                            }
                                            return (_jsx(Paragraph, Object.assign({ style: { marginLeft: 20, fontSize: 15 } }, { children: content }), `section_${index + 1}_${idx}_paragraph_${idx2}`));
                                            // };
                                        })] }));
                            })] }), `content_${content.title}`));
                }) }))), (JsonData === null || JsonData === void 0 ? void 0 : JsonData.considerations) &&
                (_jsxs(_Fragment, { children: [_jsx("div", { style: { marginTop: 30, marginBottom: 20, borderTop: "1px solid #f2f2f2" } }), _jsx("div", Object.assign({ style: {
                                fontSize: 15,
                                fontWeight: "lighter"
                            } }, { children: JsonData === null || JsonData === void 0 ? void 0 : JsonData.considerations.map((consideration, index) => {
                                return (_jsx("div", { children: consideration }, `consideration_${index}`));
                            }) }))] }))] }));
};
export default IntegrationGuide;
