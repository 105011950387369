import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
// This function converts the data returned by the tournament/events
// into a market x dates x countries for the specific league
export const groupLeaguesByCountries = (data) => {
    const leagueCategories = [];
    const groupLeaguesByCountries = [];
    data.forEach((league) => {
        const isCategoryAdded = leagueCategories.find(category => category.id === league.category.id);
        if (!isCategoryAdded) {
            leagueCategories.push(league.category);
            groupLeaguesByCountries.push({
                categoryId: league.category.id,
                category: league.category.name,
                leagues: [{
                        id: league.id,
                        name: league.name,
                        country: league.category,
                        events: league.events
                    }]
            });
        }
        else {
            groupLeaguesByCountries.find(category => category.categoryId === league.category.id).leagues.push({
                id: league.id,
                name: league.name,
                country: league.category,
                events: league.events
            });
        }
    });
    // Formats matches
    const countriesLeagues = [];
    groupLeaguesByCountries.forEach((country) => {
        const processedLeagues = [];
        country.leagues.forEach((league) => {
            if (league.events.length > 0) {
                const matches = groupLeagueMatchesByMarketsAndDates(league);
                processedLeagues.push({
                    id: league.id,
                    name: league.name,
                    matches
                });
            }
        });
        if (processedLeagues.length > 0) {
            countriesLeagues.push({
                id: country.categoryId,
                country: country.category,
                leagues: processedLeagues
            });
        }
    });
    return countriesLeagues;
};
// This function converts the data returned by the tournament/events
// into a market x dates x countries x leagues x events view
export const groupLeaguesByMarket = (data, isDateRangeOrientated = false) => {
    const leagueIds = [];
    const references = [];
    const groupLeaguesByMarket = [];
    try {
        data === null || data === void 0 ? void 0 : data.filter((league) => { var _a; return (_a = league.events) === null || _a === void 0 ? void 0 : _a.length; }).forEach((league) => {
            leagueIds.push(league.id);
            league.events.forEach((event) => {
                var _a;
                const startDate = dayjs(event.startDate).format("YYYYMMDD");
                const time = dayjs(event.startDate).format("HH:mm");
                let matchStatus;
                if (isDateRangeOrientated) {
                    if (dayjs() >= dayjs(event.startDate, "YYYY-MM-DDTHH:mm:ss+00:00") &&
                        dayjs() < dayjs(event.startDate, "YYYY-MM-DDTHH:mm:ss+00:00").add(90, "minutes")) {
                        matchStatus = "Live";
                    }
                    else if (dayjs() >= dayjs(event.startDate, "YYYY-MM-DDTHH:mm:ss+00:00") &&
                        dayjs().add(1, "day") < dayjs(event.startDate, "YYYY-MM-DDTHH:mm:ss+00:00")) {
                        matchStatus = "Next 24h";
                    }
                    else if (dayjs().add(1, "day") >= dayjs(event.startDate, "YYYY-MM-DDTHH:mm:ss+00:00") &&
                        dayjs().add(2, "day") < dayjs(event.startDate, "YYYY-MM-DDTHH:mm:ss+00:00")) {
                        matchStatus = "Next 48h";
                    }
                    else if (dayjs().add(2, "day") >= dayjs(event.startDate, "YYYY-MM-DDTHH:mm:ss+00:00") &&
                        dayjs().add(3, "day") < dayjs(event.startDate, "YYYY-MM-DDTHH:mm:ss+00:00")) {
                        matchStatus = "Next 72h";
                    }
                    else {
                        matchStatus = "Game Over";
                    }
                }
                else {
                    matchStatus = dayjs(event.startDate, "YYYY-MM-DDTHH:mm:ss+00:00") > dayjs() &&
                        dayjs() < dayjs(event.startDate, "YYYY-MM-DDTHH:mm:ss+00:00") ?
                        "upcoming" :
                        dayjs(event.startDate, "YYYY-MM-DDTHH:mm:ss+00:00") >= dayjs() &&
                            dayjs() < dayjs(event.startDate, "YYYY-MM-DDTHH:mm:ss+00:00").add(90, "minutes") ?
                            "Live" :
                            "Game Over";
                }
                if (((_a = event === null || event === void 0 ? void 0 : event.markets) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    event.markets.forEach((market) => {
                        var _a;
                        const isMarketAdded = references.find(item => item.marketName === market.name);
                        const isMarketDateAdded = references.find(item => item.marketName === market.name &&
                            item.startDate === startDate);
                        const isRefAdded = references.find(item => item.marketName === market.name &&
                            item.startDate === startDate && item.time === time && item.eventId === event.id);
                        if (!isRefAdded) {
                            references.push({ marketId: market.id, marketName: market.name, startDate, time, eventId: event.id });
                            const eventRef = {
                                id: event.id,
                                name: event.name.indexOf("@") !== -1 ?
                                    event.name.substring(0, event.name.indexOf("@") - 1) :
                                    event.name,
                                homeTeam: event.homeTeam,
                                awayTeam: event.awayTeam,
                                startDate,
                                time,
                                matchStatus,
                                marketId: market.marketTypeConfigId,
                                marketName: market.name,
                                marketCount: event.marketCount,
                                outcomes: market.outcomes.sort((a, b) => a.id - b.id)
                            };
                            // Push or amend details
                            // considering market has not been identified and added to the array
                            if (!isMarketAdded) {
                                groupLeaguesByMarket.push({
                                    marketId: market.marketTypeConfigId,
                                    marketName: market.name,
                                    marketCount: event === null || event === void 0 ? void 0 : event.marketCount,
                                    availableMarkets: (_a = event === null || event === void 0 ? void 0 : event.markets) === null || _a === void 0 ? void 0 : _a.length,
                                    marketIds: [market.id],
                                    dates: [{
                                            startDate,
                                            countries: [{
                                                    id: league.category.id,
                                                    name: league.category.name,
                                                    imageUrl: league.category.imageUrl,
                                                    leagues: [{
                                                            id: league.id,
                                                            name: league.name,
                                                            priorityOrder: league.priorityOrder && -1,
                                                            events: [eventRef]
                                                        }]
                                                }]
                                        }]
                                });
                            }
                            else {
                                const marketRef = groupLeaguesByMarket.find(item => item.marketName === market.name);
                                const dateRef = groupLeaguesByMarket.find(item => item.marketName === market.name).dates
                                    .find((item) => item.startDate === startDate);
                                // considering the specific date has not been added to the existing market ref
                                if (!isMarketDateAdded) {
                                    marketRef === null || marketRef === void 0 ? void 0 : marketRef.dates.push({
                                        startDate,
                                        countries: [{
                                                id: league.category.id,
                                                name: league.category.name,
                                                imageUrl: league.category.imageUrl,
                                                leagues: [{
                                                        id: league.id,
                                                        name: league.name,
                                                        priorityOrder: league.priorityOrder && -1,
                                                        events: [eventRef]
                                                    }]
                                            }]
                                    });
                                }
                                else {
                                    const countryRef = dateRef.countries.find((item) => item.id === league.category.id);
                                    // considering country is not in place
                                    if (!countryRef) {
                                        dateRef.countries.push({
                                            id: league.category.id,
                                            name: league.category.name,
                                            imageUrl: league.category.imageUrl,
                                            leagues: [{
                                                    id: league.id,
                                                    name: league.name,
                                                    priorityOrder: league.priorityOrder && -1,
                                                    events: [eventRef]
                                                }]
                                        });
                                    }
                                    else {
                                        // check league is in place or not
                                        const leagueRef = countryRef.leagues.find((item) => item.id === league.id);
                                        if (!leagueRef) {
                                            countryRef.leagues.push({
                                                id: league.id,
                                                name: league.name,
                                                priorityOrder: league.priorityOrder && -1,
                                                events: [eventRef]
                                            });
                                        }
                                        else {
                                            // check events are in place
                                            const eventData = leagueRef.events.find((item) => item.id === event.id);
                                            if (!eventData) {
                                                leagueRef.events.push(eventRef);
                                            }
                                            else {
                                                console.log("Something went wrong");
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    });
                }
            });
        });
    }
    catch (e) {
        console.log(e);
    }
    ;
    return groupLeaguesByMarket;
};
// This function converts the data returned by the grouped-events and/or events/upcoming
// into a market x countries x leagues x events view
// export const groupLeaguesByLeague = (data: any): any => {
//   const leagueIds: any[] = [];
//   // const references: any[] = [];
//   // const groupLeaguesByLeague: any[] = [];
//   try {
//     data?.filter((league: any) => league.events?.length)
//       .forEach((league: any) => {
//         console.log(league);
//         leagueIds.push(league.id);
//       });
//   } catch (e) {
//     console.log(e);
//   }
// };
// Filters the array, given an specific market
export const filterMarket = (data, selected = "3-Way - Full Time") => {
    const filtered = data.map((country) => {
        country.leagues.map((league) => {
            var _a, _b;
            const selectedMarket = (_b = (_a = league === null || league === void 0 ? void 0 : league.matches) === null || _a === void 0 ? void 0 : _a.marketsEvents) === null || _b === void 0 ? void 0 : _b.filter((market) => market.name === selected);
            league.matches = selectedMarket;
            return league;
        });
        return country;
    });
    return filtered;
};
// Function process the returned data by Markets and Dates
export const groupLeagueMatchesByMarketsAndDates = (data) => {
    var _a, _b;
    let marketsEvents = [];
    let options = [];
    let selectedOption;
    const processingMarkets = [];
    const processingDates = [];
    if (((_a = Object.keys(data)) === null || _a === void 0 ? void 0 : _a.length) && Array.isArray(data === null || data === void 0 ? void 0 : data.events)) {
        data.events.forEach((event) => {
            event.markets.forEach((market) => {
                var _a, _b, _c, _d, _e;
                const isMarketAdded = processingMarkets.find(addedMarket => addedMarket.name === market.name);
                const uuid = uuidv4();
                if (!isMarketAdded) {
                    processingMarkets.push({
                        id: uuid,
                        displayRule: market === null || market === void 0 ? void 0 : market.displayRule,
                        name: market.name,
                        marketIds: [market.id],
                        marketStatusId: market.statusId,
                        marketLine: market.line,
                        marketTypeConfigId: market === null || market === void 0 ? void 0 : market.marketTypeConfigId,
                        matchesByDate: [
                            {
                                startDate: dayjs(event.startDate).format("YYYYMMDD"),
                                times: [
                                    {
                                        time: dayjs(event.startDate).format("HH:mm"),
                                        matches: [
                                            {
                                                id: event.id,
                                                startDate: event.startDate,
                                                statusId: event.statusId,
                                                leagueName: data === null || data === void 0 ? void 0 : data.name,
                                                leagueId: data === null || data === void 0 ? void 0 : data.id,
                                                name: event.name,
                                                homeTeam: event.homeTeam,
                                                awayTeam: event.awayTeam,
                                                inPlay: event === null || event === void 0 ? void 0 : event.inPlay,
                                                marketName: market.name,
                                                marketId: market.id,
                                                marketStatusId: market.statusId,
                                                marketLine: market.line,
                                                marketTypeConfigId: market === null || market === void 0 ? void 0 : market.marketTypeConfigId,
                                                marketCount: event === null || event === void 0 ? void 0 : event.marketCount,
                                                availableMarkets: (_a = event === null || event === void 0 ? void 0 : event.markets) === null || _a === void 0 ? void 0 : _a.length,
                                                outcomes: market.outcomes.sort((a, b) => a.id - b.id)
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    });
                    processingDates.push({
                        startDate: dayjs(event.startDate).format("YYYYMMDD"),
                        time: dayjs(event.startDate).format("HH:mm"),
                        market: market.name
                    });
                }
                else {
                    if (!isMarketAdded.marketIds.includes(market.id)) {
                        const isDateAddedToTheMatch = processingDates.find(dateMarket => dateMarket.startDate === dayjs(event.startDate).format("YYYYMMDD") &&
                            dateMarket.market === isMarketAdded.name);
                        if (!isDateAddedToTheMatch) {
                            isMarketAdded.matchesByDate.push({
                                startDate: dayjs(event.startDate).format("YYYYMMDD"),
                                times: [
                                    {
                                        time: dayjs(event.startDate).format("HH:mm"),
                                        matches: [
                                            {
                                                id: event.id,
                                                startDate: event.startDate,
                                                statusId: event.statusId,
                                                leagueName: data === null || data === void 0 ? void 0 : data.name,
                                                leagueId: data === null || data === void 0 ? void 0 : data.id,
                                                name: event.name,
                                                homeTeam: event.homeTeam,
                                                awayTeam: event.awayTeam,
                                                inPlay: event === null || event === void 0 ? void 0 : event.inPlay,
                                                marketName: market.name,
                                                marketId: market.id,
                                                marketStatusId: market.statusId,
                                                marketLine: market.line,
                                                marketTypeConfigId: market === null || market === void 0 ? void 0 : market.marketTypeConfigId,
                                                marketCount: event === null || event === void 0 ? void 0 : event.marketCount,
                                                availableMarkets: (_b = event === null || event === void 0 ? void 0 : event.markets) === null || _b === void 0 ? void 0 : _b.length,
                                                outcomes: market.outcomes.sort((a, b) => a.id - b.id)
                                            }
                                        ]
                                    }
                                ]
                            });
                            processingDates.push({
                                startDate: dayjs(event.startDate).format("YYYYMMDD"),
                                time: dayjs(event.startDate).format("HH:mm"),
                                market: market.name
                            });
                        }
                        else if (isDateAddedToTheMatch) {
                            const isDateTimeAddedToTheMatch = processingDates.find(dateMarket => dateMarket.startDate === dayjs(event.startDate).format("YYYYMMDD") &&
                                dateMarket.time === dayjs(event.startDate).format("HH:mm") &&
                                dateMarket.market === isMarketAdded.name);
                            if (!isDateTimeAddedToTheMatch) {
                                isMarketAdded.matchesByDate.find((item) => {
                                    return item.startDate === dayjs(event.startDate).format("YYYYMMDD");
                                }).times.push({
                                    time: dayjs(event.startDate).format("HH:mm"),
                                    matches: [
                                        {
                                            id: event.id,
                                            startDate: event.startDate,
                                            statusId: event.statusId,
                                            name: event.name,
                                            leagueName: data === null || data === void 0 ? void 0 : data.name,
                                            leagueId: data === null || data === void 0 ? void 0 : data.id,
                                            homeTeam: event.homeTeam,
                                            awayTeam: event.awayTeam,
                                            inPlay: event === null || event === void 0 ? void 0 : event.inPlay,
                                            marketName: market.name,
                                            marketId: market.id,
                                            marketStatusId: market.statusId,
                                            marketLine: market.line,
                                            marketTypeConfigId: market === null || market === void 0 ? void 0 : market.marketTypeConfigId,
                                            marketCount: event === null || event === void 0 ? void 0 : event.marketCount,
                                            availableMarkets: (_c = event === null || event === void 0 ? void 0 : event.markets) === null || _c === void 0 ? void 0 : _c.length,
                                            outcomes: market.outcomes.sort((a, b) => a.id - b.id)
                                        }
                                    ]
                                });
                            }
                            else {
                                isMarketAdded.matchesByDate.find((item) => {
                                    return item.startDate === dayjs(event.startDate).format("YYYYMMDD");
                                }).times.find((item) => {
                                    return item.time === dayjs(event.startDate).format("HH:mm");
                                }).matches.push({
                                    id: event.id,
                                    startDate: event.startDate,
                                    statusId: event.statusId,
                                    leagueName: data === null || data === void 0 ? void 0 : data.name,
                                    leagueId: data === null || data === void 0 ? void 0 : data.id,
                                    name: event.name,
                                    homeTeam: event.homeTeam,
                                    awayTeam: event.awayTeam,
                                    inPlay: event === null || event === void 0 ? void 0 : event.inPlay,
                                    marketName: market.name,
                                    marketId: market.id,
                                    marketStatusId: market.statusId,
                                    marketLine: market.line,
                                    marketTypeConfigId: market === null || market === void 0 ? void 0 : market.marketTypeConfigId,
                                    marketCount: event === null || event === void 0 ? void 0 : event.marketCount,
                                    availableMarkets: (_d = event === null || event === void 0 ? void 0 : event.markets) === null || _d === void 0 ? void 0 : _d.length,
                                    outcomes: market.outcomes.sort((a, b) => a.id - b.id)
                                });
                            }
                            processingDates.push({
                                startDate: dayjs(event.startDate).format("YYYYMMDD"),
                                time: dayjs(event.startDate).format("HH:mm"),
                                market: market.name
                            });
                        }
                        else {
                            isMarketAdded.matchesByDate.find((item) => item.startDate === dayjs(event.startDate).format("YYYYMMDD")).matches.push({
                                id: event.id,
                                startDate: event.startDate,
                                statusId: event.statusId,
                                leagueName: data === null || data === void 0 ? void 0 : data.name,
                                leagueId: data === null || data === void 0 ? void 0 : data.id,
                                name: event.name,
                                homeTeam: event.homeTeam,
                                awayTeam: event.awayTeam,
                                inPlay: event === null || event === void 0 ? void 0 : event.inPlay,
                                marketName: market.name,
                                marketId: market.id,
                                marketStatusId: market.statusId,
                                marketLine: market.line,
                                marketTypeConfigId: market === null || market === void 0 ? void 0 : market.marketTypeConfigId,
                                marketCount: event === null || event === void 0 ? void 0 : event.marketCount,
                                availableMarkets: (_e = event === null || event === void 0 ? void 0 : event.markets) === null || _e === void 0 ? void 0 : _e.length,
                                outcomes: market.outcomes.sort((a, b) => a.id - b.id)
                            });
                        }
                        isMarketAdded.marketIds.push(market.id);
                    }
                }
            });
        });
        marketsEvents = processingMarkets;
        if (processingMarkets) {
            const processedTabs = processingMarkets.map((item) => {
                return {
                    name: item.name,
                    value: item.id
                };
            });
            options = processedTabs;
            selectedOption = (_b = processedTabs[0]) === null || _b === void 0 ? void 0 : _b.value;
        }
        ;
    }
    return {
        marketsEvents,
        options,
        selectedOption,
        isOutrightAvailable: data.isOutrightAvailable
    };
};
export const getFormattedDate = (startDate) => {
    let formattedDate;
    if (dayjs(startDate).isToday()) {
        formattedDate = "TODAY";
    }
    else if (dayjs(startDate).isTomorrow()) {
        formattedDate = "TOMORROW";
    }
    else {
        formattedDate = dayjs(startDate).format("DD MMMM").toUpperCase();
    }
    return formattedDate;
};
export const getSportLabelById = (sportId) => {
    switch (sportId) {
        case 1:
            return "soccer";
        case 2:
            return "basketball";
        case 3:
            return "tennis";
        case 6:
            return "ice hockey";
        case 11:
            return "volleyball";
        case 12:
            return "boxing";
        case 13:
            return "cricket";
        case 14:
            return "mma";
        case 16:
            return "table tennis";
    }
};
