import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import axios from "axios";
import { SPORTS_MARKETS_FILTERS_IDS } from "enums";
import styles from "./SportsMarketsFilters.module.scss";
const defaultAllTab = {
    id: SPORTS_MARKETS_FILTERS_IDS.ALL,
    name: "All",
    market: {
        id: -1,
        name: "All"
    }
};
const defaultBBTab = {
    id: SPORTS_MARKETS_FILTERS_IDS.BET_BUILDER,
    name: "Bet Builder",
    market: {
        id: -1,
        name: "Bet Builder"
    },
    isBB: true
};
export const SportsMarketsFilters = ({ productId = 1, setMarketsConfigs, setSelectedMarketGroup, marketGroupsNotAvailable = [], selected, tagsSource = "tags-list-markets-filter", updatedByIndex, data, overrideMarketGroups, withBBFilter }) => {
    const [marketGroups, setMarketGroups] = React.useState(overrideMarketGroups === null || overrideMarketGroups === void 0 ? void 0 : overrideMarketGroups.filter((item) => !marketGroupsNotAvailable.includes(item.id)));
    // TODO: add skeletons
    React.useEffect(() => {
        if (!overrideMarketGroups) {
            axios
                .get(productId === 1 ? "/market-type-configs" : "/esports/market-map")
                .then((res) => {
                var _a;
                const uniqueMarketGroups = [defaultAllTab];
                (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.forEach((item) => {
                    item.marketGroups.forEach((group) => {
                        // Check if the group is not already in the uniqueMarketGroups array
                        const existingGroupIndex = uniqueMarketGroups.findIndex((existingGroup) => existingGroup.id === group.id);
                        if (existingGroupIndex === -1) {
                            uniqueMarketGroups.push({
                                id: group.id,
                                name: group.name,
                                market: {
                                    id: item.id,
                                    name: item.shortName
                                }
                            });
                        }
                    });
                });
                setMarketGroups(uniqueMarketGroups === null || uniqueMarketGroups === void 0 ? void 0 : uniqueMarketGroups.filter((item) => !marketGroupsNotAvailable.includes(item.id)));
                setMarketsConfigs(res === null || res === void 0 ? void 0 : res.data);
            })
                .catch((e) => {
                console.log(e);
            });
        }
        const handleSelectedTag = (e) => {
            const { id, name, market } = e.detail;
            if (e.detail.source === tagsSource && (id || typeof id === "number")) {
                if (String(updatedByIndex) && data) {
                    const newData = data === null || data === void 0 ? void 0 : data.map((item, index) => {
                        if (index === updatedByIndex) {
                            return {
                                id,
                                name,
                                market
                            };
                        }
                        else {
                            return item;
                        }
                    });
                    setSelectedMarketGroup(newData);
                }
                else {
                    setSelectedMarketGroup({
                        id,
                        name,
                        market
                    });
                }
            }
        };
        window.addEventListener("selected-tag-details", handleSelectedTag);
        return () => {
            window.removeEventListener("selected-tag-details", handleSelectedTag);
        };
    }, []);
    React.useEffect(() => {
        if (withBBFilter) {
            setMarketGroups((marketGroups) => {
                const isBBPresent = marketGroups === null || marketGroups === void 0 ? void 0 : marketGroups.find((marketGroup) => marketGroup.isBB);
                if (!isBBPresent) {
                    const popularIndex = marketGroups === null || marketGroups === void 0 ? void 0 : marketGroups.findIndex((marketGroup) => marketGroup.id === SPORTS_MARKETS_FILTERS_IDS.POPULAR);
                    if (typeof popularIndex === "number" && popularIndex !== -1) {
                        marketGroups.splice(popularIndex + 1, 0, defaultBBTab);
                    }
                }
                return marketGroups;
            });
        }
    }, [withBBFilter, marketGroups]);
    return (_jsx("div", Object.assign({ className: styles.navigationWrapper }, { children: _jsx("esports-tags-list", { source: tagsSource, tags: JSON.stringify(marketGroups) }) })));
};
