import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Breadcrumb, Row } from "antd";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
// styles
import styles from "./Breadcrumbs.module.scss";
export const Breadcrumbs = ({ items, withoutDropdownIcon, from }) => {
    const dispatchBreadcrumbItemClicked = (item, isLastItem) => {
        const triggerEventMatchDetails = new CustomEvent(`${from}-breadcrumb-item-click`, {
            detail: {
                clickedItemHref: item.href,
                isLastItem
            },
            bubbles: true
        });
        window.dispatchEvent(triggerEventMatchDetails);
    };
    const renderBreadcrumbItems = () => {
        return items.map((item, index) => {
            return (_jsx(Breadcrumb.Item, { children: _jsx("a", Object.assign({ style: { cursor: "pointer" }, onClick: () => {
                        dispatchBreadcrumbItemClicked(item, index === items.length - 1);
                    } }, { children: item.title })) }, `breadcrumb-item-${index}`));
        });
    };
    return (_jsxs(Row, Object.assign({ align: "middle", className: styles.rowContainer }, { children: [_jsx(Breadcrumb, Object.assign({ className: styles.container }, { children: renderBreadcrumbItems() })), !withoutDropdownIcon && _jsx(ImageFromCDN, { alt: "v", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/breadcrumb-down.png", size: 10, overrideStyles: styles.downIcon, onClick: () => {
                    dispatchBreadcrumbItemClicked({ title: "" }, true);
                } })] })));
};
