import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Checkbox, Col, Row } from "antd";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
// document details of inner components
import { documentDetails as noDataForMarketDocumentDetails } from "pages/documentation/components/NoDataForMarket";
export const documentDetails = {
    title: "Country leagues search",
    component: "esports-country-leagues-search",
    description: "This component renders a list of leagues grouped by countries.",
    dependencies: [
        "esports-league-multiple-markets"
    ],
    attributes: [
        {
            key: "1",
            attr: "with-header",
            type: "boolean (optional)",
            notes: ["Presents the header information."]
        },
        {
            key: "2",
            attr: "with-search",
            type: "boolean (optional)",
            notes: ["Adds a search input to the header.",
                "with-header must be in place, for users to see this search input."]
        },
        {
            key: "3",
            attr: "bordered",
            type: "boolean (optional)",
            notes: ["Adds a border to the component."]
        },
        {
            key: "3",
            attr: "focused",
            type: "boolean (optional)",
            notes: ["Renders the component, and add a focus to the search input.",
                "with-header and with-search must be defined."]
        },
        {
            key: "4",
            attr: "no-data-options",
            type: "object{} optional",
            notes: [
                "Custom no data options.",
                `{
        message:"No results found",
        src:"https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg",
        cta:{JSON.stringify(label: "Go To Sportsbook",url: "/")}
      }`,
                `Default value is:           
        {
          message:"No results found",
          src:"https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg",
        }`
            ]
        }
    ],
    events: [
        {
            event: "sports-routing-details",
            type: "emit",
            data: ["Each time a a country is selected, this information gets emitted."]
        }
    ],
    innerComponents: [
        {
            name: "esports-nodata-for-market",
            documentDetails: noDataForMarketDocumentDetails
        }
    ]
};
const InteractiveInputs = ({ onChange, checkedProps }) => {
    return (_jsx(Checkbox.Group, Object.assign({ style: { width: "100%" }, onChange: onChange, value: checkedProps }, { children: _jsxs(Row, { children: [_jsx(Col, Object.assign({ xs: 8, sm: 8, md: 4, lg: 4, xl: 4 }, { children: _jsx(Checkbox, Object.assign({ value: "with-header" }, { children: "with-header" })) })), _jsx(Col, Object.assign({ xs: 8, sm: 8, md: 4, lg: 4, xl: 4 }, { children: _jsx(Checkbox, Object.assign({ value: "with-search" }, { children: "with-search" })) })), _jsx(Col, Object.assign({ xs: 8, sm: 8, md: 4, lg: 4, xl: 4 }, { children: _jsx(Checkbox, Object.assign({ value: "bordered" }, { children: "bordered" })) })), _jsx(Col, Object.assign({ xs: 8, sm: 8, md: 4, lg: 4, xl: 4 }, { children: _jsx(Checkbox, Object.assign({ value: "scrollable" }, { children: "scrollable" })) })), _jsx(Col, Object.assign({ xs: 8, sm: 8, md: 4, lg: 4, xl: 4 }, { children: _jsx(Checkbox, Object.assign({ value: "focused" }, { children: "focused" })) }))] }) })));
};
const CountryLeaguesSearch = () => {
    const [docDetails, setDocDetails] = React.useState(documentDetails);
    const [checkedProps, setCheckedProps] = React.useState(["with-header", "with-search", "scrollable"]);
    const onChange = (checkedValues) => {
        setCheckedProps(checkedValues);
    };
    React.useEffect(() => {
        setDocDetails((docDetails) => (Object.assign(Object.assign({}, docDetails), { example: `<esports-country-leagues-search
        with-header
        with-search
        bordered
        scrollable
        focused
      />`, interactiveInputs: _jsx(InteractiveInputs, { onChange: onChange, checkedProps: checkedProps }), code: _jsx("esports-country-leagues-search", { "with-header": checkedProps === null || checkedProps === void 0 ? void 0 : checkedProps.includes("with-header"), "with-search": checkedProps === null || checkedProps === void 0 ? void 0 : checkedProps.includes("with-search"), bordered: checkedProps === null || checkedProps === void 0 ? void 0 : checkedProps.includes("bordered"), scrollable: checkedProps === null || checkedProps === void 0 ? void 0 : checkedProps.includes("scrollable"), focused: checkedProps === null || checkedProps === void 0 ? void 0 : checkedProps.includes("focused"), "no-data-options": JSON.stringify({
                    message: "No results found",
                    src: "https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg"
                }) }) })));
    }, [checkedProps]);
    // render the page based on the provided object
    const CountryLeaguesSearchComponent = React.useCallback(() => {
        if (Object.keys(docDetails).length)
            return _jsx(MountDocumentComponent, { data: docDetails });
        return _jsx("div", { children: "Loading page..." });
    }, [docDetails]);
    return _jsx(CountryLeaguesSearchComponent, {});
};
export default CountryLeaguesSearch;
