import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import ReactDom from "react-dom";
import clsx from "clsx";
import { Grid } from "antd";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import { MODAL_POSITION } from "enums";
import styles from "./ModalBase.module.scss";
const { useBreakpoint } = Grid;
const modalBottomOffset = 12; // in px
export const ModalBase = ({ open, children, onClose = () => { }, title, position = MODAL_POSITION.CENTER, withContentPadding = true, classes, disableCloseModal }) => {
    var _a;
    const screens = useBreakpoint();
    const refOuter = React.useRef(null);
    const refFirstFocusable = React.useRef(null);
    const refLastFocusable = React.useRef(null);
    const modalRef = React.useRef(null);
    const [modalHeight, setModalHeight] = React.useState(0);
    React.useEffect(() => {
        if (modalRef.current) {
            setModalHeight(modalRef.current.clientHeight);
        }
    }, [open]);
    React.useEffect(() => {
        var _a, _b;
        const focusableElements = Array.from((_b = (_a = refOuter.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll("[tabindex]")) !== null && _b !== void 0 ? _b : []);
        refFirstFocusable.current = focusableElements[0];
        refLastFocusable.current = focusableElements[focusableElements.length - 1];
    }, [open]);
    const onKeyDown = React.useCallback((e) => {
        var _a, _b;
        if (document.activeElement === refLastFocusable.current &&
            e.key === "Tab" &&
            !e.shiftKey) {
            e.preventDefault();
            (_a = refFirstFocusable.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
        if (document.activeElement === refFirstFocusable.current &&
            e.key === "Tab" &&
            e.shiftKey) {
            e.preventDefault();
            (_b = refLastFocusable.current) === null || _b === void 0 ? void 0 : _b.focus();
        }
        if (e.key === "Escape")
            onClose();
    }, [onClose]);
    if (!open)
        return null;
    return ReactDom.createPortal(_jsxs("div", Object.assign({ ref: refOuter, onKeyDown: onKeyDown, className: clsx(styles.root, classes === null || classes === void 0 ? void 0 : classes.root), "data-testid": "ModalBase" }, { children: [_jsx("div", { className: styles.overlay, onClick: disableCloseModal ? undefined : onClose, "data-testid": "ModalBase.overlay" }), _jsx("div", Object.assign({ className: clsx(styles.modalRoot, classes === null || classes === void 0 ? void 0 : classes.root), style: position === MODAL_POSITION.BOTTOM && screens.xs ?
                    { bottom: "50%", top: `calc(100% - ${modalHeight + modalBottomOffset}px)` } :
                    undefined, "data-testid": "ModalBase.content" }, { children: _jsxs("div", Object.assign({ ref: modalRef, className: styles.main }, { children: [_jsxs("div", Object.assign({ className: clsx(styles.header, classes === null || classes === void 0 ? void 0 : classes.header, { [styles.withTitle]: !!title }) }, { children: [title && _jsx("div", Object.assign({ className: styles.title }, { children: title })), !disableCloseModal && (_jsx("div", Object.assign({ className: styles.closeBtn, onClick: onClose, tabIndex: 0, "data-testid": "ModalBase.closeBtn" }, { children: _jsx(ImageFromCDN, { alt: "Close", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/close.png", size: 15 }) })))] })), _jsx("div", Object.assign({ className: clsx(styles.content, classes === null || classes === void 0 ? void 0 : classes.content, {
                                [styles.withTitle]: !!title,
                                [styles.withContentPadding]: !!withContentPadding
                            }) }, { children: children }))] })) }))] })), (_a = document.getElementById("portal")) !== null && _a !== void 0 ? _a : document.body);
};
