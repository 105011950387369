var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const toFixed = (value) => {
    return (Math.floor(value * 100) / 100).toFixed(2);
};
export const getTimeConverter = (timestamp) => {
    const date = new Date(timestamp);
    const userLocale = navigator.language || "en-US";
    const formattedDate = date.toLocaleString(userLocale, {
        day: "numeric",
        month: "short",
        hour: "numeric",
        minute: "2-digit",
        hour12: true
    });
    const time = formattedDate.split(", ")[1];
    if (time) {
        return `${date.getDate()} ${date.toLocaleString(userLocale, {
            month: "short"
        })} ${time}`;
    }
    return "";
};
export const capitalizeFirstLetter = (inputString) => inputString.charAt(0).toUpperCase() + inputString.slice(1);
export const createCommaSeparatedString = (arr) => {
    return arr.reduce((acc, item) => {
        if (acc) {
            if (item)
                return `${acc},${item}`;
            return acc;
        }
        return String(item);
    }, "");
};
export const delay = (time) => __awaiter(void 0, void 0, void 0, function* () {
    return yield new Promise(resolve => setTimeout(resolve, time));
});
export const slugify = (str) => {
    if (!str)
        return "";
    return str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, "") // Remove non-word characters (except spaces and hyphens)
        .replace(/[\s-]+/g, "-") // Replace spaces and consecutive hyphens with a single hyphen
        .replace(/^-+|-+$/g, ""); // Remove any leading or trailing hyphens
};
export const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};
