import { jsx as _jsx } from "react/jsx-runtime";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes/router";
/**
 * The following two lines were introduced with the introduction of
 * diffusion package - https://snbgroup.atlassian.net/wiki/spaces/TECH/pages/edit-v2/352419883
 *  */
import process from "process";
window.process = process;
const App = () => {
    return (_jsx("div", Object.assign({ className: "wrapper_social_web" }, { children: _jsx(BrowserRouter, { children: _jsx(Router, {}) }) })));
};
export default App;
