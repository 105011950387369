import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col, Skeleton } from "antd";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
// styles
import styles from "./Skeleton.module.scss";
const SkeletonSportsEvents = () => {
    return (_jsxs("div", Object.assign({ style: { margin: "5px 0px" } }, { children: [_jsxs(Row, Object.assign({ className: clsx(styles.headerSmall), justify: "center", align: "middle" }, { children: [_jsx(Col, Object.assign({ xs: 11, sm: 11, md: 11, lg: 8, xl: 8 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", className: styles.maxHeightSmall }) })), _jsx(Col, Object.assign({ xs: 13, sm: 13, md: 13, lg: 16, xl: 16, className: styles.oddLabelWrapper }, { children: _jsxs(Row, Object.assign({ justify: "center", align: "middle" }, { children: [_jsx(Col, Object.assign({ span: 8, className: styles.oddLabel }, { children: _jsx(Skeleton.Input, { size: "small", className: styles.maxHeightSmall }) })), _jsx(Col, Object.assign({ span: 8, className: styles.oddLabel }, { children: _jsx(Skeleton.Input, { active: true, size: "small", className: styles.maxHeightSmall }) })), _jsx(Col, Object.assign({ span: 8, className: styles.oddLabel }, { children: _jsx(Skeleton.Input, { active: true, size: "small", className: styles.maxHeightSmall }) }))] })) }))] })), _jsx("div", Object.assign({ style: {
                    border: "1px solid #cccccc",
                    borderTopWidth: 0,
                    borderBottomLeftRadius: 3,
                    borderBottomRightRadius: 3
                } }, { children: [1, 2, 3].map(() => {
                    return (_jsxs("div", { children: [_jsx(Row, Object.assign({ className: styles.headerMedium }, { children: _jsx(Col, Object.assign({ span: 24 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", className: styles.maxHeightMedium }) })) })), [1, 2].map(() => {
                                return (_jsxs(Row, Object.assign({ justify: "start", gutter: [20, 5], className: styles.event }, { children: [_jsx(Col, Object.assign({ xs: 11, sm: 11, md: 11, lg: 8, xl: 8 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightExtraLarge }) })), _jsx(Col, Object.assign({ xs: 13, sm: 13, md: 13, lg: 16, xl: 16 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightExtraLarge }) }))] }), `skeleton-sports-event-sub-${uuidv4()}`));
                            })] }, `skeleton-sports-event-${uuidv4()}`));
                }) }))] })));
};
export default SkeletonSportsEvents;
