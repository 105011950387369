import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { KeyboardButtons } from "components/base/keyboardButtons/KeyboardButtons";
import { useKeyboardButtons } from "../../../../hooks/useKeyboardButtons";
import { StakeInput } from "components/base/stakeInput/StakeInput";
import { calculateCombinations } from "helpers/Bets";
import { BETS_COMBINATIONS_LIMIT } from "enums";
import clsx from "clsx";
import styles from "./SystemBets.module.scss";
export const SystemBetsSelection = ({ betIndex, type, legs, inputId, onStakeChange, selectedTab, stake = "", inputIdWithStake }) => {
    const [showKeyBoard, setShowKeyBoard] = React.useState(false);
    const { inputValue, setInputValue, updateInputValue, addNumber, removeLatestDigit } = useKeyboardButtons();
    const combinedBets = calculateCombinations(legs.length, betIndex);
    // TODO: set back changes after updates
    // const [applyToAll, setApplyToAll] = React.useState<boolean>(false);
    // React.useEffect(() => {
    //   onStakeChange(inputValue, betIndex, applyToAll);
    // }, [inputValue, applyToAll]);
    React.useEffect(() => {
        onStakeChange(inputValue, betIndex, inputId);
    }, [inputValue]);
    React.useEffect(() => {
        setInputValue(stake);
        // setApplyToAll(false);
    }, [stake]);
    React.useEffect(() => {
        setInputValue("");
        setShowKeyBoard(false);
    }, [selectedTab]);
    React.useEffect(() => {
        const handleActiveInput = (e) => {
            setShowKeyBoard(false);
            if (e.detail.inputId === inputId) {
                setShowKeyBoard(true);
            }
        };
        const handleMaxStakeExceeded = () => {
            setInputValue("");
            setShowKeyBoard(false);
        };
        window.addEventListener("bet-slip-active-input", handleActiveInput);
        window.addEventListener("max-stake-exceeded", handleMaxStakeExceeded);
        return () => {
            window.removeEventListener("bet-slip-active-input", handleActiveInput);
            window.removeEventListener("max-stake-exceeded", handleMaxStakeExceeded);
        };
    }, []);
    const disabled = combinedBets > BETS_COMBINATIONS_LIMIT || !!(inputIdWithStake && inputId !== inputIdWithStake);
    return (_jsxs("div", Object.assign({ className: clsx(styles.betRow, { [styles.disabled]: disabled }), "data-testid": `SystemBet-${betIndex}` }, { children: [_jsxs("div", Object.assign({ className: styles.bet }, { children: [_jsx("div", Object.assign({ className: styles.type }, { children: type })), _jsxs("div", Object.assign({ className: styles.stake }, { children: [combinedBets, "x", _jsx(StakeInput
                            // applyToAll={applyToAll}
                            , { 
                                // applyToAll={applyToAll}
                                disabled: disabled, inputValue: inputValue, setInputValue: setInputValue, inputId: inputId, isActive: showKeyBoard, className: styles.stakeInput, removeLatestDigit: () => {
                                    // setApplyToAll(false);
                                    removeLatestDigit();
                                } })] }))] })), showKeyBoard && (_jsx(KeyboardButtons, { isSystem: true, 
                // enableApplyToAll={() => { setApplyToAll(true); }}
                updateInputValue: updateInputValue, addNumber: addNumber, removeLatestDigit: () => {
                    // setApplyToAll(false);
                    removeLatestDigit();
                }, closeKeyboard: () => { setShowKeyBoard(false); } }))] })));
};
