import { jsx as _jsx } from "react/jsx-runtime";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import { Col } from "antd";
export const OddsSuspendedOrVariation = ({ suspended, refVariation, view = "top", showIndicator }) => {
    if (suspended) {
        return (_jsx(ImageFromCDN, { alt: "Lock", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/lock.svg", size: 8, overrideStyles: {
                position: "absolute",
                top: "4px",
                right: "5px"
            } }));
    }
    const getArrowIndicator = () => {
        if (refVariation === 0 || !showIndicator)
            return null;
        const arrowSvgStyles = {
            position: "absolute",
            top: 5,
            right: 8
        };
        return refVariation > 0 ?
            (_jsx(ImageFromCDN, { alt: "Arrow Up", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/arrow_up.svg", size: 8, overrideStyles: arrowSvgStyles })) :
            (_jsx(ImageFromCDN, { alt: "Arrow Down", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/arrow_down.svg", size: 8, overrideStyles: arrowSvgStyles }));
    };
    if (refVariation !== 0) {
        if (view === "top") {
            return getArrowIndicator();
        }
        return (_jsx(Col, Object.assign({ span: 1, style: { minHeight: "100%" } }, { children: getArrowIndicator() })));
    }
    return null;
};
