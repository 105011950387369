var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import { Row } from "antd";
import clsx from "clsx";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import { v4 as uuidv4 } from "uuid";
import { FILTERS } from "helpers/Sports/Filters";
// styles
import styles from "./TagsList.module.scss";
import flexStyle from "assets/styles/flex.module.scss";
const SLIDER_GAP = 35;
const TagsList = ({ source = uuidv4(), tags, selected, withSideSpacing, uppercase, fullWidth, style }) => {
    var _a;
    const isSelectedExist = tags.find(tag => (tag === null || tag === void 0 ? void 0 : tag.id) && selected && String(tag.id) === String(selected));
    const [selectedOption, setSelectedOption] = React.useState(isSelectedExist && selected ? selected : (_a = tags[0]) === null || _a === void 0 ? void 0 : _a.id);
    const rowRef = React.useRef(null);
    const triggerSelectedTagDetails = new CustomEvent("selected-tag-details", {
        detail: Object.assign({ source }, tags.find(tag => String(tag.id) === String(selectedOption))),
        bubbles: true
    });
    const emitSelectedTag = () => {
        window.dispatchEvent(triggerSelectedTagDetails);
    };
    React.useEffect(() => {
        // eslint-disable-next-line
        if (selectedOption || typeof selectedOption === "number")
            emitSelectedTag();
    }, [selectedOption]);
    React.useEffect(() => {
        var _a;
        const id = (_a = tags[0]) === null || _a === void 0 ? void 0 : _a.id;
        if (selected && isSelectedExist) {
            setSelectedOption(selected);
        }
        else if (selected && selected === FILTERS.LIVE && !isSelectedExist) {
            setSelectedOption(selected);
        }
        else if ((!selected || !isSelectedExist) && id !== undefined) {
            setSelectedOption(id);
        }
    }, [tags, selected]);
    React.useEffect(() => {
        if ((selectedOption || typeof selectedOption === "number") && rowRef.current) {
            const row = rowRef.current;
            const selectedElement = row.querySelector(`[data-filter="${selectedOption}"]`);
            if (selectedElement) {
                const containerRect = row.getBoundingClientRect();
                const elementRect = selectedElement.getBoundingClientRect();
                const elementLeftRelativeToContainer = elementRect.left - containerRect.left;
                const elementRightRelativeToContainer = elementLeftRelativeToContainer + elementRect.width;
                if (elementLeftRelativeToContainer < 0) {
                    // Scroll left
                    row.scrollTo({
                        left: row.scrollLeft + elementLeftRelativeToContainer - SLIDER_GAP,
                        behavior: "smooth"
                    });
                }
                else if (elementRightRelativeToContainer > containerRect.width) {
                    // Scroll right
                    row.scrollTo({
                        left: row.scrollLeft + (elementRightRelativeToContainer - containerRect.width) + SLIDER_GAP,
                        behavior: "smooth"
                    });
                }
            }
        }
    }, [selectedOption]);
    return (_jsx("div", Object.assign({ style: style, className: clsx(flexStyle.flex, flexStyle.alignCenter, styles.tags, {
            [styles.withSpacing]: withSideSpacing
        }) }, { children: _jsx(Row, Object.assign({ className: clsx(styles.tags__inner, "flex"), ref: rowRef }, { children: tags === null || tags === void 0 ? void 0 : tags.map((tag, index) => {
                const isLive = tag.id === "live";
                const isSelected = String(selectedOption) === String(tag.id);
                return (_jsx("div", Object.assign({ className: clsx({ [styles.fullWidth]: fullWidth }) }, { children: _jsxs("div", Object.assign({ className: clsx(styles.tag, {
                            [styles.selectedTag]: isSelected,
                            [styles.uppercase]: uppercase,
                            [styles.live]: isLive,
                            [styles.disabled]: tag.disabled,
                            [styles.BB]: tag.isBB,
                            [styles.fullWidth]: fullWidth
                        }), "data-filter": tag.id, onClick: () => { !tag.disabled && setSelectedOption(tag.id); } }, { children: [isLive && _jsx(ImageFromCDN, { alt: "Question box", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/live.svg", width: 15, height: 9, overrideStyles: styles.liveImg }), tag.name] })) }), `tag-col-${tag.id}-${index}`));
            }) })) })));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this.tags = [
            {
                label: "No data returned", value: "nodata"
            }
        ];
    }
    static get observedAttributes() {
        return ["source", "tags", "selected", "with-side-spacing", "uppercase", "full-width", "custom-style"];
    }
    connectedCallback() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(TagsList, { source: this.source, tags: this.tags, selected: this.selected, withSideSpacing: this["with-side-spacing"], uppercase: this.uppercase, fullWidth: this["full-width"], style: this["custom-style"] }));
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        var _a;
        switch (attrName) {
            case "tags":
            case "uppercase":
            case "with-side-spacing":
            case "full-width":
            case "custom-style":
                this[attrName] = JSON.parse(newValue);
                break;
            case "source":
            case "selected":
                this[attrName] = newValue;
                break;
            default:
                break;
        }
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(TagsList, { source: this.source, tags: this.tags, selected: this.selected, withSideSpacing: this["with-side-spacing"], uppercase: this.uppercase, fullWidth: this["full-width"], style: this["custom-style"] }));
    }
}
(_a = customElements.get("esports-tags-list")) !== null && _a !== void 0 ? _a : customElements.define("esports-tags-list", Element);
