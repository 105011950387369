var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import { Row, Col, Skeleton } from "antd";
import clsx from "clsx";
import axios from "axios";
import { SingleBet } from "./SingleBet";
import useMutation from "hooks/useMutation";
import { LocalStorage } from "helpers/Storage";
import InfiniteScroll from "react-infinite-scroll-component";
import { defaultRangeFiltersSelectedOption, getDateByRange } from "helpers/DayjsFunctions";
import { useSubscribeByQuery } from "helpers/graphql/useSubscribeByQuery";
import { SUBSCRIBE_OUTCOMES } from "v2/graphql/subscriptions";
import styles from "./MyBets.module.scss";
export const Tabs = {
    Live: 1,
    Unsettled: 2,
    Settled: 3,
    All: 4
};
const betTabs = [
    { id: 1, name: "Live" },
    { id: 2, name: "Unsettled" },
    { id: 3, name: "Settled" },
    { id: 4, name: "All" }
];
const DEFAULT_OPEN_TABS = 3;
export const MyBets = ({ tabs = betTabs, perPage = 50, productId, exact, noDataOptions, cashout }) => {
    var _a, _b;
    const [selectedTab, setSelectedTab] = React.useState(Tabs.Unsettled);
    const [bets, setBets] = React.useState([]);
    const [cashoutEnabled, setCashoutEnabled] = React.useState(false);
    const [isClientsLoading, setIsClientsLoading] = React.useState(false);
    const [updatedOutcomeData, setUpdatedOutcomeData] = React.useState();
    const [cashedOutBetId, setCashedOutBetId] = React.useState();
    const [betsLastFetch, setBetsLastFetch] = React.useState({});
    const { from, to } = getDateByRange(defaultRangeFiltersSelectedOption);
    const [date, setDate] = React.useState({ from, to });
    const [loadMoreTimes, setLoadMoreTimes] = React.useState(1);
    const baseUrl = ((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL) ? ((_b = process.env) === null || _b === void 0 ? void 0 : _b.REACT_APP_GATEWAY_API_URL) + "/bets" : "";
    const playerId = LocalStorage.get("widget-playerId");
    const { mutate, isLoading, error } = useMutation(({ isLoadMore }) => __awaiter(void 0, void 0, void 0, function* () {
        if (!playerId) {
            return yield Promise.reject(new Error("widget-playerId is missing in sessionStorage"));
        }
        const skip = isLoadMore ? loadMoreTimes * perPage : undefined;
        const resulted = selectedTab === Tabs.All ? "" : Boolean(selectedTab === Tabs.Settled).toString();
        const isLive = selectedTab === Tabs.Live ? "true" : undefined;
        const params = {
            fromDateTime: date.from,
            toDateTime: date.to,
            playerId,
            skip,
            take: perPage,
            resulted,
            isLive,
            productId
        };
        const res = yield axios.get(`${baseUrl}/bettor/${playerId}/bets`, {
            params
        });
        return res.data;
    }), (data, { isLoadMore }) => {
        const newData = isLoadMore && loadMoreTimes > 0 ? [...bets, ...data] : data;
        setBets(newData);
    });
    const fetchBet = (betId) => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d;
        if (((_c = process.env) === null || _c === void 0 ? void 0 : _c.REACT_APP_GATEWAY_API_URL) &&
            ((_d = process.env) === null || _d === void 0 ? void 0 : _d.REACT_APP_GATEWAY_API_KEY)) {
            const widgetToken = LocalStorage.get("widget-token");
            const widgetPlayerId = LocalStorage.get("widget-playerId");
            yield axios
                .get(process.env.REACT_APP_GATEWAY_API_URL + `/bets/${betId}/cashout-offer`, {
                headers: {
                    "widget-token": widgetToken,
                    "widget-playerId": widgetPlayerId
                }
            })
                .then((res) => {
                setBets((bets) => bets.map((bet) => {
                    if (bet.id === betId) {
                        bet.cashoutOffer = res.data.cashoutOffer;
                    }
                    return bet;
                }));
            })
                .catch((e) => {
                console.log("Refresh bet error: ", e);
            })
                .finally(() => {
                setBetsLastFetch(bets => (Object.assign(Object.assign({}, bets), { [betId]: Object.assign(Object.assign({}, bets[betId]), { isOpenToFetch: false }) })));
            });
        }
    });
    const fetchBets = () => __awaiter(void 0, void 0, void 0, function* () {
        for (const [betId, values] of Object.entries(betsLastFetch)) {
            if (values === null || values === void 0 ? void 0 : values.isOpenToFetch) {
                yield fetchBet(+betId);
            }
        }
        ;
    });
    const valueHandlerCallback = (details) => {
        const outcomeData = details.sport_domain_outcome[0];
        if (outcomeData) {
            setUpdatedOutcomeData({ id: outcomeData.id });
        }
    };
    const subscriptions = bets === null || bets === void 0 ? void 0 : bets.flatMap((bet) => bet.betLegs.map((betLeg) => {
        const outcomeId = betLeg.outcome.id;
        const marketId = betLeg.outcome.market.id;
        const eventId = betLeg.outcome.market.event.id;
        if (eventId && marketId && outcomeId) {
            return { eventId, marketId, outcomeId };
        }
        return null;
    }).filter(Boolean));
    const eventIds = subscriptions.map(sub => sub.eventId);
    const marketIds = subscriptions.map(sub => sub.marketId);
    const outcomeIds = subscriptions.map(sub => sub.outcomeId);
    useSubscribeByQuery({
        query: SUBSCRIBE_OUTCOMES,
        variables: { eventIds, marketIds, outcomeIds },
        valueHandlerCallback
    });
    React.useEffect(() => {
        // Track the occurrence of each outcomeId
        const outcomeIdOccurrences = {};
        bets === null || bets === void 0 ? void 0 : bets.forEach((bet) => bet.betLegs.forEach((betLeg) => {
            const outcomeId = betLeg.outcome.id;
            // Update outcomeIdOccurrences
            outcomeIdOccurrences[outcomeId] = (outcomeIdOccurrences[outcomeId] || 0) + 1;
        }));
        bets === null || bets === void 0 ? void 0 : bets.forEach((bet) => bet.betLegs.forEach((betLeg) => {
            var _a, _b;
            const outcomeId = betLeg.outcome.id;
            const betId = bet.id;
            const lastRefetchTime = (_b = (_a = betsLastFetch[betId]) === null || _a === void 0 ? void 0 : _a.lastRefetchTime) !== null && _b !== void 0 ? _b : 0;
            const currentTime = Date.now();
            const isTimePassed = currentTime - lastRefetchTime >= 5000;
            if (cashedOutBetId && betId === cashedOutBetId) {
                // TODO: think about graphql unsubscribe implementation
                // const marketId = betLeg.outcome.market.id;
                // const eventId = betLeg.outcome.market.event.id;
                // // unsubscribe from outcomes topics from cashed out bets
                // // unsubscribe only if outcomeId is unique
                // if (outcomeIdOccurrences[outcomeId] === 1) {
                //   unsubscribe(
                //       `sportsbook/events/${String(eventId)}/markets/${String(marketId)}/outcomes/${String(outcomeId)}`
                //   );
                // }
            }
            else {
                if (outcomeId === (updatedOutcomeData === null || updatedOutcomeData === void 0 ? void 0 : updatedOutcomeData.id) && (!lastRefetchTime || isTimePassed)) {
                    setBetsLastFetch(bets => {
                        if (cashedOutBetId) {
                            const _a = bets, _b = cashedOutBetId, removedItem = _a[_b], rest = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
                            return Object.assign(Object.assign({}, (!removedItem ? bets : rest)), { [betId]: {
                                    lastRefetchTime: currentTime,
                                    isOpenToFetch: true
                                } });
                        }
                        return Object.assign(Object.assign({}, bets), { [betId]: {
                                lastRefetchTime: currentTime,
                                isOpenToFetch: true
                            } });
                    });
                    fetchBets();
                }
            }
        }));
    }, [updatedOutcomeData, cashedOutBetId]);
    React.useEffect(() => {
        // fetches the client settings
        const fetchClientData = () => {
            var _a;
            if (!((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL))
                return;
            setIsClientsLoading(true);
            axios
                .get(`${process.env.REACT_APP_GATEWAY_API_URL}/client/config`)
                .then((res) => {
                var _a;
                const { isCashoutEnabled } = (_a = res.data) !== null && _a !== void 0 ? _a : {};
                setCashoutEnabled(isCashoutEnabled);
            })
                .catch((e) => {
                console.log(e);
            })
                .finally(() => {
                setIsClientsLoading(false);
            });
        };
        fetchClientData();
    }, []);
    React.useEffect(() => {
        const rangeFiltersSelectedOptionHandler = (e) => {
            if (typeof e.detail.value !== "string")
                return;
            const { from, to } = getDateByRange(e.detail.value);
            setDate({ from, to });
        };
        const betCashedOutHandler = (e) => {
            var _a;
            ((_a = e.detail) === null || _a === void 0 ? void 0 : _a.isFull) && setCashedOutBetId(e.detail.betId);
        };
        const closeCashOutModalHandler = (e) => {
            var _a;
            ((_a = e.detail) === null || _a === void 0 ? void 0 : _a.isCashedOut) && mutate();
        };
        window.addEventListener("bet-cashed-out", betCashedOutHandler);
        window.addEventListener("close-cash-out-modal", closeCashOutModalHandler);
        window.addEventListener("range-filters-selected-option", rangeFiltersSelectedOptionHandler);
        return () => {
            window.removeEventListener("bet-cashed-out", betCashedOutHandler);
            window.removeEventListener("close-cash-out-modal", closeCashOutModalHandler);
            window.removeEventListener("range-filters-selected-option", rangeFiltersSelectedOptionHandler);
        };
    }, []);
    React.useEffect(() => {
        mutate();
    }, [date, selectedTab]);
    const fetchMoreData = () => {
        setLoadMoreTimes((amount) => amount + 1);
        mutate({ isLoadMore: true });
    };
    return (_jsxs("div", Object.assign({ className: styles.root, "data-testid": `MyBets` }, { children: [_jsx("div", Object.assign({ className: styles.tabs }, { children: _jsx(Row, Object.assign({ gutter: [8, 8] }, { children: tabs.map((tab) => {
                        return (_jsx(Col, { children: _jsx("div", Object.assign({ className: clsx(styles.tab, {
                                    [styles.activeTab]: selectedTab === tab.id
                                }), onClick: () => {
                                    setSelectedTab(tab.id);
                                }, "data-testid": `MyBetsTab-${tab.id}-${tab.name}` }, { children: tab.name })) }, `tab-${tab.id}-${tab.name}`));
                    }) })) })), _jsx("div", Object.assign({ className: styles.bets }, { children: _jsx(InfiniteScroll, Object.assign({ dataLength: bets.length, next: fetchMoreData, hasMore: !isLoading && !isClientsLoading && !error && (bets === null || bets === void 0 ? void 0 : bets.length) > 0 && (bets === null || bets === void 0 ? void 0 : bets.length) % perPage === 0, loader: _jsx(Skeleton.Input, { style: { width: "100%" }, active: true, size: "default" }) }, { children: isLoading || isClientsLoading ?
                        Array(5)
                            .fill(0)
                            .map((_, index) => (_jsx(Skeleton.Input, { style: { width: "100%" }, active: true, size: "default" }, `skeleton-bet-${index}`))) :
                        !error &&
                            (bets === null || bets === void 0 ? void 0 : bets.length) > 0 ?
                            bets.map((bet, index) => (_jsx(SingleBet, { bet: bet, selectedTab: selectedTab, playerId: playerId, productId: productId, cashout: cashoutEnabled && cashout, open: index < DEFAULT_OPEN_TABS }, `bet-${index}`))) :
                            _jsx("esports-nodata-for-market", { message: noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.message, src: noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.src, cta: JSON.stringify(noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.cta) }) })) }))] })));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this["per-page"] = 50;
        this.cashout = true;
    }
    static get observedAttributes() {
        return ["per-page", "product-id", "date-picker-exact", "no-data-options", "cashout"];
    }
    connectedCallback() {
        this.root.render(_jsx(MyBets, { perPage: this["per-page"], productId: this["product-id"], exact: this["date-picker-exact"], noDataOptions: this["no-data-options"], cashout: this.cashout }));
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        var _a;
        switch (attrName) {
            case "date-picker-exact":
            case "no-data-options":
            case "cashout":
                this[attrName] = JSON.parse(newValue);
                break;
            case "per-page":
            case "product-id":
                this[attrName] = Number(newValue);
                break;
            default:
                break;
        }
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(MyBets, { perPage: this["per-page"], productId: this["product-id"], exact: this["date-picker-exact"], noDataOptions: this["no-data-options"], cashout: this.cashout }));
    }
}
(_a = customElements.get("esports-my-bets")) !== null && _a !== void 0 ? _a : customElements.define("esports-my-bets", Element);
