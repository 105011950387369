var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createRoot } from "react-dom/client";
// styles
import styles from "./DropdownGrouped.module.scss";
const DropdownGrouped = ({ groupedBy, label, name, allOption, options, labelKey = "label", valueKey = "value" }) => {
    return (_jsxs("div", Object.assign({ className: styles.dropdown }, { children: [label && (_jsx("div", { children: _jsx("label", Object.assign({ htmlFor: label }, { children: label })) })), _jsxs("select", Object.assign({ name: name, id: name }, { children: [allOption && (_jsx("option", Object.assign({ value: -1 }, { children: `All ${name === null || name === void 0 ? void 0 : name.toLowerCase()}` }), -1)), options.map(option => {
                        return (_jsx("optgroup", Object.assign({ label: option[groupedBy] }, { children: option.leagues.map((league) => {
                                return (_jsx("option", Object.assign({ value: league[valueKey] }, { children: league[labelKey] }), league[labelKey]));
                            }) }), `dropdown-group-${groupedBy}`));
                    })] }))] })));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this["grouped-by"] = "";
        this.name = "";
        this.options = [
            {
                label: "No data returned", value: "nodata"
            }
        ];
        this["label-key"] = "label";
        this["value-key"] = "value";
    }
    static get observedAttributes() {
        return ["grouped-by", "label", "name", "all-option", "options", "label-key", "value-key"];
    }
    changeHandler(e) {
        var _a;
        const selectedOption = this.options.find(option => String(this["value-key"] ? option[this["value-key"]] : option.value) === e.target.value);
        const triggerDropdownEvent = new CustomEvent(`${(_a = this.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().trim()}-selected-option`, {
            detail: Object.assign({}, selectedOption),
            bubbles: true
        });
        this.dispatchEvent(triggerDropdownEvent);
    }
    disconnectedCallback() {
        this.removeEventListener("change", this.changeHandler);
    }
    connectedCallback() {
        // dispatch the events
        this.addEventListener("change", this.changeHandler);
        createRoot(this).render(_jsx(DropdownGrouped, { groupedBy: this["grouped-by"], label: this.label, name: this.name, allOption: this["all-option"], options: this.options, labelKey: this["label-key"], valueKey: this["value-key"] }));
    }
    attributeChangedCallback(attrName, oldValue, newValue) {
        switch (attrName) {
            case "grouped-by":
            case "label":
            case "name":
            case "label-key":
            case "value-key":
                this[attrName] = String(newValue);
                break;
            case "all-option":
            case "options":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
    }
}
(_a = customElements.get("esports-dropdown-grouped")) !== null && _a !== void 0 ? _a : customElements.define("esports-dropdown-grouped", Element);
