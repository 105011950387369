var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid, Row, Col } from "antd";
import { createRoot } from "react-dom/client";
import { LocalStorage } from "helpers/Storage";
import { BETS_LIMIT } from "enums";
import { OddsMultiEventInput } from "components/base/oddsMultiEventtInput/OddsMultiEventInput";
// styles
import styles from "./OddsMultiEvent.module.scss";
const { useBreakpoint } = Grid;
const OddsMultiEvent = ({ data, selectedOutcomes, onOddsClick }) => {
    var _a;
    const screens = useBreakpoint();
    const triggerOddsMultiEventDetails = new CustomEvent("odds-multievent-details", {
        detail: {
            seriesId: data.seriesId,
            marketId: data.marketId
        },
        bubbles: true
    });
    const emitMultiEventMarket = React.useCallback(() => {
        window.dispatchEvent(triggerOddsMultiEventDetails);
    }, [triggerOddsMultiEventDetails]);
    if (!data.seriesId || !data.marketId || !data.marketCount || !data.marketSelections) {
        return null;
    }
    const limited = ((_a = LocalStorage.get("betsDetails")) === null || _a === void 0 ? void 0 : _a.length) >= BETS_LIMIT;
    return (_jsxs(Row, Object.assign({ justify: "space-between", align: "middle", className: styles.oddsRow }, { children: [_jsx(Col, Object.assign({ flex: "auto" }, { children: _jsx(Row, Object.assign({ gutter: 5 }, { children: data.marketSelections.map((selection) => {
                        return (_jsx(Col, Object.assign({ span: 24 / data.marketSelections.length }, { children: _jsx(OddsMultiEventInput, { data: Object.assign(Object.assign(Object.assign({}, data), selection), { isOpen: selection.isOpen && !limited }), selectedOutcomes: selectedOutcomes, productId: 2, onClick: onOddsClick }) }), `odds-${selection.label}-${selection.abId}`));
                    }) })) })), _jsxs(Col, Object.assign({ flex: screens.xs ? "30px" : "40px", className: styles.oddsMarketCount, onClick: () => emitMultiEventMarket() }, { children: [data.marketCount, " ", _jsx("span", { children: "\u203A" })] }))] })));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this.data = {
            seriesId: 0,
            marketId: 0,
            marketCount: 0,
            marketSelections: []
        };
        this["selected-outcomes"] = [];
        this.bets = [];
        this.onOddsClick = this.onOddsClick.bind(this);
    }
    static get observedAttributes() {
        return ["data", "selected-outcomes"];
    }
    onOddsClick({ abId, isOpen, marketName, selectionLabel, odds, eventName, marketId, statusId }) {
        var _a;
        this.bets = (_a = LocalStorage.get("betsDetails")) !== null && _a !== void 0 ? _a : [];
        this["selected-outcomes"] = this.bets.map((bet) => bet.outcomeId);
        let operation = "select";
        if (this["selected-outcomes"].includes(abId)) {
            operation = "unselect";
        }
        const isBetAlreadyInPlace = this.bets.find((bet) => bet.outcomeId === abId);
        let updatedBets = [...this.bets];
        if (!isBetAlreadyInPlace && operation === "select") {
            if (isOpen) {
                const newBet = {
                    outcomeId: abId,
                    market: marketName,
                    pick: selectionLabel,
                    odds,
                    eventId: this.data.seriesId,
                    productId: 2,
                    event: eventName,
                    marketId,
                    statusId
                    // time: e.detail.time,
                    // startDate: e.detail.startDate,
                };
                updatedBets.push(newBet);
            }
        }
        else if (isBetAlreadyInPlace && operation === "unselect") {
            updatedBets = updatedBets.filter((bet) => !(bet.outcomeId === abId));
        }
        this["selected-outcomes"] = updatedBets.map((bet) => bet.outcomeId);
        LocalStorage.set("betsDetails", JSON.stringify(updatedBets));
        this.renderComponent();
        const triggerOddsMultiEventInputDetails = new CustomEvent("odds-multievent-input-details", {
            detail: {
                updatedBets
            },
            bubbles: true
        });
        window.dispatchEvent(triggerOddsMultiEventInputDetails);
    }
    ;
    renderComponent() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(OddsMultiEvent, { data: this.data, selectedOutcomes: this["selected-outcomes"], onOddsClick: this.onOddsClick }));
    }
    connectedCallback() {
        this.renderComponent();
    }
    attributeChangedCallback(attrName, oldValue, newValue) {
        switch (attrName) {
            case "data":
            case "selected-outcomes":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
        this.renderComponent();
    }
}
(_a = customElements.get("esports-odds-multievent")) !== null && _a !== void 0 ? _a : customElements.define("esports-odds-multievent", Element);
