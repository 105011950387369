import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
export const documentDetails = {
    title: "Modal generic",
    component: "esports-modal-generic",
    description: "This component displays a modal that contains buttons for logging in and registering.",
    attributes: [
        {
            key: "1",
            attr: "open",
            type: "boolean (optional)",
            notes: ["Define this property to control open state"]
        },
        {
            key: "2",
            attr: "modal-title",
            type: "string (optional)",
            notes: [
                "Custom unauthenticated modal title.",
                `Default value is: Please login or register to proceed`
            ]
        },
        {
            key: "3",
            attr: "options",
            type: "object[]",
            notes: [
                "This prop is also required and it should contain one or multiple options.",
                `[
          {
            id: 1,
            label: "Yes",
            url: "/something-nice-should-happen"
          },
          {
            id: 2,
            label: "No",
            url: "/you-are-not-allowed"
          }
        ]`
            ]
        }
    ],
    overrideEvents: [
        {
            event: "click-modal-button",
            type: "emit",
            data: [
                "The component details object is emitted on click auth buttons in the modal.",
                `detail: {
          id: number
          label: string
          url: string
        }`
            ]
        },
        {
            event: "close-modal",
            type: "emit",
            data: ["The component details object is emitted on closing the modal."]
        }
    ]
};
const ModalGeneric = () => {
    const [open, setOpen] = React.useState(false);
    const [docDetails, setDocDetails] = React.useState(documentDetails);
    const [closeModal, setCloseModal] = React.useState();
    const [clickAuthButton, setClickAuthButton] = React.useState();
    React.useEffect(() => {
        const closeModalHandler = (e) => {
            if (!open)
                setOpen(false);
            setCloseModal(e.detail);
        };
        const clickAuthButtonHandler = (e) => {
            if (!open)
                setOpen(false);
            setClickAuthButton(e.detail);
        };
        window.addEventListener("close-modal", closeModalHandler);
        window.addEventListener("click-modal-button", clickAuthButtonHandler);
        return () => {
            window.removeEventListener("close-modal", closeModalHandler);
            window.removeEventListener("click-modal-button", clickAuthButtonHandler);
        };
    }, []);
    React.useEffect(() => {
        setDocDetails((docDetails) => (Object.assign(Object.assign({}, docDetails), { overrideEvents: [
                {
                    event: "click-modal-button",
                    type: "emit",
                    data: [
                        "The component details object is emitted on click auth buttons in the modal.",
                        `detail: {
              id: number
              label: string
              url: string
            }`
                    ],
                    detail: {
                        emitModel: clickAuthButton
                    }
                },
                {
                    event: "close-modal",
                    type: "emit",
                    data: ["The component details object is emitted on closing the modal."],
                    detail: {
                        emitModel: closeModal
                    }
                }
            ], example: `esports-modal-generic
        open={true}
        modal-title="Would you like to proceed?"
        options={JSON.stringify([
          { id: 1, label: "Yes", url: "/something-nice-should-happen" },
          { id: 2, label: "No", url: "/you-are-not-allowed" }
        ])}
      `, code: _jsxs(_Fragment, { children: [_jsx("button", Object.assign({ style: {
                            cursor: "pointer",
                            fontSize: "14px",
                            fontWeight: 600,
                            padding: "10px 20px",
                            borderRadius: "4px",
                            textTransform: "uppercase",
                            backgroundColor: "#f3f5f8",
                            border: "solid 1px #d6dbe3",
                            color: "#000"
                        }, onClick: () => { setOpen(true); } }, { children: "Open modal" })), _jsx("esports-modal-generic", { open: open, "modal-title": "Would you like to proceed?", options: JSON.stringify([
                            { id: 1, label: "Yes", url: "/something-nice-should-happen" },
                            { id: 2, label: "No", url: "/you-are-not-allowed" }
                        ]) })] }) })));
    }, [open, clickAuthButton, closeModal]);
    // render the page based on the provided object
    const ModalComponent = React.useCallback(() => {
        if (Object.keys(docDetails).length)
            return _jsx(MountDocumentComponent, { data: docDetails });
        return _jsx("div", { children: "Loading page..." });
    }, [docDetails]);
    return _jsx(ModalComponent, {});
};
export default ModalGeneric;
