var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { editUserProfile } from "helpers/ProfileFunctions";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import flexStyle from "assets/styles/flex.module.scss";
import clsx from "clsx";
const EditUserProfileName = ({ userName, avatarUrl, setUserName, myProfile, setRefreshPage }) => {
    const [userNameFlag, setUserNameFlag] = React.useState(false);
    const [editUserName, setEditUserName] = React.useState(userName);
    React.useEffect(() => {
        setEditUserName(userName);
    }, [userName]);
    const handleOnChange = (e) => {
        setEditUserName(e.target.value);
    };
    const saveUserName = () => __awaiter(void 0, void 0, void 0, function* () {
        setUserNameFlag(false);
        yield editUserProfile(avatarUrl.type === "svg" ? null : avatarUrl, editUserName);
        setRefreshPage(true);
        setUserName(editUserName);
    });
    return (_jsxs("div", Object.assign({ className: clsx(flexStyle.flex, flexStyle.alignCenter) }, { children: [!userNameFlag ?
                (_jsx("h5", { children: userName })) :
                (_jsx("input", { value: editUserName, onChange: handleOnChange })), !userNameFlag && myProfile && (_jsx("span", Object.assign({ onClick: () => { setUserNameFlag(true); } }, { children: _jsx(ImageFromCDN, { alt: "edit", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/more.png", size: 20 }) }))), userNameFlag && (_jsx("button", Object.assign({ onClick: saveUserName, className: "button_social_widget success" }, { children: "Save" })))] })));
};
export default EditUserProfileName;
