import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Accordion } from "components/base/Accordion/Accordion";
import dayjs from "dayjs";
import styles from "./Outrights.module.scss";
export const Outrights = ({ data, productId, defaultOutcomes, sport }) => {
    var _a;
    const tournament = data === null || data === void 0 ? void 0 : data[0];
    const getAccordionHeader = ({ title, startDate }) => {
        const AccordionHeader = () => {
            var _a, _b;
            return _jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx("div", Object.assign({ className: styles.imgWrapper }, { children: _jsx("img", { src: (_a = tournament === null || tournament === void 0 ? void 0 : tournament.category) === null || _a === void 0 ? void 0 : _a.imageUrl, alt: (_b = tournament === null || tournament === void 0 ? void 0 : tournament.category) === null || _b === void 0 ? void 0 : _b.name, style: { borderRadius: 2, width: 22 } }) })), _jsxs("div", Object.assign({ className: styles.info }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: title })), _jsx("div", Object.assign({ className: styles.startDate }, { children: (dayjs(startDate).format("DD MMMM | HH:mm")).toUpperCase() }))] }))] }));
        };
        return AccordionHeader;
    };
    if (!((_a = tournament === null || tournament === void 0 ? void 0 : tournament.events) === null || _a === void 0 ? void 0 : _a.length)) {
        return _jsx("esports-nodata-for-market", { src: "https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.png" });
    }
    return tournament.events.map((event) => {
        return event.markets.map((market) => {
            const title = _jsxs(_Fragment, { children: [_jsx("strong", { children: market.name }), " - ", event.name] });
            const AccordionHeader = getAccordionHeader({ title, startDate: event.startDate });
            return _jsx(Accordion, { Header: AccordionHeader, classes: { root: styles.root, dropdownIcon: styles.dropdownIcon } }, `${event.id}-${market.id}`);
        });
    });
};
