import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { KeyboardButtons } from "components/base/keyboardButtons/KeyboardButtons";
import { useKeyboardButtons } from "../../../../hooks/useKeyboardButtons";
import { StakeInput } from "components/base/stakeInput/StakeInput";
import { CURRENCY } from "enums";
import { FreeBet } from "components/base/freeBet/FreeBet";
import styles from "./MultipleBetsSelection.module.scss";
const multipleInputId = "bet-slip-multiple-input";
export const MultipleBetsSelection = ({ totalOdds, selectedTab, onStakeChange, betslipOpen, freeBet, showFreeBet, stake }) => {
    const [showKeyBoard, setShowKeyBoard] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [isFreeBetUsed, setIsFreeBetUsed] = React.useState(false);
    const { inputValue, setInputValue, updateInputValue, addNumber, removeLatestDigit } = useKeyboardButtons();
    React.useEffect(() => {
        if (isFreeBetUsed) {
            if (inputValue && (freeBet.amount === +inputValue)) {
                onStakeChange(inputValue, freeBet.bonusId);
            }
            else {
                setIsFreeBetUsed(false);
            }
        }
        onStakeChange(inputValue);
    }, [inputValue]);
    React.useEffect(() => {
        setInputValue("");
        setShowKeyBoard(false);
    }, [selectedTab, betslipOpen]);
    React.useEffect(() => {
        if (stake) {
            setInputValue(String(stake));
        }
    }, [stake]);
    React.useEffect(() => {
        var _a;
        const handleActiveInput = (e) => {
            if (e.detail.inputId === multipleInputId) {
                setShowKeyBoard(true);
            }
        };
        const changeMaxStakeHandler = (e) => {
            const { selections, isMaxWinExceeded } = e.detail;
            setError(null);
            const { stake } = selections;
            const errorMessage = `Maximum stake allowed ${CURRENCY} ${stake}`;
            !isMaxWinExceeded && setError(errorMessage);
            stake && setInputValue(stake);
            // Remove error message in 3 sec
            setTimeout(() => {
                setError(null);
            }, 3000);
        };
        const hash = window.location.hash;
        const searchParams = new URLSearchParams(hash.slice(hash.indexOf("?") + 1));
        const multipleValue = (_a = searchParams.getAll("isMultiple")) === null || _a === void 0 ? void 0 : _a[0];
        multipleValue && setInputValue(multipleValue);
        window.addEventListener("bet-slip-active-input", handleActiveInput);
        window.addEventListener("change-max-stake", changeMaxStakeHandler);
        return () => {
            window.removeEventListener("bet-slip-active-input", handleActiveInput);
            window.removeEventListener("change-max-stake", changeMaxStakeHandler);
        };
    }, []);
    const onFreeBetClick = () => {
        setInputValue(isFreeBetUsed ? "" : String(freeBet.amount));
        setIsFreeBetUsed(isFreeBetUsed => !isFreeBetUsed);
    };
    return (_jsxs(_Fragment, { children: [showFreeBet &&
                _jsx(FreeBet, { isFreeBetUsed: isFreeBetUsed, onFreeBetClick: onFreeBetClick, amount: freeBet.amount, expireDate: freeBet.expireDate }), _jsxs("div", Object.assign({ className: styles.root, "data-testid": "MultipleBetsSelection" }, { children: [_jsxs("div", Object.assign({ className: styles.totalOdds }, { children: ["Total Odds", _jsx("strong", { children: totalOdds })] })), _jsx(StakeInput, { inputValue: inputValue, setInputValue: setInputValue, inputId: multipleInputId, isActive: showKeyBoard, removeLatestDigit: removeLatestDigit })] })), error && _jsx("div", Object.assign({ className: styles.error }, { children: error })), showKeyBoard && (_jsx(KeyboardButtons, { isMultiple: true, updateInputValue: updateInputValue, addNumber: addNumber, removeLatestDigit: removeLatestDigit, closeKeyboard: () => { setShowKeyBoard(false); } }))] }));
};
