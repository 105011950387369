import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CURRENCY } from "enums";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import { ResultedMessage } from "./ResultedMessage";
import { Loader } from "helpers/loader/Loader";
import styles from "./BetCashOut.module.scss";
export const ConfirmationModal = ({ stake, cashoutOffer, closeModal, isLoading, fetchCashOut, isPriceChanged, isSuccess }) => {
    return (_jsx("div", Object.assign({ className: styles.confirmationModal }, { children: isSuccess === null && !isPriceChanged ?
            (_jsxs(_Fragment, { children: [_jsx(ImageFromCDN, { alt: "Question box", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/indeterminate_question_box.svg", size: 53 }), _jsxs("div", Object.assign({ className: styles.details }, { children: [_jsxs("strong", { children: ["Stake is ", CURRENCY, stake, ", Possible Return is ", CURRENCY, cashoutOffer] }), _jsx("small", { children: "Bonuses applied to this bet will be lost." })] })), _jsxs("div", Object.assign({ className: styles.modalBtns }, { children: [_jsx("button", Object.assign({ onClick: () => {
                                    closeModal();
                                }, className: styles.decline }, { children: "Decline" })), _jsx("button", Object.assign({ onClick: () => {
                                    fetchCashOut({
                                        amountOffered: cashoutOffer,
                                        amountRequested: cashoutOffer
                                    });
                                }, className: styles.confirm, disabled: isLoading }, { children: isLoading ? _jsx(Loader, {}) : `Confirm ${CURRENCY} ${cashoutOffer}` }))] }))] })) :
            (_jsx(ResultedMessage, { cashoutOffer: cashoutOffer, closeModal: closeModal, isSuccess: isSuccess, isPriceChanged: isPriceChanged, fetchCashOut: fetchCashOut, isLoading: isLoading })) })));
};
