var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable */
// TODO: Remove eslint disable
import React, { useRef, useState } from "react";
import style from "../editProfile/EditProfileWrapper.module.scss";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import { editUserProfile, getSignedUrl, uploadFile, } from "helpers/ProfileFunctions";
import { Skeleton } from "antd";
const EditProfileImage = ({ userName, avatarUrl, setAvatarUrl, isMyProfile, setRefreshPage, }) => {
    const [fileSize, setFileSize] = useState("");
    const [initKey, setInitKey] = useState("");
    const [loadingImage, setLoadingImage] = useState(false);
    const imageFormat = ["jpeg", "png", "jpg"];
    const inputRef = useRef(null);
    const handleEditUserImage = () => {
        var _a;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    const fileHandler = (event) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        const file = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0];
        const extension = (_b = file === null || file === void 0 ? void 0 : file.name.split(".").pop()) === null || _b === void 0 ? void 0 : _b.toLowerCase();
        let maxSize = 0;
        if (imageFormat.includes(extension)) {
            maxSize = 20 * 1024 * 1024;
            setFileSize(file === null || file === void 0 ? void 0 : file.size);
            const imageSize = (_c = file === null || file === void 0 ? void 0 : file.size) !== null && _c !== void 0 ? _c : 0;
            if (maxSize < imageSize) {
                alert("File size exceeded");
                return;
            }
            try {
                let keyTemp = `user/avatar/${Date.now()}`;
                setInitKey(keyTemp);
                let key = `${keyTemp}`;
                const signedUrlData = yield getSignedUrl(file, key);
                setLoadingImage(true);
                yield editUserProfile(signedUrlData === null || signedUrlData === void 0 ? void 0 : signedUrlData.data.fileUrl, userName);
                yield uploadFile(file, (_d = signedUrlData === null || signedUrlData === void 0 ? void 0 : signedUrlData.data) === null || _d === void 0 ? void 0 : _d.preSignedUrl);
                setAvatarUrl(signedUrlData === null || signedUrlData === void 0 ? void 0 : signedUrlData.data.fileUrl);
                setLoadingImage(false);
                setRefreshPage(true);
            }
            catch (e) {
                console.log(e);
            }
        }
        else {
            alert("File type not supported");
        }
    });
    const doNothing = () => { };
    return (_jsxs("div", Object.assign({ style: { cursor: "pointer" }, className: style.editProfile__profileImg, onClick: isMyProfile ? handleEditUserImage : doNothing }, { children: [loadingImage ? (_jsx("a", { children: _jsx(Skeleton.Avatar, { active: true, shape: "square", size: "large" }) })) : (_jsx("a", { children: avatarUrl.type == "svg" ? (avatarUrl) : !avatarUrl ? (_jsx(Skeleton.Avatar, { active: true, shape: "square", size: "large" })) : (_jsx("img", { src: avatarUrl, className: "avatar" })) })), _jsx("input", { accept: "image/*", ref: inputRef, type: "file", onChange: fileHandler, style: { display: "none" } }), isMyProfile && (_jsx("div", Object.assign({ className: style.editProfile__profileIcon }, { children: _jsx(ImageFromCDN, { alt: "camera", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/camera.png", size: 20 }) })))] })));
};
export default EditProfileImage;
