var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import axios from "axios";
import clsx from "clsx";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import { ModalBase } from "components/base/modal/ModalBase/ModalBase";
import { LocalStorage, SessionStorage } from "helpers/Storage";
import { BETSLIP_ODDS_SETTINGS, MODAL_POSITION, LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from "enums/index";
import { delay } from "helpers/GeneralFunctions";
import styles from "./BetSlipFooter.module.scss";
import { generateRandomUsername } from "../../../../helpers/SessionProfileId";
const options = [
    {
        type: BETSLIP_ODDS_SETTINGS.INCREASED,
        text: "Odds going up",
        example: "e.g from 1.50 to 1.75",
        arrowUpIcon: true
    },
    {
        type: BETSLIP_ODDS_SETTINGS.DECREASED,
        text: "Odds going down",
        example: "e.g from 1.50 to 1.25",
        arrowDownIcon: true
    },
    {
        type: BETSLIP_ODDS_SETTINGS.ANY,
        text: "Any odd changes"
    },
    {
        type: BETSLIP_ODDS_SETTINGS.DEFAULT,
        text: "I Would like to always review odd changes"
    }
];
const Settings = ({ open, onCloseSettings }) => {
    const [setting, setSetting] = React.useState(BETSLIP_ODDS_SETTINGS.DEFAULT);
    const oddsSetting = SessionStorage.get(SESSION_STORAGE_KEYS.ODDS_SETTING);
    React.useEffect(() => {
        if (oddsSetting) {
            setSetting(oddsSetting);
        }
    }, [open]);
    const clickOptionHandler = (option) => (e) => {
        e.preventDefault();
        option !== setting && setSetting(option);
    };
    const createBettor = (bettorId, socialUsername) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const payload = {
            providerBettorId: bettorId,
            socialUsername,
            avatar: ""
        };
        if ((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL) {
            try {
                yield axios.post(`${(_b = process.env) === null || _b === void 0 ? void 0 : _b.REACT_APP_GATEWAY_API_URL}/bets/bettor`, payload)
                    .then(data => data);
            }
            catch (err) {
                console.error(`Couldn't create bettor ${bettorId}`, err);
            }
        }
    });
    const saveOption = () => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d, _e, _f, _g;
        const bettorId = LocalStorage.get(LOCAL_STORAGE_KEYS.WIDGET_PLAYERID);
        if (bettorId) {
            const leetent = SessionStorage.get("leetent");
            const updatedOddsSettingUnauthenticated = SessionStorage.get(SESSION_STORAGE_KEYS.UPDATED_SETTING_UNAUTHENTICATED);
            const newSettings = {
                socialUsername: ((_c = leetent === null || leetent === void 0 ? void 0 : leetent.bettor) === null || _c === void 0 ? void 0 : _c.socialUsername) ? leetent === null || leetent === void 0 ? void 0 : leetent.bettor.socialUsername : "",
                avatar: ((_d = leetent === null || leetent === void 0 ? void 0 : leetent.bettor) === null || _d === void 0 ? void 0 : _d.avatar) ? (_e = leetent === null || leetent === void 0 ? void 0 : leetent.bettor) === null || _e === void 0 ? void 0 : _e.avatar : "",
                acceptDecreasedOddsChanges: false,
                acceptIncreasedOddsChanges: false
            };
            switch (setting) {
                case BETSLIP_ODDS_SETTINGS.INCREASED:
                    newSettings.acceptDecreasedOddsChanges = false;
                    newSettings.acceptIncreasedOddsChanges = true;
                    break;
                case BETSLIP_ODDS_SETTINGS.DECREASED:
                    newSettings.acceptDecreasedOddsChanges = true;
                    newSettings.acceptIncreasedOddsChanges = false;
                    break;
                case BETSLIP_ODDS_SETTINGS.ANY:
                    newSettings.acceptDecreasedOddsChanges = true;
                    newSettings.acceptIncreasedOddsChanges = true;
                    break;
                default:
                    break;
            }
            // updates the data in the database
            // TODO: Deal with errors
            const baseUrl = (_g = (_f = process.env) === null || _f === void 0 ? void 0 : _f.REACT_APP_GATEWAY_API_URL) !== null && _g !== void 0 ? _g : "";
            yield axios
                .put(`${baseUrl}/bets/bettors/${bettorId}`, newSettings)
                .then(() => {
                // to be able to save in the DB, user must be logged
                // in case the updatedSettingUnauthenticated is set, then change it to false
                // as it is no longer necessary
                if (updatedOddsSettingUnauthenticated) {
                    SessionStorage.set(SESSION_STORAGE_KEYS.UPDATED_SETTING_UNAUTHENTICATED, false);
                }
            })
                .catch((err) => __awaiter(void 0, void 0, void 0, function* () {
                var _h;
                console.log(err);
                if (((_h = err === null || err === void 0 ? void 0 : err.response) === null || _h === void 0 ? void 0 : _h.status) === 422) {
                    // bettor doesn't exist, so create it
                    const socialUsername = generateRandomUsername();
                    yield createBettor(bettorId, socialUsername);
                    newSettings.socialUsername = socialUsername;
                    const leetent = SessionStorage.get("leetent");
                    leetent.bettor = {
                        socialUsername
                    };
                    SessionStorage.set("leetent", leetent);
                    yield axios
                        .put(`${baseUrl}/bets/bettors/${bettorId}`, newSettings)
                        .then(() => {
                        // to be able to save in the DB, user must be logged
                        // in case the updatedSettingUnauthenticated is set, then change it to false
                        // as it is no longer necessary
                        if (updatedOddsSettingUnauthenticated) {
                            SessionStorage.set(SESSION_STORAGE_KEYS.UPDATED_SETTING_UNAUTHENTICATED, false);
                        }
                    });
                }
            }));
        }
    });
    const submitButtonHandler = (e) => {
        e.preventDefault();
        const bettorId = LocalStorage.get(LOCAL_STORAGE_KEYS.WIDGET_PLAYERID);
        const updatedOddsSettingUnauthenticated = SessionStorage.get(SESSION_STORAGE_KEYS.UPDATED_SETTING_UNAUTHENTICATED);
        if (oddsSetting !== setting) {
            SessionStorage.set(SESSION_STORAGE_KEYS.ODDS_SETTING, setting);
            // when updating the oddsSetting without a valid session,
            // then mark the action in storage for future reference
            if (!bettorId)
                SessionStorage.set(SESSION_STORAGE_KEYS.UPDATED_SETTING_UNAUTHENTICATED, true);
        }
        if (oddsSetting !== setting || updatedOddsSettingUnauthenticated)
            saveOption();
        onCloseSettings();
    };
    return (_jsx(ModalBase, Object.assign({ open: open, onClose: onCloseSettings, withContentPadding: false, position: MODAL_POSITION.BOTTOM }, { children: _jsxs("div", Object.assign({ className: styles.settings }, { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx("strong", Object.assign({ className: styles.title }, { children: "Bet Placement Settings" })), _jsxs("div", Object.assign({ className: styles.instructions }, { children: ["After you tap to ", _jsx("strong", { children: "\u201CPlace Bet\u201D" }), " button,", _jsx("br", {}), " automatically accept:"] }))] })), _jsx("div", Object.assign({ className: styles.options }, { children: options.map(({ type, text, example, arrowUpIcon, arrowDownIcon }) => (_jsxs("button", Object.assign({ className: clsx(styles.option, { [styles.active]: setting === type }), onClick: clickOptionHandler(type) }, { children: [_jsxs("div", Object.assign({ className: clsx(styles.details, {
                                    [styles.icon]: arrowUpIcon !== null && arrowUpIcon !== void 0 ? arrowUpIcon : arrowDownIcon,
                                    [styles.arrowUpIcon]: arrowUpIcon,
                                    [styles.arrowDownIcon]: arrowDownIcon
                                }) }, { children: [_jsx("div", { className: styles.indicator }), text] })), example && _jsx("div", Object.assign({ className: styles.example }, { children: example }))] }), `betslip-option-${type}`))) })), _jsx("button", Object.assign({ className: styles.submitButton, onClick: submitButtonHandler }, { children: "Save and Continue" }))] })) })));
};
export const BetSlipFooter = ({ buttonDisabled, clearBetslip, betSubmitHandler, oddsChangedError, isCoupon, themeVariant, isSuspended }) => {
    const [openSetting, setOpenSettings] = React.useState(false);
    const [theme, setTheme] = React.useState(themeVariant);
    const closeSettingsHandler = () => {
        setOpenSettings(false);
    };
    const triggerPlaceBet = () => {
        const bets = LocalStorage.get("betDetails");
        const placeBetbtn = new CustomEvent("betslip-placebet-btn-click", {
            detail: { bets }
        });
        window.dispatchEvent(placeBetbtn);
    };
    React.useEffect(() => {
        setTheme(themeVariant);
    }, [themeVariant]);
    React.useEffect(() => {
        var _a;
        // when settings are not in session, then fetch bettor's data and populate it
        const oddsSetting = SessionStorage.get(SESSION_STORAGE_KEYS.ODDS_SETTING);
        const oddsSettingUpdatedUnauthenticated = SessionStorage.get(SESSION_STORAGE_KEYS.UPDATED_SETTING_UNAUTHENTICATED);
        // considering odds settings is not in the session and that the widget-playerid exists
        if (((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL) &&
            LocalStorage.get(LOCAL_STORAGE_KEYS.WIDGET_PLAYERID) &&
            (!oddsSetting || oddsSettingUpdatedUnauthenticated)) {
            const fetchBettorDetails = () => __awaiter(void 0, void 0, void 0, function* () {
                var _b, _c;
                const bettorId = LocalStorage.get(LOCAL_STORAGE_KEYS.WIDGET_PLAYERID);
                const baseUrl = (_c = (_b = process.env) === null || _b === void 0 ? void 0 : _b.REACT_APP_GATEWAY_API_URL) !== null && _c !== void 0 ? _c : "";
                yield axios
                    .get(`${baseUrl}/bets/bettor/${bettorId}`)
                    .then(({ data }) => {
                    // when bettor details is fetched, then updates the odds settings in the session storage
                    if (data) {
                        if ((data === null || data === void 0 ? void 0 : data.acceptDecreasedOddsChanges) && (data === null || data === void 0 ? void 0 : data.acceptIncreasedOddsChanges)) {
                            SessionStorage.set(SESSION_STORAGE_KEYS.ODDS_SETTING, "oddsChanges");
                        }
                        else if (!(data === null || data === void 0 ? void 0 : data.acceptDecreasedOddsChanges) && (data === null || data === void 0 ? void 0 : data.acceptIncreasedOddsChanges)) {
                            SessionStorage.set(SESSION_STORAGE_KEYS.ODDS_SETTING, "increasedOddsChanges");
                        }
                        else if ((data === null || data === void 0 ? void 0 : data.acceptDecreasedOddsChanges) && !(data === null || data === void 0 ? void 0 : data.acceptIncreasedOddsChanges)) {
                            SessionStorage.set(SESSION_STORAGE_KEYS.ODDS_SETTING, "decreasedOddsChanges");
                        }
                        const leetent = SessionStorage.get("leetent");
                        leetent.bettor = {
                            socialUsername: data === null || data === void 0 ? void 0 : data.socialUsername,
                            avatar: data === null || data === void 0 ? void 0 : data.avatar
                        };
                        SessionStorage.set("leetent", leetent);
                    }
                })
                    .catch((err) => {
                    console.log(err);
                });
            });
            delay(500).then(() => {
                fetchBettorDetails();
            });
        }
        const closeUnauthenticatedModal = (e) => {
            e.preventDefault();
        };
        window.addEventListener("click-modal-button", closeUnauthenticatedModal);
        return () => {
            window.removeEventListener("click-modal-button", closeUnauthenticatedModal);
        };
    }, []);
    const getButtonText = () => {
        if (isSuspended)
            return "Suspended";
        if (oddsChangedError)
            return "Accept Odd Changes";
        if (isCoupon)
            return "Place Bet on OYAwin";
        return "Place Bet";
    };
    const urlToCDN = "https://d1bu7ybn6mtlcz.cloudfront.net/images/";
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.root }, { children: [_jsxs("div", Object.assign({ className: styles.icons }, { children: [_jsx(ImageFromCDN, { alt: "Delete", url: `${urlToCDN}${theme === "dark" ? "delete-light.png" : "delete.png"}`, size: 20, onClick: () => {
                                    clearBetslip();
                                }, "data-testid": "BetSlip.removeAll" }), !isCoupon && _jsx(ImageFromCDN, { alt: "Settings", url: `${urlToCDN}${theme === "dark" ? "settings-light.png" : "setting.png"}`, size: 20, onClick: () => {
                                    setOpenSettings(true);
                                } })] })), _jsx("button", Object.assign({ className: clsx(styles.button, { [styles.oddsChanged]: oddsChangedError, [styles.suspended]: isSuspended }), disabled: buttonDisabled || isSuspended, onClick: !isCoupon ? betSubmitHandler : triggerPlaceBet }, { children: _jsx("strong", Object.assign({ "data-testid": "BetSlip.submitBtn" }, { children: getButtonText() })) }))] })), _jsx(Settings, { open: openSetting, onCloseSettings: closeSettingsHandler })] }));
};
