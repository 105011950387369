import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import clsx from "clsx";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import styles from "./KeyboardButtons.module.scss";
const mainButtons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
const helperButtons = [100, 200, 500, 1000, 2000];
const helperButtonsMultiple = [100, 200, 500, 1000, 2000, 3000, 5000];
const helperButtonsSystem = [100, 200];
export const KeyboardButtons = ({ updateInputValue, addNumber, removeLatestDigit, enableApplyToAll, closeKeyboard, isMultiple, isSystem }) => {
    const keyboardRef = React.useRef(null);
    React.useEffect(() => {
        var _a, _b;
        (_b = (_a = keyboardRef === null || keyboardRef === void 0 ? void 0 : keyboardRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView) === null || _b === void 0 ? void 0 : _b.call(_a, { behavior: "smooth", block: "nearest" });
    }, []);
    const Helpers = () => {
        return (_jsxs("div", Object.assign({ className: styles.helpers, "data-testid": "KeyboardButtons.helpers" }, { children: [_jsx("div", Object.assign({ className: styles.row }, { children: helperButtons.map((button, index) => (_jsx("div", Object.assign({ className: styles.button, onClick: () => {
                            typeof button === "string" ? updateInputValue(button) : addNumber(button);
                        } }, { children: typeof button === "string" ? button : `+${button}` }), `helper-selection-button-${index}`))) })), _jsxs("div", Object.assign({ className: styles.row }, { children: [_jsx("div", Object.assign({ className: clsx(styles.button, styles.applyToAll), onClick: enableApplyToAll, "data-testid": "KeyboardButtons.applyToAll" }, { children: "Apply to all" })), _jsx("div", Object.assign({ className: clsx(styles.button, styles.dot), onClick: () => {
                                updateInputValue(".");
                            } }, { children: "." })), _jsx("div", Object.assign({ className: clsx(styles.button, styles.removeDigit), onClick: removeLatestDigit }, { children: _jsx(ImageFromCDN, { alt: "Delete last stake number", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/backspace.png", size: 15 }) })), _jsx("div", Object.assign({ className: clsx(styles.button, styles.closeBtn), onClick: closeKeyboard }, { children: "CLOSE" }))] }))] })));
    };
    const MultipleHelpers = () => {
        return (_jsx("div", Object.assign({ className: styles.helpersMultiple, "data-testid": "KeyboardButtons.multipleHelpers" }, { children: _jsxs("div", Object.assign({ className: styles.mainButtons }, { children: [helperButtonsMultiple.map((button, index) => (_jsx("div", Object.assign({ className: styles.button, onClick: () => {
                            addNumber(button);
                        } }, { children: `+${button}` }), `helper-selection-button-${index}`))), _jsx("div", Object.assign({ className: clsx(styles.button, styles.dot), onClick: () => {
                            updateInputValue(".");
                        } }, { children: "." })), _jsx("div", Object.assign({ className: clsx(styles.button, styles.removeDigit), onClick: removeLatestDigit }, { children: _jsx(ImageFromCDN, { alt: "Delete last stake number", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/backspace.png", size: 15 }) })), _jsx("div", Object.assign({ className: clsx(styles.button, styles.closeBtn), onClick: closeKeyboard }, { children: "CLOSE" }))] })) })));
    };
    const SystemHelpers = () => {
        return (_jsx("div", Object.assign({ className: styles.helpersMultiple, "data-testid": "KeyboardButtons.multipleHelpers" }, { children: _jsxs("div", Object.assign({ className: styles.mainButtons }, { children: [helperButtonsSystem.map((button, index) => (_jsx("div", Object.assign({ className: styles.button, onClick: () => {
                            addNumber(button);
                        } }, { children: `+${button}` }), `helper-selection-button-${index}`))), _jsx("div", Object.assign({ className: clsx(styles.button, styles.dot), onClick: () => {
                            updateInputValue(".");
                        } }, { children: "." })), _jsx("div", Object.assign({ className: clsx(styles.button, styles.removeDigit), onClick: removeLatestDigit }, { children: _jsx(ImageFromCDN, { alt: "Delete last stake number", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/backspace.png", size: 15 }) })), _jsx("div", Object.assign({ className: clsx(styles.button, styles.closeBtn), onClick: closeKeyboard }, { children: "CLOSE" }))] })) })));
    };
    const getHelpersButtons = () => {
        if (isMultiple) {
            return _jsx(MultipleHelpers, {});
        }
        if (isSystem) {
            return _jsx(SystemHelpers, {});
        }
        return _jsx(Helpers, {});
    };
    return (_jsxs("div", Object.assign({ className: styles.root, "data-testid": "KeyboardButtons", ref: keyboardRef }, { children: [_jsx("div", Object.assign({ className: styles.main }, { children: mainButtons.map((button, index) => (_jsx("div", Object.assign({ onClick: () => {
                        updateInputValue(button);
                    }, className: styles.button, "data-testid": `KeyboardButtons.button-${button}` }, { children: button }), `main-selection-button-${index}`))) })), getHelpersButtons()] })));
};
