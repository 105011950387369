import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import { CURRENCY } from "enums";
import dayjs from "dayjs";
import clsx from "clsx";
import styles from "./FreeBet.module.scss";
export const FreeBet = ({ isFreeBetUsed, onFreeBetClick, amount, expireDate, inFooterNavigation, maxWidth, isRelative }) => {
    return (_jsxs("div", Object.assign({ className: clsx(styles.root, { [styles.footerRoot]: inFooterNavigation, [styles.relative]: isRelative }), onClick: inFooterNavigation ? onFreeBetClick : () => { }, "data-testid": "FreeBet", style: { maxWidth } }, { children: [_jsxs("div", Object.assign({ className: styles.leftSide }, { children: [_jsx(ImageFromCDN, { url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/freeBet.svg", alt: "Free bet", size: 24 }), _jsx("div", Object.assign({ className: clsx(styles.info, { [styles.applied]: isFreeBetUsed }) }, { children: isFreeBetUsed ?
                            (_jsxs(_Fragment, { children: [_jsx("b", { children: `${CURRENCY}${amount.toFixed(2)}` }), " FREE BET APPLIED"] })) :
                            (_jsxs(_Fragment, { children: ["Use ", `${CURRENCY}${amount.toFixed(2)}`, " Free Bet", _jsxs("div", Object.assign({ className: styles.expires }, { children: ["Expires: ", dayjs(expireDate).format("MMM DD,YYYY, HH:mm")] }))] })) }))] })), _jsx("button", Object.assign({ className: styles.btn, onClick: onFreeBetClick, "data-testid": "FreeBet.button" }, { children: inFooterNavigation ?
                    _jsx(ImageFromCDN, { url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/arrow-tip-right.svg", alt: "Arrow", size: 24 }) :
                    isFreeBetUsed ? "Cancel" : "Use now" }))] })));
};
