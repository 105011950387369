import React from "react";
import { getApolloClient } from "config/apolloClient";
export const useSubscribeByQuery = ({ query, variables, defaultData, valueHandlerCallback, enabled = true }) => {
    const [data, setData] = React.useState(defaultData);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [isSubscribed, setIsSubscribed] = React.useState(false);
    const subscriptionRef = React.useRef();
    const previousValueRef = React.useRef(defaultData);
    // Memoize variables to prevent unnecessary re-renders
    const stableVariables = React.useMemo(() => variables, [JSON.stringify(variables)]);
    React.useEffect(() => {
        var _a;
        if (!enabled)
            return;
        let canProceed = true;
        if (variables && ((_a = Object.keys(variables)) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            for (const [key, value] of Object.entries(variables)) {
                if (!value) {
                    canProceed = false;
                }
                else if (typeof key === "string" &&
                    typeof value === "object" &&
                    Array.isArray(value)) {
                    if ((value === null || value === void 0 ? void 0 : value.length) === 0 || (value === null || value === void 0 ? void 0 : value.some((item) => !item))) {
                        canProceed = false;
                    }
                }
            }
        }
        if (!canProceed) {
            // console.error("Error: Missing values from one or more variables");
            setLoading(false);
            return;
        }
        const client = (window === null || window === void 0 ? void 0 : window.webcomponentsApolloClient) ? window.webcomponentsApolloClient : getApolloClient();
        if (client) {
            window.webcomponentsApolloClient = client;
            setLoading(true);
            const observer = client.subscribe({
                query,
                variables
            });
            subscriptionRef.current = observer.subscribe({
                next(response) {
                    const currentData = response === null || response === void 0 ? void 0 : response.data;
                    setData(currentData);
                    if (valueHandlerCallback) {
                        valueHandlerCallback(currentData, previousValueRef.current);
                    }
                    previousValueRef.current = currentData;
                    setLoading(false);
                    setIsSubscribed(true);
                },
                error(e) {
                    console.error("Error:", e);
                    setError(e);
                    setLoading(false);
                }
            });
            return () => {
                var _a;
                (_a = subscriptionRef.current) === null || _a === void 0 ? void 0 : _a.unsubscribe();
                setIsSubscribed(false);
            };
        }
    }, [stableVariables]);
    return {
        data,
        loading,
        error,
        isSubscribed,
        unsubscribe: () => {
            var _a;
            (_a = subscriptionRef.current) === null || _a === void 0 ? void 0 : _a.unsubscribe();
            setIsSubscribed(false);
        }
    };
};
