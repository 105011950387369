// TODO: It defaults to Naira, in case the localstorage -> currency is not provided.
// We should we review it when providing the services in other countries.
// When defaulting to something, we should convert the amount as well.
var _a;
// TODO: split enums into different groups
export const CURRENCY = (_a = localStorage.getItem("currency")) !== null && _a !== void 0 ? _a : "₦";
export const EXCEPT_SYMBOL = ["e", "E", "+", "-"];
export const BET_STATUS = {
    OPEN: 1,
    RESULTED: 2,
    CANCELLED: 3,
    CASHED_OUT: 4
};
export const RESULT_STATUS = {
    WON: 1,
    LOST: 2,
    REFUNDED: 3,
    CASHED_OUT: 4
};
export const SPORTS_SERIES_DEFAULT = 36;
export const ESPORTS_SERIES_DEFAULT = 1;
export const BETSLIP_ODDS_SETTINGS = {
    DECREASED: "decreasedOddsChanges",
    INCREASED: "increasedOddsChanges",
    ANY: "oddsChanges",
    DEFAULT: "default"
};
export const LOCAL_STORAGE_KEYS = {
    WIDGET_PLAYERID: "widget-playerId"
};
export const SESSION_STORAGE_KEYS = {
    ODDS_SETTING: "oddsSetting",
    UPDATED_SETTING_UNAUTHENTICATED: "updatedOddsSettingUnauthenticated"
};
export const MODAL_POSITION = {
    CENTER: "center",
    BOTTOM: "bottom"
};
export const BETSLIP_ERRORS = {
    ODDS_CHANGED_EXCEPTION: "OddsChangedException",
    MAX_WIN_EXCEEDED_EXCEPTION: "MaxWinExceededException",
    INTERRELATED_SELECTIONS: "InterrelatedSelections",
    OUTCOME_CLOSED_EXCEPTION: "OutcomeClosedException"
};
export const STAKE_SELECTIONS_TABS = {
    SINGLES: "singles",
    MULTIPLE: "multiple",
    BET_BUILDER: "bet builder",
    SYSTEM: "system"
};
export const BETS_LIMIT = 20;
export const BETS_COMBINATIONS_LIMIT = 21;
export const EVENT_STATUS = {
    ACTIVE: 10,
    STARTED: 15,
    LIVE: 18,
    FINISHED: 20,
    DISABLED: 30
};
export const MARKET_STATUS = {
    OPEN: 10,
    SUSPENDED: 20,
    CLOSED: 30
};
export const OUTCOME_STATUS = {
    OPEN: 10,
    SUSPENDED: 20,
    CLOSED: 30
};
export const CLOCK_STATUS = {
    RUNNING: 10,
    STOPPED: 20,
    DISABLED: 30
};
export const BETSLIP_SELECTION_STATUS = {
    OPEN: 10,
    SUSPENDED: 20,
    CLOSED: 30
};
export const SPORT = {
    SOCCER: 1,
    BASKETBALL: 2,
    TENNIS: 3,
    VOLLEYBALL: 11,
    BOXING: 12,
    TABLETENNIS: 16
};
export const SPORTS_MARKETS_FILTERS_IDS = {
    ALL: 0,
    BET_BUILDER: -1,
    POPULAR: 1
};
