import { jsx as _jsx } from "react/jsx-runtime";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
const componentObj = {
    marketId: 1,
    data: {
        seriesId: 123,
        marketId: 234,
        selectionId: 345,
        label: "1",
        odds: 1.05
    }
};
export const documentDetails = {
    title: "Odds multi event input",
    component: "esports-odds-multievent-input",
    description: "This is a base component, that is used as part of many composed components.",
    attributes: [
        {
            key: "1",
            attr: "data",
            type: "object",
            notes: [
                "Data object expects the following information.",
                `{
          seriesid: number,
          marketid: number,
          selectionid: number,
          label: string,
          value: number
        }`
            ]
        },
        {
            key: "2",
            attr: "variation",
            type: "number (automatically generated)",
            disabled: true,
            notes: "The old and new values are checked, and processed, so the component knows its variation"
        },
        {
            key: "3",
            attr: "highlight",
            type: "boolean",
            notes: "If true - selection will be highlighted"
        }
    ],
    events: [
        {
            event: "odds-multievent-input-details",
            type: "emit",
            data: [
                "The component details object is emitted when input is clicked, containing the following information.",
                `detail: {
          seriesid: <value>,
          marketid: <value>,
          selectionid: <value>,
          label: <value>,
          value: <value>
       }`
            ]
        }
    ]
};
const OddsMultiEventInput = () => {
    documentDetails.example = `<esports-odds-multievent-input
      id={\`odds_multievent_input_\${oddsButtonsData.marketId}\`}
      data={JSON.stringify({
        seriesId: oddsButtonsData.seriesId,
        marketId: oddsButtonsData.marketId,
        selectionId: oddsButtonsData.marketSelections[0].abId,
        label: oddsButtonsData.marketSelections[0].label,
        value: oddsButtonsData.marketSelections[0].odds
      })}
    />`;
    documentDetails.code = _jsx("esports-odds-multievent-input", { id: `odds_multivent_input_${componentObj.marketId}`, data: JSON.stringify(componentObj.data) });
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default OddsMultiEventInput;
