var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import dayjs from "dayjs";
// TODO: Define on how to use locale. Perhaps based on a .env environment or session variable
// import "dayjs/locale/es";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
// import timezone from "dayjs/plugin/timezone"; // TODO: Verify is this is important to us or not
import weekOfYear from "dayjs/plugin/weekOfYear";
import { Row, Col } from "antd";
import { createRoot } from "react-dom/client";
import { v4 as uuidv4 } from "uuid";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
// styles
import styles from "./DatePicker.module.scss";
// extensions
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(weekOfYear);
// TODO: Apply locale properly
// dayjs.locale("es");
// date formats
// TODO: Abstract those formats to a date format helper file and/or .env
const inputLabelFormat = "DD/M ddd";
const monthYearFormat = "MMM YYYY";
const yearMonthFormat = "YYYYMM";
const dateFormat = "YYYYMMDD";
const daysOfTheWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const DatePicker = () => {
    const [selectedDate, setSelectedDate] = React.useState(dayjs());
    const [selectedMonth, setSelectedMonth] = React.useState(dayjs().format(monthYearFormat));
    const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);
    const [calendar, setCalendar] = React.useState([]);
    // sets the previous or next day information
    const PreviousOrNextDay = React.useCallback((value) => {
        setSelectedDate(oldValue => dayjs(oldValue).add(value === "previous" ? -1 : 1, "day"));
    }, []);
    // calendar gets processed
    React.useEffect(() => {
        if (selectedMonth) {
            const firstDay = {
                day: 1,
                index: dayjs(selectedMonth, monthYearFormat).startOf("month").day()
            };
            const lastDay = {
                day: dayjs().endOf("month").date(),
                index: dayjs(selectedMonth, monthYearFormat).endOf("month").day()
            };
            const tempCalendar = [];
            let currentDay = firstDay.day;
            // processes the previous days
            let firstDayIndex = firstDay.index;
            let subtractedCounter = 1;
            while (firstDayIndex >= 1) {
                const date = dayjs(selectedMonth, monthYearFormat).startOf("month").subtract(subtractedCounter, "day");
                tempCalendar.unshift({
                    label: date.date(),
                    date: date.format(dateFormat)
                });
                firstDayIndex--;
                subtractedCounter++;
            }
            // adds all days in the month
            while (currentDay <= lastDay.day) {
                const date = dayjs(selectedMonth, monthYearFormat).date(currentDay);
                tempCalendar.push({
                    label: date.date(),
                    date: date.format(dateFormat)
                });
                currentDay++;
            }
            let lastDayIndex = lastDay.index;
            let addedCounter = 1;
            while (lastDayIndex < 6) {
                const date = dayjs(selectedMonth, monthYearFormat).endOf("month").add(addedCounter, "day");
                tempCalendar.push({
                    label: date.date(),
                    date: date.format(dateFormat)
                });
                lastDayIndex++;
                addedCounter++;
            }
            setCalendar(tempCalendar);
        }
    }, [selectedMonth]);
    // emits the selected date information
    React.useEffect(() => {
        if (selectedDate) {
            const triggerSelectedDate = new CustomEvent("selected-date", {
                detail: {
                    date: selectedDate.format(dateFormat),
                    year: selectedDate.year(),
                    month: selectedDate.month(),
                    day: selectedDate.date(),
                    dayOfWeek: {
                        index: selectedDate.day(),
                        name: selectedDate.format("dddd")
                    },
                    weekOfYear: selectedDate.week(),
                    iso: {
                        start: selectedDate.startOf("date").format("YYYY-MM-DDTHH:mm:ssZ"),
                        end: selectedDate.endOf("date").format("YYYY-MM-DDTHH:mm:ssZ")
                    },
                    timestamps: {
                        start: selectedDate.utc(false).startOf("date").valueOf(),
                        end: selectedDate.utc(false).endOf("date").valueOf()
                    }
                },
                bubbles: true
            });
            window.dispatchEvent(triggerSelectedDate);
        }
    }, [selectedDate]);
    // calendar component
    const Calendar = React.useCallback(() => {
        const rows = [];
        let columns = [];
        if (calendar) {
            let counter = 1;
            calendar.forEach((day, index) => {
                const isActive = selectedDate.format(dateFormat) === day.date;
                columns.push(_jsx(Col, Object.assign({ className: styles.day, style: {
                        color: dayjs(selectedMonth, monthYearFormat).format(yearMonthFormat) !== day.date.substring(0, 6) ?
                            "hsl(0, 0%, 75%)" :
                            (isActive ?
                                "#fff" :
                                "inherit"),
                        backgroundColor: isActive ?
                            "var(--primaryBrandColor, #CD3365)" :
                            "",
                        borderRadius: "4px",
                        fontWeight: day.date === dayjs().format(dateFormat) ||
                            isActive ?
                            "bold" :
                            "400"
                    }, onClick: () => {
                        setSelectedDate(dayjs(day.date, dateFormat));
                        setIsDatePickerOpen(oldValue => !oldValue);
                    }, "data-testid": `Calendar-${day.date}${isActive ? "-active" : ""}` }, { children: day.label }), day.date));
                if (counter === 7) {
                    rows.push(_jsx(Row, Object.assign({ justify: "space-around", align: "middle", className: styles.week, gutter: [10, 10] }, { children: columns }), uuidv4()));
                    columns = [];
                }
                counter === 7 ? counter = 1 : counter++;
            });
        }
        return (rows);
    }, [calendar, selectedDate]);
    return (_jsxs("div", Object.assign({ "data-testid": "DatePicker" }, { children: [_jsxs(Row, Object.assign({ justify: "space-between", align: "middle", gutter: [0, 8], className: styles.date }, { children: [_jsx(Col, { flex: "30px", className: `${styles["left-arrow"]}`, onClick: () => {
                            PreviousOrNextDay("previous");
                            setIsDatePickerOpen(false);
                        } }), _jsx(Col, Object.assign({ flex: "auto", className: styles["date-picker"] }, { children: _jsxs(Row, Object.assign({ align: "middle", gutter: [8, 8], onClick: () => { setIsDatePickerOpen(oldValue => !oldValue); }, "data-testid": "DatePicker.togglePanel" }, { children: [_jsx(Col, Object.assign({ flex: "auto" }, { children: _jsx("div", Object.assign({ className: styles.calendar }, { children: _jsx(ImageFromCDN, { alt: "Calendar date picker", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/calendar.svg" }) })) })), _jsx(Col, Object.assign({ flex: "none", style: { textAlign: "left", fontSize: "12px" } }, { children: dayjs(selectedDate).format(inputLabelFormat) })), _jsx(Col, Object.assign({ flex: "auto" }, { children: _jsx(ImageFromCDN, { id: "angleDownIcon", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/angle-down.png", size: 8, overrideStyles: styles["date-picker-arrow"] }) }))] })) })), _jsx(Col, { flex: "30px", className: `${styles["right-arrow"]}`, onClick: () => {
                            PreviousOrNextDay("next");
                            setIsDatePickerOpen(false);
                        } })] })), _jsxs("div", Object.assign({ className: styles["date-picker-content"], style: {
                    display: isDatePickerOpen ? "block" : "none"
                }, "data-testid": "DatePicker.content" }, { children: [_jsxs(Row, Object.assign({ justify: "center", align: "middle" }, { children: [_jsx(Col, Object.assign({ flex: "30px", onClick: () => {
                                    setSelectedMonth(dayjs(selectedMonth, monthYearFormat).subtract(1, "month").format(monthYearFormat));
                                } }, { children: _jsx(ImageFromCDN, { id: "angleDownLeftIcon", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/angle-down.png", overrideStyles: styles["left-arrow"], size: 10, float: "center" }) })), _jsx(Col, Object.assign({ flex: "auto", style: { textAlign: "center", fontSize: "13px", fontWeight: "bold" } }, { children: selectedMonth })), _jsx(Col, Object.assign({ flex: "30px", onClick: () => {
                                    setSelectedMonth(dayjs(selectedMonth, monthYearFormat).add(1, "month").format(monthYearFormat));
                                } }, { children: _jsx(ImageFromCDN, { id: "angleDownRightIcon", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/angle-down.png", overrideStyles: styles["right-arrow"] }) }))] })), _jsx(Row, Object.assign({ justify: "space-around", align: "middle", className: styles["day-of-the-week"] }, { children: daysOfTheWeek.map(dayOfTheWeek => {
                            return (_jsx(Col, Object.assign({ className: styles["day-of-the-week-label"] }, { children: dayOfTheWeek }), dayOfTheWeek));
                        }) })), _jsx(Calendar, {}), _jsxs(Row, Object.assign({ justify: "space-around", align: "middle", className: styles["date-picker-footer"] }, { children: [_jsx(Col, Object.assign({ span: 12, className: styles["date-picker-footer-today"], onClick: () => { setSelectedDate(dayjs()); setIsDatePickerOpen(oldValue => !oldValue); } }, { children: "Today" })), _jsx(Col, Object.assign({ span: 12, className: styles["date-picker-footer-close"], onClick: () => { setIsDatePickerOpen(oldValue => !oldValue); } }, { children: "Close" }))] }))] }))] })));
};
class Element extends HTMLElement {
    connectedCallback() {
        createRoot(this).render(_jsx(DatePicker, {}));
    }
}
(_a = customElements.get("esports-date-picker")) !== null && _a !== void 0 ? _a : customElements.define("esports-date-picker", Element);
