var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import { groupLeagueMatchesByMarketsAndDates } from "helpers/Sports/LeagueFunctions";
import { Outrights } from "./outrights/Outrights";
import axios from "axios";
import { OutrightsSkeleton } from "./outrights/OutrightsSkeleton";
const outrightOptionId = "outrights";
const LeagueMultipleMarkets = ({ productId, data, marketsView = "tabs", defaultOutcomes, sport }) => {
    const [marketsEvents, setMarketsEvents] = React.useState();
    const [filteredMarketEvents, setFilteredMarketEvents] = React.useState();
    const [options, setOptions] = React.useState([]);
    const [selectedOption, setSelectedOption] = React.useState();
    const [country, setCountry] = React.useState();
    const [league, setLeague] = React.useState();
    const [outrightsData, setOutrightsData] = React.useState();
    const [outrightAvailable, setOutrightAvailable] = React.useState(false);
    React.useEffect(() => {
        const legueMarketsTabsHandler = (e) => {
            marketsView === "tabs" && setSelectedOption(e.detail.value);
            marketsView === "tags" && setSelectedOption(e.detail.id);
        };
        marketsView === "tabs" && window.addEventListener("league-markets-options", legueMarketsTabsHandler);
        marketsView === "tags" && window.addEventListener("selected-tag-details", legueMarketsTabsHandler);
        // Processes tournaments data
        const processedData = groupLeagueMatchesByMarketsAndDates(data);
        setMarketsEvents(processedData.marketsEvents);
        setFilteredMarketEvents(processedData.marketsEvents[0]);
        setOptions([...processedData.options]);
        setOutrightAvailable(processedData.isOutrightAvailable);
        setSelectedOption(processedData.selectedOption);
        setCountry(data === null || data === void 0 ? void 0 : data.country);
        setLeague({
            id: data === null || data === void 0 ? void 0 : data.id,
            name: data === null || data === void 0 ? void 0 : data.name
        });
        // cleanup executed on unmount
        return () => {
            marketsView === "tabs" && window.removeEventListener("league-markets-options", legueMarketsTabsHandler);
            marketsView === "tags" && window.removeEventListener("selected-tag-details", legueMarketsTabsHandler);
        };
    }, []);
    React.useEffect(() => {
        if (outrightAvailable) {
            setOptions(options => {
                const isOutrightPresent = options.some(({ name, value }) => name === "Outrights" && value === outrightOptionId);
                if (!isOutrightPresent) {
                    return [
                        options[0],
                        { name: "Outrights", value: outrightOptionId },
                        ...options.slice(1)
                    ];
                }
                return options;
            });
        }
    }, [outrightAvailable]);
    React.useEffect(() => {
        var _a;
        // Filter related market
        if (selectedOption && marketsEvents && ((_a = Object.keys(marketsEvents)) === null || _a === void 0 ? void 0 : _a.length)) {
            const market = marketsEvents === null || marketsEvents === void 0 ? void 0 : marketsEvents.filter((market) => market.id === selectedOption)[0];
            setFilteredMarketEvents(market);
        }
    }, [selectedOption]);
    React.useEffect(() => {
        // Handle outrights
        if (selectedOption === outrightOptionId) {
            const fetchOutrightsData = () => __awaiter(void 0, void 0, void 0, function* () {
                if (!(data === null || data === void 0 ? void 0 : data.id))
                    return;
                yield axios
                    .get(`/outright-events?tournamentId=${data.id}`)
                    .then(({ data }) => {
                    setOutrightsData(data);
                })
                    .catch((err) => {
                    console.log(err);
                });
            });
            fetchOutrightsData();
        }
    }, [selectedOption, data]);
    if (selectedOption === outrightOptionId && outrightAvailable && !outrightsData) {
        return _jsx(OutrightsSkeleton, {});
    }
    return (_jsxs(_Fragment, { children: [marketsView === "tabs" ?
                _jsx("div", Object.assign({ style: { marginBottom: "3px" } }, { children: _jsx("esports-tabs-header", { id: "league-market-options", tabs: JSON.stringify(options), selected: selectedOption, "event-name": "league-markets-options" }) })) :
                _jsx("div", { children: _jsx("esports-tags-list", { tags: JSON.stringify(options.map((option) => {
                            return { id: option.value, name: option === null || option === void 0 ? void 0 : option.name };
                        })), selected: selectedOption, "with-side-spacing": true, uppercase: true }) }), selectedOption === outrightOptionId && outrightAvailable && outrightsData ?
                _jsx(Outrights, { data: outrightsData, productId: productId, defaultOutcomes: defaultOutcomes, sport: { id: sport === null || sport === void 0 ? void 0 : sport.id, name: sport === null || sport === void 0 ? void 0 : sport.name } }) :
                filteredMarketEvents && (_jsx("esports-league-matches-bydates", { "product-id": productId, sport: JSON.stringify({ id: sport === null || sport === void 0 ? void 0 : sport.id, name: sport === null || sport === void 0 ? void 0 : sport.name }), country: JSON.stringify(country), league: JSON.stringify(league), data: JSON.stringify(filteredMarketEvents), "default-outcomes": JSON.stringify(defaultOutcomes) }))] }));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this.data = [];
        this.sport = {};
        this["default-outcomes"] = [];
    }
    static get observedAttributes() {
        return ["data", "markets-view", "default-outcomes", "product-id", "sport"];
    }
    renderComponent() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(LeagueMultipleMarkets, { productId: this["product-id"], data: this.data, marketsView: this["markets-view"], defaultOutcomes: this["default-outcomes"], sport: this.sport }));
    }
    connectedCallback() {
        this.renderComponent();
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        switch (attrName) {
            case "data":
            case "default-outcomes":
            case "sport":
                this[attrName] = JSON.parse(newValue);
                break;
            case "markets-view":
                this[attrName] = String(newValue);
                break;
            case "product-id":
                this[attrName] = Number(newValue);
                break;
            default:
                break;
        }
        this.renderComponent();
    }
}
(_a = customElements.get("esports-league-multiple-markets")) !== null && _a !== void 0 ? _a : customElements.define("esports-league-multiple-markets", Element);
