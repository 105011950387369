import { jsx as _jsx } from "react/jsx-runtime";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
const posts = [
    {
        betRef: "84972109-e60f-4197-bc4b-514aa66e79e4",
        id: 3802,
        stake: 200,
        createdDate: "2023-08-29T09:30:25.036663+00:00",
        potentialPayout: 460.92,
        payout: null,
        statusId: 1,
        cashoutOffer: 387.83,
        resultedDate: null,
        betAccumulatorBonus: null,
        betLegs: [
            {
                odds: 1.67,
                dividend: null,
                resultStatusId: null,
                resultedDate: null,
                outcome: {
                    id: 6820447,
                    name: "BOOM Esports to win",
                    market: {
                        id: 2630198,
                        name: "Series Winner",
                        event: {
                            id: 623841,
                            name: "Southeast Asia - Lower Bracket",
                            tournament: {
                                id: 15877,
                                name: "TI",
                                sport: {
                                    id: 1,
                                    name: "Dota 2"
                                }
                            }
                        }
                    }
                }
            },
            {
                odds: 1.38,
                dividend: null,
                resultStatusId: null,
                resultedDate: null,
                outcome: {
                    id: 6820543,
                    name: "Level UP to win",
                    market: {
                        id: 2630230,
                        name: "Series Winner",
                        event: {
                            id: 591193,
                            name: "Western Europe - Lower Bracket",
                            tournament: {
                                id: 15877,
                                name: "TI",
                                sport: {
                                    id: 1,
                                    name: "Dota 2"
                                }
                            }
                        }
                    }
                }
            }
        ],
        isLive: false
    },
    {
        betRef: "4e3a6dca-82b8-416a-9b2f-2a2b030f48b7",
        id: 3797,
        stake: 100,
        createdDate: "2023-08-25T14:57:40.671786+00:00",
        potentialPayout: 41840.55,
        payout: null,
        statusId: 1,
        cashoutOffer: null,
        resultedDate: null,
        betAccumulatorBonus: null,
        betLegs: [
            {
                odds: 2.38,
                dividend: null,
                resultStatusId: null,
                resultedDate: null,
                outcome: {
                    id: 6447354,
                    name: "Bad News Bears to win",
                    market: {
                        id: 2505826,
                        name: "Series Winner",
                        event: {
                            id: 629406,
                            name: "Round Robin",
                            tournament: {
                                id: 17395,
                                name: "ESL NA",
                                sport: {
                                    id: 5,
                                    name: "CS:GO"
                                }
                            }
                        }
                    }
                }
            },
            {
                odds: 3.15,
                dividend: 0,
                resultStatusId: 2,
                resultedDate: "2023-08-25T15:32:08.665523+00:00",
                outcome: {
                    id: 6649441,
                    name: "Draw",
                    market: {
                        id: 2573191,
                        name: "Match Winner",
                        event: {
                            id: 633438,
                            name: "Nicolas_Rage (Italy) - Vicmestro (Spain)"
                        }
                    }
                }
            },
            {
                odds: 2.9,
                dividend: 0,
                resultStatusId: 2,
                resultedDate: "2023-08-25T15:56:43.461491+00:00",
                outcome: {
                    id: 6648006,
                    name: "Musky to win",
                    market: {
                        id: 2572713,
                        name: "Match Winner",
                        event: {
                            id: 633406,
                            name: "Vicmestro (Spain) - Musky (Belgium)"
                        }
                    }
                }
            },
            {
                odds: 1.27,
                dividend: 0,
                resultStatusId: 2,
                resultedDate: "2023-08-25T16:44:42.688342+00:00",
                outcome: {
                    id: 6655805,
                    name: "9INE to win",
                    market: {
                        id: 2575313,
                        name: "Series Winner",
                        event: {
                            id: 539656,
                            name: "Closed Qualifier - LB Cons. Final",
                            tournament: {
                                id: 14654,
                                name: "IEM",
                                sport: {
                                    id: 5,
                                    name: "CS:GO"
                                }
                            }
                        }
                    }
                }
            },
            {
                odds: 1.06,
                dividend: 1.06,
                resultStatusId: 1,
                resultedDate: "2023-08-25T20:41:46.215994+00:00",
                outcome: {
                    id: 6664541,
                    name: "Thunder Awaken to win",
                    market: {
                        id: 2578225,
                        name: "Series Winner",
                        event: {
                            id: 591174,
                            name: "South America - LB Semifinal",
                            tournament: {
                                id: 15877,
                                name: "TI",
                                sport: {
                                    id: 1,
                                    name: "Dota 2"
                                }
                            }
                        }
                    }
                }
            },
            {
                odds: 3.8,
                dividend: 3.8,
                resultStatusId: 1,
                resultedDate: "2023-08-25T22:43:48.479854+00:00",
                outcome: {
                    id: 6554676,
                    name: "los kogutos to win",
                    market: {
                        id: 2541602,
                        name: "Series Winner",
                        event: {
                            id: 628364,
                            name: "Bracket - Round of 16",
                            tournament: {
                                id: 19820,
                                name: "CCT NE",
                                sport: {
                                    id: 5,
                                    name: "CS:GO"
                                }
                            }
                        }
                    }
                }
            },
            {
                odds: 1.9,
                dividend: 1.9,
                resultStatusId: 1,
                resultedDate: "2023-08-26T14:00:17.584207+00:00",
                outcome: {
                    id: 6456674,
                    name: "CTBC Flying Oyster to win",
                    market: {
                        id: 2508934,
                        name: "Series Winner",
                        event: {
                            id: 508011,
                            name: "Bracket - LB Final",
                            tournament: {
                                id: 14414,
                                name: "PCS",
                                sport: {
                                    id: 2,
                                    name: "LoL"
                                }
                            }
                        }
                    }
                }
            },
            {
                odds: 1.98,
                dividend: 0,
                resultStatusId: 2,
                resultedDate: "2023-08-26T18:37:18.122381+00:00",
                outcome: {
                    id: 6462564,
                    name: "SK Gaming to win",
                    market: {
                        id: 2510897,
                        name: "Series Winner",
                        event: {
                            id: 610454,
                            name: "Bracket - LB Semifinal",
                            tournament: {
                                id: 19512,
                                name: "LEC.EMEA",
                                sport: {
                                    id: 2,
                                    name: "LoL"
                                }
                            }
                        }
                    }
                }
            }
        ],
        isLive: false
    }
];
export const documentDetails = {
    title: "Social profile card",
    component: "esports-social-profile-card",
    description: `This component presents posts in its different states (related to the social-bettor tabs.
    e.g. Popular, latest, following, etc.), and it is rendered inside the
    esports-social-bettor component, so users can interact with.`,
    attributes: [
        {
            key: "1",
            attr: "posts",
            type: "object",
            notes: "Data objects that contains related posts"
        },
        {
            key: "2",
            attr: "user-post-required (optional)",
            type: "boolean",
            notes: "TODO: Currently not in use? It must be removed if not required."
        },
        {
            key: "3",
            attr: "isloading",
            type: "boolean",
            notes: `Attribute used to present a skeleton while data is being retrieved.
        TODO: Currently not in use? It must be removed if not required.`
        },
        {
            key: "4",
            attr: "no-data-options",
            type: "object{} optional",
            notes: [
                "Custom no data options.",
                `{
          message:"No Events Available at the moment",
          src:"https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg",
          cta:{JSON.stringify(label: "Go To Sportsbook",url: "/")}
        }`,
                `Default value is:           
          {
            message:"No Events Available at the moment",
            src:"https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg",
          }`
            ]
        }
    ],
    events: [
        {
            event: "nodata-cta-event-details",
            type: "listener",
            data: [
                "When a user clicks on the button, this event gets emitted.",
                `detail : {
          url: <value>
        }`
            ]
        }
    ]
};
const SocialProfileCard = () => {
    documentDetails.example = `<esports-social-profile-card posts={[]} />`;
    documentDetails.code = _jsx("esports-social-profile-card", { posts: JSON.stringify(posts), "user-post-required": false, loading: false, "no-data-options": JSON.stringify({
            message: "No Events Available at the moment",
            src: "https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg",
            cta: { label: "Go To Sportbook", url: "/" }
        }) });
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default SocialProfileCard;
