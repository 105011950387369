import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import clsx from "clsx";
import styles from "./Live.module.scss";
import { CLOCK_STATUS } from "enums";
import { periodConverter, periods as sportsPeriods } from "helpers/Sports/Periods";
import { sportsWhereCounterIsNotRequired } from "helpers/InPlayBlacklistSports";
import dayjs from "dayjs";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
const Live = ({ period, timeCounter, isCountdown = false, isWhite, statusId, sport, startDate, minutes, seconds, style }) => {
    const [secondsLeft, setSecondsLeft] = React.useState(0);
    const minsLeft = Math.floor(secondsLeft / 60);
    // If the event is starting late (current time > start time and clock still is disabled)
    const isStartingLate = startDate !== undefined &&
        dayjs(startDate) < dayjs() &&
        minutes === 0 &&
        seconds === 0 &&
        statusId !== CLOCK_STATUS.RUNNING;
    const [isStartingSoon] = React.useState(isStartingLate || (startDate && dayjs(startDate) >= dayjs()));
    React.useEffect(() => {
        var _a;
        if (isStartingSoon) {
            // starting countdown to start date
            const secondsUntilStart = dayjs(startDate).diff(dayjs(), "second");
            isCountdown = false;
            setSecondsLeft(secondsUntilStart);
        }
        else if (timeCounter !== undefined &&
            !sportsWhereCounterIsNotRequired(sport === null || sport === void 0 ? void 0 : sport.id)) {
            // actual clock for a started match
            const minutesAndSeconds = (_a = String(timeCounter)) === null || _a === void 0 ? void 0 : _a.split(":");
            timeCounter = Number(minutesAndSeconds[0]) * 60 + Number(minutesAndSeconds[1]);
            setSecondsLeft(timeCounter);
        }
    }, []);
    React.useEffect(() => {
        // Stop counting if the clock is not running, or if we have counted down to 0 already
        if ((!isStartingSoon && statusId !== CLOCK_STATUS.RUNNING) || (isCountdown && secondsLeft <= 0))
            return;
        const timeout = setTimeout(() => {
            if (isCountdown || dayjs(startDate) > dayjs() || isStartingSoon) {
                setSecondsLeft(oldValue => oldValue - 1);
            }
            else {
                setSecondsLeft(oldValue => oldValue + 1);
            }
        }, 1000);
        return () => {
            clearTimeout(timeout);
        };
    }, [secondsLeft]);
    const convertedPeriod = periodConverter(period, sport === null || sport === void 0 ? void 0 : sport.id, statusId, minutes, seconds);
    const RemainingTime = ({ onlyMinutes = false }) => {
        const calculated = () => {
            if (convertedPeriod) {
                return (`${sportsPeriods[sport === null || sport === void 0 ? void 0 : sport.id][convertedPeriod]}:00 + ${(((secondsLeft - (sportsPeriods[sport === null || sport === void 0 ? void 0 : sport.id][convertedPeriod] * 60)) / 60) + 1).toFixed(0)}`);
            }
            return "";
        };
        if ((sport === null || sport === void 0 ? void 0 : sport.id) === 1 && convertedPeriod === "1ST HALF" &&
            secondsLeft > sportsPeriods[sport === null || sport === void 0 ? void 0 : sport.id][convertedPeriod] * 60) {
            return (_jsxs("span", { children: [calculated(), " | "] }));
        }
        else if ((sport === null || sport === void 0 ? void 0 : sport.id) === 1 && convertedPeriod === "2ND HALF" &&
            secondsLeft > sportsPeriods[sport === null || sport === void 0 ? void 0 : sport.id][convertedPeriod] * 60) {
            return (_jsxs("span", { children: [calculated(), " | "] }));
        }
        return (_jsxs("span", { children: [`${String(isStartingSoon && minsLeft < 1 ? 1 : minsLeft).padStart(onlyMinutes ? 1 : 2, "0")}` + (!onlyMinutes ?
                    `:${String(secondsLeft - Math.floor(secondsLeft / 60) * 60).padStart(2, "0")}` :
                    ` min${minsLeft > 1 ? "s" : ""}`), !isStartingSoon && " | "] }));
    };
    return (_jsxs("div", Object.assign({ style: Object.assign({ display: "flex", alignItems: "center" }, style) }, { children: [!isStartingSoon && _jsx("span", Object.assign({ className: styles.live }, { children: "LIVE" })), isStartingSoon && timeCounter &&
                _jsxs("span", Object.assign({ className: clsx(styles.startingIn) }, { children: [_jsx(ImageFromCDN, { alt: "Alarm", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/timer-line.svg", size: 16, overrideStyles: { marginRight: 1 } }), isStartingLate || (isStartingSoon && minsLeft < 1) ?
                            "Starting Soon" :
                            _jsxs(_Fragment, { children: ["Starting in", _jsx("div", Object.assign({ className: styles.remainingTimeBg }, { children: _jsx(RemainingTime, { onlyMinutes: true }) }))] })] })), period && !isStartingSoon &&
                _jsxs("span", Object.assign({ className: clsx(styles.currentPeriod, {
                        [styles.whiteLabel]: isWhite
                    }) }, { children: [timeCounter && statusId !== CLOCK_STATUS.DISABLED && _jsx(RemainingTime, {}), convertedPeriod] }))] })));
};
export default Live;
