var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { avatars } from "assets/avatars/Avatars";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import r2wc from "@r2wc/react-to-web-component";
import EditUserProfileName from "../editUserName/EditUserName";
import EditProfileImage from "../editProfileImage/EditProfileImage";
import clsx from "clsx";
import { fetchProfile } from "../../../helpers/SessionProfileId";
import { LocalStorage } from "helpers/Storage";
import flexStyle from "assets/styles/flex.module.scss";
import style from "./EditProfileWrapper.module.scss";
export const charValueSumModulo = (userName) => {
    return userName ?
        Array.from(userName === null || userName === void 0 ? void 0 : userName.toLowerCase())
            .map((letter) => letter >= "a" && letter <= "z" ? letter.charCodeAt(0) - 96 : 0)
            .reduce((sum, letterValue) => sum + letterValue, 0) % 10 :
        0;
};
const EditProfileWrapper = ({ profileId, setShowUserProfile, showUserProfile }) => {
    const [isLoading, setIsloading] = useState(true);
    const [userName, setUserName] = useState("");
    const [avatarUrl, setAvatarUrl] = useState("");
    const [showProfile, setShowProfile] = useState(false);
    const [userPosts, setUserPosts] = useState([]);
    const [refreshPage, setRefreshPage] = useState(false);
    const [followFlag, setFollowFlag] = useState(true);
    const [followLoading, setFollowLoading] = useState(false);
    const [isFollowClicked, setIsFollowClicked] = useState(false);
    const [followers, setFollowers] = useState(null);
    const [profileIdFromSession, setProfileIdFromSession] = useState(sessionStorage.getItem("profileId"));
    let debounceTimeout;
    useEffect(() => {
        const profileImageDetailsHandler = (e) => {
            e.preventDefault();
            e.detail.showUserProfile !== showProfile && setShowProfile(e.detail.showUserProfile);
        };
        window.addEventListener("profile-image-details", profileImageDetailsHandler);
        return () => {
            window.removeEventListener("profile-image-details", profileImageDetailsHandler);
        };
    }, [profileIdFromSession]);
    const playerId = LocalStorage.get("widget-playerId");
    const token = localStorage.getItem("widget-token");
    const userProfileJSON = sessionStorage.getItem("userProfile");
    let bettorIdFromSessionUserProfile;
    if (userProfileJSON) {
        bettorIdFromSessionUserProfile = JSON.parse(userProfileJSON);
    }
    else {
        bettorIdFromSessionUserProfile = {};
    }
    useEffect(() => {
        if (playerId && token && bettorIdFromSessionUserProfile.id) {
            fetchProfile(setProfileIdFromSession, bettorIdFromSessionUserProfile.id);
        }
    }, [playerId, token, bettorIdFromSessionUserProfile]);
    const triggerProfileDetails = useMemo(() => {
        return new CustomEvent("edit-profile-image-details", {
            detail: {
                showUserProfile: true
            },
            bubbles: true
        });
    }, [showProfile]);
    useEffect(() => {
        window.dispatchEvent(triggerProfileDetails);
    }, [triggerProfileDetails]);
    useEffect(() => {
        if (profileId || profileIdFromSession)
            getBettorDetails();
        setRefreshPage(false);
    }, [profileId, refreshPage, profileIdFromSession]);
    useEffect(() => {
        const getFollowees = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f, _g;
            // TODO: follows -> followers should be under the /api structure.
            try {
                setFollowLoading(true);
                const response = yield axios.get(`follows/${profileIdFromSession !== null && profileIdFromSession !== void 0 ? profileIdFromSession : ""}/followees`, { baseURL: (_b = (_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL) === null || _b === void 0 ? void 0 : _b.replace("api/", "") });
                setFollowLoading(false);
                setFollowers((_e = (_d = (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.followees) === null || _d === void 0 ? void 0 : _d.items) === null || _e === void 0 ? void 0 : _e.length);
                const followeesId = (_g = (_f = response === null || response === void 0 ? void 0 : response.data) === null || _f === void 0 ? void 0 : _f.followees) === null || _g === void 0 ? void 0 : _g.items.map((el) => { return el.profileId; });
                if (followeesId === null || followeesId === void 0 ? void 0 : followeesId.includes(profileId))
                    setFollowFlag(false);
            }
            catch (e) {
                // TODO error handling
            }
        });
        if (profileIdFromSession)
            getFollowees();
    }, [profileIdFromSession, followers]);
    const getBettorDetails = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e;
        if ((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL) {
            try {
                const profileIdToUse = !isMyProfile ?
                    profileId :
                    profileIdFromSession;
                const response = yield axios.get(`${(_d = (_c = (_b = process.env) === null || _b === void 0 ? void 0 : _b.REACT_APP_GATEWAY_API_URL) === null || _c === void 0 ? void 0 : _c.replace("api/", "")) !== null && _d !== void 0 ? _d : ""}/profile/${profileIdToUse !== null && profileIdToUse !== void 0 ? profileIdToUse : ""}`);
                const { displayName, avatarUrl } = response.data;
                setUserName(displayName);
                setAvatarUrl(avatarUrl || avatars[charValueSumModulo(displayName || "")]);
                getUserPostsData((_e = response === null || response === void 0 ? void 0 : response.data) === null || _e === void 0 ? void 0 : _e.profileId);
            }
            catch (e) {
                // TODO error handling
            }
        }
    });
    const getUserPostsData = (id) => __awaiter(void 0, void 0, void 0, function* () {
        var _f, _g;
        if ((_f = process.env) === null || _f === void 0 ? void 0 : _f.REACT_APP_GATEWAY_API_URL) {
            setIsloading(true);
            // TODO: Listen for errors and add to the error state
            const response = yield axios.get(`${(_g = process.env) === null || _g === void 0 ? void 0 : _g.REACT_APP_GATEWAY_API_URL}/posts?profile-id=${id}
      `);
            setIsloading(false);
            setUserPosts(response.data.items);
        }
    });
    const isMyProfile = !profileId || profileId === profileIdFromSession;
    const goBack = () => {
        if (showUserProfile && isMyProfile) {
            setShowUserProfile(false);
        }
        else if (!showUserProfile && isMyProfile) {
            window.history.back();
        }
        else if (showUserProfile) {
            setShowUserProfile(false);
        }
        else {
            window.history.back();
        }
    };
    const handleFollow = () => __awaiter(void 0, void 0, void 0, function* () {
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setIsFollowClicked(true);
        const followPayload = {
            followerId: profileIdFromSession,
            followeeId: profileId
        };
        if (profileId && profileIdFromSession) {
            if (followFlag) {
                debounceTimeout = setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
                    var _h, _j, _k;
                    try {
                        yield axios.post(`${(_k = (_j = (_h = process.env) === null || _h === void 0 ? void 0 : _h.REACT_APP_GATEWAY_API_URL) === null || _j === void 0 ? void 0 : _j.replace("api/", "")) !== null && _k !== void 0 ? _k : ""}/follows`, followPayload);
                        setFollowFlag(false);
                    }
                    catch (e) {
                        // TODO error handling
                    }
                    setIsFollowClicked(false);
                }), 1);
            }
            else {
                debounceTimeout = setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
                    var _l, _m, _o, _p;
                    try {
                        yield axios.delete(`${(_o = (_m = (_l = process.env) === null || _l === void 0 ? void 0 : _l.REACT_APP_GATEWAY_API_URL) === null || _m === void 0 ? void 0 : _m.replace("api/", "")) !== null && _o !== void 0 ? _o : ""}/unfollow/
              ${(_p = profileId) !== null && _p !== void 0 ? _p : ""}/${profileIdFromSession !== null && profileIdFromSession !== void 0 ? profileIdFromSession : ""}`);
                        setFollowFlag(true);
                    }
                    catch (e) {
                        // TODO error handling
                    }
                    setIsFollowClicked(false);
                }), 1);
            }
        }
    });
    return (_jsxs("div", Object.assign({ className: style.editProfile }, { children: [_jsxs("div", Object.assign({ className: clsx(style.editProfile__header, flexStyle.flex, flexStyle.alignCenter) }, { children: [_jsx(ImageFromCDN, { alt: "back", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/back-arrow.png", size: 20, onClick: goBack }), _jsx("h2", { children: isMyProfile ? "My Profile" : "Profile" })] })), _jsxs("div", Object.assign({ className: clsx(style.editProfile__profile, flexStyle.flex, flexStyle.alignCenter) }, { children: [_jsx(EditProfileImage, { userName: userName, avatarUrl: avatarUrl, setAvatarUrl: () => setAvatarUrl, isMyProfile: isMyProfile, setRefreshPage: setRefreshPage }), _jsxs("aside", { children: [_jsx(EditUserProfileName, { userName: userName, avatarUrl: avatarUrl, setUserName: () => setUserName, myProfile: isMyProfile, setRefreshPage: setRefreshPage }), _jsx("p", { children: !followLoading && `${followers !== null && followers !== void 0 ? followers : ""} followers` })] }), playerId && !isMyProfile && !followLoading && (_jsx("button", Object.assign({ disabled: isFollowClicked, onClick: handleFollow, className: "button_social_widget sm info" }, { children: followFlag ? "Follow" : "Unfollow" })))] })), (userPosts === null || userPosts === void 0 ? void 0 : userPosts.length) !== 0 && (_jsx("esports-social-profile-card", { posts: JSON.stringify(userPosts), "user-post-required": true, loading: isLoading }))] })));
};
(_a = customElements.get("esports-editprofile")) !== null && _a !== void 0 ? _a : customElements.define("esports-editprofile", r2wc(EditProfileWrapper));
export default EditProfileWrapper;
