// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UZ9672eR8cFk1hyjOKpy{float:right;vertical-align:middle;font-family:"Roboto";color:#000;margin-right:10%;font-size:10px}.UZ9672eR8cFk1hyjOKpy.FJM9jdfnpefXpGqop261{font-weight:600}`, "",{"version":3,"sources":["webpack://./src/components/base/scoresBySport/ScoresBySport.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,qBAAA,CACA,oBAAA,CACA,UAAA,CACA,gBAAA,CACA,cAAA,CAEA,2CACE,eAAA","sourcesContent":[".score {\n  float: right;\n  vertical-align: middle;\n  font-family: \"Roboto\";\n  color: #000000;\n  margin-right: 10%;\n  font-size: 10px;\n\n  &.current {\n    font-weight: 600;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"score": `UZ9672eR8cFk1hyjOKpy`,
	"current": `FJM9jdfnpefXpGqop261`
};
export default ___CSS_LOADER_EXPORT___;
