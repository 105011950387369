import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "antd";
const Webcomponents = () => {
    var _a;
    const [mode, setMode] = useState((_a = document.getElementsByTagName("HTML")[0].getAttribute("data-theme")) !== null && _a !== void 0 ? _a : "light");
    const navigate = useNavigate();
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://unpkg.com/dark-mode-toggle";
        script.defer = true;
        script.type = "module";
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    // this is how clients listen for events
    useEffect(() => {
        const switchFooterRouteHandler = (e) => {
            e.preventDefault();
            navigate(e.detail.route);
        };
        const logHandler = (e) => {
            e.preventDefault();
            console.log(e.detail);
        };
        window.addEventListener("odds-multievent-details", (e) => {
            e.preventDefault();
            console.log(e.detail);
        });
        window.addEventListener("switch-footer-route", switchFooterRouteHandler);
        window.addEventListener("sports-routing-details", logHandler);
        return () => {
            window.removeEventListener("switch-footer-route", switchFooterRouteHandler);
            window.removeEventListener("sports-routing-details", logHandler);
        };
    }, []);
    // Listens for color scheme changes and sets data-theme accordingly
    document.addEventListener("colorschemechange", (e) => {
        document
            .getElementsByTagName("HTML")[0]
            .setAttribute("data-theme", e.detail.colorScheme);
        setMode(e.detail.colorScheme);
    });
    return (_jsxs("div", Object.assign({ style: {
            margin: "0%",
            padding: "5%",
            rowGap: "10px",
            backgroundColor: mode === "light" ? "hsl(0, 0%, 98%)" : "#191919",
            color: mode === "light" ? "inherit" : "#fff"
        } }, { children: [_jsx("div", Object.assign({ style: {
                    position: "fixed",
                    right: "5px",
                    top: "10px",
                    zIndex: 9999999
                } }, { children: _jsx("dark-mode-toggle", { id: "dark-mode-toggle-1", appearance: "toggle", dark: "Dark", light: "Light", permanent: true }) })), _jsx(Row, Object.assign({ justify: "center", align: "middle", gutter: 0, style: { margin: "10px 0px" } }, { children: _jsxs(Col, Object.assign({ xs: 24, sm: 20, md: 20, lg: 16 }, { children: [_jsx("h4", Object.assign({ style: { margin: "5px 0px" } }, { children: "esports-bet-slip-confirmation" })), _jsx("esports-bet-slip-confirmation", { "bet-refs": JSON.stringify([
                                "522e4482-2a12-4394-bc0a-6f423cadbe17",
                                "78f77d0a-442e-45db-b736-9dc7d457443e",
                                "357d1099-f799-4af0-af42-165c6611474e"
                            ]) })] })) }))] })));
};
export default Webcomponents;
