import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CURRENCY } from "enums";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import { Loader } from "helpers/loader/Loader";
import { ImageLoader } from "helpers/ImageLoader/ImageLoader";
import { Skeleton } from "antd";
import styles from "./BetCashOut.module.scss";
const ImageSkeleton = () => {
    return (_jsx("div", Object.assign({ className: styles.skeleton }, { children: _jsx(Skeleton.Image, { active: true, style: { width: 66, height: 66 } }) })));
};
export const ResultedMessage = ({ isSuccess, isPriceChanged, closeModal, fetchCashOut, cashoutOffer, cashoutRequest, isLoading }) => {
    return (_jsx("div", Object.assign({ className: styles.resultedMessage }, { children: isSuccess ?
            (_jsxs(_Fragment, { children: [_jsx(ImageFromCDN, { alt: "Successful", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/add_task.svg", size: 53 }), _jsx("div", Object.assign({ className: styles.details }, { children: _jsxs("strong", { children: ["Cash Out is Successful ", CURRENCY, " ", cashoutRequest !== null && cashoutRequest !== void 0 ? cashoutRequest : cashoutOffer] }) })), _jsx("div", Object.assign({ className: styles.modalBtns }, { children: _jsx("button", Object.assign({ onClick: () => {
                                closeModal(true);
                            }, className: styles.close }, { children: "Close" })) }))] })) :
            isPriceChanged ?
                (_jsxs(_Fragment, { children: [_jsx(ImageFromCDN, { alt: "Notes", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/source_notes.svg", size: 20 }), _jsx("div", Object.assign({ className: styles.details }, { children: _jsx("strong", { children: "Cash Out is price is changed" }) })), _jsxs("div", Object.assign({ className: styles.modalBtns }, { children: [_jsx("button", Object.assign({ onClick: () => {
                                        closeModal();
                                    }, className: styles.decline }, { children: "Decline" })), _jsx("button", Object.assign({ onClick: () => {
                                        fetchCashOut({
                                            amountOffered: cashoutOffer,
                                            amountRequested: cashoutRequest !== null && cashoutRequest !== void 0 ? cashoutRequest : cashoutOffer
                                        });
                                    }, className: styles.confirm, disabled: isLoading }, { children: isLoading ? _jsx(Loader, {}) : `Confirm ${CURRENCY} ${cashoutRequest !== null && cashoutRequest !== void 0 ? cashoutRequest : cashoutOffer}` }))] }))] })) :
                (_jsxs(_Fragment, { children: [_jsx(ImageLoader, Object.assign({ Skeleton: ImageSkeleton }, { children: _jsx(ImageFromCDN, { alt: "Cash Out error", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/brightness_alert.svg", size: 66 }) })), _jsx("div", Object.assign({ className: styles.details }, { children: _jsx("strong", { children: "Cash Out is currently unavailable on this bet" }) })), _jsx("div", Object.assign({ className: styles.modalBtns }, { children: _jsx("button", Object.assign({ onClick: () => {
                                    closeModal();
                                }, className: styles.close }, { children: "Please Try Again" })) }))] })) })));
};
