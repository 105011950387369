var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
import { LocalStorage } from "helpers/Storage";
export const getSignedUrl = (file, key) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    if (!((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL))
        return null;
    const payload = {
        url: `${(_b = process.env) === null || _b === void 0 ? void 0 : _b.REACT_APP_GATEWAY_API_URL}/bets/avatar/upload`,
        data: {
            path: key,
            contentType: file.type,
            filename: (_c = file.name) === null || _c === void 0 ? void 0 : _c.split(" ").join("")
        }
    };
    try {
        return yield axios.post(payload.url, Object.assign({}, payload.data), { headers: { "Content-Type": "application/json" } });
    }
    catch (e) {
        console.log(e, "error");
    }
});
export const editUserProfile = (avatar, userName) => __awaiter(void 0, void 0, void 0, function* () {
    var _d, _e, _f, _g;
    if (!((_d = process.env) === null || _d === void 0 ? void 0 : _d.REACT_APP_GATEWAY_API_URL))
        return null;
    try {
        const playerId = LocalStorage.get("widget-playerId");
        const profileId = sessionStorage.getItem("profileId");
        if (!playerId)
            return null;
        return yield axios.put(`${(_g = (_f = (_e = process.env) === null || _e === void 0 ? void 0 : _e.REACT_APP_GATEWAY_API_URL) === null || _f === void 0 ? void 0 : _f.replace("api/", "")) !== null && _g !== void 0 ? _g : ""}/profile/${profileId !== null && profileId !== void 0 ? profileId : ""}`, {
            avatarUrl: avatar,
            displayName: userName
        }, { headers: { "Content-Type": "application/json" } });
    }
    catch (e) {
        console.log(e, "error");
    }
});
export const uploadFile = (file, url) => {
    const payload = {
        url,
        data: file,
        headers: { "Content-Type": file.type }
    };
    return putApiToUploadFile(payload);
};
const putApiToUploadFile = (obj) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        return yield axios.put(obj.url, obj.data, {
            headers: Object.assign({}, obj.headers)
        });
    }
    catch (err) {
        console.error(err, "error");
    }
});
