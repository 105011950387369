var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import axios from "axios";
import clsx from "clsx";
import { Outlet, useNavigate } from "react-router-dom";
import pkg from "../../package.json";
import { Layout, Menu, Button, ConfigProvider, theme, Grid, Row, Col } from "antd";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import { DiffOutlined, 
// CodeOutlined,
MenuFoldOutlined, MenuUnfoldOutlined, ReadOutlined, AppstoreAddOutlined
// RadiusUprightOutlined
 } from "@ant-design/icons";
import { betslipResponse, esportsSelectionsState, esportsSelectionsStateChanged, oddsChangedError } from "assets/data/data";
// styles
import styles from "./Playground.module.scss";
const { useBreakpoint } = Grid;
const { Header, Sider, Content } = Layout;
const getItem = (label, key, icon, children, type) => {
    return {
        key,
        icon,
        children,
        label,
        type
    };
};
const items = [
    getItem("1337Pro Integration Guide", "integrationguide", _jsx(DiffOutlined, {})),
    getItem("Webcomponents", "sub1", _jsx(AppstoreAddOutlined, {}), [
        getItem("Generic", "group-generic", null, [
            getItem("No data for market", "nodataformarket"),
            getItem("Footer Navigation", "footernavigation")
        ], "group"),
        getItem("Modal", "group-modal", null, [
            getItem("Modal generic", "modalgeneric"),
            getItem("Unauthenticated user modal", "unauthenticatedusermodal")
        ], "group"),
        getItem("Betslip", "group-betslip", null, [
            getItem("Bet slip confirmation", "betslipconfirmation"),
            getItem("Bet slip", "betslip"),
            getItem("My bets", "mybets")
        ], "group"),
        getItem("Odds", "group-odd", null, [
            getItem("Single event input", "oddssingleeventinput"),
            getItem("Multi-event input", "oddsmultieventinput"),
            getItem("Multi-event", "oddsmultievent"),
            getItem("Outcomes", "oddsoutcomes")
        ], "group"),
        getItem("Social", "group-social", null, [
            // TODO: In progress
            // getItem("Profile card", "socialprofilecard"),
            // getItem("Profile", "socialprofile"),
            getItem("Bettor", "socialbettor")
        ], "group")
        // getItem("eSports", "group-esports", null, [getItem("Option 3", "7"), getItem("Option 4", "8")], "group")
    ]),
    { type: "divider" },
    // getItem("Navigation Three", "sub4", <UploadOutlined />, [
    //   getItem("Option 9", "9"),
    //   getItem("Option 10", "10"),
    //   getItem("Option 11", "11"),
    //   getItem("Option 12", "12")
    // ])
    // getItem("Group", "grp", null, [getItem("Option 13", "13"), getItem("Option 14", "14")], "group")
    getItem("Release History", "releasehistory", _jsx(ReadOutlined, {}))
];
let oddsChanged = false;
const PlaygroundLayout = () => {
    var _a;
    const navigate = useNavigate();
    const screens = useBreakpoint();
    const [collapsed, setCollapsed] = React.useState(false);
    const [current, setCurrent] = React.useState("integrationguide");
    // TODO: Move it to a helper as it might be useful
    // const shortEnvironmentName = (): string => {
    //   let value = "";
    //   switch (process.env?.REACT_APP_ENV) {
    //     case "development":
    //       value = "dev";
    //       break;
    //     case "staging":
    //       value = "stg";
    //       break;
    //     case "production":
    //       value = "prod";
    //       break;
    //   }
    //   return value;
    // };
    const { token: { colorBgContainer } } = theme.useToken();
    React.useEffect(() => {
        if (screens.xs && !collapsed) {
            setCollapsed(true);
        }
    }, [screens]);
    // setup test values
    React.useEffect(() => {
        axios.interceptors.response.use(function (response) {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function* () {
                const { config } = response;
                if ((config === null || config === void 0 ? void 0 : config.url) === "/selection-states") {
                    if (((_a = config === null || config === void 0 ? void 0 : config.params) === null || _a === void 0 ? void 0 : _a.esportSelectionIds) === "111,112,113") {
                        return yield Promise.resolve(Object.assign(Object.assign({}, response), { data: esportsSelectionsState }));
                    }
                    if (((_b = config === null || config === void 0 ? void 0 : config.params) === null || _b === void 0 ? void 0 : _b.esportSelectionIds) === "222,224,226") {
                        if (oddsChanged) {
                            return yield Promise.resolve(Object.assign(Object.assign({}, response), { data: esportsSelectionsStateChanged }));
                        }
                        return yield Promise.resolve(Object.assign(Object.assign({}, response), { data: esportsSelectionsState }));
                    }
                }
                return response;
            });
        }, function (error) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            return __awaiter(this, void 0, void 0, function* () {
                // return correct data for documentation
                if (((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL) &&
                    ((_b = error === null || error === void 0 ? void 0 : error.config) === null || _b === void 0 ? void 0 : _b.url) ===
                        `${(_c = process.env) === null || _c === void 0 ? void 0 : _c.REACT_APP_GATEWAY_API_URL}/bets/bet-slip`) {
                    if (((_d = error === null || error === void 0 ? void 0 : error.config) === null || _d === void 0 ? void 0 : _d.data.includes("\"outcomeId\":111")) &&
                        ((_e = error === null || error === void 0 ? void 0 : error.config) === null || _e === void 0 ? void 0 : _e.data.includes("\"outcomeId\":112")) &&
                        ((_f = error === null || error === void 0 ? void 0 : error.config) === null || _f === void 0 ? void 0 : _f.data.includes("\"outcomeId\":113"))) {
                        yield Promise.resolve();
                        return betslipResponse;
                    }
                    if (((_g = error === null || error === void 0 ? void 0 : error.config) === null || _g === void 0 ? void 0 : _g.data.includes("\"outcomeId\":222")) &&
                        ((_h = error === null || error === void 0 ? void 0 : error.config) === null || _h === void 0 ? void 0 : _h.data.includes("\"outcomeId\":224")) &&
                        ((_j = error === null || error === void 0 ? void 0 : error.config) === null || _j === void 0 ? void 0 : _j.data.includes("\"outcomeId\":226"))) {
                        if (oddsChanged) {
                            oddsChanged = false;
                            yield Promise.resolve();
                            return betslipResponse;
                        }
                        oddsChanged = true;
                        yield Promise.reject(oddsChangedError);
                    }
                }
                return yield Promise.reject(error);
            });
        });
    }, []);
    const mappingToPathsOrActions = (key) => {
        switch (key) {
            case "integrationguide":
                navigate("/integration-guide");
                break;
            case "releasehistory":
                navigate("/release-history");
                break;
            case "nodataformarket":
                navigate("/no-data-for-market");
                break;
            case "oddssingleeventinput":
                navigate("/odds-single-event-input");
                break;
            case "oddsmultieventinput":
                navigate("/odds-multi-event-input");
                break;
            case "oddsmultievent":
                navigate("/odds-multi-event");
                break;
            case "oddsoutcomes":
                navigate("/odds-outcomes");
                break;
            case "socialprofilecard":
                navigate("/social-profile-card");
                break;
            case "socialprofile":
                navigate("/social-profile");
                break;
            case "socialbettor":
                navigate("/social-bettor");
                break;
            case "sportscountryleaguessearch":
                navigate("/country-leagues-search");
                break;
            case "mybets":
                navigate("/my-bets");
                break;
            case "betslipconfirmation":
                navigate("/bet-slip-confirmation");
                break;
            case "footernavigation":
                navigate("/footer-navigation");
                break;
            case "betslip":
                navigate("/bet-slip");
                break;
            case "marketviewdefault":
                navigate("/market-view-default");
                break;
            case "modalgeneric":
                navigate("/modal-generic");
                break;
            case "unauthenticatedusermodal":
                navigate("/unauthenticated-user-modal");
                break;
            case "v2-outcomegeneric":
                navigate("/v2/outcome-generic");
                break;
            case "v2-market-views":
                navigate("/v2/market-views");
                break;
            case "v2-market-single":
                navigate("/v2/market-single");
                break;
            default:
                break;
        }
    };
    const onSelectedMenuOption = (e) => {
        setCurrent(e.key);
        screens.xs && setCollapsed(true);
        mappingToPathsOrActions(e.key);
    };
    const CollapsedButton = ({ type }) => {
        if (type === "button-sider-mobile") {
            return (_jsx(Button, { type: screens.xs ? "primary" : "text", icon: collapsed ? _jsx(MenuUnfoldOutlined, {}) : _jsx(MenuFoldOutlined, {}), onClick: () => {
                    setCollapsed(!collapsed);
                }, style: {
                    backgroundColor: "#2B2D35",
                    fontSize: "16px",
                    width: 34,
                    height: 34,
                    margin: "0px 22px"
                } }));
        }
        else if (type === "button-header-not-mobile") {
            return (_jsx(Button, { type: screens.xs ? "primary" : "text", icon: collapsed ? _jsx(MenuUnfoldOutlined, {}) : _jsx(MenuFoldOutlined, {}), onClick: () => {
                    setCollapsed(!collapsed);
                }, style: {
                    backgroundColor: screens.xs ? "#2B2D35" : "inherit",
                    fontSize: "16px",
                    width: 36,
                    height: 36,
                    margin: "13px 6px"
                } }));
        }
        else if (type === "button-header-mobile-collapsed") {
            return (_jsx(Button, { type: screens.xs ? "primary" : "text", icon: collapsed ? _jsx(MenuUnfoldOutlined, {}) : _jsx(MenuFoldOutlined, {}), onClick: () => {
                    setCollapsed(!collapsed);
                }, style: {
                    backgroundColor: "#2B2D35",
                    fontSize: "16px",
                    width: 34,
                    height: 34,
                    margin: "0px 6px"
                } }));
        }
        else if (type === "button-header-mobile-collapsed-logo") {
            return (_jsx(Button, { type: "text", icon: _jsx(ImageFromCDN, { alt: "1337Pro-logo-mobile", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/logo.png", overrideStyles: {
                        minWidth: 120,
                        marginLeft: 10
                    } }), style: { marginTop: "10px" } }));
        }
    };
    return (_jsx(ConfigProvider, Object.assign({ theme: {
            // algorithm: theme.darkAlgorithm,
            // algorithm: theme.defaultAlgorithm,
            algorithm: theme.compactAlgorithm,
            token: {
                // colorBgContainer: "#2b2d34",
                // colorPrimaryBg: "#2b2d35",
                // colorBgLayout: "#f4f4f4",
                colorPrimary: "#e5206f",
                fontFamily: "sans-serif",
                fontSize: 15
            }
        } }, { children: _jsxs(Layout, Object.assign({ hasSider: true, className: "qwerty" }, { children: [screens.xs && !collapsed && _jsx("style", { children: "body { overflow: hidden; }" }), _jsxs(Sider, Object.assign({ trigger: null, collapsible: true, collapsed: collapsed, width: screens.xs && !collapsed ? "100%" : "250px", breakpoint: "md", collapsedWidth: screens.xs ? 0 : 80, 
                    // TODO: Is it necessary? I'm keeping it for now
                    // onBreakpoint={(broken) => {
                    //   console.log(broken);
                    // }}
                    // onCollapse={(collapsed, type) => {
                    //   console.log(collapsed, type);
                    // }}
                    className: clsx(styles.sider, { [styles.mobile]: screens.xs }), style: { position: screens.xs && !collapsed ? "absolute" : "inherit" } }, { children: [_jsxs(Row, Object.assign({ justify: screens.xs ? "start" : "center", align: "middle", className: styles.siderLogo, style: { borderBottom: !screens.xs ? "1px solid #2B2D35" : "inherit" } }, { children: [screens.xs && (_jsx(Col, { children: _jsx(CollapsedButton, { type: "button-sider-mobile" }) })), _jsx(Col, { children: _jsx(ImageFromCDN, { alt: "1337Pro-logo", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/logo.png", size: collapsed ? 65 : 120 }) })] })), _jsx(Menu, { theme: "dark", mode: "inline", defaultSelectedKeys: [current], defaultOpenKeys: ["sub1"], items: items, onClick: onSelectedMenuOption }), !screens.xs && (_jsx(_Fragment, { children: _jsx(Row, Object.assign({ justify: "center", align: "middle", style: {
                                    position: "fixed",
                                    bottom: 0,
                                    transition: "all 0.2s",
                                    color: "#fff",
                                    minWidth: collapsed ? "70px" : "250px",
                                    padding: "15px 0px"
                                } }, { children: !collapsed ?
                                    (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.environmentBackground }), _jsxs(Col, Object.assign({ span: 24, style: { textAlign: "center" } }, { children: ["Environment: ", (_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_ENV] })), _jsxs(Col, Object.assign({ span: 24, style: { textAlign: "center" } }, { children: ["Version: ", pkg.version] }))] })) :
                                    (_jsx(Col, { children: pkg.version })) })) }))] })), _jsxs(Layout, { children: [_jsxs(Header, Object.assign({ className: styles.header, style: { background: screens.xs ? "#010C2B" : colorBgContainer } }, { children: [!screens.xs && _jsx(CollapsedButton, { type: "button-header-not-mobile" }), !screens.xs && (_jsx("span", { children: "1337PRO: eSports news, live scores, stats and streaming documentation" })), screens.xs && collapsed && (_jsxs(Row, Object.assign({ justify: "start", align: "middle" }, { children: [_jsx(Col, { children: _jsx(CollapsedButton, { type: "button-header-mobile-collapsed" }) }), _jsx(Col, Object.assign({ flex: "auto" }, { children: _jsx(CollapsedButton, { type: "button-header-mobile-collapsed-logo" }) })), _jsx(Col, Object.assign({ flex: "auto", style: { color: "#fff" } }, { children: pkg.version }))] })))] })), _jsx(Content, Object.assign({ className: styles.content, style: { padding: screens.xs ? "8% 10px" : "3% 4%" } }, { children: _jsx(Outlet, {}) }))] })] })) })));
};
export default PlaygroundLayout;
