var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import clsx from "clsx";
import { createRoot } from "react-dom/client";
import dayjs from "dayjs";
import { Grid, Row, Col } from "antd";
import { LocalStorage } from "helpers/Storage";
import { BETS_LIMIT } from "enums";
import { checkIsOpen } from "helpers/IsOutcomeOpen";
import { SUBSCRIBE_OUTCOME } from "v2/graphql/subscriptions";
import { useSubscribeByQuery } from "helpers/graphql/useSubscribeByQuery";
import { OddsSuspendedOrVariation } from "../oddsSuspendedOrVariation/OddsSuspendedOrVariation";
import { useShowIndicator } from "hooks/useShowIndicator";
import { formatOdds } from "helpers/Bets";
import styles from "./OddsSingleEventInput.module.scss";
const { useBreakpoint } = Grid;
const OddsSingleEventInput = ({ view, data, productId, defaultOutcomes, variation, sport }) => {
    var _a;
    const screens = useBreakpoint();
    const [selectedOutcomes, setSelectedOutcome] = React.useState(defaultOutcomes);
    const [refVariation, setRefVariation] = React.useState(variation);
    const { showIndicator } = useShowIndicator({ refVariation });
    const [refData, setRefData] = React.useState(data);
    const [prevMarketData, setPrevMarketData] = React.useState(data === null || data === void 0 ? void 0 : data.market);
    // Keep actual data from props
    React.useEffect(() => {
        var _a, _b;
        // updates the previous data when market status has changed
        if (((_a = data === null || data === void 0 ? void 0 : data.market) === null || _a === void 0 ? void 0 : _a.statusId) !== (prevMarketData === null || prevMarketData === void 0 ? void 0 : prevMarketData.statusId)) {
            setPrevMarketData(refData === null || refData === void 0 ? void 0 : refData.market);
        }
        ((_b = data === null || data === void 0 ? void 0 : data.market) === null || _b === void 0 ? void 0 : _b.id) && setRefData(data);
    }, [data]);
    // subscribes to the outcome topic and updates
    const valueHandlerCallback = (data, prevData) => {
        var _a, _b;
        const _data = data.sport_domain_outcome[0];
        const _prevData = (_a = prevData === null || prevData === void 0 ? void 0 : prevData.sport_domain_outcome) === null || _a === void 0 ? void 0 : _a[0];
        const isOpen = checkIsOpen((_b = refData === null || refData === void 0 ? void 0 : refData.selection) === null || _b === void 0 ? void 0 : _b.isOpen, // original isOpen, generally from the endpoint
        refData === null || refData === void 0 ? void 0 : refData.market, // current market data (received from subscriptions)
        _data === null || _data === void 0 ? void 0 : _data.outcome_status_id // current outcome status id
        );
        setRefData(oldData => {
            return Object.assign(Object.assign({}, oldData), { selection: Object.assign(Object.assign({}, oldData.selection), { value: _data.odds, statusId: _data.outcome_status_id, isOpen }) });
        });
        // processes the variation
        if ((_data === null || _data === void 0 ? void 0 : _data.odds) && (_prevData === null || _prevData === void 0 ? void 0 : _prevData.odds)) {
            setRefVariation(_data.odds - _prevData.odds);
        }
    };
    // only subscribring to sports
    // when a key is passed, but not the value, it gets rejected before reaching the subscription
    useSubscribeByQuery({
        query: SUBSCRIBE_OUTCOME,
        variables: {
            eventId: productId === 1 && refData.series.id,
            marketId: productId === 1 && refData.market.id,
            outcomeId: productId === 1 && refData.selection.id
        },
        valueHandlerCallback
    });
    // updates the market statusId, based on the subcription at the market level
    React.useEffect(() => {
        var _a;
        if (productId === 1) {
            if ((prevMarketData === null || prevMarketData === void 0 ? void 0 : prevMarketData.statusId) !== ((_a = data === null || data === void 0 ? void 0 : data.market) === null || _a === void 0 ? void 0 : _a.statusId)) {
                setRefData(refData => {
                    var _a;
                    return Object.assign(Object.assign({}, refData), { market: Object.assign(Object.assign({}, refData.market), { statusId: (_a = data === null || data === void 0 ? void 0 : data.market) === null || _a === void 0 ? void 0 : _a.statusId }) });
                });
            }
        }
    }, [prevMarketData === null || prevMarketData === void 0 ? void 0 : prevMarketData.statusId, (_a = data === null || data === void 0 ? void 0 : data.market) === null || _a === void 0 ? void 0 : _a.statusId]);
    React.useEffect(() => {
        setSelectedOutcome(defaultOutcomes);
    }, [defaultOutcomes]);
    // injects default outcomes in the selectedOutcomes state, so it is highlighted
    React.useEffect(() => {
        const handleRemoveBet = (e) => {
            const { outcomeId } = e.detail;
            if (outcomeId) {
                setSelectedOutcome((oldArray) => [
                    ...oldArray === null || oldArray === void 0 ? void 0 : oldArray.filter((outcome) => outcome !== outcomeId)
                ]);
            }
        };
        const handleClearBets = () => { setSelectedOutcome([]); };
        const handleReuseBets = (e) => {
            var _a;
            ((_a = e === null || e === void 0 ? void 0 : e.detail) === null || _a === void 0 ? void 0 : _a.bets) && setSelectedOutcome(e.detail.bets);
        };
        window.addEventListener("bet-slip-reuse-bets", handleReuseBets);
        window.addEventListener("bet-slip-bet-remove", handleRemoveBet);
        window.addEventListener("bet-slip-clear-bets", handleClearBets);
        return () => {
            window.removeEventListener("bet-slip-reuse-bets", handleReuseBets);
            window.removeEventListener("bet-slip-bet-remove", handleRemoveBet);
            window.removeEventListener("bet-slip-clear-bets", handleClearBets);
        };
    }, []);
    const onClick = (obj) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
        const limited = ((_a = LocalStorage.get("betsDetails")) === null || _a === void 0 ? void 0 : _a.length) >= BETS_LIMIT;
        // add or remove odd id to the selected state
        let operation = "select";
        if (selectedOutcomes.includes(obj === null || obj === void 0 ? void 0 : obj.outcomeId)) {
            setSelectedOutcome((oldArray) => [
                ...oldArray === null || oldArray === void 0 ? void 0 : oldArray.filter((outcome) => outcome !== (obj === null || obj === void 0 ? void 0 : obj.outcomeId))
            ]);
            operation = "unselect";
        }
        else {
            (obj === null || obj === void 0 ? void 0 : obj.isOpen) && !limited && setSelectedOutcome((oldArray) => [...oldArray, obj === null || obj === void 0 ? void 0 : obj.outcomeId]);
        }
        const triggerOddsDetails = new CustomEvent("odds-singleevent-input-details", {
            detail: {
                operation,
                seriesId: (_b = refData === null || refData === void 0 ? void 0 : refData.series) === null || _b === void 0 ? void 0 : _b.id,
                matchId: (_c = refData === null || refData === void 0 ? void 0 : refData.match) === null || _c === void 0 ? void 0 : _c.id,
                event: (_e = (_d = refData === null || refData === void 0 ? void 0 : refData.series) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : (_f = data === null || data === void 0 ? void 0 : data.series) === null || _f === void 0 ? void 0 : _f.name,
                marketName: refData.market.name,
                outcomeId: refData.selection.id,
                name: ((_g = refData.selection) === null || _g === void 0 ? void 0 : _g.name) ? (_h = refData.selection) === null || _h === void 0 ? void 0 : _h.name : refData.selection.label,
                odds: refData.selection.value,
                isBetBuilderEnabled: (_j = refData.selection) === null || _j === void 0 ? void 0 : _j.isBetBuilderEnabled,
                isOutrights: (_k = refData.selection) === null || _k === void 0 ? void 0 : _k.isOutrights,
                startDate: (_l = refData === null || refData === void 0 ? void 0 : refData.series) === null || _l === void 0 ? void 0 : _l.startDate,
                time: dayjs((_m = refData === null || refData === void 0 ? void 0 : refData.series) === null || _m === void 0 ? void 0 : _m.startDate).format("HH:mm"),
                isOpen: refData.selection.isOpen && !limited,
                marketId: (_o = refData === null || refData === void 0 ? void 0 : refData.market) === null || _o === void 0 ? void 0 : _o.id,
                statusId: refData.selection.statusId,
                leagueId: (_q = (_p = refData === null || refData === void 0 ? void 0 : refData.series) === null || _p === void 0 ? void 0 : _p.leagueId) !== null && _q !== void 0 ? _q : (_r = data === null || data === void 0 ? void 0 : data.series) === null || _r === void 0 ? void 0 : _r.leagueId,
                leagueName: (_t = (_s = refData === null || refData === void 0 ? void 0 : refData.series) === null || _s === void 0 ? void 0 : _s.leagueName) !== null && _t !== void 0 ? _t : (_u = data === null || data === void 0 ? void 0 : data.series) === null || _u === void 0 ? void 0 : _u.leagueName,
                productId
            },
            bubbles: true
        });
        window.dispatchEvent(triggerOddsDetails);
        // emits a data layer when input is selected
        if (operation === "select") {
            const triggerBetDataLayerEvent = (action) => {
                var _a, _b, _c, _d, _e, _f, _g;
                window.dispatchEvent(new CustomEvent("bet-data-layer", {
                    detail: {
                        event: action,
                        event_count: 1,
                        events: [{
                                id: `${String((_a = refData === null || refData === void 0 ? void 0 : refData.selection) === null || _a === void 0 ? void 0 : _a.id)}`,
                                product_category: productId === 2 ? "esports" : null,
                                sport_category: (_b = refData === null || refData === void 0 ? void 0 : refData.series) === null || _b === void 0 ? void 0 : _b.sportName,
                                name: (_c = refData === null || refData === void 0 ? void 0 : refData.series) === null || _c === void 0 ? void 0 : _c.name,
                                market_name: (_d = refData === null || refData === void 0 ? void 0 : refData.market) === null || _d === void 0 ? void 0 : _d.name,
                                add_to_betslip_place: "",
                                league_name: (_e = refData === null || refData === void 0 ? void 0 : refData.series) === null || _e === void 0 ? void 0 : _e.leagueName,
                                outcome_name: (_f = refData === null || refData === void 0 ? void 0 : refData.selection) === null || _f === void 0 ? void 0 : _f.name,
                                odd_type: dayjs((_g = refData === null || refData === void 0 ? void 0 : refData.series) === null || _g === void 0 ? void 0 : _g.startDate) < dayjs() ? "pregame" : "inplay",
                                odd: refData.selection.value
                            }]
                    },
                    bubbles: true
                }));
            };
            triggerBetDataLayerEvent("add_bet");
        }
    };
    if (refData.selection.value) {
        const suspended = refData.selection.isOpen === false;
        let label = refData.selection.label;
        if ((sport === null || sport === void 0 ? void 0 : sport.id) === 3 || (sport === null || sport === void 0 ? void 0 : sport.id) === 12) {
            if ((label === null || label === void 0 ? void 0 : label.toLowerCase()) === "home")
                label = "W1";
            if ((label === null || label === void 0 ? void 0 : label.toLowerCase()) === "away")
                label = "W2";
        }
        const centerOddsStyles = {
            alignItems: "center",
            width: "100%",
            height: "100%",
            flex: "1 1 auto",
            maxWidth: "none"
        };
        const isCenter = view === "center";
        if (view === "top") {
            return (_jsxs(Row, Object.assign({ className: clsx(styles.topView, {
                    [styles.highlight]: selectedOutcomes.includes(refData.selection.id),
                    [styles.suspended]: suspended
                }), justify: "center", align: "middle", gutter: [0, 0], onClick: () => {
                    onClick({ outcomeId: refData.selection.id, isOpen: refData.selection.isOpen });
                } }, { children: [label && _jsx(Col, Object.assign({ span: 24, className: clsx(styles.label, styles.atTop, {
                            [styles.highlight]: selectedOutcomes.includes(refData.selection.id)
                        }) }, { children: _jsx("small", { children: label }) })), _jsx(Col, Object.assign({ span: 24, style: { display: "flex", justifyContent: "center" } }, { children: _jsx("small", Object.assign({ className: clsx(styles.odds, {
                                [styles.highlight]: selectedOutcomes.includes(refData.selection.id)
                            }) }, { children: _jsx("b", { children: suspended ? "-" : formatOdds(refData.selection.value) }) })) })), OddsSuspendedOrVariation({ suspended, refVariation, showIndicator })] }), `odds-input-row-${refData.selection.id}`));
        }
        else {
            return (_jsxs(Row, Object.assign({ className: clsx(styles.defaultView, {
                    [styles.highlight]: selectedOutcomes.includes(refData.selection.id),
                    [styles.suspended]: suspended
                }), style: {
                    padding: screens.xs ? "2px" : "5px"
                }, justify: "center", align: "middle", gutter: 0, onClick: () => {
                    onClick({ outcomeId: refData.selection.id, isOpen: refData.selection.isOpen });
                } }, { children: [label && !isCenter && _jsx(Col, Object.assign({ xs: 17, sm: 17, md: 17, lg: 17, xl: 17, className: clsx(styles.label, {
                            [styles.highlight]: selectedOutcomes.includes(refData.selection.id)
                        }) }, { children: _jsx("small", { children: label }) })), isCenter && label ?
                        _jsxs("div", Object.assign({ style: {
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            } }, { children: [_jsx("small", Object.assign({ className: styles.odds, style: { marginRight: 10, color: "#808594" } }, { children: label })), _jsx("small", Object.assign({ className: clsx(styles.odds, {
                                        [styles.highlight]: selectedOutcomes.includes(refData.selection.id)
                                    }) }, { children: _jsx("b", { children: suspended ? "-" : formatOdds(refData.selection.value) }) }))] })) :
                        _jsx(Col, Object.assign({ xs: 6, sm: 6, md: 6, lg: 6, xl: 6, style: Object.assign({ display: "flex", justifyContent: label ? "flex-end" : "center" }, (!label ? centerOddsStyles : {})) }, { children: _jsx("small", Object.assign({ className: clsx(styles.odds, {
                                    [styles.highlight]: selectedOutcomes.includes(refData.selection.id)
                                }) }, { children: _jsx("b", { children: suspended ? "-" : formatOdds(refData.selection.value) }) })) })), OddsSuspendedOrVariation({ suspended, refVariation, showIndicator })] }), `odds-input-row-${refData.selection.id}`));
        }
    }
    return null;
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this.view = "default";
        this.data = {
            series: {
                id: null
            },
            market: {
                id: null
            },
            match: {
                id: null
            },
            selection: {
                id: null,
                label: "",
                value: 0
            }
        };
        this["default-outcomes"] = [];
        this.variation = 0;
    }
    static get observedAttributes() {
        return ["view", "data", "default-outcomes", "product-id", "sport"];
    }
    connectedCallback() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(OddsSingleEventInput, { view: this.view, data: this.data, productId: this["product-id"], defaultOutcomes: this["default-outcomes"], variation: this.variation, sport: this.sport }, `odds_singleevent_input_${this.data.selection.id}`));
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, oldValue, newValue) {
        var _a;
        switch (attrName) {
            case "view":
                this[attrName] = String(newValue);
                break;
            case "data":
                this[attrName] = JSON.parse(newValue);
                break;
            case "product-id":
                this[attrName] = Number(newValue);
                break;
            case "default-outcomes":
            case "sport":
                this[attrName] = JSON.parse(newValue);
                break;
            case "variation":
                if (oldValue && newValue) {
                    this[attrName] = Number(newValue) - Number(oldValue);
                }
                else {
                    this[attrName] = 0;
                }
                break;
            default:
                break;
        }
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(OddsSingleEventInput, { view: this.view, data: this.data, productId: this["product-id"], defaultOutcomes: this["default-outcomes"], variation: this.variation, sport: this.sport }, `odds_singleevent_input_${this.data.selection.id}`));
    }
}
(_a = customElements.get("esports-odds-singleevent-input")) !== null && _a !== void 0 ? _a : customElements.define("esports-odds-singleevent-input", Element);
