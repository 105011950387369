var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import axios from "axios";
import { ConfigProvider, Tabs, Grid } from "antd";
import clsx from "clsx";
import { LocalStorage } from "helpers/Storage";
import SkeletonOutcomesEsports from "components/base/skeleton/OutcomesEsports";
import SkeletonOutcomesSports from "components/base/skeleton/OutcomesSports";
import { SportsMarketsFilters } from "./sportsMarketsFilter/SportsMarketsFilters";
import { EsportsMarketGroupFromMarket, EsportsMarketGroupFromMatches } from "./EsportMarketGroup";
import { EVENT_STATUS, SPORTS_MARKETS_FILTERS_IDS } from "enums";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
// import { GET_EVENT_OUTCOMES } from "../../../v2/graphql/queries";
import { SUBSCRIBE_EVENTS } from "v2/graphql/subscriptions";
import { useSubscribeByQuery } from "helpers/graphql/useSubscribeByQuery";
// styles
import styles from "./OddsOutcomes.module.scss";
const { useBreakpoint } = Grid;
const defaultState = {
    id: SPORTS_MARKETS_FILTERS_IDS.ALL,
    name: "All",
    market: {
        id: -1,
        name: "All"
    }
};
const OddsOutcomes = ({ productId, seriesId, noDataOptions, sport }) => {
    var _a, _b, _c, _d, _e;
    const screens = useBreakpoint();
    const [data, setData] = React.useState();
    const [liveData, setLiveData] = React.useState(data);
    const [defaultOutcomes, setDefaultOutcomes] = React.useState([]);
    const [isLoading, setIsloading] = React.useState(true);
    const [error, setError] = React.useState();
    const [marketTypeConfigs, setMarketTypeConfigs] = React.useState([]);
    const [marketsConfigs, setMarketsConfigs] = React.useState();
    const [unavailableMarketGroupsConfigs, setUnavailableMarketGroupsConfigs] = React.useState([]);
    const [isBBEnabled, setIsBBEnabled] = React.useState(false);
    const [selectedMarketGroupSports, setSelectedMarketGroupSports] = React.useState(defaultState);
    const [selectedMarketGroupMain, setSelectedMarketGroupMain] = React.useState(defaultState);
    const [selectedMarketGroupMatches, setSelectedMarketGroupMatches] = React.useState([]);
    React.useEffect(() => {
        var _a, _b;
        if (((_a = data === null || data === void 0 ? void 0 : data.matches) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            const matchesSelections = Array((_b = data === null || data === void 0 ? void 0 : data.matches) === null || _b === void 0 ? void 0 : _b.length).fill(defaultState);
            setSelectedMarketGroupMatches(matchesSelections);
        }
    }, [data]);
    // subscribes to multiple event ids
    const valueHandlerCallback = (details) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const nextData = details.sport_domain_event[0];
        const model = {
            id: nextData === null || nextData === void 0 ? void 0 : nextData.id,
            startDate: nextData === null || nextData === void 0 ? void 0 : nextData.start_at,
            statusId: nextData === null || nextData === void 0 ? void 0 : nextData.event_status_id,
            inPlay: {
                clock: {
                    minutes: (_a = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _a === void 0 ? void 0 : _a.minutes,
                    period: (_b = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _b === void 0 ? void 0 : _b.period_name,
                    seconds: (_c = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _c === void 0 ? void 0 : _c.seconds,
                    remainingMinutesInPeriod: (_d = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _d === void 0 ? void 0 : _d.minutes_left_in_period,
                    remainingSecondsInPeriod: (_e = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _e === void 0 ? void 0 : _e.seconds_left_in_period,
                    statusId: (_f = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _f === void 0 ? void 0 : _f.event_clock_status_id,
                    timestamp: (_g = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _g === void 0 ? void 0 : _g.timestamp_epoch
                },
                score: {
                    home: nextData === null || nextData === void 0 ? void 0 : nextData.home_score,
                    away: nextData === null || nextData === void 0 ? void 0 : nextData.away_score,
                    periodScores: (_h = nextData === null || nextData === void 0 ? void 0 : nextData.period_scores) === null || _h === void 0 ? void 0 : _h.map((scores) => {
                        return {
                            home: scores.home_score,
                            away: scores.away_score
                        };
                    })
                }
            }
        };
        setLiveData(model);
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_EVENTS,
        variables: {
            eventIds: [seriesId]
        },
        valueHandlerCallback
    });
    React.useEffect(() => {
        const fetchClientData = () => {
            var _a;
            if (!((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL))
                return;
            axios
                .get(`${process.env.REACT_APP_GATEWAY_API_URL}/client/config`)
                .then((res) => {
                var _a;
                const { isBetBuilderEnabled } = (_a = res.data) !== null && _a !== void 0 ? _a : {};
                setIsBBEnabled(isBetBuilderEnabled);
            })
                .catch((e) => {
                console.log(e);
            });
        };
        fetchClientData();
    }, []);
    const [overrideMarketGroups, setOverrideMarketGroups] = React.useState();
    React.useEffect(() => {
        if (productId === 2) {
            axios
                .get("/esports/market-map")
                .then((res) => {
                var _a;
                const uniqueMarketGroups = [defaultState];
                (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.forEach((item) => {
                    item.marketGroups.forEach((group) => {
                        // Check if the group is not already in the uniqueMarketGroups array
                        const existingGroupIndex = uniqueMarketGroups.findIndex((existingGroup) => existingGroup.id === group.id);
                        if (existingGroupIndex === -1) {
                            uniqueMarketGroups.push({
                                id: group.id,
                                name: group.name,
                                market: {
                                    id: item.id,
                                    name: item.shortName
                                }
                            });
                        }
                    });
                });
                setOverrideMarketGroups(uniqueMarketGroups);
                setMarketsConfigs(res === null || res === void 0 ? void 0 : res.data);
            })
                .catch((e) => {
                console.log(e);
            });
        }
    }, []);
    // read data from the betDetails storage and pass them on to the children components
    const updateDefaultOutcomes = () => {
        const betsDetails = LocalStorage.get("betsDetails");
        const betsDefaultOutcomes = betsDetails === null || betsDetails === void 0 ? void 0 : betsDetails.filter((bet) => (bet === null || bet === void 0 ? void 0 : bet.productId) === productId).map((bet) => bet === null || bet === void 0 ? void 0 : bet.outcomeId);
        setDefaultOutcomes(betsDefaultOutcomes);
    };
    React.useEffect(() => {
        if (productId) {
            updateDefaultOutcomes();
        }
    }, [productId]);
    const DisplayRuleLookup = (data, event) => {
        var _a, _b, _c, _d, _e, _f;
        const isArray = data instanceof Array;
        let formattedData;
        if (isArray) {
            formattedData = [];
            data.forEach((item) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                if (item === null || item === void 0 ? void 0 : item.market) {
                    formattedData.push({
                        displayRule: item.displayRule,
                        id: event.id,
                        statusId: item.market.statusId,
                        marketId: item.market.id,
                        marketTypeConfig: item.marketTypeConfigId,
                        isBetBuilderEnabled: item === null || item === void 0 ? void 0 : item.isBetBuilderEnabled,
                        name: item.name,
                        line: (_a = item === null || item === void 0 ? void 0 : item.line) !== null && _a !== void 0 ? _a : "",
                        outcomes: item === null || item === void 0 ? void 0 : item.outcomes,
                        eventName: event.name,
                        startDate: event.startDate,
                        leagueName: (_b = event === null || event === void 0 ? void 0 : event.tournament) === null || _b === void 0 ? void 0 : _b.name,
                        leagueId: (_c = event === null || event === void 0 ? void 0 : event.tournament) === null || _c === void 0 ? void 0 : _c.id,
                        sportName: (_f = (_e = (_d = event === null || event === void 0 ? void 0 : event.tournament) === null || _d === void 0 ? void 0 : _d.category) === null || _e === void 0 ? void 0 : _e.sport) === null || _f === void 0 ? void 0 : _f.name,
                        playerName: item === null || item === void 0 ? void 0 : item.playerName
                    });
                }
                else {
                    formattedData.push({
                        displayRule: item.displayRule,
                        id: event.id,
                        statusId: item.statusId,
                        marketId: item.id,
                        marketTypeConfig: item.marketTypeConfigId,
                        isBetBuilderEnabled: item === null || item === void 0 ? void 0 : item.isBetBuilderEnabled,
                        name: item.name,
                        line: (_g = item === null || item === void 0 ? void 0 : item.line) !== null && _g !== void 0 ? _g : "",
                        outcomes: item === null || item === void 0 ? void 0 : item.outcomes,
                        eventName: event.name,
                        startDate: event.startDate,
                        leagueName: (_h = event === null || event === void 0 ? void 0 : event.tournament) === null || _h === void 0 ? void 0 : _h.name,
                        leagueId: (_j = event === null || event === void 0 ? void 0 : event.tournament) === null || _j === void 0 ? void 0 : _j.id,
                        sportName: (_m = (_l = (_k = event === null || event === void 0 ? void 0 : event.tournament) === null || _k === void 0 ? void 0 : _k.category) === null || _l === void 0 ? void 0 : _l.sport) === null || _m === void 0 ? void 0 : _m.name,
                        playerName: item === null || item === void 0 ? void 0 : item.playerName
                    });
                }
            });
        }
        else {
            formattedData = {
                displayRule: data === null || data === void 0 ? void 0 : data.displayRule,
                id: event.id,
                marketId: data.id,
                marketTypeConfig: data.marketTypeConfigId,
                isBetBuilderEnabled: data === null || data === void 0 ? void 0 : data.isBetBuilderEnabled,
                name: data.name,
                line: (_a = data === null || data === void 0 ? void 0 : data.line) !== null && _a !== void 0 ? _a : "",
                outcomes: data === null || data === void 0 ? void 0 : data.outcomes,
                eventName: event.name,
                startDate: event.startDate,
                leagueName: (_b = event === null || event === void 0 ? void 0 : event.tournament) === null || _b === void 0 ? void 0 : _b.name,
                leagueId: (_c = event === null || event === void 0 ? void 0 : event.tournament) === null || _c === void 0 ? void 0 : _c.id,
                sportName: (_f = (_e = (_d = event === null || event === void 0 ? void 0 : event.tournament) === null || _d === void 0 ? void 0 : _d.category) === null || _e === void 0 ? void 0 : _e.sport) === null || _f === void 0 ? void 0 : _f.name,
                playerName: data === null || data === void 0 ? void 0 : data.playerName
            };
        }
    };
    const onClick = (event) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        let button, arrow;
        if ((_d = (_c = (_b = (_a = event.target.children) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.classList) === null || _c === void 0 ? void 0 : _c.value) === null || _d === void 0 ? void 0 : _d.includes(styles.dropdownIcon)) {
            button = event.target;
            arrow = event.target.children[0];
        }
        else if ((_h = (_g = (_f = (_e = event.target.children) === null || _e === void 0 ? void 0 : _e[1]) === null || _f === void 0 ? void 0 : _f.classList) === null || _g === void 0 ? void 0 : _g.value) === null || _h === void 0 ? void 0 : _h.includes(styles.dropdownIcon)) {
            button = event.target;
            arrow = event.target.children[1];
        }
        else {
            button = event.target.parentNode;
            arrow = button.children[1];
        }
        const isActive = button.classList.value.includes(styles.active);
        const content = button.nextElementSibling;
        if (isActive === true) {
            button.classList.remove(styles.active);
            arrow.style.transform = "rotate(180deg)";
            content.style.maxHeight = 0;
        }
        else {
            button.classList.add(styles.active);
            arrow.style.transform = "rotate(0deg)";
            content.style.transition = "max-height 0.2s ease-out";
            content.style.maxHeight = String(Number(content.scrollHeight) + 8) + "px";
        }
    };
    // fetches the market type configs
    const fetchMarketTypeConfigs = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _f, _g;
        if (((_f = process.env) === null || _f === void 0 ? void 0 : _f.REACT_APP_SPORTSBOOK_API_URL) && ((_g = process.env) === null || _g === void 0 ? void 0 : _g.REACT_APP_SPORTSBOOK_API_KEY)) {
            const url = productId === 2 ? "/esports/market-map" : null;
            if (url) {
                yield axios.get(url)
                    .then((res) => {
                    setError(undefined);
                    setMarketTypeConfigs(res.data);
                }).catch((e) => {
                    setError(e);
                });
            }
            else {
                setError("Environment variables are not in place");
            }
            ;
        }
    }), []);
    const fetchData = () => {
        // for productId 1 by default
        let url = `/events/${seriesId}`; // it expects the eventId
        if (productId === 2) {
            url = `/esports/series/${seriesId}`; // it takes the seriesId
        }
        axios
            .get(url)
            .then((res) => {
            if (productId === 1) {
                // replaces the market name (fullname) with the short name when productId is 1 (sport)
                // hides unavailable group markers (so, they are not presented in the group market filters)
                const marketsAvailable = [];
                const marketGroupsAvailable = [];
                const marketGroupsNotAvailable = [];
                const newMarkets = res.data.markets.map((market) => {
                    var _a, _b;
                    if (!marketsAvailable.includes(market.marketTypeConfigId))
                        marketsAvailable.push(market.marketTypeConfigId);
                    const marketConfig = marketTypeConfigs.find((config) => config.id === market.marketTypeConfigId);
                    if (marketConfig) {
                        marketConfig.marketGroups.forEach((marketGroup) => {
                            if (!marketGroupsAvailable.includes(marketGroup.id))
                                marketGroupsAvailable.push(marketGroup.id);
                        });
                    }
                    if (marketConfig === null || marketConfig === void 0 ? void 0 : marketConfig.shortName)
                        market.name = marketConfig === null || marketConfig === void 0 ? void 0 : marketConfig.shortName;
                    if ((_a = marketConfig === null || marketConfig === void 0 ? void 0 : marketConfig.marketType) === null || _a === void 0 ? void 0 : _a.displayRule)
                        market.displayRule = (_b = marketConfig === null || marketConfig === void 0 ? void 0 : marketConfig.marketType) === null || _b === void 0 ? void 0 : _b.displayRule;
                    return market;
                });
                marketTypeConfigs.forEach((config) => {
                    config.marketGroups.forEach((marketGroup) => {
                        if (!marketGroupsAvailable.includes(marketGroup.id) &&
                            !marketGroupsNotAvailable.includes(marketGroup.id))
                            marketGroupsNotAvailable.push(marketGroup.id);
                    });
                });
                setUnavailableMarketGroupsConfigs(marketGroupsNotAvailable);
                res.data.markets = newMarkets;
            }
            setData(res.data);
            setError(undefined);
        })
            .catch((e) => {
            setError(e);
        })
            .finally(() => {
            setIsloading(false);
        });
    };
    // deals with selected tab
    // const onChange = (key: string): void => {
    //   console.log("selected tab: ", key);
    // };
    // listens for an emitted data, and then creates a custom events that emitts something else
    React.useEffect(() => {
        const oddsSingleeventInputDetailsHandler = (e) => {
            var _a;
            // TODO: investigate and update without setTimeout
            setTimeout(() => {
                updateDefaultOutcomes();
            }, 0);
            if (seriesId === e.detail.seriesId) {
                const triggerOddsOutcomesDetails = new CustomEvent("odds-outcomes-details", {
                    // TODO: Define if missing items are necessary
                    detail: {
                        product: {
                            id: productId
                        },
                        market: {
                            // id: e.detail?.market?.id,
                            name: (_a = e.detail) === null || _a === void 0 ? void 0 : _a.marketName
                            // status: e.detail?.market?.status,
                            // type: e.detail?.market?.type
                        },
                        match: {
                            id: e.detail.matchId
                            // status: e.detail.match?.status
                        },
                        series: {
                            id: seriesId,
                            name: e.detail.event,
                            startDate: e.detail.startDate,
                            time: e.detail.time
                        },
                        selection: {
                            id: e.detail.outcomeId,
                            name: e.detail.name,
                            odds: e.detail.odds,
                            isOpen: e.detail.isOpen
                        }
                    },
                    bubbles: true
                });
                window.dispatchEvent(triggerOddsOutcomesDetails);
            }
        };
        const updateSelectedBetsHandler = (e) => {
            var _a, _b, _c;
            setDefaultOutcomes([...(_b = (_a = e.detail) === null || _a === void 0 ? void 0 : _a.selectedBets) === null || _b === void 0 ? void 0 : _b.map((bet) => bet === null || bet === void 0 ? void 0 : bet.outcomeId)]);
            if (productId && seriesId && (marketTypeConfigs === null || marketTypeConfigs === void 0 ? void 0 : marketTypeConfigs.length) > 0 && !((_c = e.detail) === null || _c === void 0 ? void 0 : _c.isRemoveBet)) {
                fetchData();
            }
        };
        const selectedTagDetailsHandler = () => {
            updateDefaultOutcomes();
        };
        const betslipClearBetsHandler = () => {
            setDefaultOutcomes([]);
        };
        window.addEventListener("update-selected-bets", updateSelectedBetsHandler);
        window.addEventListener("odds-singleevent-input-details", oddsSingleeventInputDetailsHandler);
        window.addEventListener("selected-tag-details", selectedTagDetailsHandler);
        window.addEventListener("bet-slip-clear-bets", betslipClearBetsHandler);
        return () => {
            window.removeEventListener("update-selected-bets", updateSelectedBetsHandler);
            window.removeEventListener("odds-singleevent-input-details", oddsSingleeventInputDetailsHandler);
            window.removeEventListener("selected-tag-details", selectedTagDetailsHandler);
            window.addEventListener("bet-slip-clear-bets", betslipClearBetsHandler);
        };
    }, []);
    React.useEffect(() => {
        if (productId && seriesId && (marketTypeConfigs === null || marketTypeConfigs === void 0 ? void 0 : marketTypeConfigs.length) === 0)
            fetchMarketTypeConfigs();
        if (productId && seriesId && (marketTypeConfigs === null || marketTypeConfigs === void 0 ? void 0 : marketTypeConfigs.length) > 0)
            fetchData();
        if (!productId || !seriesId)
            setIsloading(false);
    }, [productId, seriesId, marketTypeConfigs]);
    const MarketGroupsAvailability = (filteredMarkets) => {
        const marketGroupsAvailable = [];
        const marketGroupsNotAvailable = [];
        const marketTypeConfigsFiltered = marketTypeConfigs.filter((config) => { var _a; return ((_a = config === null || config === void 0 ? void 0 : config.marketGroups) === null || _a === void 0 ? void 0 : _a.length) > 0; });
        const marketMapIds = [...new Set(filteredMarkets.map((market) => market.marketMapId))];
        // lists all possible market groups that might be available
        const allAvailableMarketGroups = [...new Set(marketTypeConfigsFiltered.map((config) => config === null || config === void 0 ? void 0 : config.marketGroups)
                .flat(Infinity)
                .map((group) => group === null || group === void 0 ? void 0 : group.id))];
        marketTypeConfigsFiltered.filter((config) => marketMapIds.includes(config.id))
            .forEach((config) => {
            config.marketGroups.forEach((marketGroup) => {
                if (!marketGroupsAvailable.includes(marketGroup.id) &&
                    !marketGroupsNotAvailable.includes(marketGroup.id))
                    marketGroupsAvailable.push(marketGroup.id);
            });
        });
        allAvailableMarketGroups.forEach((item) => {
            if (!marketGroupsAvailable.includes(item))
                marketGroupsNotAvailable.push(item);
        });
        return { marketGroupsAvailable, marketGroupsNotAvailable };
    };
    if (productId === 2) {
        const processedMarketsMatches = [];
        // when at least one market is provided, then its details gets processed,
        // and users should be able to see a main markets tab
        if (((_a = data === null || data === void 0 ? void 0 : data.markets) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            let filteredMarketsMain = [...data === null || data === void 0 ? void 0 : data.markets];
            const processedGroups = [];
            // const { marketGroupsNotAvailable } = MarketGroupsAvailability([...data?.markets]);
            // filters the data based on the selected option
            if (marketsConfigs && selectedMarketGroupMain.id) {
                filteredMarketsMain = filteredMarketsMain === null || filteredMarketsMain === void 0 ? void 0 : filteredMarketsMain.filter((market) => {
                    // check if market included in marketGroup matches selected market
                    return marketsConfigs.find((config) => config.id === market.marketMapId &&
                        config.marketGroups.find((marketGroup) => marketGroup.id === selectedMarketGroupMain.id));
                });
            }
            // when we have markets, but all marketSelections are empty,
            // then the main market tab gets invalidated, and consequently not processed
            const isSelectionAvailable = ![...data.markets].every((market) => market.marketSelections.length === 0);
            // processes the main markets, when both market and at least one selection is available
            if (isSelectionAvailable) {
                const processedMarkets = [];
                const { marketGroupsNotAvailable } = MarketGroupsAvailability(filteredMarketsMain);
                processedMarkets.push(_jsx("div", Object.assign({ className: styles.marketMap }, { children: _jsx("div", Object.assign({ style: { marginBottom: 5 } }, { children: _jsx(SportsMarketsFilters, { productId: 2, tagsSource: "tags-list-markets-filter-main", setMarketsConfigs: setMarketsConfigs, setSelectedMarketGroup: setSelectedMarketGroupMain, marketGroupsNotAvailable: marketGroupsNotAvailable, overrideMarketGroups: overrideMarketGroups }) })) }), `market_map_main_market`));
                filteredMarketsMain === null || filteredMarketsMain === void 0 ? void 0 : filteredMarketsMain.forEach((item) => {
                    // checks if group has been already created
                    const label = item.marketType ? item.marketType : item.label;
                    if (!processedGroups.includes(label)) {
                        processedGroups.push(label);
                        processedMarkets.push(_jsxs("div", { children: [_jsxs("button", Object.assign({ className: clsx(styles.collapsible, styles.active), onClick: (e) => onClick(e) }, { children: [label, _jsx("div", { className: styles.dropdownIcon })] })), _jsx("div", Object.assign({ className: styles.content }, { children: filteredMarketsMain === null || filteredMarketsMain === void 0 ? void 0 : filteredMarketsMain.filter((market) => item.marketType ?
                                        item.marketType === market.marketType :
                                        item.label === market.label).map((marketGroup) => {
                                        return (_jsx(EsportsMarketGroupFromMarket, { marketGroup: marketGroup, item: item, productId: productId, defaultOutcomes: defaultOutcomes, seriesId: seriesId, name: data === null || data === void 0 ? void 0 : data.name, startDate: data === null || data === void 0 ? void 0 : data.startDate, sport: sport }, `market-group-row-${marketGroup.abId}`));
                                    }) }))] }, `${label.replaceAll(" ", "-").toLowerCase()}`));
                    }
                });
                processedMarketsMatches.push({
                    key: "1",
                    label: "Main Markets",
                    children: processedMarkets
                });
            }
        }
        if (((_b = data === null || data === void 0 ? void 0 : data.matches) === null || _b === void 0 ? void 0 : _b.length) > 0) {
            const clonedMatches = [...data === null || data === void 0 ? void 0 : data.matches.sort((a, b) => a.matchOrder - b.matchOrder)];
            // when we have matches, but all markets are empty,
            // then the market tab gets invalidated, and consequently not processed
            const isMarketAvailable = !(clonedMatches === null || clonedMatches === void 0 ? void 0 : clonedMatches.every((match) => match.markets.length === 0));
            // processes the matches
            if (isMarketAvailable) {
                clonedMatches === null || clonedMatches === void 0 ? void 0 : clonedMatches.forEach((match) => {
                    var _a, _b, _c, _d, _e;
                    let filteredMarkets = [...clonedMatches];
                    const processedMarkets = [];
                    const processedGroups = [];
                    // matchOrder doesn't get processed, when there are no markets associated
                    if (((_a = match === null || match === void 0 ? void 0 : match.markets) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                        // filters the data based on the selected option
                        if (((_b = selectedMarketGroupMatches[match.matchOrder - 1]) === null || _b === void 0 ? void 0 : _b.id) === 0) {
                            filteredMarkets = [...match.markets];
                        }
                        else if (marketsConfigs && ((_c = selectedMarketGroupMatches[match.matchOrder - 1]) === null || _c === void 0 ? void 0 : _c.id)) {
                            const { marketGroupsNotAvailable } = MarketGroupsAvailability([...match.markets]);
                            if (selectedMarketGroupMatches[match.matchOrder - 1].id !== 0 &&
                                marketGroupsNotAvailable.includes((_d = selectedMarketGroupMatches[match.matchOrder - 1]) === null || _d === void 0 ? void 0 : _d.id)) {
                                filteredMarkets = [...match.markets];
                            }
                            else {
                                filteredMarkets = (_e = match.markets) === null || _e === void 0 ? void 0 : _e.filter((market) => {
                                    var _a;
                                    // check if market included in marketGroup matches selected market
                                    return marketGroupsNotAvailable.includes((_a = selectedMarketGroupMatches[match.matchOrder - 1]) === null || _a === void 0 ? void 0 : _a.id) ?
                                        marketsConfigs :
                                        marketsConfigs.find((config) => config.id === market.marketMapId &&
                                            config.marketGroups.find((marketGroup) => { var _a; return marketGroup.id === ((_a = selectedMarketGroupMatches[match.matchOrder - 1]) === null || _a === void 0 ? void 0 : _a.id); }));
                                });
                            }
                        }
                        const { marketGroupsNotAvailable } = MarketGroupsAvailability(filteredMarkets);
                        processedMarkets.push(_jsx("div", Object.assign({ className: styles.marketMap }, { children: _jsx("div", Object.assign({ style: { marginBottom: 5 } }, { children: _jsx(SportsMarketsFilters, { productId: 2, tagsSource: `tags-list-markets-filter-match-${String(match.matchOrder)}`, setMarketsConfigs: setMarketsConfigs, setSelectedMarketGroup: setSelectedMarketGroupMatches, data: selectedMarketGroupMatches, marketGroupsNotAvailable: marketGroupsNotAvailable, updatedByIndex: Number(match.matchOrder - 1), overrideMarketGroups: overrideMarketGroups }) })) }), `market_map_${match.abId}`));
                        match.markets.forEach((item) => {
                            var _a;
                            const isFiltered = !filteredMarkets.includes(item);
                            if (isFiltered)
                                return;
                            // checks if group has been already created
                            const label = item.marketType ? item.marketType : item.label;
                            if (!processedGroups.includes(label)) {
                                processedGroups.push(label);
                                // in case markedSelections is populate, proceed with the market processing
                                if (item.marketSelections.length > 0) {
                                    processedMarkets.push(_jsxs("div", { children: [_jsxs("button", Object.assign({ className: clsx(styles.collapsible, styles.active), onClick: (e) => onClick(e) }, { children: [label, _jsx("div", { className: styles.dropdownIcon })] })), _jsx("div", Object.assign({ className: styles.content }, { children: (_a = match.markets) === null || _a === void 0 ? void 0 : _a.filter((market) => item.marketType ?
                                                    item.marketType === market.marketType :
                                                    item.label === market.label).map((marketGroup) => {
                                                    return (_jsx(EsportsMarketGroupFromMatches, { marketGroup: marketGroup, productId: productId, defaultOutcomes: defaultOutcomes, seriesId: seriesId, name: data === null || data === void 0 ? void 0 : data.name, startDate: data === null || data === void 0 ? void 0 : data.startDate, match: {
                                                            id: match.abId,
                                                            status: match.lifecycle
                                                        }, sport: sport }, `market-group-row-${marketGroup.abId}`));
                                                }) }))] }, `market-group-${item.abId}`));
                                }
                            }
                        });
                    }
                    if (processedMarkets.length > 0) {
                        processedMarketsMatches.push({
                            key: String(Number(match.matchOrder) + 1),
                            label: `Match ${String(match.matchOrder)}`,
                            children: processedMarkets
                        });
                    }
                });
            }
            ;
        }
        if (isLoading && !error) {
            return _jsx(SkeletonOutcomesEsports, {});
        }
        else if (error) {
            return _jsx("esports-nodata-for-market", { message: noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.message, src: noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.src, cta: JSON.stringify(noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.cta) });
        }
        else {
            return (_jsx(ConfigProvider, Object.assign({ theme: {
                    components: {
                        Tabs: {
                            colorPrimary: "var(--primaryBrandColor, #e5206f)"
                        }
                    }
                } }, { children: processedMarketsMatches.length > 0 ?
                    (_jsx(Tabs
                    // type="card"
                    , { 
                        // type="card"
                        size: "small", defaultActiveKey: "1", items: processedMarketsMatches, 
                        // onChange={onChange}
                        tabBarStyle: {
                            padding: "0px 10px"
                        }, style: {
                            lineHeight: screens.xs ? 1.2 : 1.5
                        }, className: styles.matchGroup })) :
                    _jsx("esports-nodata-for-market", { message: noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.message, src: noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.src, cta: JSON.stringify(noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.cta) }) })));
        }
    }
    else if (productId === 1) {
        if (((_c = data === null || data === void 0 ? void 0 : data.markets) === null || _c === void 0 ? void 0 : _c.length) > 0 && !isLoading && !error) {
            let filteredMarketsSports;
            const event = {
                id: data.id,
                name: data.name,
                homeTeam: data.homeTeam,
                awayTeam: data.awayTeam,
                marketCount: data.marketCount,
                startDate: data.startDate
            };
            const filteredBBMarketsSports = (_d = data.markets) === null || _d === void 0 ? void 0 : _d.filter((market) => {
                const filteredBBMarketOutcomes = market.outcomes.filter((outcome) => outcome.isBetBuilderEnabled);
                // check if market has any BB outcomes
                return filteredBBMarketOutcomes === null || filteredBBMarketOutcomes === void 0 ? void 0 : filteredBBMarketOutcomes.length;
            });
            if (marketsConfigs &&
                selectedMarketGroupSports.id !== SPORTS_MARKETS_FILTERS_IDS.ALL &&
                selectedMarketGroupSports.id !== SPORTS_MARKETS_FILTERS_IDS.BET_BUILDER) {
                filteredMarketsSports = (_e = data.markets) === null || _e === void 0 ? void 0 : _e.filter((market) => {
                    // check if market included in marketGroup matches selected market
                    return marketsConfigs.find((config) => config.id === market.marketTypeConfigId &&
                        config.marketGroups.find((marketGroup) => marketGroup.id === selectedMarketGroupSports.id));
                });
            }
            // grouping markets
            let marketGroupBeingProcessed; // make this an object with id, name, and info
            const processedComponents = [];
            let marketGroupComponents = [];
            const dataToBeProcessed = selectedMarketGroupSports.id === SPORTS_MARKETS_FILTERS_IDS.BET_BUILDER ?
                filteredBBMarketsSports :
                filteredMarketsSports !== null && filteredMarketsSports !== void 0 ? filteredMarketsSports : data.markets;
            // Some markets e.g. handicap come as separate markets, but need to be grouped together
            // for the display rule.
            const marketTypeConfigIdGroups = new Map();
            dataToBeProcessed.forEach((item, index) => {
                var _a;
                const marketKey = `${item.marketTypeConfigId}${item.name}`;
                if (marketTypeConfigIdGroups.get(marketKey) !== undefined) {
                    (_a = marketTypeConfigIdGroups.get(marketKey)) === null || _a === void 0 ? void 0 : _a.push(item);
                }
                else {
                    marketTypeConfigIdGroups.set(marketKey, [item]);
                }
            });
            const groupedMarketsToBeProcessed = Array.from(marketTypeConfigIdGroups.values());
            groupedMarketsToBeProcessed.forEach((items, index) => {
                var _a, _b;
                const showBBLogo = ((liveData === null || liveData === void 0 ? void 0 : liveData.statusId) || (data === null || data === void 0 ? void 0 : data.statusId)) !== EVENT_STATUS.LIVE &&
                    ((_b = (_a = items === null || items === void 0 ? void 0 : items[0]) === null || _a === void 0 ? void 0 : _a.outcomes) === null || _b === void 0 ? void 0 : _b[0].isBetBuilderEnabled) &&
                    isBBEnabled;
                if (!marketGroupBeingProcessed) {
                    marketGroupBeingProcessed = {
                        marketTypeConfigId: items[0].marketTypeConfigId,
                        name: items[0].name,
                        showBBLogo
                    };
                    marketGroupComponents.push(DisplayRuleLookup(items, event));
                    // handles the event when there is only one market
                    if ((groupedMarketsToBeProcessed === null || groupedMarketsToBeProcessed === void 0 ? void 0 : groupedMarketsToBeProcessed.length) === 1 && index === 0) {
                        processedComponents.push(_jsxs("div", Object.assign({ className: styles.marketGroup }, { children: [_jsxs("button", Object.assign({ className: clsx(styles.collapsible, styles.active), onClick: (e) => onClick(e) }, { children: [_jsxs("div", Object.assign({ className: styles.name }, { children: [marketGroupBeingProcessed.name, marketGroupBeingProcessed.showBBLogo && (_jsx(ImageFromCDN, { alt: "Bet Builder", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/bet-builder.svg", overrideStyles: styles.betBuilder, width: 30 }))] })), _jsx("div", { className: styles.dropdownIcon })] })), _jsx("div", Object.assign({ className: styles.content }, { children: marketGroupComponents }))] }), `market-${marketGroupBeingProcessed.marketTypeConfigId}`));
                        // clean up
                        marketGroupComponents = [];
                    }
                }
                else if ( // this means that we should push it as we are not processing the last record
                marketGroupBeingProcessed.marketTypeConfigId !== items[0].marketTypeConfigId) {
                    processedComponents.push(_jsxs("div", Object.assign({ className: styles.marketGroup }, { children: [_jsxs("button", Object.assign({ className: clsx(styles.collapsible, styles.active), onClick: (e) => onClick(e) }, { children: [_jsxs("div", Object.assign({ className: styles.name }, { children: [marketGroupBeingProcessed.name, marketGroupBeingProcessed.showBBLogo && (_jsx(ImageFromCDN, { alt: "Bet Builder", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/bet-builder.svg", overrideStyles: styles.betBuilder, width: 30 }))] })), _jsx("div", { className: styles.dropdownIcon })] })), _jsx("div", Object.assign({ className: styles.content }, { children: marketGroupComponents }))] }), `market-${marketGroupBeingProcessed.marketTypeConfigId}`));
                    marketGroupComponents = [];
                    marketGroupBeingProcessed = {
                        marketTypeConfigId: items[0].marketTypeConfigId,
                        name: items[0].name,
                        showBBLogo
                    };
                    // add the new item to the array
                    marketGroupComponents.push(DisplayRuleLookup(items, event));
                    // we are dealing with different groups, and also have reached the EOF
                    if ((groupedMarketsToBeProcessed === null || groupedMarketsToBeProcessed === void 0 ? void 0 : groupedMarketsToBeProcessed.length) - 1 === index) {
                        processedComponents.push(_jsxs("div", Object.assign({ className: styles.marketGroup }, { children: [_jsxs("button", Object.assign({ className: clsx(styles.collapsible, styles.active), onClick: (e) => onClick(e) }, { children: [_jsxs("div", Object.assign({ className: styles.name }, { children: [marketGroupBeingProcessed.name, marketGroupBeingProcessed.showBBLogo && (_jsx(ImageFromCDN, { alt: "Bet Builder", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/bet-builder.svg", overrideStyles: styles.betBuilder, width: 30 }))] })), _jsx("div", { className: styles.dropdownIcon })] })), _jsx("div", Object.assign({ className: styles.content }, { children: marketGroupComponents }))] }), `market-${marketGroupBeingProcessed.marketTypeConfigId}`));
                    }
                }
                else {
                    // it should push every single record, less the first and the last,
                    // as they are being handled in a different conditional
                    marketGroupComponents.push(DisplayRuleLookup(items, event));
                    // we are dealing with different groups, and also have reached the EOF
                    if ((groupedMarketsToBeProcessed === null || groupedMarketsToBeProcessed === void 0 ? void 0 : groupedMarketsToBeProcessed.length) - 1 === index) {
                        processedComponents.push(_jsxs("div", Object.assign({ className: styles.marketGroup }, { children: [_jsxs("button", Object.assign({ className: clsx(styles.collapsible, styles.active), onClick: (e) => onClick(e) }, { children: [_jsxs("div", Object.assign({ className: styles.name }, { children: [marketGroupBeingProcessed.name, marketGroupBeingProcessed.showBBLogo && (_jsx(ImageFromCDN, { alt: "Bet Builder", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/bet-builder.svg", overrideStyles: styles.betBuilder, width: 30 }))] })), _jsx("div", { className: styles.dropdownIcon })] })), _jsx("div", Object.assign({ className: styles.content }, { children: marketGroupComponents }))] }), `market-${marketGroupBeingProcessed.marketTypeConfigId}`));
                    }
                }
            });
            return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx("div", Object.assign({ style: { marginBottom: 10 } }, { children: _jsx(SportsMarketsFilters, { productId: 1, tagsSource: "tags-list-markets-filter-match-sports", setMarketsConfigs: setMarketsConfigs, setSelectedMarketGroup: setSelectedMarketGroupSports, marketGroupsNotAvailable: unavailableMarketGroupsConfigs, withBBFilter: ((liveData === null || liveData === void 0 ? void 0 : liveData.statusId) || (data === null || data === void 0 ? void 0 : data.statusId)) !== EVENT_STATUS.LIVE &&
                                filteredBBMarketsSports.length &&
                                isBBEnabled }) })), processedComponents] })));
        }
        else if (isLoading && !error) {
            return _jsx(SkeletonOutcomesSports, {});
        }
    }
    return (_jsx("esports-nodata-for-market", { message: noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.message, src: noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.src, cta: JSON.stringify(noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.cta) }));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this["series-id"] = 0;
        this.sport = { id: 1, name: "soccer" };
    }
    static get observedAttributes() {
        return ["no-data-options", "series-id", "product-id", "sport"];
    }
    connectedCallback() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(OddsOutcomes, { productId: this["product-id"], seriesId: this["series-id"], noDataOptions: this["no-data-options"], sport: this.sport }));
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        var _a;
        switch (attrName) {
            case "series-id":
            case "product-id":
                this[attrName] = +newValue;
                break;
            case "no-data-options":
            case "sport":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(OddsOutcomes, { productId: this["product-id"], seriesId: this["series-id"], noDataOptions: this["no-data-options"], sport: this.sport }));
    }
}
(_a = customElements.get("esports-odds-outcomes")) !== null && _a !== void 0 ? _a : customElements.define("esports-odds-outcomes", Element);
