// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kGd84UKY7qVagNYtwmpc{max-height:30vh;overflow-y:auto}.OZyHchNO95WwlkSszS5r:not(:last-child){border-bottom:.5px solid #dedede}`, "",{"version":3,"sources":["webpack://./src/components/composed/betSlip/betSelections/BetSelections.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,eAAA,CAIA,uCACE,gCAAA","sourcesContent":[".root{\n  max-height: 30vh;\n  overflow-y: auto;\n}\n\n.selection {\n  &:not(:last-child) {\n    border-bottom: 0.5px solid #dedede;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `kGd84UKY7qVagNYtwmpc`,
	"selection": `OZyHchNO95WwlkSszS5r`
};
export default ___CSS_LOADER_EXPORT___;
