import dayjs from "dayjs";
import { CLOCK_STATUS } from "enums";
export const processedTimeCounter = (clock, isCountdown, isFormatted = true // by default, we return minutes:seconds as a string
) => {
    if (!isCountdown) { // counting up
        if ((clock === null || clock === void 0 ? void 0 : clock.minutes) === undefined && (clock === null || clock === void 0 ? void 0 : clock.seconds) === undefined)
            return "";
        if ((clock === null || clock === void 0 ? void 0 : clock.statusId) !== CLOCK_STATUS.RUNNING) {
            // If the clock is stopped e.g. HT, then don't count, just show the current minutes/seconds
            const minutes = Number(clock === null || clock === void 0 ? void 0 : clock.minutes).toString().padStart(2, "0");
            const seconds = Number(clock === null || clock === void 0 ? void 0 : clock.seconds).toString().padStart(2, "0");
            return `${minutes}:${seconds}`;
        }
        const convertedToSeconds = (Number(clock === null || clock === void 0 ? void 0 : clock.minutes) * 60) + Number(clock === null || clock === void 0 ? void 0 : clock.seconds);
        const diffInSeconds = dayjs().diff(dayjs(clock === null || clock === void 0 ? void 0 : clock.timestamp), "second") + convertedToSeconds;
        const minutes = Math.floor(diffInSeconds / 60);
        const seconds = diffInSeconds - minutes * 60;
        return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }
    else if (isCountdown && (clock === null || clock === void 0 ? void 0 : clock.remainingMinutesInPeriod) && (clock === null || clock === void 0 ? void 0 : clock.remainingSecondsInPeriod)) { // counting down
        if (!isFormatted) {
            return Number(clock.remainingMinutesInPeriod * 60) + Number(clock.remainingSecondsInPeriod);
        }
        return `${String(clock.remainingMinutesInPeriod)}:${String(clock.remainingSecondsInPeriod)}`;
    }
    return "";
};
export const showClockForSport = (sportId) => {
    if (!sportId) {
        return true;
    }
    switch (sportId) {
        case 3: // Tennis
        case 11: // Volleyball
        case 13: // Cricket
        case 16: // Table Tennis
            return false;
        default:
            return true;
    }
};
