var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import axios from "axios";
import clsx from "clsx";
import { CURRENCY, MODAL_POSITION } from "enums";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import { ModalBase } from "components/base/modal/ModalBase/ModalBase";
import { SettingsModal } from "./SettingsModal";
import { ConfirmationModal } from "./ConfirmationModal";
import { delay } from "helpers/GeneralFunctions";
import styles from "./BetCashOut.module.scss";
const MODAL = {
    SETTINGS: "Settings",
    CONFIRM: "Confirm"
};
// TODO: handle reusable custom events
const triggerBalanceUpdateEvent = () => {
    window.dispatchEvent(new CustomEvent("balance-update", {}));
};
export const BetCashOut = ({ stake, payout, cashoutOffer, betId, playerId, productId, betRef, hasRefundedBetLegs }) => {
    var _a, _b;
    const [modal, setModal] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [newOffer, setNewOffer] = React.useState();
    const [cashout, setCashout] = React.useState();
    const [cashedOutAmount, setCashedOutAmount] = React.useState();
    const [isSuccess, setIsSuccess] = React.useState(null);
    React.useEffect(() => {
        setCashout(cashoutOffer);
    }, [cashoutOffer]);
    const fetchRefreshBet = () => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d, _e, _f;
        if (((_c = process.env) === null || _c === void 0 ? void 0 : _c.REACT_APP_GATEWAY_API_URL) &&
            ((_d = process.env) === null || _d === void 0 ? void 0 : _d.REACT_APP_GATEWAY_API_KEY)) {
            setIsLoading(true);
            const baseUrl = ((_e = process.env) === null || _e === void 0 ? void 0 : _e.REACT_APP_GATEWAY_API_URL) ? ((_f = process.env) === null || _f === void 0 ? void 0 : _f.REACT_APP_GATEWAY_API_URL) + "/bets" : "";
            const params = {
                playerId,
                productId,
                betRef
            };
            yield axios
                .get(`${baseUrl}/bettor/${playerId}/bets`, {
                params
            })
                .then((res) => {
                console.log(res);
                const { cashoutOffer } = res.data;
                setNewOffer(cashoutOffer);
            })
                .catch((e) => {
                console.log("Refresh bet error: ", e);
            })
                .finally(() => {
                setIsLoading(false);
            });
        }
    });
    const fetchCashOut = ({ amountOffered, amountRequested }) => __awaiter(void 0, void 0, void 0, function* () {
        var _g;
        if ((_g = process.env) === null || _g === void 0 ? void 0 : _g.REACT_APP_GATEWAY_API_URL) {
            setIsLoading(true);
            yield axios
                .post(`${process.env.REACT_APP_GATEWAY_API_URL}/bets/${betId}/cashout`, {
                amountOffered,
                amountRequested
            }, {
                headers: {
                    "widget-token": localStorage.getItem("widget-token")
                }
            })
                .then((res) => __awaiter(void 0, void 0, void 0, function* () {
                const triggerCashOut = () => {
                    const cashOut = new CustomEvent("bet-cashed-out", {
                        detail: {
                            betId,
                            isFull: amountOffered === amountRequested
                        },
                        bubbles: true
                    });
                    window.dispatchEvent(cashOut);
                };
                triggerCashOut();
                setCashedOutAmount(res.data.amount);
                setNewOffer(undefined);
                setIsSuccess(true);
                yield delay(2000);
                triggerBalanceUpdateEvent();
            }))
                .catch((e) => {
                var _a, _b, _c;
                console.log("Cashout error: ", e);
                const { type, data } = ((_c = (_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.errors) === null || _c === void 0 ? void 0 : _c[0]) || {};
                if (type === "CashoutOfferChangedException" && (data === null || data === void 0 ? void 0 : data.NewOffer)) {
                    setNewOffer(data.NewOffer);
                }
                else {
                    setIsSuccess(false);
                }
                // TODO: remove test data below
                // if (!newOffer) {
                //   setNewOffer(555);
                //   setIsSuccess(true);
                // } else {
                //   setNewOffer(undefined);
                //   setIsSuccess(false);
                // }
            })
                .finally(() => {
                setIsLoading(false);
            });
        }
    });
    const closeModal = (isCashedOut) => {
        const triggerCloseModal = () => {
            const cashOut = new CustomEvent("close-cash-out-modal", {
                detail: {
                    isCashedOut
                },
                bubbles: true
            });
            window.dispatchEvent(cashOut);
        };
        triggerCloseModal();
        setModal(false);
        setIsSuccess(null);
        setNewOffer(undefined);
        setCashedOutAmount(undefined);
    };
    const disabledCashout = !cashout || hasRefundedBetLegs;
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.root }, { children: [_jsxs("button", Object.assign({ onClick: () => {
                            setModal(MODAL.CONFIRM);
                        }, className: clsx(styles.cashOut, { [styles.disabled]: disabledCashout }), disabled: disabledCashout }, { children: ["Cash Out", !disabledCashout ?
                                _jsxs(_Fragment, { children: [" ", CURRENCY, " ", cashout] }) :
                                " Unavaliable"] })), _jsx("button", Object.assign({ onClick: () => {
                            if (!disabledCashout) {
                                setModal(MODAL.SETTINGS);
                            }
                            else {
                                fetchRefreshBet();
                            }
                        }, className: styles.helper }, { children: !disabledCashout ?
                            (_jsx(ImageFromCDN, { alt: "Settings", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/settings.png", size: 20 })) :
                            (_jsx(ImageFromCDN, { alt: "Refresh", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/refresh.png", overrideStyles: clsx({ [styles.loading]: isLoading }), size: 20 })) }))] })), _jsx(ModalBase, Object.assign({ open: !!modal, onClose: () => { closeModal(!!isSuccess); }, withContentPadding: false, position: MODAL_POSITION.BOTTOM }, { children: modal === MODAL.SETTINGS ?
                    (_jsx(SettingsModal, { closeModal: closeModal, stake: stake, cashoutOffer: (_a = cashedOutAmount !== null && cashedOutAmount !== void 0 ? cashedOutAmount : newOffer) !== null && _a !== void 0 ? _a : cashout, payout: payout, isLoading: isLoading, fetchCashOut: fetchCashOut, isPriceChanged: !!newOffer, isSuccess: isSuccess })) :
                    (_jsx(ConfirmationModal, { closeModal: closeModal, stake: stake, cashoutOffer: (_b = cashedOutAmount !== null && cashedOutAmount !== void 0 ? cashedOutAmount : newOffer) !== null && _b !== void 0 ? _b : cashout, isLoading: isLoading, fetchCashOut: fetchCashOut, isPriceChanged: !!newOffer, isSuccess: isSuccess })) }))] }));
};
