import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
import { Button, Checkbox, Col, Input, Row, Space } from "antd";
export const documentDetails = {
    title: "Bet slip confirmation",
    component: "esports-bet-slip-confirmation",
    description: "This is a base component, that is used as part of many composed components.",
    withSessionComponent: true,
    attributes: [
        {
            key: "1",
            attr: "bet-refs",
            type: "string array",
            notes: ["String array of bet refs which is used to get bets details"]
        },
        {
            key: "2",
            attr: "relative",
            type: "boolean (optional)",
            notes: [
                "If value is true, bet slip will be relative.",
                "Fixed by default."
            ]
        },
        {
            key: "3",
            attr: "product-id",
            type: "number",
            notes: [
                "1 - sports",
                "2 - eSports"
            ]
        },
        {
            key: "4",
            attr: "bet-summaries-only",
            type: "boolean (optional)",
            notes: [
                "False by default. If value is true, headers and CTAs are removed and it becomes a read only component"
            ]
        },
        {
            key: "5",
            attr: "player-id",
            type: "string (optional)",
            notes: [
                "If not provided, it will be read from local storage key 'widget-playerId'"
            ]
        },
        {
            key: "6",
            attr: "bet-slip-id",
            type: "number (optional)",
            notes: ["Integer Id of the betslip"]
        }
    ],
    events: [
        {
            event: "bet-slip-confirmation-close",
            type: "emit",
            data: [
                "The component details object is emitted on close bet slip confirmation.",
                `detail: { 
          reuseSelections?: boolean
        }`
            ]
        },
        {
            event: "bet-slip-reuse-bets",
            type: "emit",
            data: [
                `The component details object is emitted on reuse bets selections.`,
                `detail: { 
          bets?: string[]
        }`
            ]
        }
    ]
};
const BetSlipConfirmation = () => {
    const [docDetails, setDocDetails] = React.useState(documentDetails);
    const [checkedProps, setCheckedProps] = React.useState(["relative"]);
    const [betReferences, setBetReferences] = React.useState([]);
    const onChange = (checkedValues) => {
        setCheckedProps(checkedValues);
    };
    const betRefOnClick = () => {
        const commaSeparateBetRefs = document.getElementById("betRefInput").value;
        setBetReferences(commaSeparateBetRefs.split(","));
    };
    const betRefOnClear = () => {
        setBetReferences([]);
    };
    const InteractiveInputs = React.useCallback(() => {
        return (_jsxs(_Fragment, { children: [_jsx("label", { children: "Bet references (comma separated)" }), _jsxs(Row, Object.assign({ justify: "start", align: "top", gutter: [10, 10] }, { children: [_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Input, { id: "betRefInput", defaultValue: betReferences, size: "large" }) })), _jsx(Col, Object.assign({ span: 12 }, { children: _jsxs(Space, Object.assign({ style: { width: "100%" } }, { children: [_jsx(Button, Object.assign({ type: "primary", size: "large", onClick: betRefOnClick }, { children: "Submit" })), _jsx(Button, Object.assign({ type: "primary", size: "large", onClick: betRefOnClear }, { children: "Clear" }))] })) }))] })), _jsx(Row, Object.assign({ style: { marginTop: 30 } }, { children: _jsxs(Col, Object.assign({ span: 24 }, { children: [_jsx("label", { children: "Props" }), _jsx(Checkbox.Group, Object.assign({ style: { width: "100%" }, onChange: onChange, value: checkedProps }, { children: _jsxs(Row, Object.assign({ gutter: [30, 10] }, { children: [_jsx(Col, { children: _jsx(Checkbox, Object.assign({ value: "bet-summaries-only" }, { children: "bet-summaries-only" })) }), _jsx(Col, { children: _jsx(Checkbox, Object.assign({ value: "relative" }, { children: "relative" })) })] })) }))] })) }))] }));
    }, [checkedProps, betReferences]);
    React.useEffect(() => {
        setDocDetails((docDetails) => (Object.assign(Object.assign({}, docDetails), { interactiveInputs: _jsx(InteractiveInputs, {}), example: `<esports-bet-slip-confirmation
        bet-refs={JSON.stringify([
          "795790ef-5dcf-43fd-9a14-692974bbac00"
        ])}
        product-id={2}
      />`, code: (_jsx("esports-bet-slip-confirmation", { "bet-refs": JSON.stringify(betReferences), "product-id": 2, relative: checkedProps === null || checkedProps === void 0 ? void 0 : checkedProps.includes("relative"), "bet-summaries-only": checkedProps === null || checkedProps === void 0 ? void 0 : checkedProps.includes("bet-summaries-only") })) })));
    }, [checkedProps, betReferences]);
    // render the page based on the provided object
    const BetslipConfirmationComponent = React.useCallback(() => {
        if (Object.keys(docDetails).length)
            return _jsx(MountDocumentComponent, { data: docDetails });
        return _jsx("div", { children: "Loading page..." });
    }, [docDetails, betReferences]);
    // render the page based on the provided object
    return _jsx(BetslipConfirmationComponent, {});
};
export default BetSlipConfirmation;
