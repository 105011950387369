import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
let apolloClient;
export const getApolloClient = () => {
    // TODO: Investigate if we can add this control below, but yet being able to update its definition
    // if (apolloClient) {
    //   console.log("Already connected...");
    //   return apolloClient;
    // };
    const httpUrl = process.env.REACT_APP_HASURA_HTTP_URL;
    const wsUrl = process.env.REACT_APP_HASURA_WS_URL;
    const secret = process.env.REACT_APP_HASURA_SECRET;
    if (!httpUrl) {
        // throw new Error("REACT_APP_HASURA_HTTP_URL is not defined");
        console.log("REACT_APP_HASURA_HTTP_URL is not defined");
        return;
    }
    if (!wsUrl) {
        // throw new Error("REACT_APP_HASURA_WS_URL is not defined");
        console.log("REACT_APP_HASURA_WS_URL is not defined");
        return;
    }
    if (process.env.NODE_ENV !== "development" && !secret) {
        // throw new Error("REACT_APP_HASURA_SECRET is not defined");
        console.log("REACT_APP_HASURA_SECRET is not defined");
        return;
    }
    const httpLink = new HttpLink({
        uri: httpUrl
    });
    const wsLink = new GraphQLWsLink(createClient({
        url: wsUrl,
        connectionParams: {
            headers: {
                "content-type": "application/json",
                "x-hasura-admin-secret": secret
            }
        }
    }));
    const authLink = new ApolloLink((operation, forward) => {
        operation.setContext(({ headers = {} }) => ({
            headers: Object.assign(Object.assign({}, headers), { "x-hasura-admin-secret": secret })
        }));
        return forward(operation);
    });
    const splitLink = split(({ query }) => {
        const definition = getMainDefinition(query);
        return (definition.kind === "OperationDefinition" &&
            definition.operation === "subscription");
    }, wsLink, authLink.concat(httpLink));
    apolloClient = new ApolloClient({
        link: splitLink,
        cache: new InMemoryCache()
    });
    return apolloClient;
};
