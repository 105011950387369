import React from "react";
export const useKeyboardButtons = () => {
    const [inputValue, setInputValue] = React.useState("");
    const updateInputValue = (value) => {
        var _a;
        if (value === 0 && !inputValue)
            return;
        if (value === ".") {
            if (inputValue.includes("."))
                return;
        }
        const updatedValue = inputValue + value.toString();
        if (updatedValue.length < 9) {
            if (((_a = updatedValue.split(".")[1]) === null || _a === void 0 ? void 0 : _a.length) > 2)
                return;
            setInputValue(updatedValue);
        }
        else {
            setInputValue(updatedValue.slice(0, 9));
        }
    };
    const addNumber = (value) => {
        if (value === 0) {
            setInputValue("");
        }
        else {
            const updatedValue = +inputValue + value;
            setInputValue(updatedValue.toString());
        }
    };
    const removeLatestDigit = () => {
        if ((inputValue === null || inputValue === void 0 ? void 0 : inputValue.length) - 1 === 0) {
            setInputValue("");
        }
        setInputValue(inputValue.substring(0, inputValue.length - 1));
    };
    return { inputValue, setInputValue, updateInputValue, addNumber, removeLatestDigit };
};
