import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import clsx from "clsx";
import { Slider, ConfigProvider } from "antd";
import { CURRENCY } from "enums";
import { ResultedMessage } from "./ResultedMessage";
import { Loader } from "helpers/loader/Loader";
import styles from "./BetCashOut.module.scss";
export const SettingsModal = ({ closeModal, stake, payout, cashoutOffer, isLoading, fetchCashOut, isPriceChanged, isSuccess }) => {
    const [sliderValue, setSliderValue] = React.useState(0);
    React.useEffect(() => {
        !cashoutOffer && setSliderValue(0);
    }, [cashoutOffer]);
    const max = cashoutOffer !== null && cashoutOffer !== void 0 ? cashoutOffer : 100; // keep fallback value to show full disabled slider
    const marks = {
        0: 0
    };
    marks[Math.round(max / 4)] = "25%";
    marks[Math.round(max / 2)] = "50%";
    marks[Math.round((max * 3) / 4)] = "75%";
    marks[max] = "Full";
    return (_jsx("div", Object.assign({ className: styles.settingsModal }, { children: isSuccess === null && !isPriceChanged ?
            (_jsxs(_Fragment, { children: [_jsx("strong", Object.assign({ className: styles.header }, { children: "Partial Cash Out" })), _jsxs("div", Object.assign({ className: styles.content }, { children: [_jsxs("div", Object.assign({ className: clsx(styles.row, styles.betDetails) }, { children: [_jsxs("div", Object.assign({ className: styles.stake }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: "Stake" })), _jsxs("strong", { children: [CURRENCY, " ", stake] })] })), _jsxs("div", Object.assign({ className: styles.return }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: "Possible Return" })), _jsxs("strong", { children: [CURRENCY, " ", payout] })] }))] })), _jsxs("div", Object.assign({ className: clsx(styles.row, styles.fullCashOut) }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: "Full Cash Out Offer" })), _jsxs("strong", { children: [CURRENCY, " ", cashoutOffer !== null && cashoutOffer !== void 0 ? cashoutOffer : 0] })] })), _jsxs("div", Object.assign({ className: clsx(styles.row, styles.partialCashOut) }, { children: [_jsxs("div", Object.assign({ className: styles.title }, { children: ["Partial Cash Out Offer", _jsx("div", Object.assign({ className: styles.warning }, { children: "Bonuses applied to this bet will be lost" }))] })), _jsxs("strong", { children: [CURRENCY, " ", sliderValue !== null && sliderValue !== void 0 ? sliderValue : 0] })] })), _jsx("div", Object.assign({ className: clsx(styles.row, styles.slider) }, { children: _jsx(ConfigProvider, Object.assign({ theme: {
                                        components: {
                                            Slider: {
                                                railSize: 12,
                                                railBg: "#D9D9D9",
                                                railHoverBg: "#D9D9D9",
                                                trackBg: "#3DAF7F",
                                                trackHoverBg: "#3DAF7F",
                                                handleSize: 10
                                            }
                                        }
                                    } }, { children: _jsx(Slider, { max: max, value: sliderValue, marks: marks, onChange: (newValue) => {
                                            setSliderValue(newValue);
                                        }, disabled: !cashoutOffer }) })) }))] })), _jsxs("div", Object.assign({ className: styles.modalBtns }, { children: [_jsx("button", Object.assign({ onClick: () => {
                                    closeModal();
                                }, className: styles.decline }, { children: "Decline" })), _jsx("button", Object.assign({ onClick: () => {
                                    fetchCashOut({
                                        amountOffered: cashoutOffer,
                                        amountRequested: sliderValue
                                    });
                                }, className: styles.confirm, disabled: !sliderValue || isLoading }, { children: isLoading ? _jsx(Loader, {}) : `Confirm ${CURRENCY} ${sliderValue}` }))] }))] })) :
            (_jsx(ResultedMessage, { cashoutOffer: cashoutOffer, cashoutRequest: sliderValue, closeModal: closeModal, isSuccess: isSuccess, isPriceChanged: isPriceChanged, fetchCashOut: fetchCashOut, isLoading: isLoading })) })));
};
