import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col, Skeleton } from "antd";
import clsx from "clsx";
// styles
import styles from "./Skeleton.module.scss";
export const BetslipSelection = ({ isRelative = false, maxWidth = "inherit" }) => {
    return (_jsx("div", Object.assign({ "data-testid": "BetslipSelectionLoading", className: clsx(styles.root, { [styles.relative]: isRelative }), style: { maxWidth } }, { children: _jsxs(Row, { children: [_jsxs(Col, Object.assign({ span: 24 }, { children: [_jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.betslipSelection, style: { height: 51, margin: 10, width: "calc(100% - 20px)" } }), _jsx("div", { style: {
                                width: "100%",
                                display: "block",
                                height: "0.5px",
                                background: "#dedede",
                                marginTop: "-6px"
                            } })] })), _jsxs(Col, Object.assign({ span: 24 }, { children: [_jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.betslipSelection, style: { height: 51, margin: 10, width: "calc(100% - 20px)" } }), _jsx("div", { style: {
                                width: "100%",
                                display: "block",
                                height: "0.5px",
                                background: "#dedede",
                                marginTop: "-6px"
                            } })] })), _jsx(Col, Object.assign({ span: 24 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.betslipSelection, style: { height: 51, margin: 10, width: "calc(100% - 20px)" } }) }))] }) }), `skeleton_betslip_selection`));
};
