var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
import { LocalStorage } from "helpers/Storage";
import { charValueSumModulo } from "../pages/profile/editProfile/EditProfileWrapper";
import { avatars } from "../assets/avatars/Avatars";
const playerId = LocalStorage.get("widget-playerId");
const token = localStorage.getItem("widget-token");
export const fetchProfile = (setProfileIdFromSession, bettorIdFromSessionUserProfile) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    if (playerId && token && bettorIdFromSessionUserProfile) {
        try {
            const profileStatus = yield checkSocialProfile(bettorIdFromSessionUserProfile, setProfileIdFromSession);
            if (profileStatus) {
                sessionStorage.setItem("profileId", (_a = profileStatus === null || profileStatus === void 0 ? void 0 : profileStatus.data) === null || _a === void 0 ? void 0 : _a.profileId);
                setProfileIdFromSession((_b = profileStatus === null || profileStatus === void 0 ? void 0 : profileStatus.data) === null || _b === void 0 ? void 0 : _b.profileId);
            }
        }
        catch (error) {
            // TODO: error handling
        }
    }
});
const checkSocialProfile = (playerId, setProfileIdFromSession) => __awaiter(void 0, void 0, void 0, function* () {
    var _c, _d, _e;
    if (process.env.REACT_APP_GATEWAY_API_URL) {
        try {
            const response = yield axios.get(`${(_e = (_d = (_c = process.env) === null || _c === void 0 ? void 0 : _c.REACT_APP_GATEWAY_API_URL) === null || _d === void 0 ? void 0 : _d.replace("api/", "")) !== null && _e !== void 0 ? _e : ""}/sourceProfile?source-player-id=
${playerId !== null && playerId !== void 0 ? playerId : ""}`);
            return response;
        }
        catch (error) {
            // TODO: error hanling
            createProfile(playerId, setProfileIdFromSession);
            return false;
        }
    }
});
const createProfile = (playerId, setProfileIdFromSession) => __awaiter(void 0, void 0, void 0, function* () {
    var _f, _g, _h, _j, _k;
    const uniqueUsername = generateRandomUsername();
    const payload = {
        username: uniqueUsername,
        displayName: uniqueUsername,
        avatarUrl: avatars[charValueSumModulo(uniqueUsername)],
        sourceId: {
            playerReferenceId: playerId
        }
    };
    try {
        const response = yield axios.post(`${(_h = (_g = (_f = process.env) === null || _f === void 0 ? void 0 : _f.REACT_APP_GATEWAY_API_URL) === null || _g === void 0 ? void 0 : _g.replace("api/", "")) !== null && _h !== void 0 ? _h : ""}/profiles`, payload);
        sessionStorage.setItem("profileId", (_j = response === null || response === void 0 ? void 0 : response.data) === null || _j === void 0 ? void 0 : _j.profileId);
        setProfileIdFromSession((_k = response === null || response === void 0 ? void 0 : response.data) === null || _k === void 0 ? void 0 : _k.profileId);
        return response;
    }
    catch (error) {
        // TODO error handling
        return false;
    }
});
export const generateRandomUsername = () => {
    const prefix = "user";
    const randomNumbers = Math.floor(Math.random() * 100000); // Generates random number between 0 and 99999
    const username = `${prefix}${randomNumbers}`;
    return username;
};
