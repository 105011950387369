import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { debounce } from "helpers/GeneralFunctions";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import clsx from "clsx";
import styles from "./SearchInput.module.scss";
const getPreviousQueries = () => {
    const queries = localStorage.getItem("previousQueries");
    return queries ? JSON.parse(queries) : [];
};
const addPreviousQuery = (query) => {
    const queries = getPreviousQueries();
    if (!queries.includes(query)) {
        queries.unshift(query);
        localStorage.setItem("previousQueries", JSON.stringify(queries.slice(0, 5))); // Store up to 5 previous queries
    }
};
const clearPreviousQueries = () => {
    localStorage.removeItem("previousQueries");
};
const SearchInput = ({ fetchResults, focused = false, results, selectedOption }) => {
    const [query, setQuery] = React.useState("");
    const [isFocused, setIsFocused] = React.useState(focused);
    const [previousQueries, setPreviousQueries] = React.useState(getPreviousQueries());
    React.useEffect(() => {
        fetchResults(query);
    }, [selectedOption]);
    React.useEffect(() => {
        setPreviousQueries(getPreviousQueries());
    }, []);
    const debouncedFetchResults = React.useCallback(debounce((searchQuery) => {
        fetchResults(searchQuery);
    }, 500), [fetchResults]);
    const handleChange = React.useCallback((e) => {
        const value = e.target.value;
        setQuery(value);
        debouncedFetchResults(value);
    }, [debouncedFetchResults]);
    const handleClear = () => {
        setQuery("");
        fetchResults("");
    };
    const handleFocus = () => {
        setIsFocused(true);
    };
    const handleBlur = React.useCallback(() => {
        // Add small delay(set as a macro task) to allow interactions before focus state changes
        setTimeout(() => {
            setIsFocused(false);
            if (query) {
                addPreviousQuery(query);
                setPreviousQueries(getPreviousQueries());
            }
        });
    }, [query]);
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleBlur();
        }
    };
    const handleSelectQuery = (selectedQuery) => {
        setQuery(selectedQuery);
        fetchResults(selectedQuery);
    };
    const handleClearHistory = () => {
        clearPreviousQueries();
        setPreviousQueries([]);
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles.searchBlock }, { children: _jsxs("div", Object.assign({ className: clsx(styles.search, { [styles.active]: isFocused }) }, { children: [_jsx(ImageFromCDN, { alt: "Search", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/charm-search.svg", overrideStyles: styles.searchIcon, style: { color: "transparent", stroke: isFocused ? "#636363" : "#c3c3c3" }, width: 16 }), _jsx("input", { type: "text", name: "searchInput", placeholder: "Search competitions and events", onChange: handleChange, onFocus: handleFocus, onKeyDown: handleKeyDown, onBlur: handleBlur, autoFocus: isFocused, value: query, autoComplete: "off" }), query && _jsx("span", { className: styles.clear, onClick: handleClear })] })) })), typeof results === "number" &&
                _jsx("div", Object.assign({ className: styles.results }, { children: results ? `${results} Results` : "No Events Found." })), (isFocused || query) && previousQueries.length > 0 && (_jsxs("div", Object.assign({ className: styles.previousQueriesContainer }, { children: [_jsxs("div", Object.assign({ className: styles.previousQueriesHeader }, { children: [_jsx("span", { children: "RECENT" }), _jsx("span", Object.assign({ className: styles.clearHistory, onClick: handleClearHistory }, { children: "Clear" }))] })), _jsx("ul", Object.assign({ className: styles.previousQueriesList }, { children: previousQueries.slice(0, 5).map((prevQuery, index) => (_jsxs("li", Object.assign({ onMouseDown: () => { handleSelectQuery(prevQuery); }, className: styles.previousQueryItem }, { children: [_jsx("span", Object.assign({ className: styles.previousQueryIcon }, { children: _jsx(ImageFromCDN, { alt: "Search", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/charm-search.svg", style: { color: "transparent" }, width: 16 }) })), prevQuery] }), index))) }))] })))] }));
};
export default SearchInput;
