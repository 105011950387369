import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col, Skeleton } from "antd";
import clsx from "clsx";
// styles
import styles from "./Skeleton.module.scss";
const SkeletonOutcomesSports = () => {
    return (_jsx("div", Object.assign({ className: styles.wrapper }, { children: [1, 2, 3, 4].map((n) => {
            return (_jsx("div", Object.assign({ style: { marginTop: 10 } }, { children: _jsxs(Row, Object.assign({ gutter: [16, 8] }, { children: [_jsx(Col, Object.assign({ span: 24 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", className: clsx(styles.paddingTopSmall, styles.maxHeightSmall) }) })), n !== 2 && n !== 4 &&
                            _jsxs(_Fragment, { children: [_jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightLarge }) })), _jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightLarge }) })), _jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightLarge }) }))] }), n === 2 &&
                            _jsxs(_Fragment, { children: [_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightLarge }) })), _jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightLarge }) }))] }), n === 4 &&
                            _jsxs(_Fragment, { children: [_jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightLarge }) })), _jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightLarge }) })), _jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightLarge }) })), _jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightLarge }) })), _jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightLarge }) })), _jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightLarge }) }))] })] })) }), `skeleton-odds-${n}`));
        }) })));
};
export default SkeletonOutcomesSports;
