import { jsx as _jsx } from "react/jsx-runtime";
import { Row, Col } from "antd";
import styles from "./LeagueSingleMarketFeatured.module.scss";
export const DisplayHeader = ({ keys, sport, sportId, displayRule }) => {
    let defaultKeys = ["HOME", "DRAW", "AWAY"];
    if ((sportId !== null && sportId !== void 0 ? sportId : sport.id) === 3 ||
        (sportId !== null && sportId !== void 0 ? sportId : sport.id) === 12) {
        if ((keys === null || keys === void 0 ? void 0 : keys.length) === 2) {
            defaultKeys = ["W1", "W2"];
        }
        else {
            defaultKeys = ["W1", "DRAW", "W2"];
        }
    }
    else {
        if (displayRule === "DEFAULT") {
            defaultKeys = ["HOME", "AWAY"];
        }
        else {
            defaultKeys = ["HOME", "DRAW", "AWAY"];
        }
    }
    let columns;
    if ((keys === null || keys === void 0 ? void 0 : keys.length) && (sportId !== null && sportId !== void 0 ? sportId : sport.id) !== 3 && (sportId !== null && sportId !== void 0 ? sportId : sport.id) !== 12) {
        columns = keys.map((k) => {
            return _jsx(Col, Object.assign({ span: 8, className: styles.oddLabel }, { children: k }), `column_${k}`);
        });
    }
    else {
        columns = defaultKeys.map((k) => {
            return _jsx(Col, Object.assign({ span: 8, className: styles.oddLabel }, { children: k }), `column_${k}`);
        });
    }
    return (_jsx(Row, Object.assign({ justify: "center", align: "middle" }, { children: columns })));
};
