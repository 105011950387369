import { toFixed } from "./GeneralFunctions";
export const getBetCombinations = (bets, combinations) => {
    const combinedBets = [];
    const betsAccum = [];
    const start = 0;
    const updateBets = (bets, combinations, start, betsAccum, combinedBets) => {
        if (combinations === 0) {
            const betsAccumSlice = betsAccum.slice();
            combinedBets.push({
                legs: betsAccumSlice,
                id: betsAccumSlice.reduce((acc, item) => acc + "," + String(item.outcomeId), "")
            });
            return;
        }
        for (let i = start; i <= bets.length - combinations; i++) {
            betsAccum.push(bets[i]);
            updateBets(bets, combinations - 1, i + 1, betsAccum, combinedBets);
            betsAccum.pop();
        }
    };
    updateBets(bets, combinations, start, betsAccum, combinedBets);
    return combinedBets;
};
export const calculateCombinations = (bets, combinations) => {
    // Calculate the factorial of a number
    const factorial = (n) => {
        if (n === 0 || n === 1)
            return 1;
        let result = 1;
        for (let i = 2; i <= n; i++) {
            result *= i;
        }
        return result;
    };
    // Calculate the combination (n choose k)
    const combination = (n, k) => {
        return factorial(n) / (factorial(k) * factorial(n - k));
    };
    // Calculate the number of combinations
    const totalCombinations = combination(bets, combinations);
    return totalCombinations;
};
export const isBetLive = ({ startDate, time }) => {
    // time format is "HH:mm"
    // Check if startDate format includes time property
    const timeExists = startDate === null || startDate === void 0 ? void 0 : startDate.includes("T");
    const matchDateTimeString = timeExists ?
        startDate :
        startDate + `${time ? " " + time : ""}`;
    const matchDateTime = new Date(matchDateTimeString);
    const currentDate = new Date();
    return currentDate > matchDateTime;
};
export const getEventsLegs = (totalEvents, totalLegs) => `${totalEvents} Event${totalEvents > 1 ? "s" : ""} - ${totalLegs} Leg${totalLegs > 1 ? "s" : ""}`;
export const formatOdds = (odds) => {
    if (odds >= 100) {
        return Math.floor(odds);
    }
    return toFixed(odds);
};
export const hasOutrightsFromSameLeague = (bets) => {
    // Count the occurrences of each leagueId for outright and non-outright bets
    const leagueCount = {};
    for (const bet of bets) {
        if (bet.leagueId !== undefined) {
            if (!leagueCount[bet.leagueId]) {
                leagueCount[bet.leagueId] = { outright: 0, total: 0 };
            }
            if (bet.isOutrights) {
                leagueCount[bet.leagueId].outright += 1;
            }
            leagueCount[bet.leagueId].total += 1;
        }
    }
    // Check if there is at least one leagueId with at least one outright bet and at least two total bets from same league
    return Object.values(leagueCount).some(count => count.outright > 0 && count.total > 1);
};
