import { jsx as _jsx } from "react/jsx-runtime";
import { STAKE_SELECTIONS_TABS } from "enums";
import { BetSelection } from "./selection/BetSelection";
import { GroupedBetSelection } from "./groupedSelection/GroupedBetSelection";
import styles from "./BetSelections.module.scss";
export const BetSelections = ({ bets, groupedBets, selectedTab, onBetRemove, onBetChange, freeBet, showFreeBet, errors }) => {
    const isSingles = selectedTab === STAKE_SELECTIONS_TABS.SINGLES;
    const isMultiple = selectedTab === STAKE_SELECTIONS_TABS.MULTIPLE;
    return (_jsx("div", Object.assign({ className: styles.root }, { children: isMultiple && groupedBets ?
            Object.values(groupedBets).map(({ betLegs, totalOdds }, index) => (_jsx("div", Object.assign({ className: styles.selection, "data-testid": betLegs.length === 1 ? `BetSelection-${index}` : `GroupedBets-${index}` }, { children: betLegs.length === 1 ?
                    (_jsx(BetSelection, { bets: bets, inputId: `bet-slip-selection-input-${betLegs[0].outcomeId}`, bet: betLegs[0], isSingles: isSingles, betsLength: bets.length, onBetRemove: onBetRemove, onBetChange: onBetChange, isMultiple: isMultiple, errors: errors })) :
                    (_jsx(GroupedBetSelection, { groupedBet: betLegs, totalOdds: totalOdds, onBetRemove: onBetRemove, errors: errors })) }), `bet-slip-selection-div-${betLegs[0].outcomeId}`))) :
            bets.map((bet, index) => {
                const isFirstSelection = index === 0;
                return (_jsx("div", Object.assign({ className: styles.selection, "data-testid": `BetSelection-${index}` }, { children: _jsx(BetSelection, { bets: bets, inputId: `bet-slip-selection-input-${bet.outcomeId}`, bet: bet, isSingles: isSingles, betsLength: bets.length, onBetRemove: onBetRemove, onBetChange: onBetChange, isMultiple: isMultiple, freeBet: freeBet, showFreeBet: isFirstSelection && showFreeBet, errors: errors }) }), `bet-slip-selection-div-${bet.outcomeId}`));
            }) })));
};
