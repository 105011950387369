import { slugify } from "helpers/GeneralFunctions";
export const generateEventDetailBreadcrumbData = ({ sportName, countryName, leagueName, eventName, eventId }) => {
    const routes = generateRoutesForSportsbookPages(sportName, countryName, leagueName, eventName, eventId);
    const breadcrumbsData = [
        {
            title: sportName,
            href: routes.sportRoute
        },
        {
            title: leagueName,
            href: routes.leagueRoute
        }
    ];
    if (eventName && routes.eventIdRoute) {
        breadcrumbsData.push({
            title: eventName,
            href: routes.eventIdRoute
        });
    }
    return breadcrumbsData;
};
export const generateRoutesForSportsbookPages = (sportName, countryName, leagueName, eventName, eventId) => {
    const sportRoute = slugify(sportName === null || sportName === void 0 ? void 0 : sportName.toLowerCase());
    const countryRoute = slugify(countryName === null || countryName === void 0 ? void 0 : countryName.toLowerCase());
    const leagueRoute = slugify(leagueName === null || leagueName === void 0 ? void 0 : leagueName.toLowerCase());
    const eventRoute = eventName && slugify(eventName === null || eventName === void 0 ? void 0 : eventName.toLowerCase());
    const eventIdRoute = eventId;
    return {
        sportRoute: `/${sportRoute}`,
        countryRoute: `/${sportRoute}/${countryRoute}`,
        leagueRoute: `/${sportRoute}/${countryRoute}/${leagueRoute}`,
        eventRoute: eventRoute && `/${sportRoute}/${countryRoute}/${leagueRoute}/${eventRoute}`,
        eventIdRoute: eventRoute && eventIdRoute ?
            `/${sportRoute}/${countryRoute}/${leagueRoute}/${eventRoute}/${eventIdRoute}` :
            undefined
    };
};
