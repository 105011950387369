export const FILTERS = {
    FEATURED: "featured",
    ALLLIVE: "all_live",
    AZ: "a-z",
    LIVE: "live",
    UPCOMING: "upcoming",
    COUPONS: "coupons",
    TODAY: "today",
    TOMORROW: "tomorrow",
    HOURS_4: "4hours",
    HOURS_12: "12hours",
    WEEKEND: "weekend"
};
