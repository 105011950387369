import dayjs from "dayjs";
// This function returns a dayjs date based on a given period
export const getDateByPeriod = (value, exact) => {
    var _a, _b, _c, _d, _e;
    const isFutureDate = (_a = value === null || value === void 0 ? void 0 : value.toLowerCase()) === null || _a === void 0 ? void 0 : _a.startsWith("next");
    // The \D metacharacter matches non-digit characters
    const amount = Number((_c = (_b = value === null || value === void 0 ? void 0 : value.toLowerCase()) === null || _b === void 0 ? void 0 : _b.replace("next", "")) === null || _c === void 0 ? void 0 : _c.replace(/\D/g, ""));
    const period = amount && ((_e = (_d = value === null || value === void 0 ? void 0 : value.toLowerCase()) === null || _d === void 0 ? void 0 : _d.replace("next", "")) === null || _e === void 0 ? void 0 : _e.replace(/[^a-zA-Z]+/g, ""));
    if (!amount)
        return null;
    // returns a complete date and time (it considers hours, minutes, seconds, milliseconds and timezone)
    if (exact) {
        if (isFutureDate)
            return dayjs().add(amount, period);
        return dayjs().subtract(amount, period);
    }
    return isFutureDate ?
        dayjs().add(amount, period).startOf("date") :
        dayjs().subtract(amount, period).startOf("date");
};
export const getDateByRange = (value) => {
    const now = dayjs();
    let from, to;
    if (!value.match(/\d/)) {
        return {};
    }
    const matchResult = value.match(/\d+/);
    const result = matchResult ? matchResult[0] : "";
    const numberOfDays = parseInt(result);
    if (!isNaN(numberOfDays)) {
        from = now.subtract(numberOfDays, "day").startOf("day");
        to = now.endOf("day");
    }
    return {
        from: from === null || from === void 0 ? void 0 : from.toISOString(),
        to: to === null || to === void 0 ? void 0 : to.toISOString()
    };
};
export const defaultRangeFiltersSelectedOption = "30days";
// This function returns a matched period, given a data range
export const isRangeInPeriods = (periods, range, exact) => {
    let defaultsTo = "byDate";
    periods.forEach(option => {
        let startDate;
        let endDate;
        if (option.startsWith("next")) {
            startDate = dayjs().format("YYYYMMDD");
            endDate = dayjs(getDateByPeriod(option, exact)).format("YYYYMMDD");
        }
        else {
            startDate = dayjs(getDateByPeriod(option, exact)).format("YYYYMMDD");
            endDate = exact ? dayjs().add(1, "day").format("YYYYMMDD") : dayjs().format("YYYYMMDD");
        }
        if (startDate &&
            String(range.from) === startDate &&
            String(range.to) === endDate)
            defaultsTo = option;
    });
    return defaultsTo;
};
export const getEventDetailDate = (eventDate) => {
    const date = dayjs(eventDate);
    return {
        eventDate: date.format("ddd D MMM"),
        eventTime: date.format("HH:mm")
    };
};
export const sortEventsByDates = (events) => {
    const sorted = events.reduce((acc, match) => {
        const matchDate = match.startDate.split("T")[0];
        if (!acc[matchDate]) {
            acc[matchDate] = [];
        }
        acc[matchDate].push(match);
        return acc;
    }, {});
    return sorted;
};
export const getEventStartDay = (date) => {
    const today = new Date().toISOString().split("T")[0];
    const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split("T")[0];
    const formattedDate = `${date.split("-")[2]}.${date.split("-")[1]}.${date.split("-")[0]} ` +
        `${new Date(date).toLocaleDateString("en-US", { weekday: "short" })}`;
    let heading;
    if (date === today) {
        heading = "Starting Soon";
    }
    else if (date === tomorrow) {
        heading = "Tomorrow";
    }
    else {
        heading = formattedDate;
    }
    return heading;
};
