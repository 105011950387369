import { SPORT } from "enums";
// This function is used to say that we shouldn't present the inPlay information on certain sports
// This can happen for a period of time, while we sort out a few things with third parties
export const isInPlayBlacklistedSport = (sportId) => {
    const blacklistedSports = [SPORT.BOXING];
    return blacklistedSports.includes(sportId);
};
// Some sports don't require a timeCounter nor countdown, so then can be whitelisted here
// Which means that we would ignore its secondsLeft information
export const sportsWhereCounterIsNotRequired = (sportId) => {
    const listOfSports = [
        SPORT.TENNIS,
        SPORT.VOLLEYBALL,
        SPORT.TABLETENNIS
    ];
    return listOfSports.includes(sportId);
};
