import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import clsx from "clsx";
import styles from "./Accordion.module.scss";
export const Accordion = ({ title, children, classes, Header, open, withArrow = true }) => {
    const [isOpen, setIsOpen] = React.useState(open !== undefined ? open : true);
    const handleClick = () => {
        setIsOpen(!isOpen);
    };
    return (_jsxs("div", Object.assign({ className: clsx(styles.root, classes === null || classes === void 0 ? void 0 : classes.root) }, { children: [_jsxs("div", Object.assign({ className: styles.header, onClick: handleClick }, { children: [Header && _jsx(Header, { open: isOpen }), title && _jsx("h5", Object.assign({ className: styles.title }, { children: title })), withArrow &&
                        _jsx("div", { style: {
                                transform: isOpen ? "rotate(180deg)" : "rotate(0deg)"
                            }, className: clsx(styles.dropdownIcon, classes === null || classes === void 0 ? void 0 : classes.dropdownIcon, (classes === null || classes === void 0 ? void 0 : classes.dropdownIconActive) && {
                                [classes.dropdownIconActive]: isOpen
                            }) })] })), _jsx("div", Object.assign({ className: clsx(styles.contentContainer, classes === null || classes === void 0 ? void 0 : classes.content, {
                    [styles.open]: isOpen
                }) }, { children: children }))] })));
};
