// Map outcome names for Over 2.5 => +2.5 and Over 2.5 => -2.5
export const mapOutcomeName = (name) => {
    if (name === null || name === void 0 ? void 0 : name.includes("Over ")) {
        return name.replace("Over ", "");
    }
    else if (name === null || name === void 0 ? void 0 : name.includes("Under ")) {
        return name.replace("Under ", "");
    }
    return name;
};
