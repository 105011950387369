import React from "react";
export const useDisableBodyScroll = (disable) => {
    React.useEffect(() => {
        if (disable) {
            const styleElement = document.createElement("style");
            styleElement.innerHTML = `
        html, body {
          overflow: hidden;
          width: 100%;
          touch-action: none;
        }
        body {
          height: 100% !important;
        }
      `;
            document.body.appendChild(styleElement);
            return () => {
                document.body.removeChild(styleElement);
            };
        }
    }, [disable]);
};
