import { documentDetails as singleEventInputDocumentDetails } from "pages/documentation/components/OddsSingleEventInput";
const leagueMatchesBymarketDocumentDetails = {
    events: [
        {
            event: "bet-slip-reuse-bets",
            type: "listener",
            data: [
                `The component details object is emitted on reuse bets selections.`,
                `detail: { 
          bets?: string[]
        }`
            ]
        },
        {
            event: `bet-slip-bet-remove`,
            type: "listener",
            data: [
                "This event is emitted when user removes bet from the betslip."
            ]
        },
        {
            event: "bet-slip-clear-bets",
            type: "listener",
            data: [
                "This event is emitted when user clears betslip."
            ]
        },
        {
            event: "matches-bymarket-input-details",
            type: "listener",
            data: ["When add to betslip is clicked, the component emits its data."]
        },
        {
            event: "match-event-details",
            type: "listener",
            data: [
                "When the match row is clicked, an event is emitted containing the match's information."
            ]
        },
        {
            event: "match-more-markets",
            type: "listener",
            data: ["When the more markets option is clicked, an event is emitted."]
        }
    ]
};
const tagsListDocumentDetails = {
    events: [{
            event: "selected-tag-details",
            type: "emit",
            data: ["-"]
        }]
};
const tabsHeaderDocumentDetails = {
    events: [{
            event: "selected-tab",
            type: "listener",
            data: [
                "Each time a new tab is selected, the state gets updated.",
                "This is transparent to users."
            ]
        }]
};
// TODO: update dynamic events
const dropdownGenericDocumentDetails = {
    events: [{
            event: "name-selected-option",
            type: "emit",
            data: ["-"]
        }]
};
const datePickerDocumentDetails = {
    events: [{
            event: "selected-date",
            type: "emit",
            data: [
                `Each time selected date changed, event gets emitted with details:`,
                `{
        date: string;
        year: number;
        month: number;
        day: number;
        dayOfWeek: {
          index: number;
          name: string;
        };
        weekOfYear: number;
        iso: {
          start: string;
          end: string;
        };
        timestamps: {
          start: number;
          end: number;
        };
      }`
            ]
        }]
};
export { singleEventInputDocumentDetails, leagueMatchesBymarketDocumentDetails, tagsListDocumentDetails, tabsHeaderDocumentDetails, dropdownGenericDocumentDetails, datePickerDocumentDetails };
