import { jsx as _jsx } from "react/jsx-runtime";
import { Table, Typography } from "antd";
import { v4 as uuidv4 } from "uuid";
// import Code from "components/base/editor/Code";
const { Text } = Typography;
const columns = [
    {
        title: "Event",
        dataIndex: "event",
        key: "event-" + uuidv4(),
        width: 145,
        render: (text) => _jsx(Text, Object.assign({ copyable: true, style: { color: "blue" } }, { children: text }))
    },
    {
        title: "Type",
        dataIndex: "type",
        key: "type-" + uuidv4(),
        width: 85
    },
    {
        title: "Description",
        dataIndex: "data",
        key: "data-" + uuidv4(),
        render: (text, record) => Array.isArray(text) ?
            text.map((item) => {
                // TODO: Test if element or text, and render it accordingly
                // TODO: If JSON format, then use a JSON visualiser
                // TODO: If code, then use a visualiser
                return _jsx("div", { children: item }, `event-${record.event}-data-${uuidv4()}`);
            }) :
            text
    }
];
const EventsTable = ({ data }) => {
    const dataSource = [...data];
    const uuid = uuidv4();
    return (_jsx("div", { children: _jsx(Table, { rowKey: "key", columns: columns, dataSource: dataSource, pagination: false }, `events-table-table-${uuid}`) }, `events-table-div-${uuid}`));
};
export default EventsTable;
