import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Col, Skeleton, Space } from "antd";
// styles
import styles from "./Skeleton.module.scss";
const SkeletonOutcomesEsports = () => {
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx(Row, Object.assign({ className: styles.headerMedium }, { children: _jsx(Col, Object.assign({ span: 24 }, { children: _jsxs(Space, Object.assign({ size: "middle" }, { children: [_jsx(Skeleton.Input, { active: true, size: "small", className: styles.maxHeightLarge }), _jsx(Skeleton.Input, { active: true, size: "small", className: styles.maxHeightLarge }), _jsx(Skeleton.Input, { active: true, size: "small", className: styles.maxHeightLarge })] })) })) })), [1, 2, 3].map((n) => {
                return (_jsx("div", Object.assign({ style: { marginTop: 10 } }, { children: _jsxs(Row, Object.assign({ gutter: [16, 8] }, { children: [_jsx(Col, Object.assign({ span: 24 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightSmall }) })), _jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightLarge }) })), n === 2 &&
                                _jsxs(_Fragment, { children: [_jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightLarge }) })), _jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightLarge }) }))] }), _jsx(Col, Object.assign({ span: 12 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.maxHeightLarge }) }))] })) }), `skeleton-odds-${n}`));
            })] })));
};
export default SkeletonOutcomesEsports;
