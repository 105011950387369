import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import { Tabs } from "./MyBets";
import clsx from "clsx";
import { BetAccordion } from "components/base/betAccordion/BetAccordion";
import { BET_STATUS, RESULT_STATUS } from "enums";
import styles from "./MyBets.module.scss";
export const SingleBet = ({ selectedTab, bet, playerId, productId, betslipConfirmation, cashout, open }) => {
    const { statusId } = bet;
    const AccordionHeaderStatus = ({ status, resultStatusId }) => {
        const isLive = selectedTab === Tabs.Live;
        const isResulted = statusId === BET_STATUS.RESULTED;
        const isCashedOut = statusId === BET_STATUS.CASHED_OUT;
        const isCancelled = statusId === BET_STATUS.CANCELLED;
        const isWon = resultStatusId === RESULT_STATUS.WON;
        const isRefunded = resultStatusId === RESULT_STATUS.REFUNDED;
        return (_jsx(_Fragment, { children: !isLive && (_jsxs("div", Object.assign({ className: clsx(styles.headerStatus, {
                    [styles.won]: isWon && isResulted,
                    [styles.refunded]: (isRefunded && isResulted) || isCancelled,
                    [styles.cashedOut]: isCashedOut
                }) }, { children: [isWon && isResulted && _jsx(ImageFromCDN, { alt: "Won", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/double-tick-white.png", width: 15, height: 10 }), ((isRefunded && isResulted) || isCancelled) && _jsx(ImageFromCDN, { alt: "Refunded", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/currency_exchange.png", size: 15 }), isCashedOut && _jsx(ImageFromCDN, { alt: "Cashed Out", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/exit_to_app.svg", size: 15 }), isCancelled ? "Refunded" : !isResulted && !isCashedOut ? "Unsettled" : status] }))) }));
    };
    return (_jsx(BetAccordion, { selectedTab: selectedTab, bet: bet, HeaderStatus: AccordionHeaderStatus, playerId: playerId, productId: productId, betslipConfirmation: betslipConfirmation, cashout: cashout, open: open }));
};
