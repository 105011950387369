var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { LocalStorage } from "helpers/Storage";
import SkeletonSportsEvents from "components/base/skeleton/SportsEvents";
import { SUBSCRIBE_LIVE_EVENT_COUNTS } from "v2/graphql/subscriptions";
import { useSubscribeByQuery } from "helpers/graphql/useSubscribeByQuery";
import { FILTERS } from "helpers/Sports/Filters";
import { Featured } from "./filteredComponents/Featured";
import { TodaysPopular } from "./filteredComponents/TodaysPopular";
import { LiveGroupedBySports } from "./filteredComponents/LiveGroupedBySports";
import { AZ } from "./filteredComponents/AZ/AZ";
import { getSportLabelById } from "helpers/Sports/LeagueFunctions";
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
const LeagueSingleMarketFeatured = ({ productId, data, autoLoadsNextData = false, noDataOptions, defaultSportId }) => {
    var _a, _b, _c;
    const [sportsIcons, setSportsIcons] = React.useState(LocalStorage.get("sportsIcons") && []);
    let allLiveFilters = [];
    if (sportsIcons) {
        allLiveFilters = [
            { id: -1, name: "All", disabled: false, shouldShowMore: false },
            ...(_a = structuredClone(sportsIcons)) === null || _a === void 0 ? void 0 : _a.map((sportIcon) => {
                return {
                    id: sportIcon === null || sportIcon === void 0 ? void 0 : sportIcon.id,
                    name: sportIcon === null || sportIcon === void 0 ? void 0 : sportIcon.name,
                    disabled: false,
                    shouldShowMore: false
                };
            })
        ];
    }
    const dispatchUpdateTagsListSelection = (tag) => {
        const updateTagsListSelection = new CustomEvent("update-selected-tags-list", {
            detail: {
                tag,
                source: "tags-list-navigation-featured"
            },
            bubbles: true
        });
        window.dispatchEvent(updateTagsListSelection);
    };
    // these states should only be used, when data is not provided
    const [isLoading, setIsLoading] = React.useState((data === null || data === void 0 ? void 0 : data.length) === 0);
    const [isFinalDataLoaded, setIsFinalDataLoaded] = React.useState(false);
    const [error, setError] = React.useState();
    const [filters, setFilters] = React.useState();
    const [lastRequest, setLastRequest] = React.useState();
    const [lastSelectedMarket, setLastSelectedMarket] = React.useState();
    const [originalLeagues, setOriginalLeagues] = React.useState([]);
    const [marketTypeConfigs, setMarketTypeConfigs] = React.useState([]);
    const [finalData, setFinalData] = React.useState(data);
    const [skipTake, setSkipTake] = React.useState({ skip: 0, take: 50 });
    const [isFullyLoaded, setIsFullyLoaded] = React.useState(false);
    const [defaultOutcomes, setDefaultOutcomes] = React.useState((_b = LocalStorage.get("betsDetails")) === null || _b === void 0 ? void 0 : _b.map((bet) => bet.outcomeId));
    const [isBBEnabled, setIsBBEnabled] = React.useState(false);
    const [displayRule, setDisplayRule] = React.useState("THREE_WAY");
    const [prevSport, setPrevSport] = React.useState();
    const [sport, setSport] = React.useState({ id: defaultSportId, label: getSportLabelById(defaultSportId) });
    // when accessing specific pages directly, we might not have the filters in place
    const [prevTag, setPrevTag] = React.useState();
    React.useEffect(() => {
        setSkipTake({ skip: 0, take: 50 });
    }, [filters]);
    React.useEffect(() => {
        const fetchClientData = () => {
            var _a;
            if (!((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL))
                return;
            axios
                .get(`${process.env.REACT_APP_GATEWAY_API_URL}/client/config`)
                .then((res) => {
                var _a;
                const { isBetBuilderEnabled } = (_a = res.data) !== null && _a !== void 0 ? _a : {};
                setIsBBEnabled(isBetBuilderEnabled);
            })
                .catch((e) => {
                console.log(e);
            });
        };
        fetchClientData();
    }, []);
    React.useEffect(() => {
        const eventsLoading = new CustomEvent("events-loading", {
            detail: {
                isLoading: isLoading || (!isFinalDataLoaded && skipTake.skip === 0)
            },
            bubbles: true
        });
        window.dispatchEvent(eventsLoading);
    }, [isLoading, isFinalDataLoaded, skipTake]);
    React.useEffect(() => {
        if ((data === null || data === void 0 ? void 0 : data.length) === 0) {
            const navigationDetailsHandler = (e) => {
                var _a, _b, _c, _d;
                e.preventDefault();
                // in case we are navigating from the all live to a different sport
                // generaly by clicking on the show more button
                if ((prevSport === null || prevSport === void 0 ? void 0 : prevSport.id) < 0 &&
                    (sport === null || sport === void 0 ? void 0 : sport.id) > 0 &&
                    !e.detail.id &&
                    (prevTag === null || prevTag === void 0 ? void 0 : prevTag.id) === ((_a = filters === null || filters === void 0 ? void 0 : filters.tag) === null || _a === void 0 ? void 0 : _a.id)) {
                    dispatchUpdateTagsListSelection(filters.tag);
                    return;
                }
                // resets the skip and take information when tag changes
                if (((_b = filters === null || filters === void 0 ? void 0 : filters.tag) === null || _b === void 0 ? void 0 : _b.id) !== ((_c = e.detail.emitModel.tag) === null || _c === void 0 ? void 0 : _c.id) || (filters === null || filters === void 0 ? void 0 : filters.serie) !== ((_d = e.detail.emitModel) === null || _d === void 0 ? void 0 : _d.serie)) {
                    setIsFullyLoaded(false);
                    setSkipTake({ skip: 0, take: 50 });
                }
                setFilters(e.detail.emitModel);
            };
            const updateSelectedBetsHandler = (e) => {
                var _a, _b, _c;
                !((_a = e.detail) === null || _a === void 0 ? void 0 : _a.isRemoveBet) && setMarketTypeConfigs([]);
                setDefaultOutcomes([...(_c = (_b = e.detail) === null || _b === void 0 ? void 0 : _b.selectedBets) === null || _c === void 0 ? void 0 : _c.map((bet) => bet === null || bet === void 0 ? void 0 : bet.outcomeId)]);
            };
            const selectedExternalOutcomeDetails = (e) => {
                var _a;
                e.preventDefault();
                const betsDetails = (_a = LocalStorage.get("betsDetails")) === null || _a === void 0 ? void 0 : _a.map((bet) => bet.outcomeId);
                if (e.detail.operation === "unselect") {
                    setDefaultOutcomes([...betsDetails.filter(outcome => outcome !== e.detail.outcomeId)]);
                }
                else if (e.detail.operation === "select") {
                    if (e.detail.isOpen) {
                        setDefaultOutcomes([
                            ...betsDetails.filter((outcome) => outcome !== e.detail.outcomeId),
                            e.detail.outcomeId
                        ]);
                    }
                }
            };
            const matchesBymarketInputHandler = (e) => {
                setDefaultOutcomes(outcomes => {
                    if (e.detail.operation === "select") {
                        if (e.detail.isOpen) {
                            return [...outcomes, e.detail.outcomeId];
                        }
                        return outcomes;
                    }
                    else {
                        return [...outcomes.filter(outcome => outcome !== e.detail.outcomeId)];
                    }
                });
            };
            const handleClearBets = () => { setDefaultOutcomes([]); };
            const selectedIconTabHandler = (e) => {
                const { type, id, label } = e.detail;
                const prevSportAux = structuredClone(sport);
                const sportLabel = label || getSportLabelById(id);
                if (e.detail.id !== (prevSportAux === null || prevSportAux === void 0 ? void 0 : prevSportAux.id))
                    setPrevSport({ id, label: sportLabel });
                type === "sports-navigation" && setSport({ id, label: sportLabel });
                // updates the tag when dealing with all live events grouped by sports
                const newFilters = structuredClone(filters);
                if (e.detail.id === -1 && (newFilters === null || newFilters === void 0 ? void 0 : newFilters.tag)) {
                    newFilters.tag = {
                        id: FILTERS.ALLLIVE,
                        name: "Live",
                        source: "tags-list-navigation-featured"
                    };
                    setFilters(Object.assign(Object.assign({}, filters), { tag: newFilters.tag }));
                }
                else if (e.detail.id === -2 && (newFilters === null || newFilters === void 0 ? void 0 : newFilters.tag)) {
                    newFilters.tag = {
                        id: FILTERS.AZ,
                        name: "All",
                        source: "tags-list-navigation-featured"
                    };
                    setFilters(Object.assign(Object.assign({}, filters), { tag: newFilters.tag }));
                }
                else if (prevSportAux.id < 0 &&
                    e.detail.id > 0 &&
                    (newFilters === null || newFilters === void 0 ? void 0 : newFilters.tag)) {
                    newFilters.tag = {
                        id: prevTag.id,
                        name: prevTag.name,
                        source: "tags-list-navigation-featured"
                    };
                    // resets the upcoming filters
                    if (prevTag.id === FILTERS.UPCOMING) {
                        setIsFullyLoaded(false);
                        setSkipTake({ skip: 0, take: 50 });
                    }
                    setFilters(Object.assign(Object.assign({}, filters), { tag: newFilters.tag }));
                }
            };
            const handleSelectedTagDetails = (e) => {
                var _a, _b, _c;
                // TODO: Removed the || !e.detail.source for testing purposes
                if (e.detail.source === "tags-list-navigation-featured") {
                    const newFilters = structuredClone(filters);
                    setPrevTag(newFilters === null || newFilters === void 0 ? void 0 : newFilters.tag);
                    // only updates it when the tag is different
                    if (((_a = filters === null || filters === void 0 ? void 0 : filters.tag) === null || _a === void 0 ? void 0 : _a.id) !== ((_c = (_b = e.detail) === null || _b === void 0 ? void 0 : _b.tag) === null || _c === void 0 ? void 0 : _c.id)) {
                        setFilters((oldFilters) => (Object.assign(Object.assign({}, oldFilters), { tag: e.detail })));
                    }
                }
            };
            const handleUpdatedSportsIcon = (e) => {
                setSportsIcons(e.detail.icons);
            };
            window.addEventListener("selected-icon-tab-details", selectedIconTabHandler);
            window.addEventListener("matches-bymarket-input-details", matchesBymarketInputHandler);
            window.addEventListener("odds-singleevent-input-details", matchesBymarketInputHandler);
            window.addEventListener("selected-external-outcome-details", selectedExternalOutcomeDetails);
            window.addEventListener("navigation-featured-details", navigationDetailsHandler);
            window.addEventListener("update-selected-bets", updateSelectedBetsHandler);
            window.addEventListener("bet-slip-clear-bets", handleClearBets);
            window.addEventListener("updated-sports-icon", handleUpdatedSportsIcon);
            window.addEventListener("selected-tag-details", handleSelectedTagDetails);
            return () => {
                window.removeEventListener("selected-icon-tab-details", selectedIconTabHandler);
                window.removeEventListener("matches-bymarket-input-details", matchesBymarketInputHandler);
                window.removeEventListener("odds-singleevent-input-details", matchesBymarketInputHandler);
                window.removeEventListener("selected-external-outcome-details", selectedExternalOutcomeDetails);
                window.removeEventListener("navigation-featured-details", navigationDetailsHandler);
                window.removeEventListener("update-selected-bets", updateSelectedBetsHandler);
                window.removeEventListener("bet-slip-clear-bets", handleClearBets);
                window.removeEventListener("updated-sports-icon", handleUpdatedSportsIcon);
                window.removeEventListener("selected-tag-details", handleSelectedTagDetails);
            };
        }
    }, [data, filters, marketTypeConfigs, prevSport, sport, prevTag]);
    React.useEffect(() => {
        var _a;
        if (marketTypeConfigs.length) {
            const selectedConfig = marketTypeConfigs.find((marketTypeConfig) => (marketTypeConfig === null || marketTypeConfig === void 0 ? void 0 : marketTypeConfig.id) === filters.serie);
            ((_a = selectedConfig === null || selectedConfig === void 0 ? void 0 : selectedConfig.marketType) === null || _a === void 0 ? void 0 : _a.displayRule) && setDisplayRule(selectedConfig.marketType.displayRule);
        }
    }, [filters, marketTypeConfigs]);
    // should fetch 100 records on its first load, and then 50 afterwards
    const loadMoreTimes = () => {
        if (skipTake.skip === 0) {
            setSkipTake({ skip: 50, take: 50 });
        }
        else if (skipTake.skip === 50) {
            setSkipTake({ skip: 100, take: 50 });
        }
        else {
            setSkipTake({ skip: skipTake.skip + 50, take: 50 });
        }
    };
    // fetches the market type configs
    const fetchMarketTypeConfigs = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _d, _e;
        if (((_d = process.env) === null || _d === void 0 ? void 0 : _d.REACT_APP_SPORTSBOOK_API_URL) && ((_e = process.env) === null || _e === void 0 ? void 0 : _e.REACT_APP_SPORTSBOOK_API_KEY)) {
            yield axios.get("/market-type-configs")
                .then((res) => {
                setError(undefined);
                setMarketTypeConfigs(res.data);
            }).catch((e) => {
                setError(e);
            });
        }
        else {
            setError("Environment variables are not in place");
        }
        ;
    }), []);
    const amendLeagueData = (data) => {
        if ((data === null || data === void 0 ? void 0 : data.length) > 0) {
            const newOriginalLeagues = structuredClone(originalLeagues);
            data.forEach((league) => {
                const obj = newOriginalLeagues.find((obj) => (obj === null || obj === void 0 ? void 0 : obj.id) === (league === null || league === void 0 ? void 0 : league.id));
                // when the league is not in the originalLeagues array, we just need to add it
                // otherwise, identify the object and amend events only
                if (!obj) {
                    newOriginalLeagues.push(league);
                }
                else {
                    obj.events = [...obj.events, ...league.events];
                }
            });
            setOriginalLeagues(newOriginalLeagues);
        }
    };
    const fetchMatches = React.useCallback((skip = 0, take = 100) => __awaiter(void 0, void 0, void 0, function* () {
        var _f, _g, _h, _j, _k, _l, _m, _o, _p;
        if (((data === null || data === void 0 ? void 0 : data.length) === 0 &&
            ((_f = process.env) === null || _f === void 0 ? void 0 : _f.REACT_APP_GATEWAY_API_URL) &&
            ((_g = process.env) === null || _g === void 0 ? void 0 : _g.REACT_APP_SPORTSBOOK_API_KEY))) {
            if (!filters)
                return;
            const categoryId = ((_h = filters.country) === null || _h === void 0 ? void 0 : _h.id) < 0 ? `&categoryId=${(_j = filters.country) === null || _j === void 0 ? void 0 : _j.id}` : "";
            const tournamentId = ((_k = filters.league) === null || _k === void 0 ? void 0 : _k.id) < 0 ? `&tournamentId=${(_l = filters.league) === null || _l === void 0 ? void 0 : _l.id}` : "";
            // stores the requested information, before making the call
            // only makes a call to the endpoint when initialising and or the filters have changed
            // this should work as a last resource,
            // in case clients are making multiple calls (re-rendering this component unnecessarily)
            const categoryAndTournament = `${categoryId && ""}${tournamentId && ""}`;
            const skipTakeParams = `sportId=${sport.id}${categoryAndTournament}&skip=${skip}&take=${take}`;
            const timezoneOffset = new Date().getTimezoneOffset() * -1;
            let endpoint;
            // Workaround
            // TODO: Find the right solution
            if ((sport === null || sport === void 0 ? void 0 : sport.id) === -1) {
                filters.tag.id = "all_live";
                filters.tag.name = "Live";
            }
            switch ((_m = filters.tag) === null || _m === void 0 ? void 0 : _m.id) {
                case FILTERS.FEATURED:
                    endpoint = `/grouped-events/highlights?sportId=${sport.id}${categoryAndTournament}&${skipTakeParams}`;
                    break;
                case FILTERS.ALLLIVE:
                case FILTERS.AZ:
                    endpoint = "/sport-grouped-events/live";
                    break;
                case FILTERS.LIVE:
                    endpoint = `/grouped-events/live?sportId=${sport.id}`;
                    break;
                case FILTERS.UPCOMING:
                    switch ((_o = filters.timeRangeTag) === null || _o === void 0 ? void 0 : _o.id) {
                        case FILTERS.TODAY:
                            endpoint = `/grouped-events/todays?timezoneOffset=${timezoneOffset}&${skipTakeParams}`;
                            break;
                        case FILTERS.TOMORROW:
                            endpoint = `/grouped-events/tomorrows?timezoneOffset=${timezoneOffset}&${skipTakeParams}`;
                            break;
                        case FILTERS.HOURS_4:
                            endpoint = `/grouped-events/fourhours?timezoneOffset=${timezoneOffset}&${skipTakeParams}`;
                            break;
                        case FILTERS.HOURS_12:
                            endpoint = `/grouped-events/twelvehours?timezoneOffset=${timezoneOffset}&${skipTakeParams}`;
                            break;
                        case FILTERS.WEEKEND:
                            endpoint = `/grouped-events/weekend?timezoneOffset=${timezoneOffset}&${skipTakeParams}`;
                            break;
                    }
                    break;
                case FILTERS.COUPONS:
                    if ((_p = filters.tournamentTag) === null || _p === void 0 ? void 0 : _p.id) {
                        // eslint-disable-next-line max-len
                        endpoint = `/tournament-group/${filters.tournamentTag.id}/grouped-events?timezoneOffset=${timezoneOffset}&${skipTakeParams}`;
                    }
                    break;
                default:
                    break;
            }
            // when there is no defined endpoint, stop processing
            if (!endpoint) {
                setOriginalLeagues(undefined);
                setLastRequest(undefined);
                return;
            }
            if (lastRequest !== endpoint) {
                setLastRequest(endpoint);
                setLastSelectedMarket(filters === null || filters === void 0 ? void 0 : filters.serie);
                !isLoading && skip === 0 && setIsLoading(true);
                yield axios.get(endpoint)
                    .then((res) => {
                    if (sport.id !== -1) {
                        const dataWithReplacedMarketNames = res.data.map((league) => {
                            var _a, _b, _c, _d;
                            let newLeagueEvents;
                            // when the selected tag is featured
                            if ((((_a = filters.tag) === null || _a === void 0 ? void 0 : _a.id) === FILTERS.FEATURED ||
                                ((_b = filters.tag) === null || _b === void 0 ? void 0 : _b.id) === FILTERS.UPCOMING ||
                                ((_c = filters.tag) === null || _c === void 0 ? void 0 : _c.id) === FILTERS.COUPONS ||
                                ((_d = filters.tag) === null || _d === void 0 ? void 0 : _d.id) === FILTERS.LIVE)) {
                                newLeagueEvents = league === null || league === void 0 ? void 0 : league.events.map((event) => {
                                    var _a;
                                    const newMarkets = (_a = event === null || event === void 0 ? void 0 : event.markets) === null || _a === void 0 ? void 0 : _a.map((market) => {
                                        var _a, _b;
                                        const marketConfig = marketTypeConfigs.find((config) => config.id === market.marketTypeConfigId);
                                        if (marketConfig === null || marketConfig === void 0 ? void 0 : marketConfig.shortName)
                                            market.name = marketConfig === null || marketConfig === void 0 ? void 0 : marketConfig.shortName;
                                        if ((_a = marketConfig === null || marketConfig === void 0 ? void 0 : marketConfig.marketType) === null || _a === void 0 ? void 0 : _a.displayRule) {
                                            market.displayRule = (_b = marketConfig === null || marketConfig === void 0 ? void 0 : marketConfig.marketType) === null || _b === void 0 ? void 0 : _b.displayRule;
                                        }
                                        return market;
                                    });
                                    event.leagueName = league === null || league === void 0 ? void 0 : league.name;
                                    event.markets = newMarkets;
                                    return event;
                                });
                                league.events = newLeagueEvents;
                            }
                            return league;
                        });
                        // depending on the view
                        // at this point only upcoming is loading more data
                        if (!skip) {
                            setOriginalLeagues(dataWithReplacedMarketNames);
                        }
                        else {
                            // verify we fetched the necessary data and there is room to fetch more
                            let countEvents = 0;
                            dataWithReplacedMarketNames.forEach((league) => {
                                var _a;
                                countEvents += Number((_a = league === null || league === void 0 ? void 0 : league.events) === null || _a === void 0 ? void 0 : _a.length);
                            });
                            if (countEvents < take)
                                setIsFullyLoaded(true);
                            if (countEvents > 0) {
                                amendLeagueData(dataWithReplacedMarketNames);
                            }
                        }
                    }
                    else if (sport.id === -1) { // all live events grouped by sport
                        setOriginalLeagues(res.data);
                    }
                    setIsFinalDataLoaded(false);
                }).catch((e) => {
                    setIsFinalDataLoaded(true);
                    setError(e);
                }).finally(() => {
                    setIsLoading(false);
                });
            }
        }
        else {
            setError("Environment variables are not in place");
        }
        ;
    }), [data, filters, originalLeagues, marketTypeConfigs, sport]);
    React.useEffect(() => {
        var _a, _b;
        if ((data === null || data === void 0 ? void 0 : data.length) === 0 &&
            filters &&
            ((_a = Object.keys(filters)) === null || _a === void 0 ? void 0 : _a.length) &&
            (marketTypeConfigs === null || marketTypeConfigs === void 0 ? void 0 : marketTypeConfigs.length) === 0)
            fetchMarketTypeConfigs();
        if (((data === null || data === void 0 ? void 0 : data.length) === 0 &&
            filters &&
            ((_b = Object.keys(filters)) === null || _b === void 0 ? void 0 : _b.length) &&
            (marketTypeConfigs === null || marketTypeConfigs === void 0 ? void 0 : marketTypeConfigs.length) > 0)) {
            fetchMatches(skipTake.skip, skipTake.take);
        }
    }, [data, sport, marketTypeConfigs, skipTake]);
    React.useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        if ((data === null || data === void 0 ? void 0 : data.length) === 0) {
            if ((originalLeagues && filters && !isLoading) ||
                ((originalLeagues === null || originalLeagues === void 0 ? void 0 : originalLeagues.length) > 0 && (filters === null || filters === void 0 ? void 0 : filters.serie) !== lastSelectedMarket && !isLoading)) {
                let filteredMarket;
                const tempOriginalLeagues = structuredClone(originalLeagues);
                if ((((originalLeagues === null || originalLeagues === void 0 ? void 0 : originalLeagues.length) > 0 &&
                    (((_a = filters.tag) === null || _a === void 0 ? void 0 : _a.id) === FILTERS.FEATURED ||
                        ((_b = filters.tag) === null || _b === void 0 ? void 0 : _b.id) === FILTERS.COUPONS ||
                        ((_c = filters.tag) === null || _c === void 0 ? void 0 : _c.id) === FILTERS.UPCOMING ||
                        ((_d = filters.tag) === null || _d === void 0 ? void 0 : _d.id) === FILTERS.LIVE ||
                        ((_e = filters.tag) === null || _e === void 0 ? void 0 : _e.id) === FILTERS.AZ)))) {
                    filteredMarket = tempOriginalLeagues === null || tempOriginalLeagues === void 0 ? void 0 : tempOriginalLeagues.map((league) => {
                        var _a;
                        // sometimes events is returning an empty array, so this exception was added
                        // to prevent processing from breaking until it gets updated
                        if ((_a = league === null || league === void 0 ? void 0 : league.events) === null || _a === void 0 ? void 0 : _a.length) {
                            const events = league === null || league === void 0 ? void 0 : league.events.map((event) => {
                                var _a, _b, _c, _d;
                                if (((_a = event === null || event === void 0 ? void 0 : event.markets) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                                    let selectedFilterId = (_b = marketTypeConfigs.find((marketTypeConfig) => {
                                        return marketTypeConfig.sportId === (sport === null || sport === void 0 ? void 0 : sport.id) && (marketTypeConfig === null || marketTypeConfig === void 0 ? void 0 : marketTypeConfig.isDefault);
                                    })) === null || _b === void 0 ? void 0 : _b.id;
                                    if (((_c = filters.tag) === null || _c === void 0 ? void 0 : _c.id) !== FILTERS.ALLLIVE && ((_d = filters.tag) === null || _d === void 0 ? void 0 : _d.id) !== FILTERS.AZ) {
                                        selectedFilterId = filters.serie;
                                    }
                                    const market = event === null || event === void 0 ? void 0 : event.markets.find((details) => details.marketTypeConfigId === selectedFilterId);
                                    event.market = market;
                                    delete event.markets;
                                }
                                // TODO: If market is empty, then record should not be returned
                                return event;
                            });
                            // TODO: If events are empty, then record should not be returned
                            // TODO: If league has no events, then record should not be returned
                            league.events = events;
                        }
                        else {
                            league.events = [];
                        }
                        return league;
                    });
                }
                else if (((_f = filters.tag) === null || _f === void 0 ? void 0 : _f.id) === FILTERS.ALLLIVE || ((_g = filters.tag) === null || _g === void 0 ? void 0 : _g.id) === FILTERS.AZ) {
                    filteredMarket = tempOriginalLeagues === null || tempOriginalLeagues === void 0 ? void 0 : tempOriginalLeagues.map((sport) => {
                        var _a;
                        const tournament = (_a = sport.tournamentEvents) === null || _a === void 0 ? void 0 : _a.map((tournament) => {
                            var _a, _b;
                            const events = (_b = (_a = tournament.events) === null || _a === void 0 ? void 0 : _a.filter((event) => { var _a; return ((_a = event === null || event === void 0 ? void 0 : event.markets) === null || _a === void 0 ? void 0 : _a.length) > 0; })) === null || _b === void 0 ? void 0 : _b.map((event) => {
                                var _a, _b;
                                let selectedFilterId = (_a = marketTypeConfigs.find((marketTypeConfig) => marketTypeConfig.sportId === sport.sportId && marketTypeConfig.isDefault)) === null || _a === void 0 ? void 0 : _a.id;
                                if (((_b = filters.tag) === null || _b === void 0 ? void 0 : _b.id) !== FILTERS.ALLLIVE) {
                                    selectedFilterId = filters.serie;
                                }
                                const market = event === null || event === void 0 ? void 0 : event.markets.find((details) => details.marketTypeConfigId === selectedFilterId);
                                event.market = market;
                                delete event.markets;
                                return event;
                            });
                            tournament.events = events;
                            return tournament;
                        });
                        sport.tournament = tournament;
                        return sport;
                    });
                }
                if (((_h = filters.tag) === null || _h === void 0 ? void 0 : _h.id) === FILTERS.FEATURED ||
                    ((_j = filters.tag) === null || _j === void 0 ? void 0 : _j.id) === FILTERS.COUPONS ||
                    ((_k = filters.tag) === null || _k === void 0 ? void 0 : _k.id) === FILTERS.UPCOMING) {
                    setFinalData(filteredMarket);
                    if ((skipTake === null || skipTake === void 0 ? void 0 : skipTake.skip) === 0 &&
                        (filteredMarket === null || filteredMarket === void 0 ? void 0 : filteredMarket.reduce((acc, item) => { var _a; return acc + Number((_a = item === null || item === void 0 ? void 0 : item.events) === null || _a === void 0 ? void 0 : _a.length); }, 0)) < (skipTake === null || skipTake === void 0 ? void 0 : skipTake.take)) {
                        setIsFullyLoaded(true);
                    }
                    else {
                        setIsFullyLoaded(false);
                    }
                }
                else if (((_l = filters.tag) === null || _l === void 0 ? void 0 : _l.id) === FILTERS.ALLLIVE || ((_m = filters.tag) === null || _m === void 0 ? void 0 : _m.id) === FILTERS.AZ) {
                    setFinalData(filteredMarket);
                }
                else {
                    setFinalData(filteredMarket === null || filteredMarket === void 0 ? void 0 : filteredMarket.filter((items) => { var _a; return ((_a = items === null || items === void 0 ? void 0 : items.events) === null || _a === void 0 ? void 0 : _a.length) > 0; }));
                }
                setIsFinalDataLoaded(true);
            }
            else if (error) {
                setError(error);
                setIsFinalDataLoaded(true);
            }
            else {
                setFinalData([]);
            }
        }
    }, [data, originalLeagues, filters, isLoading]);
    const dispatchEventLeagueRow = (country, league, sportId, sportName) => {
        var _a;
        const triggerEventLeagueDetails = new CustomEvent("league-event-details", {
            detail: {
                sport: { id: sportId !== null && sportId !== void 0 ? sportId : sport.id, name: sportName !== null && sportName !== void 0 ? sportName : sport.label },
                country: {
                    id: country.id,
                    name: country.name
                },
                league: {
                    id: league === null || league === void 0 ? void 0 : league.id,
                    name: league === null || league === void 0 ? void 0 : league.name
                },
                totalEvents: (_a = league.events) === null || _a === void 0 ? void 0 : _a.length
            },
            bubbles: true
        });
        window.dispatchEvent(triggerEventLeagueDetails);
    };
    const dispatchSportRow = (item) => {
        setSport(Object.assign(Object.assign({}, sport), { id: item.sportId, label: item.sportName.toLowerCase() }));
        // updates the sports navigation selection
        const updateSportsNavigationSelection = new CustomEvent("update-selected-icons-tab-header", {
            detail: {
                id: item.sportId,
                label: item.sportName.toLowerCase(),
                type: "sports-navigation"
            },
            bubbles: true
        });
        window.dispatchEvent(updateSportsNavigationSelection);
    };
    // subscribes to the live counter topic and updates
    const valueHandlerCallback = (details) => {
        const nextData = details.getLiveEventCounts;
        const processing = {
            all: 0,
            sports: {}
        };
        // processes the results by sports
        nextData.forEach((item) => {
            processing.all = Number(processing.all) + Number(item.TotalEvents);
            if (Object.prototype.hasOwnProperty.call(processing.sports, item.SportId)) {
                processing.sports[item.SportId] = Number(processing.sports[item.SportId]) + Number(item.TotalEvents);
            }
            else {
                processing.sports[item.SportId] = item.TotalEvents;
            }
        });
        if (processing) {
            allLiveFilters.map((filter) => {
                const value = processing.sports[filter === null || filter === void 0 ? void 0 : filter.id];
                if (value) {
                    if (filter.name.indexOf("(") === -1) {
                        filter.name = `${filter.name} (${String(value)})`;
                    }
                    else {
                        filter.name = `
              ${String(filter.name).substring(0, String(filter.name).indexOf("(") - 1)} (${String(value)})
            `;
                    }
                    filter.disabled = false;
                }
                else {
                    if ((filter === null || filter === void 0 ? void 0 : filter.id) !== -1)
                        filter.disabled = true;
                }
                return filter;
            });
        }
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_LIVE_EVENT_COUNTS,
        valueHandlerCallback
    });
    if (isLoading || (!isFinalDataLoaded && skipTake.skip === 0)) {
        return _jsx(SkeletonSportsEvents, {});
    }
    if (!(finalData === null || finalData === void 0 ? void 0 : finalData.length) && ((_c = filters === null || filters === void 0 ? void 0 : filters.tag) === null || _c === void 0 ? void 0 : _c.id) !== FILTERS.AZ) {
        return _jsx("esports-nodata-for-market", { message: noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.message, src: noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.src, cta: JSON.stringify(noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.cta) });
    }
    const onLeagueClick = (category, league, ...rest) => () => dispatchEventLeagueRow(category, league, ...rest);
    const onShowMoreClick = (sport) => dispatchSportRow(sport);
    const getFilteredComponent = () => {
        var _a;
        switch ((_a = filters === null || filters === void 0 ? void 0 : filters.tag) === null || _a === void 0 ? void 0 : _a.id) {
            case FILTERS.FEATURED:
                return _jsx(InfiniteScroll, Object.assign({ dataLength: finalData.reduce((acc, item) => { var _a; return acc + Number((_a = item === null || item === void 0 ? void 0 : item.events) === null || _a === void 0 ? void 0 : _a.length); }, 0), next: loadMoreTimes, hasMore: !isFullyLoaded, loader: _jsx(SkeletonSportsEvents, {}) }, { children: _jsx(Featured, { finalData: finalData, defaultOutcomes: defaultOutcomes, isLoading: isLoading, filters: filters, onLeagueClick: onLeagueClick, sport: sport, displayRule: displayRule, isBBEnabled: isBBEnabled }) }));
            case FILTERS.ALLLIVE:
                return _jsx(LiveGroupedBySports, { finalData: finalData, defaultOutcomes: defaultOutcomes, isLoading: isLoading, filters: filters, onLeagueClick: onLeagueClick, onShowMoreClick: onShowMoreClick, displayRule: displayRule, sportsIcons: sportsIcons, allLiveFilters: allLiveFilters, noDataOptions: noDataOptions, isBBEnabled: isBBEnabled });
            case FILTERS.AZ:
                return _jsx(AZ, { noDataOptions: noDataOptions });
            case FILTERS.LIVE:
                // Different data in same component
                return _jsx(Featured, { finalData: finalData, defaultOutcomes: defaultOutcomes, isLoading: isLoading, filters: filters, onLeagueClick: onLeagueClick, sport: sport, displayRule: displayRule, isBBEnabled: isBBEnabled });
            case FILTERS.COUPONS:
            case FILTERS.UPCOMING:
                return (_jsx(InfiniteScroll, Object.assign({ dataLength: finalData.reduce((acc, item) => { var _a; return acc + Number((_a = item === null || item === void 0 ? void 0 : item.events) === null || _a === void 0 ? void 0 : _a.length); }, 0), next: loadMoreTimes, hasMore: !isFullyLoaded, loader: _jsx(SkeletonSportsEvents, {}) }, { children: _jsx(TodaysPopular, { finalData: finalData, defaultOutcomes: defaultOutcomes, isLoading: isLoading, filters: filters, onLeagueClick: onLeagueClick, sport: sport, displayRule: displayRule, productId: productId, isBBEnabled: isBBEnabled, noDataOptions: noDataOptions }) })));
            default:
                return _jsx("esports-nodata-for-market", { message: noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.message, src: noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.src, cta: JSON.stringify(noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.cta) });
        }
    };
    return (_jsx("div", Object.assign({ style: { backgroundColor: "#f2f2f2", padding: "5px" } }, { children: getFilteredComponent() })));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this.data = [];
        this["default-sport-id"] = 1;
    }
    static get observedAttributes() {
        return ["data", "auto-loads-next-data", "no-data-options", "product-id", "default-sport-id"];
    }
    connectedCallback() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(LeagueSingleMarketFeatured, { productId: this["product-id"], data: this.data, autoLoadsNextData: this["auto-loads-next-data"], noDataOptions: this["no-data-options"], defaultSportId: this["default-sport-id"] }));
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        var _a;
        switch (attrName) {
            case "data":
            case "auto-loads-next-data":
            case "no-data-options":
                this[attrName] = JSON.parse(newValue);
                break;
            case "product-id":
            case "default-sport-id":
                this[attrName] = Number(newValue);
                break;
            default:
                break;
        }
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(LeagueSingleMarketFeatured, { productId: this["product-id"], data: this.data, autoLoadsNextData: this["auto-loads-next-data"], noDataOptions: this["no-data-options"], defaultSportId: this["default-sport-id"] }));
    }
}
(_a = customElements.get("esports-league-single-market-featured")) !== null && _a !== void 0 ? _a : customElements.define("esports-league-single-market-featured", Element);
