var _a;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import clsx from "clsx";
import { Grid, Row, Col } from "antd";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import { LocalStorage } from "helpers/Storage";
import { BETS_LIMIT } from "enums";
import { isOutcomeOpen } from "helpers/IsOutcomeOpen";
import { formatOdds } from "helpers/Bets";
import styles from "./Matches.module.scss";
const { useBreakpoint } = Grid;
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
const LeagueMatchesByMarket = ({ productId, sport, events, country, league, marketCount, defaultOutcomes, onlyOutcomes, marketView }) => {
    var _a;
    const screens = useBreakpoint();
    const [selectedOutcomes, setSelectedOutcome] = React.useState(defaultOutcomes !== null && defaultOutcomes !== void 0 ? defaultOutcomes : []);
    // injects default outcomes in the selectedOutcomes state, so it is highlighted
    React.useEffect(() => {
        if (defaultOutcomes && (defaultOutcomes === null || defaultOutcomes === void 0 ? void 0 : defaultOutcomes.length) > 0) {
            setSelectedOutcome(oldArray => [...oldArray, ...defaultOutcomes]);
        }
        const handleRemoveBet = (e) => {
            const { outcomeId } = e.detail;
            if (outcomeId) {
                setSelectedOutcome(oldArray => [...oldArray === null || oldArray === void 0 ? void 0 : oldArray.filter(outcome => outcome !== outcomeId)]);
            }
        };
        const handleClearBets = () => { setSelectedOutcome([]); };
        const handleReuseBets = (e) => {
            var _a;
            ((_a = e === null || e === void 0 ? void 0 : e.detail) === null || _a === void 0 ? void 0 : _a.bets) && setSelectedOutcome(e.detail.bets);
        };
        window.addEventListener("bet-slip-reuse-bets", handleReuseBets);
        window.addEventListener("bet-slip-bet-remove", handleRemoveBet);
        window.addEventListener("bet-slip-clear-bets", handleClearBets);
        return () => {
            window.removeEventListener("bet-slip-reuse-bets", handleReuseBets);
            window.removeEventListener("bet-slip-bet-remove", handleRemoveBet);
            window.removeEventListener("bet-slip-clear-bets", handleClearBets);
        };
    }, []);
    const onClick = (obj) => {
        var _a;
        const limited = ((_a = LocalStorage.get("betsDetails")) === null || _a === void 0 ? void 0 : _a.length) >= BETS_LIMIT;
        // add or remove odd id to the selected state
        let operation = "select";
        if (selectedOutcomes.includes(obj === null || obj === void 0 ? void 0 : obj.outcomeId)) {
            operation = "unselect";
            setSelectedOutcome(oldArray => [...oldArray === null || oldArray === void 0 ? void 0 : oldArray.filter(outcome => outcome !== (obj === null || obj === void 0 ? void 0 : obj.outcomeId))]);
        }
        else {
            (obj === null || obj === void 0 ? void 0 : obj.isOpen) && !limited && setSelectedOutcome(oldArray => [...oldArray, obj === null || obj === void 0 ? void 0 : obj.outcomeId]);
        }
        const triggerMatchesByMarketInputDetails = new CustomEvent("matches-bymarket-input-details", {
            detail: Object.assign(Object.assign({}, obj), { operation, isOpen: obj.isOpen && !limited }),
            bubbles: true
        });
        window.dispatchEvent(triggerMatchesByMarketInputDetails);
    };
    const dispatchEventMatchRow = (match) => {
        const triggerEventMatchDetails = new CustomEvent("match-event-details", {
            detail: {
                sport: {
                    id: sport === null || sport === void 0 ? void 0 : sport.id,
                    name: sport === null || sport === void 0 ? void 0 : sport.name
                },
                country: {
                    id: country === null || country === void 0 ? void 0 : country.id,
                    name: country === null || country === void 0 ? void 0 : country.name
                },
                league: {
                    id: league === null || league === void 0 ? void 0 : league.id,
                    name: league === null || league === void 0 ? void 0 : league.name
                },
                match: {
                    id: match.id,
                    name: match.name,
                    homeTeam: match.homeTeam,
                    awayTeam: match.awayTeam,
                    status: match.status,
                    marketName: match.marketName,
                    startDate: match.startDate,
                    time: match.time
                }
            },
            bubbles: true
        });
        window.dispatchEvent(triggerEventMatchDetails);
    };
    const dispatchEventMoreMarkets = (match) => {
        const triggerEventMatchMoreMarkets = new CustomEvent("match-more-markets", {
            detail: {
                sport: {
                    id: sport === null || sport === void 0 ? void 0 : sport.id,
                    name: sport === null || sport === void 0 ? void 0 : sport.name
                },
                country: {
                    id: country === null || country === void 0 ? void 0 : country.id,
                    name: country === null || country === void 0 ? void 0 : country.name
                },
                league: {
                    id: league === null || league === void 0 ? void 0 : league.id,
                    name: league === null || league === void 0 ? void 0 : league.name
                },
                match: {
                    id: match.id,
                    name: match.name,
                    homeTeam: match.homeTeam,
                    awayTeam: match.awayTeam,
                    status: match.status,
                    marketName: match.marketName,
                    startDate: match.startDate,
                    time: match.time
                }
            },
            bubbles: true
        });
        window.dispatchEvent(triggerEventMatchMoreMarkets);
    };
    if ((_a = Object.keys(events)) === null || _a === void 0 ? void 0 : _a.length) {
        return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ className: styles.wrapper }, { children: events.map((match, matchIndex) => {
                    return (_jsx("div", Object.assign({ className: styles.oddsRow, style: { borderBottom: ((events === null || events === void 0 ? void 0 : events.length) === matchIndex + 1) ? "" : "1px solid #f2f2f2" } }, { children: _jsx(_Fragment, { children: _jsxs(Row, Object.assign({ style: { marginLeft: 8, marginRight: 8 } }, { children: [!onlyOutcomes &&
                                        _jsxs(Col, Object.assign({ xs: 11, sm: 12, md: 12, lg: 8, xl: 8, style: { cursor: "pointer" }, onClick: () => dispatchEventMatchRow(match) }, { children: [_jsx("div", Object.assign({ style: {
                                                        fontSize: "11px",
                                                        letterSpacing: "-1%",
                                                        lineHeight: "12.89px"
                                                    } }, { children: `${match.homeTeam}` })), _jsx("div", Object.assign({ style: {
                                                        fontSize: "11px",
                                                        letterSpacing: "-1%",
                                                        lineHeight: "12.89px",
                                                        marginTop: 5
                                                    } }, { children: `${match.awayTeam}` })), _jsx("div", Object.assign({ style: {
                                                        fontSize: "9px",
                                                        letterSpacing: "2%",
                                                        lineHeight: "10.55px",
                                                        marginTop: 8
                                                    } }, { children: _jsxs(Row, Object.assign({ justify: "center", align: "middle" }, { children: [_jsx(Col, Object.assign({ flex: "30px", style: {
                                                                    fontSize: "9px",
                                                                    letterSpacing: "2%",
                                                                    lineHeight: "10.55px",
                                                                    color: "#707070"
                                                                } }, { children: match.time })), _jsx(Col, Object.assign({ flex: "auto" }, { children: match.matchStatus === "Live" &&
                                                                    _jsx("img", { src: "https://d1sg8aqjbar3ur.cloudfront.net/other/live_tv.svg", alt: "live_icon", style: { width: 12, marginTop: 2 } }) }))] })) }))] })), _jsxs(Col, Object.assign({ xs: onlyOutcomes ? 24 : 13, sm: onlyOutcomes ? 24 : 12, md: onlyOutcomes ? 24 : 12, lg: onlyOutcomes ? 24 : 16, xl: onlyOutcomes ? 24 : 16 }, { children: [_jsx(Row, Object.assign({ justify: "space-between", align: "middle" }, { children: match.outcomes.map((outcome, outcomeIndex) => {
                                                    outcome.isOpen = isOutcomeOpen(outcome === null || outcome === void 0 ? void 0 : outcome.isOpen, outcome === null || outcome === void 0 ? void 0 : outcome.statusId);
                                                    const suspended = outcome.isOpen === false;
                                                    return (_jsxs(Col, Object.assign({ flex: "auto", className: clsx(styles.odd, {
                                                            [styles.suspended]: suspended,
                                                            [styles.highlight]: selectedOutcomes.includes(outcome.id),
                                                            [styles.mobile]: screens.xs
                                                        }), onClick: () => {
                                                            onClick({
                                                                matchId: match.id,
                                                                marketName: match.marketName || match.market.name,
                                                                event: match.name,
                                                                outcomeId: outcome.id,
                                                                name: outcome.name,
                                                                odds: outcome.odds,
                                                                startDate: match.startDate,
                                                                isOpen: outcome.isOpen,
                                                                time: match.time,
                                                                order: matchIndex * 10 + outcomeIndex,
                                                                statusId: outcome === null || outcome === void 0 ? void 0 : outcome.statusId
                                                            });
                                                        } }, { children: [suspended && _jsx(ImageFromCDN, { alt: "Lock", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/lock.svg", size: 8, overrideStyles: {
                                                                    position: "absolute",
                                                                    top: "4px",
                                                                    right: "5px"
                                                                } }), marketView === "overUnder" &&
                                                                _jsx("span", Object.assign({ className: styles.line }, { children: match === null || match === void 0 ? void 0 : match.line })), suspended ? "-" : formatOdds(outcome.odds)] }), `match-${match.id}-outcome-${outcome.id}`));
                                                }) })), _jsx(Row, Object.assign({ justify: "end", align: "middle", style: { marginBottom: "10px" } }, { children: _jsxs(Col, Object.assign({ flex: "auto", className: clsx(styles.marketCount), onClick: () => dispatchEventMoreMarkets(match) }, { children: ["More +", match.marketCount, " ", _jsx("span", { children: "\u203A" })] })) }))] }))] })) }) }), `league-wrapper-${match.id}-${matchIndex}`));
                }) })) }));
    }
    // TODO: Add skeleton and error messages - WIP
    return _jsx("div", {});
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.sport = {};
        this.events = {};
        this.country = {};
        this.league = {};
        this["market-count"] = 0;
        this["default-outcomes"] = [];
        this["market-view"] = "default";
    }
    static get observedAttributes() {
        return [
            "sport",
            "events",
            "country",
            "league",
            "market-count",
            "default-outcomes",
            "only-outcomes",
            "market-view",
            "product-id"
        ];
    }
    connectedCallback() {
        createRoot(this).render(_jsx(LeagueMatchesByMarket, { productId: this["product-id"], sport: this.sport, events: this.events, country: this.country, league: this.league, marketCount: this["market-count"], defaultOutcomes: this["default-outcomes"], onlyOutcomes: this["only-outcomes"], marketView: this["market-view"] }));
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        switch (attrName) {
            case "sport":
            case "events":
            case "country":
            case "league":
            case "default-outcomes":
            case "only-outcomes":
                this[attrName] = JSON.parse(newValue);
                break;
            case "market-count":
            case "product-id":
                this[attrName] = Number(newValue);
                break;
            case "market-view":
                this[attrName] = String(newValue);
                break;
            default:
                break;
        }
    }
}
(_a = customElements.get("esports-league-matches-bymarket")) !== null && _a !== void 0 ? _a : customElements.define("esports-league-matches-bymarket", Element);
