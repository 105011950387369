const setItem = (storage, key, value) => { storage.setItem(key, value); };
const getItem = (storage, key) => storage.getItem(key);
const removeItem = (storage, key) => { storage.removeItem(key); };
const clear = (storage) => { storage.clear(); };
const has = (storage, key) => getItem(storage, key) !== null;
const set = (storage, key, value) => {
    if (typeof key === "undefined" || typeof value === "undefined") {
        return false;
    }
    if (typeof value === "object" && value !== null) {
        value = JSON.stringify(value);
    }
    try {
        setItem(storage, key, value);
        const expirationTime = new Date();
        expirationTime.setHours(expirationTime.getHours() + 24);
        setItem(storage, key + "-expire", String(expirationTime.getTime()));
        return true;
    }
    catch (e) {
        return false;
    }
};
const get = (storage, key) => {
    if (typeof key === "undefined") {
        return false;
    }
    const storedData = getItem(storage, key);
    try {
        return storedData ? JSON.parse(storedData) : storedData;
    }
    catch (e) {
        return storedData;
    }
};
const remove = (storage, key) => {
    if (typeof key === "undefined") {
        return false;
    }
    if (has(storage, key)) {
        removeItem(storage, key);
        return true;
    }
    return false;
};
export const updateStorageExpire = (storage, key) => {
    const expirationTime = get(storage, `${key}-expire`);
    if (expirationTime && new Date().getTime() > expirationTime) {
        remove(storage, `${key}-expire`);
        remove(storage, key);
    }
};
const createStorage = (storage) => ({
    set: (key, value) => set(storage, key, value),
    get: (key) => get(storage, key),
    has: (key) => has(storage, key),
    remove: (key) => remove(storage, key),
    clear: () => { clear(storage); }
});
export const LocalStorage = createStorage(localStorage);
export const SessionStorage = createStorage(sessionStorage);
