var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
// Reference: https://www.npmjs.com/package/external-svg-loader
import "external-svg-loader";
import clsx from "clsx";
import styles from "./ImageFromCDN.module.scss";
const ImageFromCDN = (_a) => {
    var _b, _c;
    var { id, url, alt, color = "#000", size, width, height, float = "center", overrideStyles, onClick } = _a, restProps = __rest(_a, ["id", "url", "alt", "color", "size", "width", "height", "float", "overrideStyles", "onClick"]);
    // Example from the internet in case we want to test the color
    // data-src="https://unpkg.com/@mdi/svg@5.9.55/svg/heart.svg"
    const additionalStyling = typeof overrideStyles === "object" ? overrideStyles : {};
    if (!url)
        return _jsx(_Fragment, {});
    if (!url.endsWith(".svg")) {
        return (_jsx("img", Object.assign({ src: url, alt: alt, className: clsx(styles.content, {
                [overrideStyles]: typeof overrideStyles === "string"
            }), style: Object.assign(Object.assign({}, additionalStyling), { color,
                float }), width: (_b = size !== null && size !== void 0 ? size : width) !== null && _b !== void 0 ? _b : "auto", height: (_c = size !== null && size !== void 0 ? size : height) !== null && _c !== void 0 ? _c : "auto", onClick: onClick }, restProps)));
    }
    ;
    return (_jsx("svg", Object.assign({ "data-src": url, "data-loading": "lazy", fill: "currentColor", className: clsx(styles.content, {
            [overrideStyles]: typeof overrideStyles === "string"
        }), style: Object.assign(Object.assign({}, additionalStyling), { color,
            float, width: size ? String(size) + "px" : width ? String(width) + "px" : "auto", height: size ? String(size) + "px" : height ? String(height) + "px" : "auto" }), onClick: onClick }, restProps), id));
};
export default ImageFromCDN;
