var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import SkeletonLoader from "components/base/skeleton/SkeletonLoader";
import clsx from "clsx";
import styles from "./ImageLoader.module.scss";
export const ImageLoader = ({ Skeleton, children }) => {
    const [loaded, setLoaded] = React.useState(false);
    const handleLoad = () => {
        setLoaded(true);
    };
    return (_jsxs(_Fragment, { children: [_jsx(SkeletonLoader, Object.assign({ ready: loaded, skeleton: _jsx(Skeleton, {}) }, { children: _jsx(_Fragment, { children: children }) })), React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    const _a = child.props, { className: existingClassName } = _a, otherProps = __rest(_a, ["className"]);
                    return React.cloneElement(child, Object.assign({ className: clsx(existingClassName, styles.hiddenImage), onLoad: handleLoad }, otherProps));
                }
                return child;
            })] }));
};
