import { jsx as _jsx } from "react/jsx-runtime";
import { SystemBetsSelection } from "./SystemBetSelection";
import styles from "./SystemBets.module.scss";
export const SystemBets = ({ bets, onStakeChange, selectedTab, inputIdWithStake }) => {
    const getBetType = (index) => {
        if (bets.length === index && index !== 1 && index !== 2 && index !== 3) {
            return "Multiple";
        }
        else {
            switch (index) {
                case 1:
                    return "Singles";
                case 2:
                    return "Double";
                case 3:
                    return "Treble";
                default:
                    return `${index} Folds`;
            }
        }
    };
    const legs = bets === null || bets === void 0 ? void 0 : bets.map(({ outcomeId, productId, odds }) => ({
        outcomeId,
        productId,
        odds
    }));
    return (_jsx("div", Object.assign({ className: styles.root, "data-testid": "SystemBets" }, { children: bets === null || bets === void 0 ? void 0 : bets.map(({ stake }, index) => (_jsx(SystemBetsSelection, { inputIdWithStake: inputIdWithStake, selectedTab: selectedTab, betIndex: bets.length - index, type: getBetType(bets.length - index), stake: stake, legs: legs, inputId: `bet-slip-system-bet-${index}`, onStakeChange: onStakeChange }, `bet-slip-system-bet-${index}`))) })));
};
