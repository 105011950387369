import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Row, Skeleton } from "antd";
import styles from "./Outrights.module.scss";
export const OutrightsSkeleton = () => {
    return (_jsxs(Row, { children: [_jsxs(Col, Object.assign({ span: 24 }, { children: [_jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.skeletonMarket, style: { height: 335, margin: 10, width: "calc(100% - 20px)" } }), _jsx("div", { style: {
                            width: "100%",
                            display: "block",
                            height: "0.5px",
                            background: "#dedede",
                            marginTop: "-6px"
                        } })] })), _jsxs(Col, Object.assign({ span: 24 }, { children: [_jsx(Skeleton.Input, { active: true, size: "small", block: true, style: { height: 335, margin: 10, width: "calc(100% - 20px)" } }), _jsx("div", { style: {
                            width: "100%",
                            display: "block",
                            height: "0.5px",
                            background: "#dedede",
                            marginTop: "-6px"
                        } })] }))] }));
};
