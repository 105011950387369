import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import { LocalStorage } from "helpers/Storage";
import clsx from "clsx";
import styles from "./BBPromo.module.scss";
export const BBPromo = ({ style, className }) => {
    const [show, setShow] = React.useState(LocalStorage.get("showBBPromo"));
    if (show === false) {
        return null;
    }
    const closeHandler = () => {
        LocalStorage.set("showBBPromo", false);
        setShow(false);
    };
    return (_jsxs("div", Object.assign({ className: clsx(styles.root, { [className]: className }), style: style, "data-testid": "BBPromo" }, { children: [_jsxs("div", Object.assign({ className: styles.leftSide }, { children: [_jsx(ImageFromCDN, { alt: "BB icon", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/BBPromo.svg", overrideStyles: styles.BBLogo, width: 50 }), _jsxs("div", Object.assign({ className: styles.text }, { children: ["You can now place multiple selections from the", _jsx("br", {}), "same event with the ", _jsx("strong", { children: "BET BUILDER." }), " Give it a try!"] }))] })), _jsx("div", Object.assign({ className: styles.rightSide, onClick: closeHandler, "data-testid": "BBPromo.closeBtb" }, { children: _jsx(ImageFromCDN, { alt: "Remove bet", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/close.svg", size: 16 }) }))] })));
};
