export const esportsSelectionsState = [
    {
        odds: 1.5,
        id: 111,
    },
    {
        odds: 2.06,
        id: 112,
    },
    {
        odds: 2.75,
        id: 113,
    },
];
export const esportsSelectionsStateChanged = [
    {
        odds: 1.7,
        id: 222,
    },
    {
        odds: 1.95,
        id: 224,
    },
    {
        odds: 2.56,
        id: 226,
    },
];
export const betslipResponse = {
    data: [
        {
            betRef: "027c4c0c-58f7-4fb9-8861-18a9e680e44e",
            index: 0,
            id: 3902,
            stake: 100,
            potentialPayout: 132,
            bonusPotentialPayout: null,
        },
        {
            betRef: "ff4e05fd-911b-402f-9dc9-0c22634eb617",
            index: 1,
            id: 3903,
            stake: 50,
            potentialPayout: 103,
            bonusPotentialPayout: null,
        },
    ],
};
export const oddsChangedError = {
    response: {
        data: {
            errors: [
                {
                    type: "OddsChangedException",
                    message: "Odds have changed",
                },
            ],
        }
    }
};
